import React, {Component} from 'react';
import {compose} from 'recompose';
import {withRouter} from 'react-router-dom';

import {withAuthorization} from '../Session';
import {withFirebase} from '../Firebase';
import firebase from 'firebase';
import 'bootstrap/dist/css/bootstrap.min.css';

const AddNotePage = () =>(
    <div>
        <AddNoteForm />
    </div>
);

class NoteFormBase extends Component{

    constructor(props) {
        super(props);

        this.state = {
          entity:null,
          entityID: '',
          note:'',
          pinnedNote:false,
          isActive:1,
          dtStamp:'',
          errormessage: '',
          loading:true,
          ...props.location.state,
        };
      }

      mySubmitHandler = (event) => {
        event.preventDefault();
        /*let age = this.state.age;
        if (!Number(age)) {
          alert("Your age must be a number");
        }*/
        this.props.firebase.notes().add({
          comment:this.state.note,
          datetime:firebase.firestore.Timestamp.now(),
          entityID:this.state.entityID,
          pinned:this.state.pinnedNote,
          isActive:1
        });

        this.setState({comment:'',pinned:''});
        //this.props.history.push('/company/'+this.state.entityID);
        this.myFormRef.reset();
      }

      componentDidMount(){
        this.setState({entityID:this.props.match.params.cid});
        
        this.unsubscribe = this.props.firebase
        .company(this.props.match.params.cid)
        .onSnapshot(snapshot => {
          //console.log("Snapshot: " + snapshot.data());
          this.setState({
            entity:snapshot.data(),
            loading:false,
          });
        });
        //console.log("State: " + this.state.entity);
        //console.log("Param: " + this.props.match.params.cid);
        //console.log("EID State: " + this.state.entityID);
        //console.log(this);
      }

      componentWillUnmount()
      {
          //this.props.firebase.users().off();
          //this.unsubscribe();
      }

      myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        let err = '';
        if (nam === "age") {
          if (val !="" && !Number(val)) {
            err = <strong>Your age must be a number</strong>;
          }
        }
        if(val === "on"){
          val = true;
        }
        this.setState({errormessage: err});
        this.setState({[nam]: val});
      }

      setNewValue(newValue) {
        console.log('this is the State code:' + newValue);
      }
      render() {
        const {entity,loading} = this.state;
        return (
          
          <div>
            {loading && <div>Loading form...</div>}
          {entity && <form onSubmit={this.mySubmitHandler} ref={(el) => this.myFormRef = el}>
          {/*<h3>Add a note for {entity.name}</h3>*/}
          {this.state.errormessage}
          <p>Enter your note:</p>
          <textarea
            wrap='true'
            name='note'
            className="form-control"
            onChange={this.myChangeHandler}
          />
          <div className="form-group row">
          <div className="col-md-1"></div>
              <div className="col-md-6">
                  <input className="form-check-input" type="checkbox" name="pinnedNote" onChange={this.myChangeHandler}/>
                  <label for="pinnedNote">Pin this note?</label>
              </div>
              <div className="col-md-5"></div>
            </div>
            <div className="row">
              <div className="col-md-4"><input type="submit" className="btn btn-success" value="Submit"/></div>
              <div className="col-md-4"></div>
              <div className="col-md-4"></div>
            </div>
          
          </form>}
          </div>
        );
      }
    }
    const AddNoteForm= compose(
        withRouter,
        withFirebase,
    )(NoteFormBase);
    
    export default AddNotePage;
    
    export {AddNoteForm};
