import React, { Component } from 'react';
import {withFirebase} from '../Firebase';
import 'bootstrap/dist/css/bootstrap.min.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPencilAlt,faCheckCircle,faTimes, faCheck} from '@fortawesome/free-solid-svg-icons';
import { Redirect } from 'react-router-dom';

class RepControl extends Component{
    constructor(props){
        super(props);

        this.state = {
            loading:false,
            username:this.props.username,
            companyid:this.props.cid,
            company:null,
            editMode:false,
            users:[],
        };
    }

    componentDidMount(){
        //this.setState({loading:true});
        //this.setState({companyid:this.props.cid});
        //this.setState({username:this.props.username});
        //console.log(this.state.companyid);
        
        this.unsubscribe = this.props.firebase
            .users()
            .onSnapshot(snapshot => {
                let users = [];

                snapshot.forEach(doc =>
                    users.push({ fullname:doc.data().fname + " " + doc.data().lname,uid: doc.id }),
                );

                this.setState({
                    users,
                    loading: false,
                });

            });


        this.unsubscribe = this.props.firebase
            .company(this.props.cid)
            .onSnapshot(snapshot => {
                this.setState({
                    company:snapshot.data,
                    loading: false,
                });
            });
    }

    componentWillUnmount(){
        //this.unsubscribe();
    }

    repEditMode=()=>{
        this.setState({editMode:true});

    }

    repNameSelect=(event)=>{
        let nam = event.target.name;
        let val = event.target.value;
        //console.log(nam);
        this.setState({[nam]: val});
    }

    repNameCommit=()=>{
        //this.setState({username:'foobar'});
        //console.log("Commit new Rep: " + this.state.username + " for: "+this.state.company.entityID);
        this.props.firebase.updateRep(this.props.cid,this.state.username);
        this.setState({editMode:false});
    }

    repNameCancel=()=>{
        this.setState({editMode:false});
    }

    render(){
        const {editMode,users,username, loading} = this.state;
        return(
            <div className="row" style={{marginTop:10+"px"}}><div className="col-sm-7"><strong>Sharratt Provisions Representative:</strong></div>
            {!editMode 
                ? <div className="col-sm-4">{username}<span>     </span><FontAwesomeIcon icon={faPencilAlt} style={{cursor:'hand',marginLeft:15+"px"}} onClick={this.repEditMode} data-toggle="tooltip" data-placement="right" title="Edit"></FontAwesomeIcon></div> 
                : <div className="row"><div className="col-sm-9"><select name="username" onChange={this.repNameSelect} className="form-control">
                <option value="00">Select Rep</option>
                {users.map(user=>
                    <option value={user.fullname} key={user.uid}>{user.fullname}</option>
                )}
            </select>
            </div>
            {/*<div className="col-sm-1"><FontAwesomeIcon icon={faCheck} style={{color:"green",cursor:'hand'}} onClick={this.repNameCommit}></FontAwesomeIcon></div><div className="col-sm-1"><FontAwesomeIcon icon={faTimes} style={{color:"red"}} onClick={this.repNameCancel}></FontAwesomeIcon></div>*/}
            <div className="col-sm-2"><div className="btn-group" role="group" aria-label="Basic example"><button type="button" className="btn btn-sm btn-success" onClick={this.repNameCommit}>Submit</button><button type="button" className="btn btn-sm btn-danger" onClick={this.repNameCancel}>Cancel</button></div></div>
            </div>}
            </div>
        );
    }
}
export default withFirebase(RepControl);
