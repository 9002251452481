import React, {Component} from 'react';
import {BrowserRouter as Router, Route,Switch} from 'react-router-dom';

//import Bootstrap from 'bootstrap';

import Layout from '../Layout';

import Navigation from '../Navigation';
import AdminPage from '../Admin';
import SearchPage from '../Search';
import LandingPage from '../Landing';
import HomePage from '../Home';
import SignInPage from '../SignIn';
import PasswordForgotPage from '../PasswordForgot';
import UsersPage from '../User';
import AddUserPage from '../User/adduser';
import EditUserPage from '../User/edituser';
import CompanyPage from '../Company';
import NotesPage from '../Notes/notebase';
import NotesForm from '../Notes';
import Navbar from '../Sidebar/navbar';
import ContactPage from '../Contact';
import CompanyDetailsPage from '../Company/details';
import AllNotesPage from '../Notes/allnotes';
import EditOffice from '../Company/editoffice';
import EditContact from '../Contact/editcontact';
import AddItemControl from '../Item';
//import EditItemControl from '../Item/edit';
import SupplierSearchControl from '../Supplier';
import SupplierItemsPage from '../Item/companyitems';
import ItemSearchPage from '../Item/search';
import EditItemPage from '../Item/edititem';
import ViewItemPage from '../Item/viewitem';
import DomainPage from '../Domains';
import WarehousePage from '../Warehouse';
import WarehouseList from '../Warehouse/list';
import PricingPage from '../Pricing';
import CartPage from '../Cart';
import ReportPage from '../Report';
import QuotePage from '../Quote';
import EditQuote from '../Quote/edit';
import EditQuoteRD from '../Quote/revamp_v1';
import QuoteTable from '../Quote/search';
import ExportQuote from '../Quote/view';
import InventoryOrder from '../Inventory/neworder';
import EditInventoryOrder from '../Inventory/editorder';
import OrderList from '../Inventory/orderslist';
import OrderExport from '../Inventory/orderexport';
import QuoteBreakdown from '../Quote/adminbreakdown';
//import OrderBreakdown from '../Inventory/adminbreakdown';
import InventoryItem from '../Inventory/inventoryitem';
import EditInventoryItem from '../Inventory/editinventoryitem';
import AppVersionPage from '../User/appversion';
import QuoteQA from '../Quote/qa';
import AltHome from '../Admin/althome';

import * as ROUTES from '../../constants/routes';
import {withAuthentication} from '../Session';
import Footer from '../Sidebar/footer';

const App = () => (
            <Router>
                {/*<Navbar/>*/}

                <div>
                    {/**/}
                    <Route exact path={ROUTES.LANDING} component={SignInPage}/>
                    <Route exact path={ROUTES.HOME} component={HomePage}/>
                    <Route exact path={ROUTES.SIGN_IN} component={SignInPage}/>
                    <Route exact path={ROUTES.SIGN_OUT} component={SignInPage}/>
                    <Route exact path={ROUTES.ADMIN} component={AdminPage}/>
                    <Route exact path={ROUTES.PASSWORD_FORGOT} component={PasswordForgotPage}/>
                    <Route exact path={ROUTES.USERS} component={UsersPage}/>
                    <Route exact path={ROUTES.USER_DETAILS} component={UsersPage}/>
                    <Route exact path={ROUTES.ADD_USER} component={AddUserPage}/>
                    <Route exact path={ROUTES.EDIT_USER} component={EditUserPage}/>
                    <Route exact path={ROUTES.SEARCH} component={SearchPage}/>
                    <Route exact path={ROUTES.COMPANY_DETAILS} component={CompanyDetailsPage}/>
                    <Route exact path={ROUTES.ADD_COMPANY} component={CompanyPage}/>
                    <Route exact path={ROUTES.ADD_CONTACT} component={ContactPage}/>
                    <Route exact path={ROUTES.NOTES} component={NotesPage}/>
                    <Route path={ROUTES.ADD_NOTE} component={NotesForm}/>
                    <Route exact path={ROUTES.ALLNOTES} component={AllNotesPage}/>
                    <Route exact path={ROUTES.EDITLOCATION} component={EditOffice}/>
                    <Route exact path={ROUTES.EDIT_CONTACT} component={EditContact}/>
                    <Route exact path={ROUTES.ADD_ITEM} component={AddItemControl}/>
                    <Route exact path={ROUTES.SUPPLIER_SEARCH} component={SupplierSearchControl}/>
                    <Route exact path={ROUTES.COMPANYITEMS} component={SupplierItemsPage}/>
                    <Route exact path={ROUTES.ITEMSEARCH} component={ItemSearchPage}/>
                    <Route exact path={ROUTES.EDIT_ITEM} component={EditItemPage}/>
                    <Route exact path={ROUTES.DOMAINS} component={DomainPage}/>
                    <Route exact path={ROUTES.WAREHOUSE} component={WarehousePage}/>
                    <Route exact path={ROUTES.WAREHOUSEMGR} component={WarehouseList}/>
                    <Route exact path={ROUTES.PRICING} component={PricingPage}/>
                    <Route exact path={ROUTES.CART} component={CartPage}/>
                    <Route exact path={ROUTES.REPORT} component={ReportPage}/>
                    <Route exact path={ROUTES.QUOTE} component={QuotePage}/>
                    {/*<Route exact path={ROUTES.EDIT_QUOTE} component={EditQuote}/>*/}
                    <Route exact path={ROUTES.EDIT_QUOTE} component={EditQuoteRD}/>
                    <Route exact path={ROUTES.EDIT_QUOTE_RD} component={EditQuoteRD}/>
                    <Route exact path={ROUTES.ALLQUOTES} component={QuoteTable}/>
                    <Route exact path={ROUTES.VIEW_ITEM} component={ViewItemPage}/>
                    <Route exact path={ROUTES.VIEW_QUOTE} component={ExportQuote}/>
                    <Route exact path={ROUTES.ORDER} component={InventoryOrder}/>
                    <Route exact path={ROUTES.EDIT_ORDER} component={EditInventoryOrder}/>
                    <Route exact path={ROUTES.ORDER_EXPORT} component={OrderExport}/>
                    <Route exact path={ROUTES.ALLORDERS} component={OrderList}/>
                    <Route exact path={ROUTES.QUOTE_ADMIN_VALUE} component={QuoteBreakdown}/>
                    <Route exact path={ROUTES.ADD_INVENTORY_ITEM} component={InventoryItem}/>
                    <Route exact path={ROUTES.EDIT_INVENTORY_ITEM} component={EditInventoryItem}/>
                    <Route exact path={ROUTES.ABOUT} component={AppVersionPage}/>
                    <Route exact path={ROUTES.QUOTEQA} component={QuoteQA}/>
                    <Route exact path={ROUTES.ALTHOME} component={AltHome}/>
                    <Footer/>
                </div>
            </Router>
);


export default withAuthentication(App);

