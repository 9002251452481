// pm.com/pricing
import React,{Component} from 'react';
import { Switch,Route, Link } from 'react-router-dom';
import { compose } from 'recompose';
import * as ROUTES from '../../constants/routes';
import { AuthUserContext } from '../Session';
import { withAuthorization } from '../Session';
import {withFirebase} from '../Firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faRedo,faEdit,faTrashAlt,faUserEdit,faUserPlus,faFileExcel, faEraser, faPlus,faPlusCircle,faHome, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { CSVLink } from "react-csv";
import {withRouter} from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import filterFactory,{textFilter,selectFilter} from 'react-bootstrap-table2-filter';
import { ThemeProvider } from 'styled-components';
import Modal from '../Modal';
import firebase from 'firebase';

import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 
import { thatReturnsThis } from 'react-select-us-states';
import { firestore } from 'firebase-admin';

class PricingForm extends Component{

    constructor(props) {
        super(props);
        this.state = {
          noprice:true,
          fullprice:true,
          noPricing:[],
          fullPricing:[],
          nomuItems:[],
          errorMsg:'',
          showModal:false,
          focusItemId:'',
          focusFOBPrice:'',
          focusMarkUp:'',
          focusMarkUpType:'',
          focusFullPrice:'',
          editModal:false,
          editItemId:'',
          editFOBPrice:'',
          editMarkUp:'',
          editMarkUpType:'',
          editFullPrice:'',
          activateSave:true,
          activateCalc:true,
          tblColumns:[
            {
              data: "itemid",
              title: "Item ID",
              visible:false,
              //render:x=>`<a href={'/'+companyid+'/item/${x.itemid}}>${x}</a>`
            },
            {
              data: "itemLink",
              title: "Item Name",
              //render:x=>`<a href={'/'+companyid+'/item/${x.itemid}}>${x}</a>`
            },
            {
              data: "itemDescription",
              title: "Description",
            },
            {
              data: "itemSizeFull",
              title: "Size",
            },
            {
                data: "fobCasePrice",
                title: "FOB Price",
            },
            {
                data: "markUp",
                title: "Mark Up",
            },
            {
                data: "markUpType",
                title: "Type",
            },
            {
              data: "fullPrice",
              title: "Price",
              render: function (data,type,row){
                if(row.fobCasePrice == "")
                {
                  return `<button class='editPrice btn btn-sm btn-outline-info' disabled>${data}</button>`;  
                }
                else if(row.fobCasePrice != "" && row.fullPrice == "--")
                {
                  return `<button class='editPrice btn btn-sm btn-info'>${data}</button>`;
                }
                else{
                  return `<button class='editPrice btn btn-sm btn-info'>${data}</button>`;            }
              }
              //render:x=>`<button class="editPrice btn btn-sm btn-info" >${x}</button>`
            },
            /*{
              data: "fullPriceParams",
              title: "Price Params",
              visible: false,
            },
            {
              title:"Edit",
              orderable:false,
              defaultContent: '<input type="button" value="Edit" class="editPrice btn btn-sm btn-info"/>'

            },*/
            {
                data: "supplierName",
                title: "Supplier",
                
            },
            {
                data: "fobFullLocation",
                title: "FOB Location",
                
            } 
        ],
          loading:true
        };
      }

      myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        let err = '';
        if (val == "" ) {
            //alert(<strong>Your must supply a value</strong>);
            return;
        }
        
        this.setState({errormessage: err});
        this.setState({[nam]: val});
        console.log(nam + " " + val);

        //console.log(this.state.focusMarkUp + " | " + this.state.focusMarkUpType)
        if(this.state.focusMarkUp != "" && nam == 'focusMarkUpType' && val != "00")
        {
          this.setState({activateCalc:false});
        }
        else{
          this.setState({activateCalc:true});
        }
      }

      componentDidMount(){
 
        this.unsubscribe = this.props.firebase
        .getFPItems()
        .onSnapshot(snapshot => {
   
          let fullPricing=[];
          //itemid,markUp,markUpType,fobPrice,fullPrice
          snapshot.forEach(function(doc){
            var docDate = doc.data().dateUpdated;
            if(doc.data().dateCreated != null)
            {
              //console.log("NULL DateCreated:"+doc.id);
              docDate = doc.data().dateCreated;
            }
            fullPricing.push({ ...doc.data(),fullPrice:doc.data().fullPrice,itemLink:'<a href="/'+doc.data().companyid+'/item/'+doc.id+'">'+doc.data().itemName+'</a>',dateUpdated:new Date(docDate.seconds *1000).toLocaleDateString(), itemSizeFull:doc.data().unitSize + " " + doc.data().unitUnit ,fobFullLocation: doc.data().fobCity + " " + doc.data().fobState, dateAdded:new Date(docDate.seconds *1000).toLocaleDateString(),itemid: doc.id });
          });
            console.log(fullPricing.length);
            this.setState({fullPricing,fullprice:false});

            $('#example').DataTable({
              destroy: true,
              stateSave:true,
              data: fullPricing,
              columns: this.state.tblColumns,
              pageLength: 10,
              //filter: true,
              //deferRender: true,
              initComplete: function () {
                this.api().columns([7,8,9]).every( function () {
                    var column = this;
                    var select = $('<select class="form-control filterCtrl"><option value="">...</option></select>')
                        .appendTo( $(column.footer()).empty() )
                        .on( 'change', function () {
                            var val = $.fn.dataTable.util.escapeRegex(
                                $(this).val()
                            );
     
                            column
                                .search( val ? '^'+val+'$' : '', true, false )
                                .draw();
                        } );
     
                    column.data().unique().sort().each( function ( d, j ) {
                        select.append( '<option value="'+d+'">'+d+'</option>' )
                    } );
                } );

                $('#example tfoot tr').appendTo('#example thead');
            }
            });

            $('.clear-filters').prependTo('#example_length');
        });

        var self = this;

       
        $('#example tbody').on( 'click', 'button', function () {
          var data = $('#example').DataTable().row( $(this).parents('tr') ).data();
          //console.log(data.itemid);
          //alert( "Edit Pricing");
          //self.addItemToCurrentTab(data.itemid);
          /*$('#focusItemId').val(data.itemid);
          $('#focusFOBPrice').val(data.itemName);
          $('#focusMarkUp').val(data.itemDescription);
          $('#focusMarkUpType').val(data.packSize);
          $('#focusFullPrice').val(data.itemSizeFull);
          $("#editModal").modal('show');*/
          self.editModal(data.itemid,data.markUp,data.markUpType,data.fobCasePrice,data.fullPrice);
      } );

        /*this.unsubscribe = this.props.firebase
        .getNoMUItems()
        .onSnapshot(nomusnap=>
          {
            let nomuItems=[];
            nomusnap.forEach(doc=>{
              var DA = new Date();
            var muValue = "";
            var muType = "";
            if(typeof doc.data().dateCreated == "undefined")
            {
              DA = new Date(doc.data().dateUpdated.seconds *1000).toLocaleDateString()
            }
            else{
              DA = new Date(doc.data().dateCreated.seconds *1000).toLocaleDateString();
            }
            if(typeof doc.data().markUp == "undefined")
            {
              //alert(doc.id);
              muValue = "";
            }
            else{
              muValue = doc.data().markUp;
            }
            if(typeof doc.data().markUpType == "undefined")
            {
              //alert(doc.id);
              muType = "";
            }
            else{
              muType = doc.data().markUpType;
            }
            nomuItems.push({ ...doc.data(),dateAdded:DA, itemSizeFull:doc.data().unitSize + " " + doc.data().unitUnit ,fullPrice:"--",fobFullLocation: doc.data().fobCity + " " + doc.data().fobState,itemLink:'<a href="/'+doc.data().companyid+'/item/'+doc.id+'">'+doc.data().itemName+'</a>',markUp:muValue, markUpType:muType, itemid: doc.id });
          });
            console.log(nomuItems.length);
            this.setState({nomuItems});
        });*/


        this.unsubscribe = this.props.firebase
        .getNPItems()
        .onSnapshot(snapshot => {
   
          let noPricing=[];

          snapshot.forEach(doc =>{
            var DA = new Date();
            var muValue = "";
            var muType = "";
            if(typeof doc.data().dateCreated == "undefined")
            {
              DA = new Date(doc.data().dateUpdated.seconds *1000).toLocaleDateString()
            }
            else{
              DA = new Date(doc.data().dateCreated.seconds *1000).toLocaleDateString();
            }
            if(typeof doc.data().markUp == "undefined")
            {
              //alert(doc.id);
              muValue = "";
            }
            else{
              muValue = doc.data().markUp;
            }
            if(typeof doc.data().markUpType == "undefined")
            {
              //alert(doc.id);
              muType = "";
            }
            else{
              muType = doc.data().markUpType;
            }
            noPricing.push({ ...doc.data(),dateAdded:DA, itemSizeFull:doc.data().unitSize + " " + doc.data().unitUnit ,fullPrice:"--",fobFullLocation: doc.data().fobCity + " " + doc.data().fobState,itemLink:'<a href="/'+doc.data().companyid+'/item/'+doc.id+'">'+doc.data().itemName+'</a>',markUp:muValue, markUpType:muType, itemid: doc.id });
          });
            console.log(noPricing.length);
            this.setState({noPricing,noprice:false});

            $('#nopricetable').DataTable({
              destroy: true,
              stateSave:true,
              data: noPricing,
              columns: this.state.tblColumns,
              pageLength: 10,
              //filter: true,
              //deferRender: true,
              initComplete: function () {
                this.api().columns([7,8,9]).every( function () {
                    var column = this;
                    var select = $('<select class="form-control filterCtrl"><option value="">...</option></select>')
                        .appendTo( $(column.footer()).empty() )
                        .on( 'change', function () {
                            var val = $.fn.dataTable.util.escapeRegex(
                                $(this).val()
                            );
     
                            column
                                .search( val ? '^'+val+'$' : '', true, false )
                                .draw();
                        } );
     
                    column.data().unique().sort().each( function ( d, j ) {
                        select.append( '<option value="'+d+'">'+d+'</option>' )
                    } );
                } );
    
                $('#nopricetable tfoot tr').appendTo('#nopricetable thead');
            }
            });
    
            $('.clear-filters-np').prependTo('#nopricetable_length');

            $('#nopricetable tbody').on( 'click', 'button', function () {
              var data = $('#nopricetable').DataTable().row( $(this).parents('tr') ).data();
              //console.log(data.itemid);
              //alert( "Edit Pricing");
              //self.addItemToCurrentTab(data.itemid);
              /*$('#focusItemId').val(data.itemid);
              $('#focusFOBPrice').val(data.itemName);
              $('#focusMarkUp').val(data.itemDescription);
              $('#focusMarkUpType').val(data.packSize);
              $('#focusFullPrice').val(data.itemSizeFull);
              $("#editModal").modal('show');*/
              self.editModal(data.itemid,data.markUp,data.markUpType,data.fobCasePrice,data.fullPrice);
          } );
    

        });


 

        $(document).ready(function () {
         
          //var table = $('#example').DataTable();
 
        /*$("#example tfoot th").each( function ( i ) {
            var select = $('<select class="form-control"><option value="">Select...</option></select>')
                .appendTo( $(this).empty() )
                .on( 'change', function () {
                  $('#example').DataTable().column( i )
                        .search( $(this).val() )
                        .draw();
                } );
    
                $('#example').DataTable().column( i ).data().unique().sort().each( function ( d, j ) {
                select.append( '<option value="'+d+'">'+d+'</option>' )
            } );
        } );

        $('#example tfoot tr').appendTo('#example thead');*/

        

        });




      }

      openModal(itemid,fobPrice) {
        this.setState({ modal: true, focusItemId:itemid, focusFOBPrice:fobPrice });
      }
    
      closeModal() {
        this.setState({
          focusItemId: '',
          focusFOBPrice:'',
          focusFullPrice:'',
          focusMarkUp:'',
          focusMarkUpType:'',
          modal: false
        });
    }

    editModal(itemid,markUp,markUpType,fobPrice,fullPrice) {
      //alert(itemid);
      this.setState({ editModal: true,focusItemId:itemid,focusFOBPrice:fobPrice,focusMarkUp:markUp,focusMarkUpType:markUpType,focusFullPrice:fullPrice });
    }
  
    closeEditModal() {
      this.setState({
        focusItemId: "",
        focusFOBPrice:'',
        focusFullPrice:'',
        focusMarkUp:'',
        focusMarkUpType:'',
        editModal: false
      });
  }

  setBasePricing() {
    let baseUnit = "Percent";
    let baseMU = 3;

    this.state.noPricing.map((itm)=>{
        let fp = parseFloat(parseFloat(itm.fobCasePrice) + (parseFloat(itm.fobCasePrice)*.03)).toFixed(2);

        try{
          this.props.firebase.db.collection('items').doc(itm.itemid).update({
            markUp:baseMU,
            markUpType:baseUnit,
            needsPricing:0,
            fullPrice:fp,
            priceDate:firebase.firestore.Timestamp.now(),
        });
        }catch(e){
          console.log('Transaction Failure:',e," ", itm.itemid);
        }
    });
   
  }

    calculatePrice(){
        var basePrice = parseFloat(this.state.focusFOBPrice);
        var finalPrice;
        var altMarkup = parseFloat(this.state.focusMarkUp);
        if(this.state.focusMarkUpType == "Percent")
        {
          finalPrice = basePrice + (basePrice * (altMarkup/100));
        }
        else{
          finalPrice = basePrice + altMarkup;
        }
        
        this.setState({focusFullPrice:finalPrice.toFixed(2),activateSave:false});
    }

    clearSearchFilters(){
      $('#example').DataTable().search( '' ).columns().search( '' ).draw();
      $('#nopricetable').DataTable().search( '' ).columns().search( '' ).draw();
      $('tfoot input').val('');

      $('.filterCtrl').each(function(){
        $(this).val('');
      });

      
    }

    updateItemPrice(){
          if(this.state.focusFullPrice != 'NaN'){
          this.props.firebase.db.collection('items').doc(this.state.focusItemId).update({
            markUp:this.state.focusMarkUp,
            markUpType:this.state.focusMarkUpType,
            needsPricing:0,
            fullPrice:this.state.focusFullPrice,
            priceDate:firebase.firestore.Timestamp.now(),
        });
        this.closeEditModal();
        $('#example').DataTable().destroy();
      }
      else{
        alert("There is a calculation error - please try again.");
      }
    }

    updateTable(){
      $('#example').DataTable().data.reload();
    }


      render() {

        const goBack = () => {
          this.props.history.goBack()
      }
        const { ExportCSVButton } = CSVExport;
        const {customers, tblColumns, loading,noPricing,fullPricing, fullPrice,noprice} = this.state;
        const containerStyle={
          maxWidth:1200 +"px",
        };
        const { SearchBar, ClearSearchButton } = Search;
        
        if(customers == "undefined")
        {
          return (<div><h1>Loading Customer Data Please Wait...</h1></div>)
        }
        return (
          <AuthUserContext.Consumer>
            {authUser=>(
            <div className="content">
              <Modal show={this.state.modal} handleClose={e => this.closeModal(e)}>
                                    <div className="modal-content">
                                      <div className="modal-header">
                                        <h5 className="modal-title">Add Markup</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e=>this.closeModal()}>
                                          <span aria-hidden="true">&times;</span>
                                        </button>
                                      </div>
                                      <div className="modal-body">
                                        <input type="hidden" name="itemId" value={this.state.focusItemId}/>
                                        <label htmlFor="fobPrice">FOB Price</label>
                                        <input type="text" name="fobPrice" className="form-control" value={this.state.focusFOBPrice} disabled/>
                                        <label htmlFor="focusMarkUp">Mark Up Value</label>
                                        <input type="text" name="focusMarkUp" className="form-control" value={this.state.focusMarkUp} onChange={this.myChangeHandler}/>
                                        <label htmlFor="focusMarkUpType">Mark Up Type</label>
                                        <select name="focusMarkUpType" className="form-control" onChange={this.myChangeHandler} value={this.state.focusMarkUpType}>
                                          <option value="00">Select...</option>
                                          <option value="Dollar">Dollar</option>
                                          <option value="Percent">Percent</option>
                                        </select>
                                        <label htmlFor="focusFullPrice">Full Price</label>
                                        <input type="text" name="focusFullPrice" className="form-control" value={this.state.focusFullPrice} disabled/>
                                      </div>
                                      <div className="modal-footer">
                                        <button type="button" disabled={this.state.activateCalc} className="btn btn-primary" onClick={e => this.calculatePrice(e)}>Calculate Full Price</button>
                                        <button type="button" disabled={this.state.activateSave} className="btn btn-success" onClick={e => this.updateItemPrice(e)}>Save changes</button>
                                        <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={e=>this.closeModal()}>Close</button>
                                      </div>
                                    </div>
                                </Modal>
                                <Modal show={this.state.editModal} handleClose={e => this.closeEditModal(e)}>
                                    <div className="modal-content">
                                      <div className="modal-header">
                                        <h5 className="modal-title">Edit Markup</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e=>this.closeEditModal()}>
                                          <span aria-hidden="true">&times;</span>
                                        </button>
                                      </div>
                                      <div className="modal-body">
                                        <input type="hidden" name="itemId" value={this.state.focusItemId}/>
                                        <label htmlFor="fobPrice">FOB Price</label>
                                        <input type="text" name="fobPrice" className="form-control" value={this.state.focusFOBPrice} disabled/>
                                        <label htmlFor="focusMarkUp">Mark Up Value</label>
                                        <input type="text" name="focusMarkUp" className="form-control" value={this.state.focusMarkUp} onChange={this.myChangeHandler}/>
                                        <label htmlFor="focusMarkUpType">Mark Up Type</label>
                                        <select name="focusMarkUpType" className="form-control" value={this.state.focusMarkUpType} onChange={this.myChangeHandler}>
                                          <option value="00">Select...</option>
                                          <option value="Dollar">Dollar</option>
                                          <option value="Percent">Percent</option>
                                        </select>
                                        <label htmlFor="focusFullPrice">Full Price</label>
                                        <input type="text" name="focusFullPrice" className="form-control" value={this.state.focusFullPrice} disabled/>
                                      </div>
                                      <div className="modal-footer">
                                        <button type="button" className="btn btn-primary" onClick={e => this.calculatePrice(e)}>Calculate Full Price</button>
                                        <button type="button" className="btn btn-success" onClick={e => this.updateItemPrice(e)}>Save changes</button>
                                        <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={e=>this.closeEditModal()}>Close</button>
                                      </div>
                                    </div>
                                </Modal>
            <nav className="navbar navbar-expand-lg navbar-light bg-blue">
                  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                      <span className="navbar-toggler-icon"></span>
                  </button>
                  <div className="collapse navbar-collapse" id="navbarNav">
                      <ul className="navbar-nav">
                      <li className="nav-item">
                            <button className="btn btn-outline-light" style={{marginTop:"25px"}} onClick={goBack}>Back</button>
                        </li>
                        {/*<li className="nav-item">
                        <a className="nav-link-menu" href="../home">Home</a>
                        </li>*/}
                      </ul>
                  </div>
                  </nav>
                  <div style={{marginTop:15+"px"}}>
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Pricing To Be Set</a>
                        </li>
                        {/*<li className="nav-item">
                            <a className="nav-link" id="inv-tab" data-toggle="tab" href="#inv" role="tab" aria-controls="inv" aria-selected="true">Inventory Pricing To Be Set</a>
                      </li>*/}
                        <li className="nav-item">
                            <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Active Pricing</a>
                        </li>
                        
                        </ul>

                        <div className="tab-content">
                        <div className="tab-pane active" id="home" role="tabpanel" aria-labelledby="home-tab">
                              <div className="container">
                              <div className="row">
                                <div className="col-md-12">
                                <div className="table-responsive">
                        <div className="table-wrapper">
                            <div className="table-title">
                                <div className="row">
                                    <div className="col-sm-5">
                                        <h2>Pricing to be Set</h2>
                                        <div className="clear-filters-np" style={{float:"left",marginRight:10+"px"}}>
                                        <button type="button" className="btn btn-outline-success right" onClick={e => this.setBasePricing()}><FontAwesomeIcon icon={faDollarSign}></FontAwesomeIcon> Set Base Mark-up</button>
                                        </div>
                                    </div>
                                    <div className="col-sm-7">
                                    </div>
                                </div>
                            </div>
                            {noprice && <h2>Loading...</h2>}
                            <div className="clear-filters-np" style={{float:"left",marginRight:10+"px"}}>
                            <button type="button" className="btn btn-outline-primary right" onClick={e => this.clearSearchFilters()}><FontAwesomeIcon icon={faRedo}></FontAwesomeIcon> Clear Search/Filters</button>
                            </div>
                            <table id="nopricetable" className="display">
                                      <thead></thead>
                                      <tbody></tbody>
                                      <tfoot>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                      </tfoot>
                                  </table>
                                </div>
                                </div>
                                </div>
                            </div>
                                </div>
                            </div>
                            {/*<div className="tab-pane" id="inv" role="tabpanel" aria-labelledby="inv-tab">
                          <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                <div className="table-responsive">
                        <div className="table-wrapper">
                            <div className="table-title">
                                <div className="row">
                                    <div className="col-sm-5">
                                        <h2>Inventory Item Pricing To Be Set</h2>
                                    </div>
                                    <div className="col-sm-7">
                                    </div>
                                </div>
                            </div>
                            <table className="table table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th>Item Name</th>
                                        <th>Description</th>
                                        <th>Supplier</th>	
                                        <th>Cases</th>
                                        <th>Size</th>
                                        <th>FOB Price</th>
                                        <th>Date Added</th>	
                                        <th>Controls</th>									
                                    </tr>
                                </thead>
                                <tbody>
                                </tbody>
                                </table>
                                </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            </div>*/}
                            <div className="tab-pane" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                              <div className="container">
                              <div className="row">
                                <div className="col-md-12">
                                <div className="table-responsive">
                        <div className="table-wrapper">
                            <div className="table-title">
                                <div className="row">
                                    <div className="col-sm-5">
                                        <h2>Active Pricing</h2>
                                    </div>
                                    <div className="col-sm-7">
                                    </div>
                                </div>
                            </div>
                            {fullPrice && <h2>Loading...</h2>}
                            <div className="clear-filters" style={{float:"left",marginRight:10+"px"}}>
                            <button type="button" className="btn btn-outline-primary right" onClick={e => this.clearSearchFilters()}><FontAwesomeIcon icon={faRedo}></FontAwesomeIcon> Clear Search/Filters</button>
                            </div>
                            <table id="example" className="display">
                                      <thead></thead>
                                      <tbody></tbody>
                                      <tfoot>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                      </tfoot>
                                  </table>
                                </div>
                                </div>
                                </div>
                            </div>
                                </div>
                            </div>
  
                        </div>
                  </div>
            </div>
          )}
          </AuthUserContext.Consumer>
        );
      }
    }
    const condition = authUser => !!authUser;

    export default withFirebase(PricingForm);