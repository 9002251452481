import React, {Component} from 'react';
import {compose} from 'recompose';

import {withAuthorization} from '../Session';
import {withFirebase} from '../Firebase';

class AddUserPage extends Component{

    constructor(props) {
        super(props);
        this.state = {
          role:'',
          fname:'',
          lname:'',
          email:'',
          phone:'',
          isActive:1,
          errormessage: ''
        };
      }

      mySubmitHandler = (event) => {
        event.preventDefault();
        /*let age = this.state.age;
        if (!Number(age)) {
          alert("Your age must be a number");
        }*/
        this.props.firebase.contacts().add({
          email:this.state.email,
          fname:this.state.fname,
          lname:this.state.lname,
          isActive:this.state.isActive,
          role:this.state.role,
          phone:this.state.phone
        });

        this.setState({});
        this.props.history.push('/users');
      }

      componentDidMount(){
        this.setState({entityID:this.props.match.params.cid});
        console.log(this.props.match.params.cid);
    }

    cancelForm = (event) => {
      this.setState({});
        this.props.history.push('/users');
    }

      myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        let err = '';
        if (nam === "role") {
          if (val ==="00") {
            err = <strong>Your Provide A Role.</strong>;
          }
        }
        this.setState({errormessage: err});
        this.setState({[nam]: val});
      }

      setNewValue(newValue) {
        console.log('this is the State code:' + newValue);
      }
      render() {
        return (
          <div className="container">
          <form onSubmit={this.mySubmitHandler}>
          <h1>Add a User</h1>
          {this.state.errormessage}
          <p>User First Name:</p>
          <input
            type='text'
            name='fname'
            className="form-control"
            onChange={this.myChangeHandler}
          />
          <p>User Last Name:</p>
          <input
            type='text'
            name='lname'
            className="form-control"
            onChange={this.myChangeHandler}
          />
          <p>Role:</p>
          <select name="role" className="form-control" onChange={this.myChangeHandler}>
              <option value="00">Select Role</option>
              <option value="Admin">Admin</option>
              <option value="Sales">Sales</option>
              <option value="Buyer">Buyer</option>
              <option value="Office">Office</option>
          </select>
          <p>E-mail</p>
          <input
            type='text'
            name='email'
            className="form-control"
            onChange={this.myChangeHandler}
          />
          <p>Phone:</p>
          <input
            type='phone'
            name='phone'
            className="form-control"
            onChange={this.myChangeHandler}
          />
          <div className="row" style={{marginTop:15+"px"}}>
            <div className="col-md-4"><input type="button" className="btn btn-danger" onClick={this.cancelForm} value="Cancel"/></div>
            <div className="col-md-4"></div>
            <div className="col-md-4"><input type="submit" className="btn btn-success" value="Submit"/></div>
          </div>
          
          
          </form>
          </div>
        );
      }
    }

    export default withFirebase(AddUserPage);
