import React, { Component } from 'react'
import { NavLink, Link } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import './sidebar.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faHome, faSearch} from '@fortawesome/free-solid-svg-icons'


class Sidebar extends Component {
  render() {
    return (
      <div className="sidebar">
        <div className="sidebar-wrapper">
          <div className="logo">
            <Link to='/' className="simple-text">
              Simple Dashboard
            </Link>
          </div>
          <ul className="nav">
            <li className="nav-item">
              <NavLink className="nav-link" to='/dashboard'>
                <FontAwesomeIcon icon={faHome}></FontAwesomeIcon>
                <p>Dashboard</p>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to='/users'>
                <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                <p>Search</p>
              </NavLink>
            </li>

          </ul>
        </div>
      </div>
    )
  }
}

export default Sidebar