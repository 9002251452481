import React, { Component } from 'react';
import { Switch,Route, Link,withRouter } from 'react-router-dom';
import { DatePicker, DatePickerInput } from 'rc-datepicker';
import 'rc-datepicker/lib/style.css';
import * as ROUTES from '../../constants/routes';
import {withFirebase} from '../Firebase';
import firebase from 'firebase';
import moment from "moment";
import { withAuthorization } from '../Session';
import { AuthUserContext } from '../Session';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faStar,faTrash,faPlus,faMinus,faRedo,faCheck,faThumbtack,faUserPlus,faTimes,faEdit,faEraser, faStickyNote,faIndustry,faBuilding,faHome, faTrashAlt,faPencilAlt, faCheckCircle, faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import Modal from '../Modal';
import TableModal from '../Modal/tablemodal';
import TabControl from '../Quote/tabcontrol';
import RecieverCard from '../Cart/recievercard';
import ItemCard from '../Cart/itemcard';
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery'; 

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import Item from '../Item';

class QA extends Component{

    constructor(props) {
        super(props);

        this.state = {
         default:'',
         userID:'',
         quoteInfo:'',
         quoteID:'',
         dbID:'',
         quoteDate:'',
         customerPO:'',
         salesNotes:'',
         salesOrderNumber:'',
         recieverName:'',
         items:[],
         freight:'',
         contact:'',
         customers:'',
         totalCost:'00.00',
         loading:true,
         hideRecipient:true,
         note:'',
         status:'',
         statusDomain:[],
         clientStatus:'',
         clientStatusDomain:[],
         customers:[],
         selectedCustomerName:'',
         selectedCustomerID:'',
         selectedCustomerSelectedLocation:'',
         quoteNote:'',
         itemsModel:false,
         showMUModal:false,
         showQtyModal:false,
         activeRequest: true,
         showFreightModal:false,
         frieghtModalCost:'',
         frieghtModalOrigin:'',
         frieghtModalItem:'',
         tabs: [
            { id: 1, name: "Initial Quote", notes: "Wow this is tab 1", total:"00.00", items:[], dateCreated: ""},
            { id: 99, name: "Order", notes: "This is the final order quote", total:"00.00", items:[], dateCreated: ""}
          ],
          currentTab: { id: 1, name: "Initial Quote", notes: "Wow this is tab 1", total:"00.00", items:[], dateCreated: "" },
          editMode: false,
          editTabNameMode: false,
          MUitemID:'',
          MUitemPrice:'',
          itemMUPrice:'',
          itemMUValue:'',
          itemMUType:'',
          QtyItemID:'',
          QtyItemQty:'',
          tabSelect:'',
          pricedItems:[],
          canSave:true,
          customerPO:'',
          salesOrderNumber:'',
          shippingOrderNumber:'',
          purchaseOrderNumber:'',
          salesNotes:'',
          quoteCode:'',
          showTotal:true,
          agentName:'',
          isTemplate:'',
          tblColumns:[
            {
              data: "itemid",
              title: "Item ID",
              sort: false,
              visible:false
            },
            {
              data: "itemLink",
              title: "Item Name",
              sort: true,
              //formatter: (cell, row) => <a href={'/'+row.companyid+'/item/'+row.itemid}> {cell} </a>
            },
            {
              data: "itemDescription",
              title: "Description",
              sort:true
            },
            {
              data: "itemSizeFull",
              title: "Size",
              sort:true
            },
            {
              data: "packSize",
              title: "Pack Size",
              sort:true
            },
            {
              data: "fullPrice",
              title: "Price",
              //defaultContent:"$-",
              render: $.fn.dataTable.render.number( ',', '.', 2, '$' ),
              sort:true,
            },
            {
              data: "supplierName",
              title: "Supplier",
              sort:true,
                
            },
            {
              data: "fobFullLocation",
              title: "FOB Location",
              sort:false,
                
            },
            {
              title:"",
              sort:false, 
              render: function (data,type,row){
                if(row.fullPrice == "")
                {
                  return "<button class='btn btn-sm btn-success' disabled>+</button>";  
                }
                else{
                  return "<button class='btn btn-sm btn-success'>+</button>";            }
              }
            }
          ]
        };
      }

      updateItemTotal=(itemTotal)=>{
          console.log("Updating Cart Total "  + parseFloat(itemTotal));
          this.setState({totalCost:itemTotal});
      }

      calculateTabTotal(){
        const { tabs, currentTab } = this.state;
        let ctItems='';
        let tabTotal='';
        let isLoadedPrice=0;
        const updatedTabs = tabs.map(tab => {
          if (tab.name === currentTab.name) {
            //console.log(tab);
            ctItems = tab.items;
            //ctItems.push(newItem);
            tabTotal = parseFloat(0);
            return {
              ...tab,
              items: ctItems,
              total:tabTotal
              //content: evt.target.value
            };
          } else {
            return tab;
          }
        });
        console.log("Current Tab Total: "+tabTotal);
        //console.log(updatedTabs);
        //this.setState({showQtyModal:false, currentTab:sResults,QtyItemID:'',QtyItemQty:''});
        this.setState({
          tabs: updatedTabs,
          currentTab: {
            ...currentTab,
            items: ctItems,
            total:tabTotal
          }
        });
      }

      componentDidMount(){
       if(localStorage.getItem('newquote') != null){
            var quoteInfo = JSON.parse(localStorage.getItem('newquote'));
            console.log(quoteInfo);
            this.setState({
                userID: quoteInfo.user,
                quoteID:quoteInfo.quoteID,
                items:quoteInfo.items,
                quoteDate:quoteInfo.dateTimeStarted,
                totalCost:quoteInfo.items.itemTotalCost,
                loading:false,
            });
            console.log("Total Cost: " + this.state.totalCost);
            /*let key = 1;
            this.setState(prevState=>({
                tabs:prevState.tabs.map(
                    el=>el.id === key? { ...el, items: quoteInfo.items}: el
                )
            }));*/

            let tabs = [...this.state.tabs];
            let tab1 = {...tabs[0]};
            //console.log(tab1.name);
            tab1.items.push.apply(tab1.items,quoteInfo.items);
            let itemsTotal = 0;
            tab1.items.map(itm=>{
              itemsTotal = itemsTotal + (itm.qty * parseFloat(itm.price));
            })
            tab1.total = itemsTotal;
            tabs[0] = tab1;
            this.setState({tabs});

            //need to also set currentTab state item
            let currentTab = this.state.currentTab;
            currentTab.items.push.apply(currentTab.items,quoteInfo.items);

            currentTab.total = itemsTotal;
            this.setState({currentTab});

            //console.log(this.state.tabs[0].items);
            //console.log(this.state.tabs[0].items.length + " -- " + quoteInfo.items.length);
            /*this.props.firebase.db.collection('quotes').add({
                userID: quoteInfo.user,
                quoteID:quoteInfo.quoteID,
                quoteDate:quoteInfo.dateTimeStarted,
                totalCost:'',
                activeRequest:true,
                customerID:'',
                customerLocationID:'',
                quoteNote:'',
                status:'',
                clientStatus:'',
              });*/

        }
        this.unsubscribe = this.props.firebase
        .getFPItems()
        .onSnapshot(snapshot => {
   
          let pricedItems=[];

          snapshot.forEach(doc =>
            pricedItems.push({ ...doc.data(),itemLink:'<a href="/'+doc.data().companyid+'/item/'+doc.id+'">'+doc.data().itemName+'</a>', itemSizeFull:doc.data().unitSize + " " + doc.data().unitUnit ,fobFullLocation: doc.data().fobCity + " " + doc.data().fobState, itemid: doc.id }),
          );
            //console.log(pricedItems.length);
            this.setState({pricedItems,loading:false}, function(){console.log("set state complete");});


            console.log("setting up table");

            $('#example').DataTable({
              data: pricedItems,
              columns: this.state.tblColumns,
              pageLength: 10,
              columnDefs:[{targets:-1,data:null,defaultContent:'<button class="btn btn-sm btn-success">+</button>'}],
              //filter: true,
              //deferRender: true,
              initComplete: function () {
                this.api().columns([4,6,7]).every( function () {
                    var column = this;
                    var select = $('<select class="form-control filterCtrl"><option value="">...</option></select>')
                        .appendTo( $(column.footer()).empty() )
                        .on( 'change', function () {
                            var val = $.fn.dataTable.util.escapeRegex(
                                $(this).val()
                            );
     
                            column
                                .search( val ? '^'+val+'$' : '', true, false )
                                .draw();
                        } );
     
                    column.data().unique().sort().each( function ( d, j ) {
                        select.append( '<option value="'+d+'">'+d+'</option>' )
                    } );
                } );

                $('#example tfoot tr').appendTo('#example thead');
            }
            });

            $('.clear-filters').prependTo('#example_length');

        });
      
        var self = this;
        //console.log(customers.length);
        $('#example tbody').on( 'click', 'button', function () {
          var data = $('#example').DataTable().row( $(this).parents('tr') ).data();
          console.log(data.itemid);
          //alert( "Feature Coming Soon");
          self.addItemToCurrentTab(data.itemid,data.itemName,data.itemDescription,data.casesPerPallet,data.fullPrice,data.itemSizeFull,data.packSize);
          /*$('#modalItemID').val(data.itemid);
          $('#modalItemName').val(data.itemName);
          $('#modalItemDescription').val(data.itemDescription);
          $('#modalItemPackSize').val(data.packSize);
          $('#modalItemUnitSize').val(data.itemSizeFull);
          $('#modalItemFullPrice').val(data.fullPrice);
          $('#modalItemCasesPerPallet').val(data.casesPerPallet);
          $("#basicModal").modal('show');*/
      } );
        
      //LOAD DOMAINS
      var statusDomain = [];
        this.unsubscribe = this.props.firebase
        .statusItems()
        .onSnapshot(snapshot => {
            //
            snapshot.forEach(doc =>
              statusDomain.push({ ...doc.data(), itemid: doc.id }),
             );

             this.setState({statusDomain:statusDomain,loading:true});

        });

        var clientStatusDomain = [];
        this.unsubscribe = this.props.firebase
        .clientStatusItems()
        .onSnapshot(snapshot => {
            //
            snapshot.forEach(doc =>
              clientStatusDomain.push({ ...doc.data(), itemid: doc.id }),
             );

             this.setState({clientStatusDomain:clientStatusDomain,loading:true});

        });

      }

      clearSearchFilters(){
        $('#example').DataTable().search( '' ).columns().search( '' ).draw();
        $('tfoot input').val('');
  
        $('.filterCtrl').each(function(){
          $(this).val('');
        });
  
        
      }

      addItemToCurrentTab(itemID,itemName,itemDescription,casesPerPallet,fullPrice,itemSizeFull,packSize){
        console.log("Add Item to Current Tab: " + itemID);
        //let currentTab = this.state.currentTab;
        const newItem = {
          id:itemID,
          title:itemName,
          description:itemDescription,
          qty:'1',
          packsize:packSize,
          unitSize:itemSizeFull,
          casesperpallet:casesPerPallet,
          itemmuvalue:'',
          itemmutype:'',
          price:fullPrice,
          freightcost:'00.00',
          muvalue:'00',
          mutype:'',
          fullprice:fullPrice,
        }
        //currentTab.items.push(newItem);
        //currentTab.total = currentTab.total + (1 * parseFloat(newItem.price));
        //this.setState({currentTab});

        const { tabs, currentTab } = this.state;
        let ctItems='';
        let tabTotal='';
        let isLoadedPrice=0;
        const updatedTabs = tabs.map(tab => {
          if (tab.name === currentTab.name) {
            //console.log(tab);
            ctItems = tab.items;
            ctItems.push(newItem);
            tabTotal = parseFloat(tab.total) + (1 * parseFloat(newItem.price));
            return {
              ...tab,
              items: ctItems,
              total:tabTotal
              //content: evt.target.value
            };
          } else {
            return tab;
          }
        });
        console.log("Current Tab Total: "+tabTotal);
        //console.log(updatedTabs);
        //this.setState({showQtyModal:false, currentTab:sResults,QtyItemID:'',QtyItemQty:''});
        this.setState({
          tabs: updatedTabs,
          currentTab: {
            ...currentTab,
            items: ctItems,
            total:tabTotal
          }
        });
      }

      removeItemFromCurrentTab(itemID){
        console.log("Remove Item to Current Tab: " + itemID);
        const { tabs, currentTab } = this.state;
        let ctItems='';
        let tabTotal='';
        let isLoadedPrice=0;
        const updatedTabs = tabs.map(tab => {
          if (tab.name === currentTab.name) {
            //console.log(tab);
            ctItems = tab.items;
            let cItem = ctItems.find(i=>i.id === itemID);
            const j = ctItems.findIndex(i=>i.id === itemID);
            if(cItem.fullprice !== "00.00"){
              isLoadedPrice = 1;
            }
            //console.log(parseFloat(tab.total) - " " + parseInt(this.state.QtyItemQty)+ " " +parseInt(cItem.qty)+ " " + parseFloat(cItem.price));
            tabTotal = parseFloat(tab.total) - (parseInt(cItem.qty) * parseFloat(cItem.fullprice));
            cItem.qty = 0;
            ctItems.splice([j],1);
            return {
              ...tab,
              items: ctItems,
              total:tabTotal
              //content: evt.target.value
            };
          } else {
            return tab;
          }
        });
        console.log("Current Tab Total: "+tabTotal);
        //console.log(updatedTabs);
        //this.setState({showQtyModal:false, currentTab:sResults,QtyItemID:'',QtyItemQty:''});
        this.setState({
          tabs: updatedTabs,
          currentTab: {
            ...currentTab,
            items: ctItems,
            total:tabTotal
          }
        });
      }

      componentWillUnmount()
      {
          
      }

      clearCart(){
        localStorage.clear();
        this.props.history.push('/itemsearch');
      }

      async saveCart(){
        const newQuote = await this.props.firebase.quotes().add({
            userID: this.state.userID,
            quoteID:this.state.quoteID,
            quoteDate:this.state.quoteDate,
            totalQuote:'',
            activeRequest:true,
            customerID:this.state.selectedCustomerID,
            customerLocationID:this.state.selectedCustomerSelectedLocation,
            status:this.state.status,
            clientStatus:this.state.clientStatus,
            tabs:this.state.tabs,
            selectedCustomerName:this.state.selectedCustomerName,
            selectedCustomerID:this.state.selectedCustomerID,
            selectedCustomerSelectedLocation:this.state.selectedCustomerSelectedLocation,
            customerPO:this.state.customerPO,
            salesOrderNumber:this.state.salesOrderNumber,
            shippingOrderNumber:this.state.shippingOrderNumber,
            purchaseOrderNumber:this.state.purchaseOrderNumber,
            salesNotes:this.state.salesNotes,
            recieverName:this.state.recieverName,
        });

        this.setState({dbID:newQuote.id});
        localStorage.clear();
        this.props.history.push('/quote/'+newQuote.id);
        //this.addItems(this.state.items);
      }

      
      openItemsModal(){
        console.log("Open Items Modal");
        this.setState({ showItemsModal: true,});
      }

      closeItemsModal(){
        this.setState({ showItemsModal: false,});
      }

      

    

      


      removeItemHandler=(itemID)=>{
          console.log(itemID);
          //this.state.items.map(item=>(console.log(item.id)));
          this.setState({items: this.state.items.filter(function(item){
            if(item.id !== itemID){
                return item;
            }
          })});

          var quoteInfo = JSON.parse(localStorage.getItem('newquote'));
          const currentItems = quoteInfo['items'];
          const updItems = currentItems.filter((item)=> item.id !== itemID);
        quoteInfo['items'] = updItems;
        this.state.cartItems.pop("1");
        localStorage.setItem('newquote', JSON.stringify(quoteInfo));

      }

      myChangeHandler = (event) => {
        //console.log('Chages');
        let nam = event.target.name;
        let val = event.target.value;
        let err = '';
    
        if(val==="NaN")
        {
          err="Error!";
        }

        if(nam == "status" || nam == "clientStatus")
        {
          if(this.state.selectedCustomerID != '')
          {
            this.setState({canSave:false});
          }
        }

        this.setState({errormessage: err});
        this.setState({[nam]: val});
        //console.log(nam + " " + val);
        //console.log(val);
      }
     
      selectReciever=(type,id,name)=>{
          console.log("Quote: " + type + '--'+id+"--"+name);
          if(type == "selectedCustomer"){
              //console.log("There is a selected Customer");
              if(typeof id == "object")
              {
                this.setState({selectedCustomerID:id.cid});   
              }
              else{
                this.setState({selectedCustomerID:id});   
              }
              this.setState({selectedCustomerName:name});
              if(this.state.status == '' || this.state.clientStatus == ''){
                this.setState({canSave:true});
              }
              if(this.state.status !== '' && this.state.clientStatus !== ''){
                this.setState({canSave:false});
              }
              console.log(this.state.selectedCustomerID);
          }
          else{
              this.setState({selectedCustomerSelectedLocation:id});
          }
          console.log("Select Reciever: " + type + "-"+id);
      }

      removeItemFromTab(itemID,tabID){
        console.log("Remove Item to Current Tab: " + itemID);
        const { tabs, currentTab } = this.state;
        let ctItems='';
        let tabTotal='';
        let isLoadedPrice=0;
        const updatedTabs = tabs.map(tab => {
          if (tab.name === currentTab.name) {
            //console.log(tab);
            ctItems = tab.items;
            let cItem = ctItems.find(i=>i.id === itemID);
            const j = ctItems.findIndex(i=>i.id === itemID);
            if(cItem.fullprice !== "00.00"){
              isLoadedPrice = 1;
            }
            //console.log(parseFloat(tab.total) - " " + parseInt(this.state.QtyItemQty)+ " " +parseInt(cItem.qty)+ " " + parseFloat(cItem.price));
            tabTotal = parseFloat(tab.total) - (parseInt(cItem.qty) * parseFloat(cItem.fullprice));
            cItem.qty = 0;
            ctItems.splice([j],1);
            return {
              ...tab,
              items: ctItems,
              total:tabTotal
              //content: evt.target.value
            };
          } else {
            return tab;
          }
        });
        console.log("Current Tab Total: "+tabTotal);
        //console.log(updatedTabs);
        //this.setState({showQtyModal:false, currentTab:sResults,QtyItemID:'',QtyItemQty:''});
        this.setState({
          tabs: updatedTabs,
          currentTab: {
            ...currentTab,
            items: ctItems,
            total:tabTotal
          }
        });
      }

      transferItemToTab(itemObj,tabID){
        //console.log(tabID + "  "+itemObj.title);
 
        {/*let currentTabCall = this.state.currentTab;
        let ctItems = currentTabCall.items;
       
        let searchItem = ctItems.filter(i=>i.id == itemObj.id);*/}

        let tabs = this.state.tabs;

        let updatetab = tabs.filter(t=>t.id == tabID);
        
        updatetab[0].dateCreated = firebase.firestore.Timestamp.now();
        updatetab[0].items.push(itemObj);
        this.setState({tabs});
        console.log(tabs);

      }

      tabTransfer = (event) =>{
        console.log("Copying Item to Tab");
        let nam = event.target.name;
        let val = event.target.value;//id of tab to copy to
        
        let controlName = nam.split("_");
        let item_id = controlName[1];

        if(val !== "00"){
          //console.log(event.target);
          let currentTabCall = this.state.currentTab;
          //console.log(currentTabCall);
          let ctItems = currentTabCall.items;
          //console.log(ctItems);
          let searchItem = ctItems.filter(i=>i.id == item_id);
          //console.log(searchItem);
          let tabs = this.state.tabs;
          //console.log(tabs);
          let updatetab = tabs.filter(t=>t.id == val);
          let deepClone = {...searchItem[0]};
          /*
          console.log("Price: " + deepClone.price + " Full Price: " + deepClone.fullprice + " Qty: "+deepClone.qty);
          if(deepClone.fullprice === "00.00" && deepClone.qty > 0)
          {
            deepClone.fullprice = "00.00";
          }
          */
          updatetab[0].dateCreated = firebase.firestore.Timestamp.now();
          updatetab[0].items = [...updatetab[0].items, deepClone];//searchItem[0]];
          //console.log(updatetab[0].items);
          this.setState({tabs});
          console.log(tabs);
        }

        console.log("Tab Xfer Value:"+val);
      }

      backPage = (event) =>{
        //props.histroy.push('/itemsearch');
      }

      itemPriceUpdate(itemObj){
          if(itemObj !== undefined){
            console.log("Item Price Update:");console.log(itemObj);
            let tabs = this.state.tabs;
            //console.log(tabs);
            //console.log(itemObj.tabID);
            let updatetab = tabs.filter(t=>t.id == itemObj.tabID);
            //console.log(updatetab);
            const itemIndex = itemObj.itemID;
            updatetab[0].items[itemIndex] = itemObj;
            //console.log(updatetab[0].items);
            //const updatedItems = update(updatetab.items, {$splice: [[itemIndex,1,itemObj]]});
            this.setState({tabs},()=>{this.updateTabTotal(itemObj.tabID);});            
        }
      }

      updateTabTotal(tabID){
        console.log("Updating Tab Total for Tab: "+tabID)
        let tabs = this.state.tabs;
        console.log(tabs);
        let tab2Total = tabs.filter(t=>t.id == tabID);
        console.log(tab2Total);
        let ctItems = tab2Total[0].items;
        //console.log(ctItems);
        var tabTotal = parseFloat("0.00");
        ctItems.map(item => { console.log(item.qty + "-"+ item.fullprice); tabTotal = tabTotal + (Number(item.qty) * parseFloat(item.fullprice).toFixed(2))});
        tab2Total[0].total = tabTotal;
        if(tabID === this.state.currentTab.id)
        {
            this.state.currentTab.total = tabTotal;
        }
        this.setState({tabs});
        console.log(tabs);
      }

      //Tab Controller
      handleDoubleClick = () => {
        this.setState({
          editTabNameMode: true
        });
      };
    
      handleEditTabName = e => {
        const { currentTab, tabs } = this.state;
    
        const updatedTabs = tabs.map(tab => {
          if (tab.id === currentTab.id) {
            return {
              ...tab,
              name: e.target.value
            };
          } else {
            return tab;
          }
        });
    
        this.setState({
          tabs: updatedTabs,
          currentTab: {
            ...currentTab,
            name: e.target.value
          }
        });
      };
    
      handleOnBlur = () => {
        this.setState({
          editTabNameMode: false
        });
      };
    
      createTabs = () => {
        const { tabs, currentTab, editTabNameMode } = this.state;
    
        const allTabs = tabs.map(tab => {
          return (
            <li className="nav-item">
              {editTabNameMode && currentTab.id === tab.id ? (
                <input
                  value={tab.name}
                  onBlur={this.handleOnBlur}
                  onChange={this.handleEditTabName}
                  className="form-control"
                />
              ) : (
                <button
                  className={currentTab.id === tab.id ? "tab active" : "tab"}
                  onClick={() => this.handleSelectTab(tab)}
                  onDoubleClick={() => this.handleDoubleClick(tab)}
                >
                  {tab.name}
                </button>
              )}
            </li>
          );
        });
    
        return <ul className="nav nav-tabs">{allTabs}</ul>;
      };
    
      handleSelectTab = tab => {
        console.log("Selecting Tab: "+tab.id);
        this.setState({
          currentTab: tab,
          editMode: false,
          editTabNameMode: false
        });
      };
    
      handleAddTab = () => {
        const { tabs } = this.state;
        console.log("Add a new Tab");
        const newTabObject = {
          id: tabs.length + 1,//uuid(),
          name: `Tab ${tabs.length + 1}`,
          notes: `This is Tab ${tabs.length + 1}`,
          items:[],
          total:"00.00",
          dateCreated:firebase.firestore.Timestamp.now()
        };
    
        this.setState({
          tabs: [...tabs, newTabObject],
          currentTab: newTabObject,
          editMode: false,
          editTabNameMode: false
        });
      };
    
      handleDeleteTab = tabToDelete => {
        //alert(tabToDelete);
        if(tabToDelete.id != 1 && tabToDelete.id != 99){
        const { tabs } = this.state;
        const tabToDeleteIndex = tabs.findIndex(tab => tab.id === tabToDelete.id);
    
        const updatedTabs = tabs.filter((tab, index) => {
          return index !== tabToDeleteIndex;
        });
    
        const previousTab =
          tabs[tabToDeleteIndex - 1] || tabs[tabToDeleteIndex + 1] || {};
    
        this.setState({
          tabs: updatedTabs,
          editMode: false,
          editTabNameMode: false,
          currentTab: previousTab
        });

      }
      else{
        alert("Cannot Delete This Tab");
      }
      };
    
      setEditMode = () => {
        this.setState({
          editMode: !this.state.editMode
        });
      };
    
      handleContentChange = e => {
        const { tabs, currentTab } = this.state;
    
        const updatedTabs = tabs.map(tab => {
          if (tab.name === currentTab.name) {
            return {
              ...tab,
              content: e.target.value
            };
          } else {
            return tab;
          }
        });
    
        this.setState({
          tabs: updatedTabs,
          currentTab: {
            ...currentTab,
            content: e.target.value
          }
        });
      };
      //END TAB CONTROLLER

      render() {
        const goBack = () => {
          this.props.history.goBack()
      }
        const {statusDomain,clientStatusDomain,items,QtyItemID,MUitemID,MUitemPrice,frieghtModalItem,frieghtModalOrigin, frieghtModalCost,quoteDate,quoteID,totalCost,currentTab, editMode,selectedCustomerID,userID} = this.state;

        return (
          <AuthUserContext.Consumer>
            {authUser=>(
            <div className="content">
                <TableModal show={this.state.showItemsModal} handleClose={e => this.closeItemsModal(e)}>
                <div className="modal-content">
                <div className="modal-header">
                                        <h5 className="modal-title">Search Items</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e=>this.closeItemsModal()}>
                                          <span aria-hidden="true">&times;</span>
                                        </button>
                                      </div>
                <div>
                <div className="clear-filters" style={{float:"left",marginRight:10+"px"}}>
                            <button type="button" className="btn btn-outline-danger right" onClick={e => this.clearSearchFilters()}><FontAwesomeIcon icon={faRedo}></FontAwesomeIcon> Clear Search/Filters</button>
                            </div>
                            <table id="example" className="display">
                                      <thead></thead>
                                      <tbody></tbody>
                                      <tfoot>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                      </tfoot>
                                  </table>
                    </div>
                    </div>
                </TableModal>
                <nav className="navbar navbar-expand-lg navbar-light bg-yellow">
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav">
                        <li className="nav-item">
                            <button className="btn btn-outline-light" style={{marginTop:"25px"}} onClick={goBack}>Back</button>
                        </li>
                        <li className="nav-item">
                        <button className="btn btn-outline-light text-white" style={{marginTop:"25px"}} type="button" onClick={e=>this.clearCart()}>Clear Cart  <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon></button>
                        </li>
                        </ul>
                    </div>
                    </nav>
<div className="container-fluid">
                      <p>
  <a className="btn btn-outline-warning" style={{margin:5+"px"}} data-toggle="collapse" href="#multiCollapseExample1" role="button" aria-expanded="false" aria-controls="multiCollapseExample1">Toggle Status</a>
  <button style={{margin:5+"px"}} className="btn btn-outline-warning" type="button" data-toggle="collapse" data-target="#multiCollapseExample2" aria-expanded="false" aria-controls="multiCollapseExample2">Toggle Recipient</button>
  <button style={{margin:5+"px"}} className="btn btn-outline-warning" type="button" data-toggle="collapse" data-target="#multiCollapseExample3" aria-expanded="false" aria-controls="multiCollapseExample3">Toggle Sales Info</button>
  <button style={{margin:5+"px"}} className="btn btn-outline-warning" type="button" data-toggle="collapse" data-target=".multi-collapse" aria-expanded="false" aria-controls="multiCollapseExample1 multiCollapseExample2 multiCollapseExample3">Toggle All</button>
  <button style={{margin:5+"px"}} className="btn btn-success" onClick={e=>this.saveCart()} disabled={this.state.canSave}>Save Quote</button>
</p>
<div className="row">
  <div className="col">
    <div className="collapse multi-collapse" id="multiCollapseExample1">
      <div className="card card-body">
      <div className="card">
                            <div className="card-body">
                            <div className="form-group">
                                <label htmlFor="status">Internal Status:</label>
                                <select id="status" name="status" className="form-control" onChange={this.myChangeHandler}>
                                    <option value="00">Select Status...</option>
                                    {statusDomain.map(sta=>(
                                        <option value={sta.statusValue}>{sta.statusValue}</option>
                                    ))
                                    }
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="clientStatus">Client Quote Status:</label>
                                <select id="clientStatus" name="clientStatus" className="form-control" onChange={this.myChangeHandler}>
                                    <option value="00">Select Status...</option>
                                    {clientStatusDomain.map(cs=>(
                                        <option value={cs.clientStatus}>{cs.clientStatus}</option>
                                    ))
                                    }
                                </select>
                            </div>
                                <div className="row">
                                  
                                </div>
                            </div>
                            </div>
      </div>
    </div>
  </div>
  <div className="col">
    <div className="collapse multi-collapse" id="multiCollapseExample2">
      <div className="card card-body">
      <RecieverCard onSelect={this.selectReciever}></RecieverCard>
      </div>
    </div>
  </div>
  <div className="col">
    <div className="collapse multi-collapse" id="multiCollapseExample3">
      <div className="card card-body">
      <div className="card">
                            <div className="card-body">
                            <div className="form-group">
                                <label htmlFor="salesOrderNumber">Sharratt Sales Order:</label>
                                <input type="text" name="salesOrderNumber" className="form-control" onChange={this.myChangeHandler}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="purchaseOrderNumber">Purchase Order:</label>
                                <input type="text" name="purchaseOrderNumber" className="form-control" onChange={this.myChangeHandler}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="shippingOrderNumber">Shipping/Transportation PO:</label>
                                <input type="text" name="shippingOrderNumber" className="form-control" onChange={this.myChangeHandler}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="customerPO">Customer PO:</label>
                                <input type="text" name="customerPO" className="form-control" onChange={this.myChangeHandler}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="recieverName">Customer Name/Reciever Name:</label>
                                <input type="text" name="recieverName" className="form-control" onChange={this.myChangeHandler}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="salesNotes">Sales Notes:</label>
                                <input type="text" name="salesNotes" className="form-control" onChange={this.myChangeHandler}/>
                            </div>
                            </div>
                            </div>
      </div>
    </div>
  </div>
</div>
                    <div className="row">
                        <div className="col-md-12">
                                <div className="container">
                                    <div className="well">
                                    <button className="btn-sm btn-info" onClick={this.handleAddTab} style={{marginBottom:10+"px"}}>
                                        <FontAwesomeIcon icon={faPlus}/> Add Tab
                                    </button>
                                    <button className="btn-sm btn-warning" onClick={e=>this.openItemsModal()} style={{marginLeft:10+"px",marginBottom:10+"px"}}>
                                        <FontAwesomeIcon icon={faPlus}/> Add Item
                                    </button>
                                    {this.createTabs()}
                                    <div className="tab-content" style={{marginTop:10+"px"}}>
                                        {editMode ? (
                                        <div>
                                            <textarea
                                            onChange={this.handleContentChange}
                                            value={this.state.currentTab.notes}
                                            />
                                            <button className="btn btn-success" onClick={this.setEditMode}>
                                            Done
                                            </button>
                                        </div>
                                        ) : (
                                        <div>
                                          <div className="row">
                                            <div className="col-md-3"><strong>Tab Total: </strong>${(Math.round(this.state.currentTab.total*100)/100).toFixed(2)}
                                            </div>
                                            <div className="col-md-3"><strong>Total Items: </strong>{this.state.currentTab.items.length}</div>
                                            <div className="col-md-3"></div>
                                            <div className="col-md-3"></div>
                                            </div>
                                            <table className="table table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Item</th>
                                        <th>Case Price</th>
                                        <th>Qty</th>
                                        <th>Freight Cost</th>
                                        <th>Mark up</th>
                                        <th>Final Case Price/Unit Price</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                {this.state.currentTab.items.map((itemData,i)=> (
                                    <ItemCard key={i} item={itemData} tabID={currentTab.id} itemTabID={this.state.currentTab.items.indexOf(itemData)} tabNames={this.state.tabs} itemPriceUpdate={this.itemPriceUpdate.bind(this)} removeItem={this.removeItemFromTab.bind(this)} transferItem={this.transferItemToTab.bind(this)}/>
                                ))}
                                </tbody>
                                
                                </table>
                                            {currentTab.id ? (
                                            <div
                                                style={{ display: "flex", justifyContent: "space-between" }}
                                            >
                                                <button className="btn btn-danger" onClick={() => this.handleDeleteTab(currentTab)}>
                                                    <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>&nbsp;
                                                Delete Tab
                                                </button>
                                                
                                                <button className="btn btn-success" onClick={e=>this.saveCart()} disabled={this.state.canSave}>Save Quote</button>
                                            </div>
                                            ) : (
                                            ""
                                            )}
                                        </div>
                                        )}
                                    </div>
                                    </div>
                                </div>
                        
                        </div>
                        
                    </div>
                    <div className="row">
                        <div className="col-md-7"></div>
                        <div className="col-md-4">
                       
                        </div>
                    </div>
                    </div>
            </div>
                        )}
                        </AuthUserContext.Consumer>
        );
      }
    }
    
    
    export default withFirebase(QA);
    
