import React, { Component } from 'react';
import * as ROUTES from '../../constants/routes';
import {withFirebase} from '../Firebase';
import firebase from 'firebase';
import moment from "moment";
import { withAuthorization } from '../Session';
import 'bootstrap/dist/css/bootstrap.min.css';
import pageStyles from './view.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faEye, faEyeSlash,faFile,faPrint,faPhone, faEnvelope,faGlobe,faStar,faTrash,faPlus,faMinus,faRedo,faCheck,faThumbtack,faUserPlus,faTimes,faEdit,faEraser, faStickyNote,faIndustry,faBuilding,faHome, faTrashAlt,faPencilAlt, faCheckCircle, faInfoCircle} from '@fortawesome/free-solid-svg-icons';


class QuoteBreakdownView extends Component{

    constructor(props) {
        super(props);
        this.state = {
            quoteID:this.props.match.params.qid,
            tabID:'',
            showTotal:'',
            tabsArray:[],
            loadedData:false,
            pricedItems:[],
            itemsArray:[],
            priceArray:[],
            tabTotal:'',
            quote:'',
            shipToLocation:'',
            loading:true,
        }
       
      }

      componentDidMount(){
        console.log("Quote ID: " + this.props.match.params.qid);
        this.unsubscribe = this.unsubscribe = this.props.firebase
        .getFPItems()
        .onSnapshot(snapshot => {
   
            let pricedItems=[];
  
            snapshot.forEach(doc =>{
                console.log(doc.data().itemName + " " + doc.data().needsPricing + " " + doc.data().fobCasePrice);
              pricedItems.push({ ...doc.data(),itemLink:'<a href="/'+doc.data().companyid+'/item/'+doc.id+'">'+doc.data().itemName+'</a>', itemSizeFull:doc.data().unitSize + " " + doc.data().unitUnit ,fobFullLocation: doc.data().fobCity + " " + doc.data().fobState,supplierPrice:doc.data().fobCasePrice,sharrattPrice:doc.data().fullPrice, itemid: doc.id});
            });

            this.setState({pricedItems}, function(){console.log("Complete Loading Full Priced Query " + pricedItems.length);});;
        });

        //console.log("Priced Items:"+ this.state.pricedItems.length);

        this.unsubscribe = this.props.firebase.db
        .collection(`quotes`).doc(this.props.match.params.qid)
        .onSnapshot(qsnapshot => {
            //console.log(pricedItems.length);
            //console.log(snapshot.data());
            var tabsArray = [];
            var itemsArray = [];
            let tabTotal = '';
            for (var key in qsnapshot.data().tabs) {
                tabsArray.push(qsnapshot.data().tabs[key]);
                //console.log("Tabs Available in this Quote: " + tabsArray.length);
                
                //itemsArray[qsnapshot.data().tabs[key].id] = "333";
                
                /*if(snapshot.data().tabs[key].id == "1")
                {
                    console.log("Match: " + snapshot.data().tabs[key].id);
                    tabTotal = parseFloat(snapshot.data().tabs[key].total).toFixed(2);
                    for (var k in snapshot.data().tabs[key].items)
                    {
                        itemsArray.push(snapshot.data().tabs[key].items[k])
                    }
                }*/
            }
            //console.log(tabsArray.length + "  " + itemsArray.length);
            //console.log(itemsArray[0]);
            //console.log("Full Price Items: " + ref);
            
            tabsArray.sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));

            this.setState({
                quote:qsnapshot.data(),
                tabsArray:tabsArray,
                //itemsArray:itemsArray,
                //tabTotal:tabTotal,
                loading:false,
            });
        });
      }

      hideTab(tabID)
      {
        //alert("Visibility Click");
        if(document.getElementById(tabID).style.display == "none")
        {
            document.getElementById(tabID).style.display = "table";
            //document.getElementById("tabVis_"+tabID).class="fa-eye";
        }
        else{
            document.getElementById(tabID).style.display = "none";
        }
        
      }

      getFOBPrice(itemID)
      {
          //let itemFOBPrice = this.state.priceItems.find(item=>item.id == itemID).fobCasePrice;
          //const result = inventory.find( ({ name }) => name === 'cherries' );
          console.log("Querying FOB Case Price for: "+itemID);
          let itemFOBPrice = this.state.pricedItems.find( ({ itemid }) => itemid === itemID );
          console.log(itemFOBPrice);
          return "$"+itemFOBPrice.fobCasePrice;
      }

      getShippedPrice(itemID,shippingCost,qty)
      {
          //let itemFOBPrice = this.state.priceItems.find(item=>item.id == itemID).fobCasePrice;
          //const result = inventory.find( ({ name }) => name === 'cherries' );
          //console.log("Querying FOB Case Price for: "+itemID);
          let itemFOBPrice = this.state.pricedItems.find( ({ itemid }) => itemid === itemID );
          let perCaseShip = shippingCost/qty;
          let shippedCost = (parseFloat(itemFOBPrice.fobCasePrice) + parseFloat(perCaseShip)).toFixed(2);
          //console.log(parseFloat(itemFOBPrice) + parseFloat(perCaseShip));
          return "$"+shippedCost;
      }

     

      getMargin(itemID)
      {
          //let itemFOBPrice = this.state.priceItems.find(item=>item.id == itemID).fobCasePrice;
          //const result = inventory.find( ({ name }) => name === 'cherries' );
          //console.log("Querying FOB Case Price for: "+itemID);
          let itemFOBPrice = this.state.pricedItems.find( ({ itemid }) => itemid === itemID );
          //console.log(itemFOBPrice);
          return "$"+itemFOBPrice.fobCasePrice;
      }

      render() {
        const goBack = () => {
            this.props.history.goBack()
        }

        const getNET= (itemID,shippingCost,qty,fullPrice)=>
        {
            //let itemFOBPrice = this.state.priceItems.find(item=>item.id == itemID).fobCasePrice;
            //const result = inventory.find( ({ name }) => name === 'cherries' );
            //console.log("Querying FOB Case Price for: "+itemID);
            let itemFOBPrice = this.state.pricedItems.find( ({ itemid }) => itemid === itemID );
            let perCaseShip = shippingCost/qty;
            let shippedCost = parseFloat(itemFOBPrice.fobCasePrice).toFixed(2) + parseFloat(perCaseShip).toFixed(2);
            let NetProfit = parseFloat(fullPrice).toFixed(2) - parseFloat(shippedCost).toFixed(2) - parseFloat(perCaseShip).toFixed(2);
            let totalNetProfit = parseFloat(NetProfit)*Number(qty);
            console.log(itemFOBPrice + " " + perCaseShip + " " + shippedCost + " "+totalNetProfit + " "+ NetProfit + " " + fullPrice);
            if(NetProfit <= 0)
            {
                return (<span style={{color:'red'}}>${(totalNetProfit).toFixed(2)}</span>);
            }else{
              return "$"+(totalNetProfit).toFixed(2);
            }
            
        }

        const getMargin=(itemID,shippingCost,qty,fullPrice)=>
        {
            let itemFOBPrice = this.state.pricedItems.find( ({ itemid }) => itemid === itemID );
            let perCaseShip = shippingCost/qty;
            let shippedCost = parseFloat(itemFOBPrice.fobCasePrice).toFixed(2) + parseFloat(perCaseShip).toFixed(2);
            let NetProfit = parseFloat(fullPrice).toFixed(2) - parseFloat(shippedCost).toFixed(2) - parseFloat(perCaseShip).toFixed(2);
            //console.log(itemFOBPrice);
            let marginValue = NetProfit/parseFloat(fullPrice).toFixed(2)*100;
            if(marginValue <= 0)
            {
                return (<span style={{color:'red'}}>{(marginValue).toFixed(2)}%</span>);
            }else{
              return (marginValue).toFixed(2)+"%";
            }
        }

        const {quote,quoteID,shipToLocation,tabID,tabTotal,itemsArray,loading,tabsArray} = this.state;
        if(tabsArray.length <= 1)
        {
          return (<div><h1>Loading Quote Data Please Wait...</h1></div>)
        }
        return (
            <div className="content">
                <nav className="navbar navbar-expand-lg navbar-light bg-yellow">
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav">
                        <li className="nav-item">
                            <button className="btn btn-outline-light" style={{marginTop:"25px"}} onClick={goBack}>Back</button>
                        </li>
                        <li className="nav-item">
                        <a className="nav-link-menu" href="/quotes">Quotes List</a>
                        </li>
                        </ul>
                    </div>
                    </nav>
                    {loading && <h1>Loading State...</h1>}
            <div style={{margin:20+"px"}}>
                {/*<div className="row">
                <div className="col-md-12">
                    <table className="table">
                        <thead>
                        <tr>
                            <th>Tab</th>
                            <th>Item</th>
                            <th>FOB Price</th>
                            <th>Freight Cost</th>
                            <th>Delivered Cost</th>
                            <th>Sharratt Price</th>
                            <th>Quote Mark-Up</th>
                            <th>Quote Price (wo/freight)</th>
                            <th>NET $</th>
                            <th>% Margin</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tabsArray.map(tabItem=>(
                            tabItem.items.map(orderItem=>(
                                <tr key={orderItem.id}>
                                    <td>{tabItem.name}({tabItem.id})</td>
                                    <td>{orderItem.title}</td>
                                    <td>{this.getFOBPrice(orderItem.id)}</td>
                                    <td>${(Math.round(orderItem.freightcost*100)/100).toFixed(2)}</td>
                                    <td>{this.getShippedPrice(orderItem.id,orderItem.freightcost,orderItem.qty)}</td>
                                    <td>${(Math.round(orderItem.price*100)/100).toFixed(2)}</td>
                                    {orderItem.mutype =="Dollar" && <td>${orderItem.muvalue}</td>}
                                    {orderItem.mutype =="Percent" && <td>{orderItem.muvalue}%</td>}
                                    <td>${(Math.round(parseFloat(((orderItem.fullprice*orderItem.qty)-orderItem.freightcost)/orderItem.qty)*100)/100).toFixed(2)}</td>
                                    <td>{getNET(orderItem.id,(Math.round(orderItem.freightcost*100)/100).toFixed(2),orderItem.qty,orderItem.fullprice)}</td>
                                    <td>% Margin ($ Profit/QuotePrice)</td>
                                </tr>
                            ))
                        ))}
                        </tbody>
                    </table>
                </div>
                            </div>*/}
                {tabsArray.map(tabItem=>(
                    <div className="row" >
                        <div className="col-md-12">
                            <h5 style={{display:"inline"}}>Tab: {tabItem.name} ({tabItem.id})</h5><FontAwesomeIcon id={"tabVis_"+tabItem.id} style={{color:"lightgrey",marginLeft:15+"px",cursor:"pointer"}} icon={faEyeSlash} onClick={e=>this.hideTab(tabItem.id)}></FontAwesomeIcon> 
                            <table className="table" id={tabItem.id}>
                        <thead>
                        <tr>
                            <th>Tab</th>
                            <th>Item</th>
                            <th style={{borderLeftStyle:'solid'}}>FOB Price</th>
                            <th>Freight Cost</th>
                            <th style={{borderRightStyle:'solid'}}>Delivered Cost</th>
                            <th>Sharratt Price</th>
                            <th>Quote Mark-Up</th>
                            <th style={{borderRightStyle:'solid'}}>Quote Price (wo/freight)</th>
                            <th>Final Case Price</th>
                            <th>NET $</th>
                            <th>% Margin</th>
                        </tr>
                        </thead>
                        <tbody>
                            {tabItem.items.map(orderItem=>(
                                <tr key={orderItem.id}>
                                    <td>{tabItem.name}({tabItem.id})</td>
                                    <td>{orderItem.title}</td>
                                    <td style={{borderLeftStyle:'solid'}}>{this.getFOBPrice(orderItem.id)}</td>
                                    <td>${(orderItem.freightcost*100/100).toFixed(2)}</td>
                                    <td style={{borderRightStyle:'solid'}}>{this.getShippedPrice(orderItem.id,orderItem.freightcost,orderItem.qty)}</td>
                                    <td>${((orderItem.price*100)/100).toFixed(2)}</td>
                                    {orderItem.mutype =="Dollar" ? <td>${orderItem.muvalue}</td>: <td>{orderItem.muvalue}%</td>}
                                    {/*orderItem.mutype =="Percent" && <td>{orderItem.muvalue}%</td>*/}
                                    <td style={{borderRightStyle:'solid'}}>${(Math.round(parseFloat(((orderItem.fullprice*orderItem.qty)-orderItem.freightcost)/orderItem.qty)*100)/100).toFixed(2)}</td>
                                    <td>${parseFloat(orderItem.fullprice).toFixed(2)}</td>
                                    <td>{getNET(orderItem.id,(orderItem.freightcost*100/100).toFixed(2),orderItem.qty,orderItem.fullprice.toFixed(2))}</td>
                                    <td>{getMargin(orderItem.id,(orderItem.freightcost*100/100).toFixed(2),orderItem.qty,orderItem.fullprice.toFixed(2))}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                        </div>
                    </div>
                ))}
            </div>
            </div>
        );
      }
    }
    
    
    export default withFirebase(QuoteBreakdownView);
    
