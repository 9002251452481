import React, {Componenet, Component} from 'react';
import {compose} from 'recompose';

import {withFirebase} from '../Firebase';

class NoteBase extends Component {
    constructor(props){
        super(props);

        this.state = {
            loading:false,
            companyid:null,
            notes:[],
        };
    }

    componentDidMount(){
        this.setState({loading:true});
        this.setState({companyid:this.props.match.params.cid});

        this.unsubscribe = this.props.firebase
            .notesByCompany(this.props.match.params.cid)
            .orderBy('datetime', "desc")
            .onSnapshot(snapshot => {
                let noteArray = [];

                snapshot.forEach(doc =>
                    noteArray.push({ comment:doc.data().comment,timestamp:new Date(doc.data().datetime.seconds * 1000).toDateString() + "-" +new Date(doc.data().datetime.seconds * 1000).toTimeString(), uid: doc.id }),
                );
    
                console.log(noteArray);

                this.setState({
                    notes:noteArray,
                    loading: false,
                });
            });

        console.log(this.state.notes.length);

        /*let rtn =[];
        const notesRefRtn = this.props.firebase.notesRef();
        const snapshot = await notesRefRtn.where('entityID','==','y1aR7a8CkNx7mNy61ZNF').get();

        if (snapshot.empty) {
            console.log('No matching documents.');
            this.setState({notes:[]});
            return;
        }  
          
        snapshot.forEach(doc => {
            rtn.push({ ...doc.data().comment, cid:doc.id});
        });

        this.setState({notes:rtn,loading:false});

        console.log(rtn.length);*/
       
    }

    componentWillUnmount(){
        //this.unsubscribe();
    }

    render(){
        const {notes, loading} = this.state;

        return(
            <div>
                {loading && <div>Loading...</div>}
                {notes ? (<NoteList notes={notes}/>):(<div>No notes on file...</div>)}
            </div>
        );
    }
}

const NoteList = ({notes}) => (
    <ul>
        {notes.map(note=>(<NoteItem key={note.uid} note={note}/>))}
    </ul>
);

const NoteItem = ({note})=>(
    <li><strong>{note.timestamp}:</strong> {note.comment}</li>
);

export default withFirebase(NoteBase);