import React, {Component} from 'react';
import {compose} from 'recompose';

import {withAuthorization} from '../Session';
import {withFirebase} from '../Firebase';

class AddUserPage extends Component{

    constructor(props) {
        super(props);
        this.state = {
          userID:this.props.match.params.uid,
          role:'',
          fname:'',
          lname:'',
          email:'',
          phone:'',
          isActive:'',
          errormessage: ''
        };
      }

      mySubmitHandler = (event) => {
        event.preventDefault();
        /*let age = this.state.age;
        if (!Number(age)) {
          alert("Your age must be a number");
        }*/
        this.props.firebase.contacts(this.state.userID).update({
          email:this.state.email,
          fname:this.state.fname,
          lname:this.state.lname,
          lname:this.state.lname,
          phone:this.state.phone,
          role:this.state.role
        });

        this.setState({});
        this.props.history.push('/users');
      }

      componentDidMount(){
        this.unsubscribe = this.props.firebase
        .getUser(this.state.userID)
        .onSnapshot(snapshot => {
          this.setState({
            email:snapshot.data().email,
            fname:snapshot.data().fname,
            lname:snapshot.data().lname,
            lname:snapshot.data().lname,
            phone:snapshot.data().phone,
            role:snapshot.data().role
          });

          //console.log(this.state.location.stateAbbr);
        });
    }

      myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        let err = '';
        if (nam === "age") {
          if (val !="" && !Number(val)) {
            err = <strong>Your age must be a number</strong>;
          }
        }
        this.setState({errormessage: err});
        this.setState({[nam]: val});
      }

      setNewValue(newValue) {
        console.log('this is the State code:' + newValue);
      }

      cancelForm = (event)=>{
        console.log("Form Cancelled");
        this.props.history.push('/users');
      }
      render() {
        return (
          <div className="container">
          <form onSubmit={this.mySubmitHandler}>
          <h1>Add a User</h1>
          {this.state.errormessage}
          <p>User First Name:</p>
          <input
            type='text'
            name='fname'
            className="form-control"
            onChange={this.myChangeHandler}
            value={this.state.fname}
          />
          <p>User Last Name:</p>
          <input
            type='text'
            name='lname'
            className="form-control"
            onChange={this.myChangeHandler}
            value={this.state.lname}
          />
          <p>Role:</p>
          <select name="role" className="form-control" onChange={this.myChangeHandler} value={this.state.role}>
            <option value="00">Select Role</option>
              <option value="Admin">Admin</option>
              <option value="Sales">Sales</option>
              <option value="Buyer">Buyer</option>
              <option value="Office">Office</option>
          </select>
          <p>E-mail</p>
          <input
            type='text'
            name='email'
            className="form-control"
            onChange={this.myChangeHandler}
            value={this.state.email}
          />
          <p>Phone:</p>
          <input
            type='phone'
            name='phone'
            className="form-control"
            onChange={this.myChangeHandler}
            value={this.state.phone}
          />
          
          <div className="row" style={{marginTop:15+"px"}}>
            <div className="col-md-4"><input type="button" className="btn btn-danger" onClick={this.cancelForm} value="Cancel"/></div>
            <div className="col-md-4"></div>
            <div className="col-md-4"><input type="submit" className="btn btn-success" value="Submit"/></div>
          </div>
          </form>
          </div>
        );
      }
    }

    export default withFirebase(AddUserPage);
