import React from 'react';
import { Calendar, momentLocalizer,Views } from "react-big-calendar";
import {withFirebase} from '../Firebase';
import firebase from 'firebase';
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";

import "react-big-calendar/lib/addons/dragAndDrop/styles.css";

const propTypes = {}
const localizer = momentLocalizer(moment);

let allViews = Object.keys(Views).map(k => Views[k])

const DragAndDropCalendar = withDragAndDrop(Calendar)

const ColoredDateCellWrapper = ({ children }) =>
  React.cloneElement(React.Children.only(children), {
    style: {
      backgroundColor: 'lightblue',
    },
  })

class DragDropCal extends React.Component {
  constructor(props) {
    super(props)

    this.state = { 
        events:this.props.events,
        filteredEvents:[],
        authUserID:this.props.auid,
        role:this.props.role,
    };

    this.moveEvent = this.moveEvent.bind(this);
  }

  handleSelect = ({ start, end }) => {
    const title = window.prompt('New Event name')
    if (title)
      this.setState({
        events: [
          ...this.state.events,
          {
            start,
            end,
            title,
          },
        ],
      })
  }

  moveEvent({ event, start, end }) {
    const { events } = this.state

    //alert(`${event.id} now moved to ${start} - ${end}`);

    const idx = events.indexOf(event)
    const updatedEvent = { ...event, start, end }

    const nextEvents = [...events]
    nextEvents.splice(idx, 1, updatedEvent)

    /*this.setState({
      events: nextEvents,
    })*/

    this.props.firebase.db.collection('reminders').doc(event.id).update({
      reminderDate:start
    });

    this.setState({
      events: nextEvents,
    })

    //window.location.reload();
    //alert(`${event.title} was dropped onto ${event.start}`)
  }

  resizeEvent = (resizeType, { event, start, end }) => {
    const { events } = this.state

    const nextEvents = events.map(existingEvent => {
      return existingEvent.id == event.id
        ? { ...existingEvent, start, end }
        : existingEvent
    })

    alert(`${event.title} was resized to ${start}-${end}`)
  }
  componentDidMount(){
      //console.log("Props: " + this.props.events + " State: " + this.state.events + " User ID: "+this.state.authUserID);
      //this.setState({events:this.props.events});
      //console.log("Events Count: " + this.state.events.length);
      //this.state.events.map((reminderItem)=>console.log(reminderItem.userID + " " + this.state.authUserID));
      //const newList = this.state.events.filter(item=>item.userID == this.state.authUserID);
      //alert(this.state.role);
      if(this.state.role != "Admin")
      {
        this.setState({filteredEvents:this.state.events.filter(item=>item.userID == this.state.authUserID)});
      }
      else{
        this.setState({filteredEvents:this.state.events});
      }
      
  }

  eventStyleGetter(event, start, end, isSelected) {
    console.log(event);
    var backgroundColor = '#B22222';
    var style = {
        backgroundColor: backgroundColor,
        borderRadius: '10px',
        opacity: 0.8,
        color: 'white',
        border: '0px',
        display: 'block'
    };
    return {
        style: style
    };
}

  render() {
      const {filteredEvents} = this.state;
    return (
      <div style={{background:"white",border:"1px solid rgb(0,0,0,0.125)",height:"100%",fontSize:.75+"em"}}>
        <DragAndDropCalendar
        selectable
        localizer={localizer}
        events={filteredEvents}
        components={{
            timeSlotWrapper: ColoredDateCellWrapper,
          }}
        startAccessor="start"
        endAccessor="end"
        onSelectEvent={event => window.open('../company/'+event.entityID+'#reminders', "_blank")}
        onEventDrop={this.moveEvent}
        //onSelectSlot={this.handleSelect}
        //eventPropGetter={(this.eventStyleGetter)}
        eventPropGetter={
          (event, start, end, isSelected) => {
            let newStyle = {
              backgroundColor: "#B22222",
              borderRadius: '10px',
              opacity: 0.8,
              color: 'white',
              border: '0px',
              display: 'block'
            };
      
            if (event.userID == this.state.authUserID){
              newStyle.backgroundColor = "#5a76a7"
            }
      
            return {
              className: "",
              style: newStyle
            };
          }
        }
      /></div>
    )
  }
}

DragDropCal.propTypes = propTypes

export default withFirebase(DragDropCal)