import React, { Component } from 'react';
import { Switch,Route, Link } from 'react-router-dom';

import * as ROUTES from '../../constants/routes';
import {withFirebase} from '../Firebase';
import firebase from 'firebase';
import ChartistGraph from 'react-chartist'


import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faHammer} from '@fortawesome/free-solid-svg-icons';

class QuoteTable extends Component {
    constructor(props){
        super(props);

        this.state = {
            loading:false,
            companyid:this.props.cid,
            columns:[
                  {
                    dataField: "comment",
                    text: "Comment",
                    sort: true
                  },
                  {
                    dataField: "timestamp",
                    text: "Time Stamp",
                    sort:true
                  },
                  {
                      dataField: "pinned",
                      text: "Pinned Comment",
                      sort:false
                  }      
            ],
            quotes:[],
        };
    }

    componentDidMount(){
        this.setState({loading:true});
        this.setState({companyid:this.props.cid});
        console.log("Quote Table: " + this.state.companyid);
        
        this.unsubscribe = this.props.firebase
            .quotesByCompany(this.props.cid)
            .orderBy('quoteDate', "desc")
            .onSnapshot(snapshot => {
                let quotes = [];

                snapshot.forEach(doc =>
                    quotes.push({ name:doc.data().selectedCustomerName,status:doc.data().status,timestamp:doc.data().quoteDate, clientStatus: doc.data().clientStatus,uid: doc.id }),
                );
    
                //console.log(noteArray);

                this.setState({
                    quotes,
                    loading: false,
                });
            });

        console.log("Quotes Count: " + this.state.quotes.length);

    }

    componentWillUnmount(){
        //this.unsubscribe();
    }

    render(){
        const {quotes, loading} = this.state;

        return(
            <div style={{marginTop:25+"px"}}>
                
            {/*<h1><FontAwesomeIcon icon={faHammer}></FontAwesomeIcon>  Feature Under Construction  <FontAwesomeIcon icon={faHammer}></FontAwesomeIcon></h1>*/}
                <table className="table table-striped table-hover">
                            <thead>
                                    <tr>
                                        <th>Date/Time</th>
                                        <th>Status</th>
                                        <th>Client Status</th>
                                        <th></th>						
                                    </tr>
                                </thead>
                                <tbody>
                                {quotes.map(qt=> (
                                   <tr key={qt.uid}>
                                       <td>{new Date(qt.timestamp).toLocaleDateString()}</td>
                                       <td>{qt.clientStatus}</td>
                                       <td>{qt.status}</td>
                                       <td><a href={'../quote/'+qt.uid} target="_blank">VIEW</a></td>
                                   </tr>     
                                ))}
                                </tbody>
                                </table>
            </div>
        );
    }
}
export default withFirebase(QuoteTable);