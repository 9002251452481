import React,{Component,useState} from 'react';
import * as ROUTES from '../../constants/routes';
import { Route,withRouter, Link} from 'react-router-dom';
import { compose } from 'recompose';
import warehouse from '../../images/coming_sonn.jpeg';
import { faEdit,faPlusCircle,faHome, faMinus,faPlus, faThList, faThumbtack} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCheck,faTrash,faShoppingBasket,faRedo,faUser,faUserCircle,faLock,faSearch,faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import QuoteRecieverCard from '../Quote/recievercard';
import ItemCard from '../Cart/itemcard';
import { withAuthorization } from '../Session';
import { AuthUserContext } from '../Session';
import {withFirebase} from '../Firebase';
import firebase from 'firebase';
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from '../Modal';
import TableModal from '../Modal/tablemodal';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { render } from "react-dom";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

Number.prototype.toFixedNoRound = function(precision = 2) {
  const factor = Math.pow(10,precision);
  return Math.floor(this * factor)/factor;
}


class EditQuote extends Component{

    constructor(props) {
        super(props);

        this.state = {
         default:'',
         usersList:[],
         contactInfo:'',
         userID:'',
         quoteInfo:'',
         quoteID:this.props.match.params.qid,
         dbID:'',
         quoteDate:'',
         customerPO:'',
         salesNotes:'',
         salesOrderNumber:'',
         recieverName:'',
         items:[],
         freight:'',
         contact:'',
         customers:'',
         totalCost:'00.00',
         loading:true,
         hideRecipient:true,
         note:'',
         status:'',
         clientStatus:'',
         customers:[],
         selectedCustomerName:'',
         selectedCustomerID:'',
         selectedCustomerSelectedLocation:'',
         quoteNote:'',
         itemsModel:false,
         showMUModal:false,
         showQtyModal:false,
         activeRequest: true,
         showFreightModal:false,
         frieghtModalCost:'',
         frieghtModalOrigin:'',
         frieghtModalItem:'',
         tabs:[],
          currentTab: '',
          editMode: false,
          editTabNameMode: false,
          MUitemID:'',
          itemFOBPrice:'',
          itemMUPrice:'',
          itemMUValue:'',
          itemMUType:'',
          QtyItemID:'',
          QtyItemQty:'',
          tabSelect:'',
          pricedItems:[],
          customerPO:'',
          salesOrderNumber:'',
          shippingOrderNumber:'',
          purchaseOrderNumber:'',
          salesNotes:'',
          quoteCode:'',
          showTotal:true,
          agentName:'',
          isTemplate:'',
          showQuotes:false,
          otherQuotes:[],
          showNotes:false,
          quoteNotes:[],
          pinnedNotes:[],
          quoteItemNote:'',
          currentUserName:'',
          tblColumns:[
            {
              data: "itemid",
              title: "Item ID",
              sort: false,
              visible:false
            },
            {
              data: "itemLink",
              title: "Item Name",
              sort: true,
              //formatter: (cell, row) => <a href={'/'+row.companyid+'/item/'+row.itemid}> {cell} </a>
            },
            {
              data: "itemDescription",
              title: "Description",
              sort:true
            },
            {
              data: "itemSizeFull",
              title: "Size",
              sort:true
            },
            {
              data: "packSize",
              title: "Pack Size",
              sort:true
            },
            {
              data: "fullPrice",
              title: "Price",
              //defaultContent:"$-",
              render: $.fn.dataTable.render.number( ',', '.', 2, '$' ),
              sort:true,
            },
            {
              data: "supplierName",
              title: "Supplier",
              sort:true,
                
            },
            {
              data: "fobFullLocation",
              title: "FOB Location",
              sort:false,
                
            },
            {
              title:"",
              sort:false, 
              render: function (data,type,row){
                if(row.fullPrice == "")
                {
                  return "<button class='btn btn-sm btn-success' disabled>+</button>";  
                }
                else{
                  return "<button class='btn btn-sm btn-success'>+</button>";            }
              }
            }
          ]
        };

        this.tabItemRef = React.createRef();
      }

      updateItemTotal=(itemTotal)=>{
          console.log("Updating Cart Total "  + parseFloat(itemTotal));
          this.setState({totalCost:itemTotal});
      }

      updateTabTotalCost(){

      }

      //#region ComponentDidMount
      componentDidMount(){
        console.log("Edit Order - file: revamp_v1");
        this.setState({loading:true});
        console.log(this.props.match.params.qid);
        //console.log(this.props.firebase.getCurrentUserFullName());
        var usersList = [];
        this.unsubscribe = this.props.firebase
        .users()
        .onSnapshot(us=>
          {
            us.forEach(ud =>
                usersList.push({...ud.data(),uid:ud.id}),
              );

              this.setState({usersList,loading:true});
          })
        //LOAD DOMAINS
        var statusDomain = [];
        this.unsubscribe = this.props.firebase
        .statusItems()
        .onSnapshot(snapshot => {
            //
            snapshot.forEach(doc =>
              statusDomain.push({ ...doc.data(), itemid: doc.id }),
            );

            this.setState({statusDomain:statusDomain,loading:true});

        });

        var clientStatusDomain = [];
        this.unsubscribe = this.props.firebase
        .clientStatusItems()
        .onSnapshot(snapshot => {
            //
            snapshot.forEach(doc =>
              clientStatusDomain.push({ ...doc.data(), itemid: doc.id }),
            );

            this.setState({clientStatusDomain:clientStatusDomain,loading:true});

        });


        this.unsubscribe = this.props.firebase.db
        .collection(`quotes`).doc(this.state.quoteID).collection('notes').orderBy('noteDate')
        .onSnapshot(snapshot => {
          let allNotes=[];
          let pinnedNotes=[];
          
          snapshot.forEach(doc =>{
            //allNotes.push({ ...doc.data(),noteid: doc.id});
            if(doc.pinnedNote === true)
            {
              pinnedNotes.push({ ...doc.data(),noteid: doc.id});
            }
            else{
              allNotes.push({ ...doc.data(),noteid: doc.id});
            }

          });
            //console.log(pricedItems.length);
            this.setState({quoteNotes:allNotes.reverse(),pinnedNotes,loading:true}, function(){console.log("Loading Notes Complete");});

        })



        this.unsubscribe = this.props.firebase.db
        .collection(`quotes`).doc(this.state.quoteID)
        .onSnapshot(snapshot => {
            //console.log(pricedItems.length);
            console.log("Tabs Profile: ");
            //console.log(snapshot.collection('notes').length);
            console.log(snapshot.data().tabs);

            this.setState({
                userID: snapshot.data().userID,
                totalCost:snapshot.data().itemTotalCost,
                quoteDate:moment(snapshot.data().quoteDate).format("MM-DD-YYYY hh:mm A"),
                totalQuote:'',
                activeRequest:true,
                customerID:snapshot.data().selectedCustomerID,
                customerLocationID:snapshot.data().selectedCustomerSelectedLocation,
                quoteNote:snapshot.data().quoteNote,
                status:snapshot.data().status,
                clientStatus:snapshot.data().clientStatus,
                tabs:snapshot.data().tabs,
                currentTab:snapshot.data().tabs[0],
                selectedCustomerName:snapshot.data().selectedCustomerName,
                selectedCustomerID:snapshot.data().selectedCustomerID,
                selectedCustomerSelectedLocation:snapshot.data().selectedCustomerSelectedLocation,
                customerPO:snapshot.data().customerPO,
                salesOrderNumber:snapshot.data().salesOrderNumber,
                shippingOrderNumber:snapshot.data().shippingOrderNumber,
                purchaseOrderNumber:snapshot.data().purchaseOrderNumber,
                salesNotes:snapshot.data().salesNotes,
                recieverName:snapshot.data().recieverName,
                agentName:snapshot.data().userID,
                quoteCode:snapshot.id.slice(-8),
            },
              ()=>{
                var clientQuotes = [];
                this.unsubscribe = this.props.firebase
                .quotesByCompany(this.state.customerID)
                .orderBy('quoteDate', "desc")
                .onSnapshot(csnapshot => {
                    //
                    csnapshot.forEach(cdoc =>{
                      console.log(cdoc.id);
                      if(cdoc.id !== this.props.match.params.qid)
                      clientQuotes.push({ ...cdoc.data(), quoteID: cdoc.id });
                });
  
                    this.setState({otherQuotes:clientQuotes});
  
                });
            });
            //console.log("Total Cost: " + this.state.totalCost);

  

        });
        console.log("Tab State:")
        console.log(this.state.tabs);

        let pricedItems=[];

        this.unsubscribe = this.props.firebase
        .getAvailableinventory()
        .onSnapshot(invSH => {
          
          //let pricedInventory = [];
          
          invSH.forEach(dc => 
            pricedItems.push({...dc.data(),fullPrice:dc.data().itemPrice,supplierName:"Inventory",itemLink:'<a href="/item/'+dc.data().itemID+'">'+dc.data().itemName+'</a>', itemSizeFull:dc.data().itemSizeFull,fobFullLocation: dc.data().warehouseName, itemid: dc.data().itemID})  
          );
        })

        this.unsubscribe = this.props.firebase
        .getFPItems()
        .onSnapshot(snapshot => {
   
          //let pricedItems=[];

          snapshot.forEach(doc =>
            pricedItems.push({ ...doc.data(),itemLink:'<a href="/'+doc.data().companyid+'/item/'+doc.id+'">'+doc.data().itemName+'</a>', itemSizeFull:doc.data().unitSize + " " + doc.data().unitUnit ,fobFullLocation: doc.data().fobCity + " " + doc.data().fobState, itemid: doc.id}),
          );
            //console.log(pricedItems.length);
            this.setState({pricedItems,loading:false}, function(){console.log("set state complete");});


            console.log("setting up table");

            $('#example').DataTable({
              data: pricedItems,
              columns: this.state.tblColumns,
              pageLength: 10,
              columnDefs:[{targets:-1,data:null,defaultContent:'<button class="btn btn-sm btn-success">+</button>'}],
              //filter: true,
              //deferRender: true,
              initComplete: function () {
                this.api().columns([4,6,7]).every( function () {
                    var column = this;
                    var select = $('<select class="form-control filterCtrl"><option value="">...</option></select>')
                        .appendTo( $(column.footer()).empty() )
                        .on( 'change', function () {
                            var val = $.fn.dataTable.util.escapeRegex(
                                $(this).val()
                            );
     
                            column
                                .search( val ? '^'+val+'$' : '', true, false )
                                .draw();
                        } );
     
                    column.data().unique().sort().each( function ( d, j ) {
                        select.append( '<option value="'+d+'">'+d+'</option>' )
                    } );
                } );

                $('#example tfoot tr').appendTo('#example thead');
            }
            });

            $('.clear-filters').prependTo('#example_length');

            var self = this;
        //console.log(this.state.pricedItems.length);
        $('#example tbody').on( 'click', 'button', function () {
          //alert("FOO");
          var data = $('#example').DataTable().row( $(this).parents('tr') ).data();
          //console.log(data.itemid);
          //alert( "Feature Coming Soon");
          self.addItemToCurrentTab(data.itemid,data.itemName,data.itemDescription,data.casesPerPallet,data.fullPrice,data.itemSizeFull,data.packSize,data.fobFullLocation);
        } );


        });
        this.setState({loading:false});
      }
      //#endregion

      //#region Clear Search Filters
      clearSearchFilters(){
        $('#example').DataTable().search( '' ).columns().search( '' ).draw();
        $('tfoot input').val('');
  
        $('.filterCtrl').each(function(){
          $(this).val('');
        });

      }
      //#endregion

      findTabByID(tabID)
      {
        return this.state.tabs.find((tabItem)=>{
          return tabItem.id === parseInt(tabID);
        })
      }

      itemPriceUpdate(tabID, itemtabid, qty, itemObj){
        const orderRef = this.db.collection('quotes').doc(this.state.quoteID);

        console.log(orderRef.tabs);

        //orderRef.update({
          //items: ['item1', 'item2', 'item3']
        //});

        //let tabs = this.state.tabs;
        //console.log(tabs);
        //console.log(itemObj.tabID);
        //let updatetab = tabs.filter(t=>t.id == tabID)[0];
        //console.log("Current: " + updatetab?.items[parseInt(itemtabid)]?.qty + " Updated: "+parseInt(qty));
        //let item2UpdateQty = updatetab[0].items[parseInt(itemtabid)];
        //updatetab?.items[parseInt(itemtabid)]?.qty = parseInt(qty);
        //this.setState({tabs},()=>{this.updateTabTotal(tabID);}); 

        /*if(itemObj !== undefined){
          console.log("Item Price Update:");console.log(itemObj);
          let tabs = this.state.tabs;
          //console.log(tabs);
          //console.log(itemObj.tabID);
          let updatetab = tabs.filter(t=>t.id == itemObj.tabID);
          //console.log(updatetab);
          const itemIndex = itemObj.itemID;
          updatetab[0].items[itemIndex] = itemObj;
          //console.log(updatetab[0].items);
          //const updatedItems = update(updatetab.items, {$splice: [[itemIndex,1,itemObj]]});
          this.setState({tabs},()=>{this.updateTabTotal(itemObj.tabID);});            
      }*/
    }

    updateTabTotal(tabID){
      console.log("Updating Tab Total for Tab: "+tabID)
      let tabs = this.state.tabs;
      console.log(tabs);
      let tab2Total = tabs.filter(t=>t.id == tabID);
      console.log(tab2Total);
      let ctItems = tab2Total[0].items;
      var tabTotal = parseFloat("0.00");
      ctItems.map(item => { console.log(item.qty + "-"+ item.fullprice + " | " +(item.qty * parseFloat(item.fullprice).toFixed(2))); tabTotal = tabTotal + (Number(item.qty) * parseFloat(item.fullprice).toFixed(2))});
      tab2Total[0].total = tabTotal;
      if(tabID === this.state.currentTab.id)
      {
          this.state.currentTab.total = tabTotal;
      }
      this.setState({tabs});
      //console.log(tabs);
    }
    //#region Transfer Item To Tab
    transferItemToTab(itemObj,tabID){
     
      let tabs = this.state.tabs;

      let updatetab = tabs.filter(t=>t.id == tabID);
      let dcItem = itemObj;
      dcItem.tabID = parseInt(tabID);
      updatetab[0].dateCreated = firebase.firestore.Timestamp.now();
      //updatetab[0].items.push(itemObj);
      updatetab[0].items.push(dcItem);
      this.setState({tabs});
      console.log(tabs);

    }
    //#endregion

    removeItemFromTab(itemID,tabID){
      console.log("Remove Item " + itemID+ " from Tab: " +tabID);
      const { tabs, currentTab } = this.state;
      let ctItems='';
      let tabTotal='';
      let isLoadedPrice=0;
      const updatedTabs = tabs.map(tab => {
        if (tab.id === tabID) {
          //console.log(tab);
          ctItems = tab.items;
          let cItem = ctItems.find(i=>i.itemID === itemID);
          const j = ctItems.findIndex(i=>i.itemID === itemID);
          //console.log(ctItems);
          /*if(cItem.fullprice !== "00.00"){
            isLoadedPrice = 1;
          }*/
          //console.log(parseFloat(tab.total) - " " + parseInt(this.state.QtyItemQty)+ " " +parseInt(cItem.qty)+ " " + parseFloat(cItem.price));
          tabTotal = parseFloat(tab.total) - (parseInt(cItem.qty) * parseFloat(cItem.fullprice));
          cItem.qty = 0;
          ctItems.splice([j],1);
          return {
            ...tab,
            items: ctItems,
            total:tabTotal
            //content: evt.target.value
          };
        } else {
          return tab;
        }
      });
      console.log("Current Tab Total: "+tabTotal);
      //console.log(updatedTabs);
      //this.setState({showQtyModal:false, currentTab:sResults,QtyItemID:'',QtyItemQty:''});
      this.setState({
        tabs: updatedTabs,
        currentTab: {
          ...currentTab,
          items: ctItems,
          total:tabTotal
        }
      },()=>{this.updateTabTotal(tabID)});
    }

      addItemToCurrentTab(itemID,itemName,itemDescription,casesPerPallet,fullPrice,itemSizeFull,packSize,fobFullLocation){
        console.log("Add Item to Current Tab: " + itemID + " "+this.state.currentTab.id);
        //let currentTab = this.state.currentTab;
        const newItem = {
          id:itemID,
          title:itemName,
          description:itemDescription,
          qty:'1',
          packsize:packSize,
          unitSize:itemSizeFull,
          casesperpallet:casesPerPallet,
          inventoryhold:false,
          frominventory:false,
          specialFOB:'00.00',
          fobLocation:fobFullLocation,
          mutype:'',
          muprice:'',
          itemAddDesc:'',
          price:fullPrice,
          freightcost:'00.00',
          freightorigin:'',
          muvalue:'00',
          mutype:'',
          tabID:this.state.currentTab.id,
          fullprice:fullPrice,
        }
        //currentTab.items.push(newItem);
        //currentTab.total = currentTab.total + (1 * parseFloat(newItem.price));
        //this.setState({currentTab});

        const { tabs, currentTab } = this.state;
        let ctItems='';
        let tabTotal='';
        let isLoadedPrice=0;
        const updatedTabs = tabs.map(tab => {
          if (tab.name === currentTab.name) {
            //console.log(tab);
            ctItems = tab.items;
            ctItems.push(newItem);
            tabTotal = parseFloat(tab.total) + (1 * parseFloat(newItem.price));
            return {
              ...tab,
              items: ctItems,
              total:tabTotal
              //content: evt.target.value
            };
          } else {
            return tab;
          }
        });
        console.log("Current Tab Total: "+tabTotal);
        //console.log(updatedTabs);
        //this.setState({showQtyModal:false, currentTab:sResults,QtyItemID:'',QtyItemQty:''});
        this.setState({
          tabs: updatedTabs,
          currentTab: {
            ...currentTab,
            items: ctItems,
            total:tabTotal
          }
        });

        this.saveCart();
      }

      //#region unused function
      /*removeItemFromCurrentTab(itemID){
        console.log("Remove Item to Current Tab: " + itemID);
        const { tabs, currentTab } = this.state;
        let ctItems='';
        let tabTotal='';
        let isLoadedPrice=0;
        const updatedTabs = tabs.map(tab => {
          if (tab.name === currentTab.name) {
            //console.log(tab);
            ctItems = tab.items;
            let cItem = ctItems.find(i=>i.id === itemID);
            const j = ctItems.findIndex(i=>i.id === itemID);
            if(cItem.fullprice !== "00.00"){
              isLoadedPrice = 1;
            }
            //console.log(parseFloat(tab.total) - " " + parseInt(this.state.QtyItemQty)+ " " +parseInt(cItem.qty)+ " " + parseFloat(cItem.price));
            tabTotal = parseFloat(tab.total) - (parseInt(cItem.qty) * parseFloat(cItem.fullprice));
            cItem.qty = 0;
            ctItems.splice([j],1);
            return {
              ...tab,
              items: ctItems,
              total:tabTotal
              //content: evt.target.value
            };
          } else {
            return tab;
          }
        });
        console.log("Current Tab Total: "+tabTotal);
        //console.log(updatedTabs);
        //this.setState({showQtyModal:false, currentTab:sResults,QtyItemID:'',QtyItemQty:''});
        this.setState({
          tabs: updatedTabs,
          currentTab: {
            ...currentTab,
            items: ctItems,
            total:tabTotal
          }
        });

        this.saveCart();
      }*/
      //#endregion
      
      componentWillUnmount()
      {
          
      }
      
      //#region clear cart/save cart
      clearCart(){
        localStorage.clear();
        this.props.history.push('/itemsearch');
      }

      async saveCart(){
        //console.log("Save Cart: "+this.state.quoteID + "\n\r" + this.state.tabs[0].items[0]);
        const notify = () => toast("Wow so easy !");

        let usr ='';
        if(/\d/.test(this.state.userID))
        {
          const filterUser = this.state.usersList.filter(u=>{
            return u.uid == this.state.userID;
          })
          usr =  filterUser[0].fname + " " + filterUser[0].lname;
          console.log(filterUser);
          this.setState({userID:usr});
        }

        toast.info("Saving...");
        const newQuote = await this.props.firebase.db.collection(`quotes`).doc(this.state.quoteID).update({
        //const newQuote = await this.props.firebase.quotes(this.state.quoteID).update({
            userID: usr,
            quoteID:this.state.quoteID,
            quoteDate:this.state.quoteDate,
            totalQuote:'',
            activeRequest:true,
            customerID:this.state.selectedCustomerID,
            customerLocationID:this.state.selectedCustomerSelectedLocation,
            status:this.state.status,
            clientStatus:this.state.clientStatus,
            tabs:this.state.tabs,
            selectedCustomerName:this.state.selectedCustomerName,
            selectedCustomerID:this.state.selectedCustomerID,
            selectedCustomerSelectedLocation:this.state.selectedCustomerSelectedLocation,
            customerPO:this.state.customerPO,
            salesOrderNumber:this.state.salesOrderNumber,
            shippingOrderNumber:this.state.shippingOrderNumber,
            purchaseOrderNumber:this.state.purchaseOrderNumber,
            salesNotes:this.state.salesNotes,
            recieverName:this.state.recieverName,
        });

        //this.setState({dbID:newQuote.id});

        if (this.state.status === "Complete" && this.state.customerID == "Sharratt Provisions")
        {
          //send things to inventory
          let tabs = this.state.tabs;
          tabs.map(tab=>{
            if(tab.name == "Order")
            {
              let tabItems = tab.items;
              tabItems.map(tItem=>{
                //SAVE EACH ITEM
                const savedItem = this.props.firebase.db.collection('inventory').add({
                    dateAdded: firebase.firestore.Timestamp.now(),
                    itemID: tItem.itemID,
                    supplierID: tItem.supplierID,
                    itemName: tItem.itemName,
                    itemDescription: tItem.itemDescription,
                    warehouseID:this.state.selectedCustomerSelectedLocation,
                    warehouseName:this.state.selectedCustomerSelectedLocation,
                    warehouseCode:'',
                    itemQty:tItem.itemQty,
                    itemPrice:tItem.fullPrice,
                    itemSizeFull:tItem.itemSizeFull,
                    itemCasesPerPallet:tItem.casesPerPallet,
                    quoteID:this.state.quoteID,
                    arrivalDate:'',
                    lotCount:0,
                });

                this.props.firebase.db.collection('inventory').doc(savedItem.id).collection('transactions').add({
                  quoteID:'',
                  lotNumber:'',
                  bestBuyDate:'',
                  qtyOrdered:'',
                  dateOrdered:'',
                  orderType:'inbound',//could be outbound - outbound discounts itemQty
                  onHold:false,
                });

                this.props.firebase.db.collection('inventory').doc(savedItem.id).collection('lots').add({
                  lotNumber:'',
                  bestBuyDate:'',
                  casesInLot:'',
                });

              });
            }
          });
        }
        //this.addItems(this.state.items);
        toast.dismiss();
        toast.success("Saved!");
      }
      //#endregion

      /*addItems = (itemsList) =>{
        //console.log("Add Location for ID: " + this.state.newAddID);
        console.log(itemsList);
        itemsList.map(itm=>{
            //console.log(itm.id);
            this.props.firebase.db.collection('quotes').doc(this.state.dbID).collection('tabs').add({
                itemID:itm.id,
                itemQty:itm.qty,
                itemFOBPrice:'',
                itemMUPrice:'',
                freightOrigin:'',
                freightCost:'',
                itemTotalCost:'',
                isActive:1,
                dateAdded:firebase.firestore.Timestamp.now(),
                dateUpdated:firebase.firestore.Timestamp.now(),
              })
        })
        
        this.props.firebase.db.collection('quotes').doc(this.state.dbID).collection('revisions').add({
            customerID:this.state.selectedCustomerID,
            customerLocationID:this.state.selectedCustomerSelectedLocation,
            quoteNote:this.state.quoteNote,
            status:this.state.status,
            clientStatus:this.state.clientStatus,
            dateAdded:firebase.firestore.Timestamp.now(),
        })

        localStorage.clear();

        this.props.history.push('/editquote/'+this.state.dbID);
      }*/

      renderMUPrice(itemPrice,MUPrice){
        console.log(itemPrice+"  "+ MUPrice);
        if(itemPrice > MUPrice)
        {
          return itemPrice;
        }
        if(itemPrice === MUPrice)
        {
          return itemPrice;
        }
        if(itemPrice < MUPrice)
        {
          return MUPrice;
        }
      }

      //#region Modal Vis Controls
      openItemsModal(){
        console.log("Open Items Modal");
        this.setState({ showItemsModal: true,});
      }

      closeItemsModal(){
        this.setState({ showItemsModal: false,});
      }

      openMUModal(itemid,mup,fobPrice,imuv,imut) {
        this.setState({ showMUModal: true, MUitemID:itemid, itemMUPrice:mup, itemFOBPrice:fobPrice, itemMUType: imut, itemMUValue:imuv });
      }
    
      closeModal() {
        this.setState({
          focusItemId: "",
          MUitemID:'',
          itemFOBPrice:'',
          MUitemPrice:'',
          itemMUPrice:'',
          itemMUValue:'',
          itemMUType:'',
          showMUModal: false
        });
    }

    openQtyModal(itemid,itemqty) {
        this.setState({ showQtyModal: true, QtyItemID:itemid, QtyItemQty:itemqty });
      }
    
      closeQtyModal() {
        this.setState({
          focusItemId: "",
          focusFOBPrice:'',
          focusFullPrice:'',
          focusMarkUp:'',
          focusMarkUpType:'',
          showQtyModal: false
        });
    }

    openFreightModal(itemid,fmc,fmo) {
      this.setState({ showFreightModal: true, frieghtModalItem:itemid, frieghtModalCost: fmc, frieghtModalOrigin:fmo});
    }
  
    closeFreightModal() {
      this.setState({
        frieghtModalItem:'',
        frieghtModalCost:'',
        frieghtModalOrigin:'',
        showFreightModal: false
      });
  }
  //#endregion

      downIncrement=(evt)=>{
        var val = this.state.QtyItemQty;
        if(val > 0)
        {
          var newVal = --this.state.QtyItemQty;
          this.setState({
            QtyItemQty:newVal,
              itemTotalCost:Number(this.state.itemTotalCost) - (1 * this.state.itemPrice)
            });
          console.log(newVal);
        }
      }
  
      upIncrement=(evt)=>{
        var newVal = ++this.state.QtyItemQty;
        //console.log(modelItemNam);
        this.setState({
            QtyItemQty:newVal,
            itemTotalCost:Number(this.state.itemTotalCost) + (1 * this.state.itemPrice)
        });
        //console.log(newVal);
      }

      updateItemQty=(evt)=>{
        const { tabs, currentTab } = this.state;
        console.log("Updating Current Tab Item Qty for ID: "+currentTab.id); 
        console.log("Updateing QTY for item: " + this.state.QtyItemID); 
        let ctItems='';
        let tabTotal='';
        let fullPrice = '';
        const updatedTabs = tabs.map(tab => {
          if (tab.name === currentTab.name) {
            console.log(tab);
            ctItems = tab.items;
            let cItem = ctItems.find(i=>i.id === this.state.QtyItemID);
            if(cItem.fullprice == "00.00"){
              fullPrice = parseFloat(cItem.price);
            }
            else if(cItem.muvalue == "00" && cItem.freightcost == "00.00")
            {
              fullPrice = parseFloat(cItem.price);
            }
            else if(cItem.muvalue !== "00" && cItem.freightcost == "00.00")
            {
              fullPrice = parseFloat(cItem.muprice);
            }
            else if(cItem.muvalue == "00" && cItem.freightcost !== "00")
            {
              fullPrice = parseFloat(cItem.fullprice) + (parseFloat(cItem.freightcost) / parseInt(this.state.QtyItemQty));
            }
            else{
              fullPrice = parseFloat(cItem.muprice) + (parseFloat(cItem.freightcost) / parseInt(this.state.QtyItemQty));
            }
            tabTotal = (parseFloat(tab.total).toFixedNoRound() - (parseFloat(cItem.fullprice).toFixedNoRound() * parseInt(cItem.qty))) + (parseFloat(fullPrice).toFixedNoRound() * parseInt(cItem.qty));
            cItem.fullprice = fullPrice;
            cItem.qty = this.state.QtyItemQty;
            return {
              ...tab,
              items: ctItems,
              total:tabTotal
              //content: evt.target.value
            };
          } else {
            return tab;
          }
        });
    
        console.log(updatedTabs);
        //this.setState({showQtyModal:false, currentTab:sResults,QtyItemID:'',QtyItemQty:''});
        this.setState({
          showQtyModal:false,
          QtyItemID:'',
          QtyItemQty:'',
          tabs: updatedTabs,
          currentTab: {
            ...currentTab,
            items: ctItems,
            total:tabTotal
          }
        },()=>{this.saveCart();});

        //this.saveCart();
      }

      updateFreightCost=(evt)=>{
        const { tabs, currentTab } = this.state;
        console.log("Updating Current Tab Item Qty for ID: "+currentTab.id); 
        console.log("Updateing Freight Cost for item: " + this.state.frieghtModalCost); 
        let ctItems='';
        let tabTotal='';
        let fullPrice = '';
        const updatedTabs = tabs.map(tab => {
          if (tab.name === currentTab.name) {
            console.log(tab);
            ctItems = tab.items;
            let cItem = ctItems.find(i=>i.id === this.state.frieghtModalItem);
            //console.log(cItem.fullprice, cItem.price, cItem.qty, cItem.freightcost);
            if(cItem.fullprice == "00.00"){
              fullPrice = parseFloat(cItem.price) + (parseFloat(this.state.frieghtModalCost)/parseInt(cItem.qty));
            }
            else if(cItem.muvalue !== "00")
            {
              fullPrice = parseFloat(cItem.muprice) + (parseFloat(this.state.frieghtModalCost)/parseInt(cItem.qty));
            }
            else if(parseFloat(cItem.fullprice).toFixed(2) === parseFloat(cItem.price).toFixed(2))
            {
              if(cItem.muvalue !== "00")
              {
                fullPrice = parseFloat(cItem.muprice) + (parseFloat(this.state.frieghtModalCost)/parseInt(cItem.qty));
              }
              else{
                fullPrice = parseFloat(cItem.price) + (parseFloat(this.state.frieghtModalCost)/parseInt(cItem.qty));
              }
            }
            else{
              fullPrice = parseFloat(cItem.fullprice) + (parseFloat(this.state.frieghtModalCost)/parseInt(cItem.qty));
            }
            tabTotal = (parseFloat(tab.total).toFixedNoRound() - (parseFloat(cItem.fullprice).toFixedNoRound() * parseInt(cItem.qty))) + (parseFloat(fullPrice).toFixedNoRound() * parseInt(cItem.qty))
            cItem.fullprice = fullPrice;
            cItem.freightcost = this.state.frieghtModalCost;
            cItem.freightorigin = this.state.frieghtModalOrigin;
            return {
              ...tab,
              items: ctItems,
              total:tabTotal
              //content: evt.target.value
            };
          } else {
            return tab;
          }
        });
    
        console.log(updatedTabs);
        //this.setState({showQtyModal:false, currentTab:sResults,QtyItemID:'',QtyItemQty:''});
        this.setState({
          showFreightModal:false,
          frieghtModalCost:'',
          frieghtModalOrigin:'',
          frieghtModalItem:'',
          tabs: updatedTabs,
          currentTab: {
            ...currentTab,
            items: ctItems,
            total:tabTotal
          }
        },()=>{this.saveCart();});

        //this.saveCart();
    }


    updateItemPrice=(evt)=>{
      const { tabs, currentTab } = this.state;
      //console.log("Updating Current Tab Item Qty for ID: "+currentTab.id); 
      console.log("Updating Markup for item: " + this.state.MUitemID); 
      let ctItems='';
      let tabTotal='';
      let fullPrice = '';
      const updatedTabs = tabs.map(tab => {
        if (tab.name === currentTab.name) {
          //console.log(tab);
          ctItems = tab.items;
          let cItem = ctItems.find(i=>i.id === this.state.MUitemID);
          console.log(cItem.fullprice);
           //UPDATE 7/12/2023
          if(cItem.freightcost !=="OO" || isNaN(parseFloat(cItem.freightcost)))
          {
            fullPrice = parseFloat(this.state.itemMUPrice) + (parseFloat(cItem.freightcost)/parseInt(cItem.qty));
          }
          else if(cItem.fullprice == "00.00" || isNaN(parseFloat(cItem.fullprice)))
          {
            fullPrice = parseFloat(this.state.itemMUPrice);
          }
          else if(this.state.itemMUPrice == cItem.muprice)
          {
            //do nothing
            fullPrice = parseFloat(cItem.fullprice);
          }
          else{
            fullPrice = parseFloat(this.state.itemMUPrice) + (parseFloat(cItem.freightcost)/parseInt(cItem.qty));
          }
          
          tabTotal = (parseFloat(tab.total).toFixedNoRound() - (parseFloat(cItem.fullprice).toFixedNoRound() * parseInt(cItem.qty))) + (parseFloat(fullPrice).toFixedNoRound() * parseInt(cItem.qty))
          console.log("Previous Total: "+tab.total + " New Total: "+ tabTotal);
          cItem.fullprice = fullPrice;
          cItem.muprice = this.state.itemMUPrice;
          cItem.muvalue = this.state.itemMUValue;
          cItem.mutype = this.state.itemMUType;
          return {
            ...tab,
            items: ctItems,
            total:tabTotal
            //content: evt.target.value
          };
        } else {
          return tab;
        }
      });
  
      console.log(updatedTabs);
      //this.setState({showQtyModal:false, currentTab:sResults,QtyItemID:'',QtyItemQty:''});
      this.setState({
        showMUModal:false,
        itemFOBPrice:'',
        itemMUPrice:'',
        itemMUValue:'',
        itemMUType:'',
        MUitemID:'',
        tabs: updatedTabs,
        currentTab: {
          ...currentTab,
          items: ctItems,
          total:tabTotal
        }
      },()=>{this.saveCart();});


  }
      calculatePrice(){
        var basePrice = parseFloat(this.state.MUitemPrice);
        var finalPrice;
        var altMarkup = parseFloat(this.state.itemMUValue);
        if(this.state.itemMUType == "Percent")
        {
          finalPrice = basePrice + (basePrice * (altMarkup/100));
        }
        else{
          finalPrice = basePrice + altMarkup;
        }
        
        this.setState({itemMUPrice:finalPrice.toFixed(2), itemTotalCost:(this.state.itemQty * finalPrice)});
    }

    checkItemPrice(iID,tID,fobp,mut,muv,frt,q)
    {
        let hasMU = true;
        let hasFrt = true;
        let hasBoth = true;
        if(muv == "00.00")
        {
            hasMU = false;
            hasBoth = false;
        }
        if(frt == "00")
        {
            hasFrt = false;
            hasBoth = false;
        }

        let totalCasePrice = parseFloat(fobp);

        if(hasBoth)
        {
            if(mut == "Dollar")
            {
                totalCasePrice = parseFloat(fobp) + parseFloat(muv) + (parseFloat(frt)/parseInt(q))
            }
            else{
                totalCasePrice = parseFloat(fobp) + (parseFloat(fobp) * parseFloat(muv)) + (parseFloat(frt)/parseInt(q))
            }
            
        }
        else if(hasFrt == false && hasMU == true)
        {

        }
        else if(hasFrt == true && hasMU == false)
        {

        }


        return totalCasePrice;

    }

      removeItemHandler=(itemID,tabID)=>{
          //console.log(itemID);
          console.log("Remove Item " + itemID+ " from Tab: " +tabID);
      const { tabs, currentTab } = this.state;
      let ctItems='';
      let tabTotal='';
      let isLoadedPrice=0;
      const updatedTabs = tabs.map(tab => {
        if (tab.id === tabID) {
          //console.log(tab);
          ctItems = tab.items;
          //let cItem = ctItems.find(i=>i.itemID === itemID);
          const j = ctItems.findIndex(i=>i.itemID === itemID);
          //console.log(ctItems);
          /*if(cItem.fullprice !== "00.00"){
            isLoadedPrice = 1;
          }*/
          //console.log(parseFloat(tab.total) - " " + parseInt(this.state.QtyItemQty)+ " " +parseInt(cItem.qty)+ " " + parseFloat(cItem.price));
          //tabTotal = parseFloat(tab.total) - (parseInt(cItem.qty) * parseFloat(cItem.fullprice));
          //cItem.qty = 0;
          ctItems.splice([j],1);
          return {
            ...tab,
            items: ctItems,
            total:tabTotal
            //content: evt.target.value
          };
        } else {
          return tab;
        }
      });
      console.log("Current Tab Total: "+tabTotal);
      //console.log(updatedTabs);
      //this.setState({showQtyModal:false, currentTab:sResults,QtyItemID:'',QtyItemQty:''});
      this.setState({
        tabs: updatedTabs,
        currentTab: {
          ...currentTab,
          items: ctItems,
          total:tabTotal
        }
      },()=>{this.updateTabTotal(tabID)});
          //this.state.items.map(item=>(console.log(item.id)));
          /*this.setState({items: this.state.items.filter(function(item){
            if(item.id !== itemID){
                return item;
            }
          })});

          var quoteInfo = JSON.parse(localStorage.getItem('newquote'));
          const currentItems = quoteInfo['items'];
          const updItems = currentItems.filter((item)=> item.id !== itemID);
        quoteInfo['items'] = updItems;
        this.state.cartItems.pop("1");
        localStorage.setItem('newquote', JSON.stringify(quoteInfo));*/

      }

      myMUChangeHandler=(event)=>{
        let nam = event.target.name;
        let val = event.target.value;
        let err = '';
        if(nam === "itemMUType" || nam === "itemMUValue")
        {
          console.log("A");
          if(nam === "itemMUType" && this.state.itemMUValue != null)
          {
            var finalPrice;
            if(val === "Percent")
            {
              finalPrice = parseFloat(this.state.itemFOBPrice) + (parseFloat(this.state.itemFOBPrice) * (parseFloat(this.state.itemMUValue)/100));
            }
            else{
              finalPrice = parseFloat(this.state.itemFOBPrice) + parseFloat(this.state.itemMUValue);
            }
            console.log("B");
            this.setState({itemMUPrice:finalPrice});
          }
          else if(nam === "itemMUType" && this.state.itemMUValue == null)
          {
            //do nothing
            console.log("C");
          }
          else if(nam==="itemMUValue" && this.state.itemMUType != null)
          {
            var finalPrice;
            if(this.state.itemMUType === "Percent")
            {
              finalPrice = parseFloat(this.state.itemFOBPrice) + (parseFloat(this.state.itemFOBPrice) * (val/100));
            }
            else if(this.state.itemMUType === "Dollar"){
              finalPrice = parseFloat(this.state.itemFOBPrice) + parseFloat(val);
            }
            console.log("D");
            this.setState({itemMUPrice:finalPrice});
          }
          else if(nam==="itemMUValue" && this.state.itemMUType == null)
          {
            //do nothing
            console.log("E");
          }
        }


        this.setState({errormessage: err});
        this.setState({[nam]: val});
        this.saveCart();
      }

      myChangeHandler = (event) => {
        //console.log('Chages');
        let nam = event.target.name;
        let val = event.target.value;
        let err = '';
        if(val==="NaN")
        {
          err="Error!";
        }
        this.setState({errormessage: err});
        this.setState({[nam]: val});
        //console.log(nam + " " + val);
        //console.log(val);
      }

      submitQuoteNote=(event,authUser)=>{
        let user = firebase.auth().currentUser;

        console.log(authUser);

        this.props.firebase.db.collection('quotes').doc(this.state.quoteID).collection('notes').add({
          noteDate: firebase.firestore.Timestamp.now(),
          author: authUser.fname + " " + authUser.lname,
          note:this.state.quoteItemNote,
          pinnedNote:false
        });

        let newNote ={};
        newNote.noteDate = new Date();
        newNote.author = authUser.fname + " " + authUser.lname;
        newNote.pinnedNote = false;
        newNote.note = this.state.quoteItemNote;

        const allNotes = {...this.state.quoteNotes,newNote};

        this.setState({
          quoteNotes: allNotes,
          quoteItemNote:'',
          pinnedNote:false,
          showNotes:false,
        });
      }
     
      selectReciever=(type,id)=>{
          console.log(type + '--'+id);
          if(type == "selectedCustomer"){
              //console.log("There is a selected Customer");
              if(typeof id == "object")
              {
                this.setState({selectedCustomerID:id.cid});   
              }
              else{
                this.setState({selectedCustomerID:id});   
              }
              //console.log(this.state.selectedCustomerID);
          }
          else{
              this.setState({selectedCustomerSelectedLocation:id});
          }
          //console.log("Select Reciever: " + type + "-"+id);
      }

      tabTransfer = (event) =>{
        console.log("Copying Item to Tab");
        let nam = event.target.name;
        let val = event.target.value;//id of tab to copy to
        
        let controlName = nam.split("_");
        let item_id = controlName[1];

        if(val !== "00"){
          //console.log(event.target);
          let currentTabCall = this.state.currentTab;
          //console.log(currentTabCall);
          let ctItems = currentTabCall.items;
          console.log(ctItems);
          let searchItem = ctItems.filter(i=>i.id == item_id);
          console.log(searchItem);
          let tabs = this.state.tabs;
          //console.log(tabs);
          let updatetab = tabs.filter(t=>t.id == val);
          let deepClone = {...searchItem[0]};
          updatetab[0].dateCreated = firebase.firestore.Timestamp.now();
          console.log("Price: " + deepClone.price + " Full Price: " + deepClone.fullprice + " Qty: "+deepClone.qty);
          deepClone.tabID = parseInt(val);
          if(deepClone.fullprice === "00.00" && deepClone.qty > 0)
          {
            deepClone.fullprice = "00.00";
          }
          //console.log(parseFloat(updatetab[0].total) + " " + parseFloat(deepClone.fullprice) +" "+ parseInt(deepClone.qty))
          updatetab[0].total = parseFloat(updatetab[0].total) + (parseFloat(deepClone.fullprice) * parseInt(deepClone.qty));
          updatetab[0].items = [...updatetab[0].items, deepClone];//searchItem[0]];
          //console.log(updatetab[0].items);
          this.setState({tabs});
          //console.log(tabs);
        }
        this.updateTabTotal(val);
        this.saveCart();
        //console.log("Tab Xfer Value:"+val);
      }

      viewPrintout= (event) =>{
        this.props.histroy.push('/export/'+this.state.quoteID+"/"+this.state.currentTab.id);
      }

      backPage = (event) =>{
        //props.histroy.push('/itemsearch');
      }

      //Tab Controller
      handleDoubleClick = () => {
        this.setState({
          editTabNameMode: true
        });
      };
    
      handleEditTabName = e => {
        const { currentTab, tabs } = this.state;
    
        const updatedTabs = tabs.map(tab => {
          if (tab.id === currentTab.id) {
            return {
              ...tab,
              name: e.target.value
            };
          } else {
            return tab;
          }
        });
    
        this.setState({
          tabs: updatedTabs,
          currentTab: {
            ...currentTab,
            name: e.target.value
          }
        });
      };
    
      handleOnBlur = () => {
        this.setState({
          editTabNameMode: false
        });
      };
    
      createTabs = () => {
        //console.log(this.state.tabs);
        //console.log(this.state.currentTab.items);
        const { tabs, currentTab, editTabNameMode } = this.state;
    
        const allTabs = tabs.map(tab => {
          return (
            <li className="nav-item">
              {editTabNameMode && currentTab.id === tab.id ? (
                <input
                  value={tab.name}
                  onBlur={this.handleOnBlur}
                  onChange={this.handleEditTabName}
                  className="form-control"
                />
              ) : (
                <button
                  className={currentTab.id === tab.id ? "tab active" : "tab"}
                  onClick={() => this.handleSelectTab(tab)}
                  onDoubleClick={() => this.handleDoubleClick(tab)}
                >
                  {tab.name}
                </button>
              )}
            </li>
          );
        });
    
        return <ul className="nav nav-tabs">{allTabs}</ul>;
      };
    
      handleSelectTab = tab => {
        console.log("Selecting Tab: "+tab.id);
        this.setState({
          currentTab: tab,
          editMode: false,
          editTabNameMode: false
        });
        this.updateTabTotal(tab.id);
        this.saveCart();
      };
    
      handleAddTab = () => {
        const { tabs } = this.state;
    
        const newTabObject = {
          id: tabs.length + 1,//uuid(),
          name: `Tab ${tabs.length + 1}`,
          notes: `This is Tab ${tabs.length + 1}`,
          items:[],
          total:"00.00",
          dateCreated:firebase.firestore.Timestamp.now()
        };
    
        this.setState({
          tabs: [...tabs, newTabObject],
          currentTab: newTabObject,
          editMode: false,
          editTabNameMode: false
        });
      };
    
      handleDeleteTab = tabToDelete => {
        /*const { tabs } = this.state;
        const tabToDeleteIndex = tabs.findIndex(tab => tab.id === tabToDelete.id);
    
        const updatedTabs = tabs.filter((tab, index) => {
          return index !== tabToDeleteIndex;
        });
    
        const previousTab =
          tabs[tabToDeleteIndex - 1] || tabs[tabToDeleteIndex + 1] || {};
    
        this.setState({
          tabs: updatedTabs,
          editMode: false,
          editTabNameMode: false,
          currentTab: previousTab
        });*/
        if(tabToDelete.id != 1 && tabToDelete.id != 99){
          const { tabs } = this.state;
          const tabToDeleteIndex = tabs.findIndex(tab => tab.id === tabToDelete.id);
      
          const updatedTabs = tabs.filter((tab, index) => {
            return index !== tabToDeleteIndex;
          });
      
          const previousTab =
            tabs[tabToDeleteIndex - 1] || tabs[tabToDeleteIndex + 1] || {};
      
          this.setState({
            tabs: updatedTabs,
            editMode: false,
            editTabNameMode: false,
            currentTab: previousTab
          });
  
        }
        else{
          alert("Cannot Delete This Tab");
        }
      };
    


      setEditMode = () => {
        this.setState({
          editMode: !this.state.editMode
        });
      };
    
      handleCheckTotal = e =>{
        if(this.state.showTotal)
        {
          this.setState({showTotal:false});
        }
        else{
          this.setState({showTotal:true});
        }
      }

      handleContentChange = e => {
        const { tabs, currentTab } = this.state;
    
        const updatedTabs = tabs.map(tab => {
          if (tab.name === currentTab.name) {
            return {
              ...tab,
              content: e.target.value
            };
          } else {
            return tab;
          }
        });
    
        this.setState({
          tabs: updatedTabs,
          currentTab: {
            ...currentTab,
            content: e.target.value
          }
        });
      };
      //END TAB CONTROLLER

      render() {
        const goBack = () => {
          this.props.history.goBack()
      }
        const {quoteNotes,pinnedNotes,statusDomain,clientStatusDomain,loading,otherQuotes,items,QtyItemID,MUitemID,MUitemPrice,frieghtModalItem,frieghtModalOrigin, frieghtModalCost,quoteDate,quoteID,totalCost,currentTab, editMode,selectedCustomerID,userID} = this.state;
        //const [state, setState] = useState({showQuotes:false});

        if (currentTab.items === undefined)
        {
          return(<div className='content'><h1>Loading...</h1></div>)
        }
        return (
          <AuthUserContext.Consumer>
            {authUser=>(
            <div className="content">
               
                <TableModal show={this.state.showItemsModal} handleClose={e => this.closeItemsModal(e)}>
                <div className="modal-content">
                <div className="modal-header">
                                        <h5 className="modal-title">Search Items</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e=>this.closeItemsModal()}>
                                          <span aria-hidden="true">&times;</span>
                                        </button>
                                      </div>
                <div>
                <div className="clear-filters" style={{float:"left",marginRight:10+"px"}}>
                            <button type="button" className="btn btn-outline-danger right" onClick={e => this.clearSearchFilters()}><FontAwesomeIcon icon={faRedo}></FontAwesomeIcon> Clear Search/Filters</button>
                            </div>
                            <table id="example" className="display">
                                      <thead></thead>
                                      <tbody></tbody>
                                      <tfoot>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                      </tfoot>
                                  </table>
                    </div>
                    </div>
                </TableModal>
                <nav className="navbar navbar-expand-lg navbar-light bg-yellow">
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav">
                        {/*<li className="nav-item">
                            <button className="btn btn-outline-light" style={{marginTop:"25px"}} onClick={goBack}>Back</button>
            </li>*/}
                        {/*<li className="nav-item">
                        <a className="nav-link-menu" href="../home">Home</a>
                        </li>*/}
                        <li className="nav-item">
                        <a className="btn btn-outline-light" href="../quotes">Quotes List</a>
                        </li>
                        </ul>
                    </div>
                    </nav>
                    {loading && <h1>Loading State...</h1>}
                    {/*<div style={{textAlign:'center'}}>
                        <img src={warehouse}/>
                    </div>*/}<div className="container-fluid">
                      <p>
  <a className="btn btn-outline-warning" style={{margin:5+"px"}} data-toggle="collapse" href="#multiCollapseExample1" role="button" aria-expanded="false" aria-controls="multiCollapseExample1">Toggle Status</a>
  <button style={{margin:5+"px"}} className="btn btn-outline-warning" type="button" data-toggle="collapse" data-target="#multiCollapseExample2" aria-expanded="false" aria-controls="multiCollapseExample2">Toggle Recipient</button>
  <button style={{margin:5+"px"}} className="btn btn-outline-warning" type="button" data-toggle="collapse" data-target="#multiCollapseExample3" aria-expanded="false" aria-controls="multiCollapseExample3">Toggle Sales Info</button>
  <button style={{margin:5+"px"}} className="btn btn-outline-warning" type="button" data-toggle="collapse" data-target=".multi-collapse" aria-expanded="false" aria-controls="multiCollapseExample1 multiCollapseExample2 multiCollapseExample3">Toggle All</button>
  <button style={{margin:5+"px"}} className="btn btn-info" onClick={e=>this.cloneQuote()} disabled>Clone Quote</button>
  <button style={{margin:5+"px"}} className="btn btn-success" onClick={e=>this.saveCart()}>Save Quote</button>
</p>
<ToastContainer autoClose={3000} toastStyle={{ backgroundColor: "green" }}/>
<div className="row">
  <div className="col">
    <div className="collapse multi-collapse" id="multiCollapseExample1">
    <div className="card card-body">
      <div className="card">
          <div className="card-body">
                <strong>Quote Date:</strong>{this.state.quoteDate}
                <div className="form-group">
                                <label htmlFor="quoteCode">Quote ID:</label>
                                <input type="text" name="quoteCode" className="form-control" onChange={this.myChangeHandler} value={this.state.quoteCode}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="agentName">Sales Agent:</label>
                                <input type="text" name="agentName" className="form-control" onChange={this.myChangeHandler} value={this.state.agentName}/>
                            </div>
            </div>
        </div>
      </div>
      <div className="card card-body">
      <div className="card">
                            <div className="card-body">
                            <div className="form-group">
                                <label htmlFor="status" className="required">Internal Status:</label>
                                <select id="status" name="status" className="form-control required" onChange={this.myChangeHandler} value={this.state.status}>
                                    <option value="00">Select Status...</option>
                                    {/*<option value="In Progress">In Progress</option>
                                    <option value="Review">Review</option>
                                    <option value="Complete">Complete</option>*/}
                                    {statusDomain.map(sta=>(
                                        <option value={sta.statusValue}>{sta.statusValue}</option>
                                    ))
                                    }
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="clientStatus" className="required">Quote Status:</label>
                                <select id="clientStatus" name="clientStatus" className="form-control required" onChange={this.myChangeHandler} value={this.state.clientStatus}>
                                    <option value="00">Select Status...</option>
                                    {clientStatusDomain.map(cs=>(
                                        <option value={cs.clientStatus}>{cs.clientStatus}</option>
                                    ))
                                    }
                                    {/*<option value="Not Sent">Not Sent</option>
                                    <option value="On Hold">On Hold</option>
                                    <option value="Sent">Sent</option>
                                    <option value="Rejected">Rejected</option>
                                    <option value="Partial Accepted">Partial Accepted</option>
                                  <option value="Accepted">Accepted</option>*/}
                                </select>
                            </div>
                                <div className="row">
                                    {/*<div className="col-md-6"><button className="btn btn-danger" onClick={e=>this.clearCart()}>Discard Request</button></div>
                                    <div className="col-md-6"><button className="btn btn-success" onClick={e=>this.saveCart()}>Save Request</button></div>*/}
                                </div>
                            </div>
                            </div>
      </div>
    </div>
  </div>
  <div className="col">
    <div className="collapse multi-collapse" id="multiCollapseExample2">
      <div className="card card-body">
      <QuoteRecieverCard onSelect={this.selectReciever} scid={this.state.customerID} sclid={this.state.customerLocationID}></QuoteRecieverCard>
      </div>
    </div>
  </div>
  <div className="col">
    <div className="collapse multi-collapse" id="multiCollapseExample3">
      <div className="card card-body">
      <div className="card">
                            <div className="card-body">
                            <div className="form-group">
                                <label htmlFor="salesOrderNumber" className="required">Sharratt Sales Order:</label>
                                <input type="text" name="salesOrderNumber" className="form-control required" onChange={this.myChangeHandler} value={this.state.salesOrderNumber}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="purchaseOrderNumber">Purchase Order:</label>
                                <input type="text" name="purchaseOrderNumber" className="form-control" onChange={this.myChangeHandler} value={this.state.purchaseOrderNumber}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="shippingOrderNumber">Shipping/Transportation PO:</label>
                                <input type="text" name="shippingOrderNumber" className="form-control" onChange={this.myChangeHandler} value={this.state.shippingOrderNumber}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="customerPO">Customer PO:</label>
                                <input type="text" name="customerPO" className="form-control" onChange={this.myChangeHandler} value={this.state.customerPO}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="recieverName">Customer Name/Reciever Name:</label>
                                <input type="text" name="recieverName" className="form-control" onChange={this.myChangeHandler} value={this.state.recieverName}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="salesNotes">Sales Notes:</label>
                                <input type="text" name="salesNotes" className="form-control" onChange={this.myChangeHandler} value={this.state.salesNotes}/>
                            </div>
                            </div>
                            </div>
      </div>
    </div>
  </div>
</div>
                    <div className="row">
                        <div className="col-md-12">
                                <div className="container">
                                    <div className="well">
                                    <button className="btn-sm btn-info" onClick={this.handleAddTab} style={{marginBottom:10+"px"}}>
                                        <FontAwesomeIcon icon={faPlus}/> Add Tab
                                    </button>
                                    <button className="btn-sm btn-warning" onClick={e=>this.openItemsModal()} style={{marginLeft:10+"px",marginBottom:10+"px"}}>
                                        <FontAwesomeIcon icon={faPlus}/> Add Item
                                    </button>
                                    {otherQuotes.length > 0 &&
                                    <button className="btn-sm btn-outline-info" onClick={() => this.setState({ showQuotes: true })} style={{marginLeft:10+"px",marginBottom:10+"px"}}>
                                        View Client History
                                    </button>}
                                    
                                    <button className="btn-sm btn-outline-info" onClick={() => this.setState({ showNotes: true })} style={{marginLeft:10+"px",marginBottom:10+"px"}}>
                                        Quote Notes
                                    </button>
                                    {this.createTabs()}
                                    <div className="tab-content" style={{marginTop:10+"px"}}>
                                        {editMode ? (
                                        <div>
                                            <textarea
                                            onChange={this.handleContentChange}
                                            value={this.state.currentTab.notes}
                                            />
                                            <button className="btn btn-success" onClick={this.setEditMode}>
                                            Done
                                            </button>
                                        </div>
                                        ) : (
                                        <div>
                                          <div className="row">
                                            <div className="col-md-4"><input type="checkbox" onClick={this.handleCheckTotal} onChange={this.handleCheckTotal} checked={this.state.showTotal}></input><strong> Tab Total: </strong>{this.state.showTotal? <div style={{display:"inline"}}>${parseFloat(this.state.currentTab.total).toFixed(2)}</div>:"---"}
                                            </div>
                                            <div className="col-md-3"><strong>Total Items: </strong>{this.state.currentTab.items.length}</div>
                                            <div className="col-md-2"></div>
                                            <div className="col-md-3"></div>
                                            </div>
                                            <table className="table table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Item</th>
                                        <th>Case Price</th>
                                        <th>Qty</th>
                                        <th>Freight Cost</th>
                                        <th>Mark up</th>
                                        <th>Final Case Price/Unit Price</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                {this.state.currentTab.items.map((itemData,i)=> (
                                    <React.Fragment>
                                         <Modal show={this.state.showQtyModal} handleClose={e => this.closeQtyModal(e)}>
                    <div className="modal-content">
                    <div className="modal-header">
                                        <h5 className="modal-title">Update Item Quantity</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e=>this.closeQtyModal()}>
                                          <span aria-hidden="true">&times;</span>
                                        </button>
                                      </div>
                                      <div className="modal-body">
                    <strong>Qty:</strong>
                                  <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                  <button className="btn btn-danger" type="button" id="button-addon1" onClick={e=>this.downIncrement(e)} disabled={this.state.itemQty == 0}><FontAwesomeIcon icon={faMinus}></FontAwesomeIcon></button>
                                                </div>
                                                <input type="text" className="form-control" name="QtyItemQty" id="QtyItemQty" value={this.state.QtyItemQty} onChange={this.myChangeHandler}/>
                                                <div className="input-group-append">
                                                  <button className="btn btn-success" type="button" id="button-addon2" onClick={e=>this.upIncrement(e)}><FontAwesomeIcon icon={faPlus}></FontAwesomeIcon></button>
                                                </div>
                                              </div>
                                              </div>
                                              <div className="modal-footer">
                                        <button type="button" className="btn btn-success" onClick={e=>this.updateItemQty()}>Update Quantity</button>
                                        <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={e=>this.closeQtyModal()}>Close</button>
                                      </div>
                    </div>
                </Modal>
                <Modal show={this.state.showFreightModal} handleClose={e => this.closeFreightModal(e)}>
                                    <div className="modal-content">
                                      <div className="modal-header">
                                        <h5 className="modal-title">Add Freight Cost</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e=>this.closeFreightModal()}>
                                          <span aria-hidden="true">&times;</span>
                                        </button>
                                      </div>
                                      <div className="modal-body">
                                        <label htmlFor="frieghtModalOrigin" className="required">Freight Origin</label>
                                        <input type="text" name="frieghtModalOrigin" className="form-control required" value={this.state.frieghtModalOrigin} onChange={this.myChangeHandler}/>
                                        <label htmlFor="frieghtModalCost" className="required">Freight Cost</label>
                                        <input type="text" name="frieghtModalCost" className="form-control required" onChange={this.myChangeHandler} value={this.state.frieghtModalCost}/>
                                      </div>
                                      <div className="modal-footer">
                                        
                                        <button type="button" className="btn btn-success" onClick={e => this.updateFreightCost(e)} disabled={this.state.frieghtModalCost==null}>Update Pricing w/Freight</button>
                                        <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={e=>this.closeFreightModal()}>Close</button>
                                      </div>
                                    </div>
                                </Modal>
                              <Modal show={this.state.showMUModal} handleClose={e => this.closeModal(e)}>
                                    <div className="modal-content">
                                      <div className="modal-header">
                                        <h5 className="modal-title">Add Markup</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e=>this.closeModal()}>
                                          <span aria-hidden="true">&times;</span>
                                        </button>
                                      </div>
                                      <div className="modal-body">
                                        <label htmlFor="itemFOBPrice">FOB Price</label>
                                        <input type="text" name="itemFOBPrice" className="form-control" value={parseFloat(this.state.itemFOBPrice).toFixed(2)} disabled/>
                                        <label htmlFor="itemMUValue">Mark Up Value</label>
                                        <input type="text" name="itemMUValue" className="form-control" onChange={this.myMUChangeHandler} value={this.state.itemMUValue}/>
                                        <label htmlFor="itemMUType">Mark Up Type</label>
                                        <select name="itemMUType" className="form-control" onChange={this.myMUChangeHandler} value={this.state.itemMUType}>
                                          <option value="">Select...</option>
                                          <option value="Dollar">Dollar</option>
                                          <option value="Percent">Percent</option>
                                        </select>
                                        <label htmlFor="itemMUPrice">Marked Up Price (w/o Frieght)</label>
                                        <input type="text" name="itemMUPrice" className="form-control" value={parseFloat(this.state.itemMUPrice).toFixed(2)} disabled/>
                                      </div>
                                      <div className="modal-footer">
                                        {/*<button type="button" className="btn btn-primary" onClick={e => this.calculatePrice(e)} disable={this.state.price == this.state.fullprice}>Calculate Full Price</button>*/}
                                        <button type="button" className="btn btn-success" onClick={e => this.updateItemPrice(e)}>Update Pricing</button>
                                        <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={e=>this.closeModal()}>Close</button>
                                      </div>
                                    </div>
                                </Modal>
                                     <tr key={itemData.id}>
                                                  <td><button type="button" className="btn btn-sm btn-danger" onClick={(event)=>this.removeItemHandler(itemData.id,itemData.tabID)}
                                                  /*{() => {
                                                   if (window.confirm("Delete this Item?")) { 
                                                        alert(itemData.itemID);
                                                    //let removeToCollection = this.removeItemFromTab.bind(this,itemData.itemID,itemData.tabID);
                                                    //removeToCollection();
                                                        //this.removeItemFromTab(itemData.itemID, itemData.tabID); 
                                                   }
                                               }}*/>
                                                    <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
                                                    </button><button type="button" className="btn" data-toggle="collapse" data-target={`#collapse${itemData.id}`}>
                                                      <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon></button>
                                                   </td>
                                                  <td><a href={`/item/${itemData.id}`} target="blank">{itemData.title}</a></td>
                                                  {this.state.currentTab.id === 99 ? (<td>$<input type="text" name="price" style={{width: '75px'}} value={itemData.price} onChange={this.specialPricing}/></td>): (<td>${parseFloat(itemData.price).toFixed(2)}</td>)}
                                                  <td><button onClick={e=>this.openQtyModal(itemData.id,itemData.qty)} className="btn btn-outline-primary">{itemData.qty}</button></td>
                                                  <td><button onClick={e=>this.openFreightModal(itemData.id, itemData.freightcost, itemData.freightorigin)} className="btn btn-outline-primary"><FontAwesomeIcon icon={itemData.freightcost != '00.00'?faCheck:faEdit}></FontAwesomeIcon></button></td>
                                                  <td><button onClick={e=>this.openMUModal(itemData.id,itemData.muprice,itemData.price,itemData.muvalue,itemData.mutype)} className="btn btn-outline-info"><FontAwesomeIcon icon={itemData.muvalue != '00'?faCheck:faEdit}></FontAwesomeIcon></button>
                                                   </td>
                                                   <td>${parseFloat(itemData.fullprice).toFixedNoRound()}/${((parseFloat(itemData.fullprice) / parseFloat(itemData.packsize)*100)/100).toFixed(3)}</td>
                                                   <td><div className="input-group"><select name={`tabSelect_${itemData.id}`} onChange={this.tabTransfer} className="form-control-sm">
                                                       <option value="00">Add to Tab...</option>
                                                       {this.state.tabs.length > 1 &&
                                                           this.state.tabs.filter(t=>t.id !== this.state.currentTab.id).map(tabItem=>(
                                                               <option value={tabItem.id}>{tabItem.name}</option>
                                                           ))
                                                       }
                                                       </select></div></td>
                                              </tr> 
                                              <tr id={`collapse${itemData.id}`} className="collapse out">
                                                   <td colSpan="3"><input type="text" className="form-control" name="itemAddDesc" placeholder="Additional Description" onChange={this.myChangeHandler} value={itemData.itemAddDesc}></input><br></br>{this.state.description}</td>
                                                   <td><strong>Pack Size:</strong>{itemData.packsize}</td>
                                                   <td><strong>Cases Per Pallet:</strong>{itemData.casesperpallet}</td>
                                                   <td colSpan="2"><strong>Unit Size:</strong>{itemData.unitSize}</td>
                                                   <td><strong>Unit Cost:</strong>${((parseFloat(itemData.price) / parseFloat(itemData.packsize)*100)/100).toFixed(3)}</td>
                                               </tr>  
                                               </React.Fragment>
                                          ))}
                                </tbody>
                                </table>

                                            {/*<p>{currentTab.notes}</p>
                                            <p><strong>Total Items: </strong>{items.length}</p>
                                            <p><strong>Tab Total: </strong>${this.state.currentTab.total}</p>*/}
                                            {currentTab.id ? (
                                            <div
                                                style={{ display: "flex", justifyContent: "space-between" }}
                                            >
                                                {/*<button
                                                className="btn btn-secondary"
                                                onClick={this.setEditMode}
                                                >
                                                    <FontAwesomeIcon icon={faPencilAlt}></FontAwesomeIcon>&nbsp;
                                                Edit
                                                </button>*/}
                                                <button className="btn btn-danger" onClick={() => this.handleDeleteTab(currentTab)}>
                                                    <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>&nbsp;
                                                Delete Tab
                                                </button>
                                                {authUser.role === "Admin" &&  
                                                  <Link to={"/quote/breakdown/"+this.state.quoteID} params={{qid:this.state.quoteID}}>Breakdown</Link>
                                                }
                                                <a href={"/export/"+quoteID+"/"+this.state.currentTab.id+"?showTotal="+this.state.showTotal} className="btn btn-info" target="_blank"> Export Current Tab</a>
                                                <button className="btn btn-success" onClick={e=>this.saveCart()}>Save Quote</button>
                                            </div>
                                            ) : (
                                            ""
                                            )}
                                        </div>
                                        )}
                                    </div>
                                    </div>
                                </div>
                        
                        </div>
                        
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                        <SlidingPane
                        closeIcon={<div>X</div>}
                        isOpen={this.state.showNotes}
                        title="Comments"
                        from="right"
                        width="400px"
                        onRequestClose={() => this.setState({ showNotes: false })}
                        >
                          <div>
                            <div className="row">
                              <input type="text" className="form-control" name="quoteItemNote" onChange={this.myChangeHandler}></input>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                              <input className="form-check-input" type="checkbox" name="pinnedNote" onChange={this.myChangeHandler}/>
                                <label for="pinnedNote">Pin this note?</label>
                              </div>
                              <div className="col-md-6"><button style={{marginTop:5+"px"}}className="btn btn-sm btn-success" onClick={e=>this.submitQuoteNote(e,authUser)}>Submit Note</button></div>
                            </div>
                          <div style={{overflow:'auto'},{marginTop:15+"px"}}>
                          {pinnedNotes.length > 0 && pinnedNotes.map(pN=>(
                            <div className="row" key={pN.noteid}>
                                <div className="col-sm-12">
                                  <hr></hr>
                                        <div className="row" style={{fontSize:.75+"em"}}>
                                          <div className="col-sm-12"><FontAwesomeIcon icon={faThumbtack}></FontAwesomeIcon><strong>{new Date(pN.noteDate.toDate()).toLocaleDateString()} {new Date(pN.noteDate.toDate()).toLocaleTimeString()}</strong> - <i>{pN.author}</i></div>
                                        </div>
                                        <div className="row" style={{fontSize:.75+"em"}}>
                                          <div className="col-sm-12">{pN.note}</div>
                                        </div>
                                </div>
                            </div>))}
                            {quoteNotes.length > 0 && quoteNotes.map(qN=>(
                            <div className="row" key={qN.noteid}>
                                <div className="col-sm-12">
                                  <hr></hr>
                                        <div className="row" style={{fontSize:.75+"em"}}>
                                          <div className="col-sm-12"><strong>{new Date(qN.noteDate.toDate()).toLocaleDateString()} {new Date(qN.noteDate.toDate()).toLocaleTimeString()}</strong> - <i>{qN.author}</i></div>
                                        </div>
                                        <div className="row" style={{fontSize:.75+"em"}}>
                                          <div className="col-sm-12">{qN.note}</div>
                                        </div>
                                </div>
                            </div>))}
                          </div>
                        </div>
                      </SlidingPane>
                        </div>
                        <div className="col-md-6">
                        <SlidingPane
                        closeIcon={<div>X</div>}
                        isOpen={this.state.showQuotes}
                        title="Previous Quotes"
                        from="right"
                        width="600px"
                        onRequestClose={() => this.setState({ showQuotes: false })}
                        >
                        <div>
                          <table className="table">
                            <thead>
                              <tr>
                              <th>Quote Date</th>
                              <th>Quote Status</th>
                              <th>Quote Link</th>
                              </tr>
                            </thead>
                            <tbody style={{fontSize:.75+"em"}}>
                            {otherQuotes.map((quote,index)=>{

                                        /*return <tr key={quote.quoteID}>
                                          <td>{new Date(quote.quoteDate).toLocaleDateString()}</td>
                                          <td><a href={'../quote/'+quote.quoteID} target="_blank">Link</a></td>
                                        </tr>;*/
                                        //console.log(quote.tabs.filter(tb=>tb.id==99)[0])
                                        if(quote.tabs.filter(tb=>tb.id==99)[0].items.length === 0)
                                        {
                                          
                                          return <tr key={quote.quoteID}>
                                            <td>{new Date(quote.quoteDate).toLocaleDateString()}</td>
                                            <td>{quote.clientStatus}</td>
                                            <td><a href={'../quote/'+quote.quoteID} target="_blank">Link</a></td>
                                          </tr>;
                                        }
                                         
                                          return <tr key={quote.quoteID}>
                                            <td>{new Date(quote.quoteDate).toLocaleDateString()}</td>
                                            <td>{quote.clientStatus}<br>
                                            </br>
                                            <table>
                                            {quote.tabs.filter(tb=>tb.id==99)[0].items.map((itm,i)=>{
                                                return <tr><td colspan="4">{itm.title} - ${parseFloat(itm.fullprice).toFixed(2)}</td></tr>;
                                                })}
                                            </table>
                                            </td>
                                            <td><a href={'../quote/'+quote.quoteID} target="_blank">Link</a></td>

                                           
                                          </tr>;
                                       
                                          })}
                            </tbody>
                            </table>
                        </div>
                      </SlidingPane>
                        </div>
                    </div>
                    </div>
            </div>
            )}
            </AuthUserContext.Consumer>
        );
      }
    }
    
    const condition = authUser =>
  authUser && (authUser.role == "Admin" || authUser.role == "Sales");

    export default compose(
      withAuthorization(condition),
      withFirebase,
    )(EditQuote);

    //export default withFirebase(EditQuote);
    
