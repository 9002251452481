//ORDER INVENTORY EDIT should mirror quote/edit.js
import React,{Component} from 'react';
import { Route,withRouter} from 'react-router-dom';
import warehouse from '../../images/coming_sonn.jpeg';
import { faEdit,faPlusCircle,faHome, faMinus,faPlus, faThList, faThumbtack} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCheck,faTrash,faShoppingBasket,faRedo,faUser,faUserCircle,faLock,faSearch,faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import InventoryRecieverCard from './recievercard';
import ItemCard from '../Cart/itemcard';
import InventoryItemCard from '../Cart/inventoryitemcard';
import { AuthUserContext } from '../Session';
import {withFirebase} from '../Firebase';
import firebase from 'firebase';
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from '../Modal';
import TableModal from '../Modal/tablemodal';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { render } from "react-dom";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

class InventoryOrder extends Component{

  constructor(props) {
    super(props);

    this.state = {
     default:'',
     userID:'',
     quoteInfo:'',
     quoteID:this.props.match.params.oid,
     dbID:'',
     quoteDate:'',
     customerPO:'',
     salesNotes:'',
     salesOrderNumber:'',
     recieverName:'',
     items:[],
     freight:'',
     contact:'',
     customers:'',
     totalCost:'00.00',
     loading:true,
     hideRecipient:true,
     note:'',
     status:'',
     statusDomain:[],
     clientStatus:'',
     clientStatusDomain:[],
     customers:[],
     selectedCustomerName:'',
     selectedCustomerID:'',
     selectedCustomerSelectedLocation:'',
     quoteNote:'',
     itemsModel:false,
     showMUModal:false,
     showQtyModal:false,
     activeRequest: true,
     showFreightModal:false,
     frieghtModalCost:'',
     frieghtModalOrigin:'',
     frieghtModalItem:'',
     quoteNotes:[],
     pinnedNotes:[],
     warehouseinventory:[],
     tabs: [],
      currentTab: '',
      editMode: false,
      editTabNameMode: false,
      MUitemID:'',
      MUitemPrice:'',
      itemMUPrice:'',
      itemMUValue:'',
      itemMUType:'',
      QtyItemID:'',
      QtyItemQty:'',
      showWarehouseCostModal:false,
      WHItemID:'',
      warehouseCost:'00',
      warehouseCostInterval:'',
      warehouseInOut:'',
      tabSelect:'',
      pricedItems:[],
      warehouses:[],
      canSave:true,
      customerPO:'',
      salesOrderNumber:'',
      shippingOrderNumber:'',
      purchaseOrderNumber:'',
      salesNotes:'',
      quoteCode:'',
      showTotal:true,
      agentName:'',
      isTemplate:'',
      lotNumber:'',
      bestByDate:'',
      estArrivalDate:'',
      canComplete:false,
      updateOnly:false,
      inventoryNames:[],
      tblColumns:[
        {
          data: "itemid",
          title: "Item ID",
          sort: false,
          visible:false
        },
        {
          data: "itemLink",
          title: "Item Name",
          sort: true,
          //formatter: (cell, row) => <a href={'/'+row.companyid+'/item/'+row.itemid}> {cell} </a>
        },
        {
          data: "itemDescription",
          title: "Description",
          sort:true
        },
        {
          data: "itemSizeFull",
          title: "Size",
          sort:true
        },
        {
          data: "packSize",
          title: "Pack Size",
          sort:true
        },
        {
          data: "fullPrice",
          title: "Price",
          //defaultContent:"$-",
          render: $.fn.dataTable.render.number( ',', '.', 2, '$' ),
          sort:true,
        },
        {
          data: "supplierName",
          title: "Supplier",
          sort:true,
            
        },
        {
          data: "fobFullLocation",
          title: "FOB Location",
          sort:false,
            
        },
        {
          title:"",
          sort:false, 
          render: function (data,type,row){
            if(row.fullPrice == "")
            {
              return "<button class='btn btn-sm btn-outline-success' disabled>+</button>";  
            }
            else{
              return "<button class='btn btn-sm btn-success nBTNX'>+</button>";            }
          }
        }
      ]
    };
  }

  
  componentDidMount(){
    console.log('editorder.js - mounting component');


    this.unsubscribe = this.props.firebase
    .getAvailableinventory()
    .onSnapshot(snp => {
        let inventoryNames=[];
        snp.forEach(iD=>{
          let itmNm = iD.data().itemName;
          console.log(inventoryNames.includes(itmNm));
          if(inventoryNames.indexOf(itmNm) === -1)
          {
            inventoryNames.push({id:iD.data().itemID,name:itmNm});
          }
        });
        this.setState({inventoryNames}, function(){console.log(inventoryNames);});
    })

    this.unsubscribe = this.props.firebase
        .warehouses()
        .onSnapshot(snapshot => {
          let warehouses = [];
   
          snapshot.forEach(doc =>
            warehouses.push({ ...doc.data(), wid: doc.id }),
          );

          this.setState({
            warehouses,
            loading: true,
          });
        });


        this.unsubscribe = this.props.firebase
        .availInventory()
        .onSnapshot(snapshot => {
          let warehouseInventory = [];
   
          snapshot.forEach(doc =>
            warehouseInventory.push({wiid: doc.id }),
          );

          this.setState({
            warehouseInventory,
            loading: true,
          });
        });


    this.unsubscribe = this.props.firebase.db
    .collection(`inventoryorders`).doc(this.props.match.params.oid)
    .onSnapshot(snapshot => {
        //console.log(pricedItems.length);
        //console.log("Tabs Profile: ");
        //console.log(snapshot.collection('notes').length);
        console.log(snapshot.data().tabs);
        this.setState({
          userID: firebase.auth().currentUser.uid,
          orderDate:firebase.firestore.Timestamp.now(),
          totalOrder:snapshot.data().totalOrder,
          activeRequest:snapshot.data().activeRequest,
          warehouse:snapshot.data().warehouse,
          warehouseName:snapshot.data().warehouseName,
          status:snapshot.data().status,
          tabs:snapshot.data().tabs,
          currentTab:snapshot.data().tabs[0],
          customerPO:snapshot.data().customerPO,
          lotNumber:snapshot.data().lotNumber,
          bestByDate:snapshot.data().bestByDate,
          estArrivalDate:snapshot.data().estArrivalDate,
          salesOrderNumber:snapshot.data().salesOrderNumber,
          shippingOrderNumber:snapshot.data().shippingOrderNumber,
          purchaseOrderNumber:snapshot.data().purchaseOrderNumber,
          salesNotes:snapshot.data().salesNotes,
          status:snapshot.data().status
        },()=>{
            if(this.state.status === "Complete")
            {
              this.setState({updateOnly:true});
              console.log("Completed Order - Redirecting to Export");
              //this.props.history.push('/orderexport/'+this.state.quoteID);
            }
        });


    });

    

    this.unsubscribe = this.props.firebase.db
    .collection(`inventoryorders`).doc(this.state.quoteID).collection('notes').orderBy('noteDate')
    .onSnapshot(snapshot => {
      let allNotes=[];
      let pinnedNotes=[];
      
      snapshot.forEach(doc =>{
        //allNotes.push({ ...doc.data(),noteid: doc.id});
        if(doc.pinnedNote === true)
        {
          pinnedNotes.push({ ...doc.data(),noteid: doc.id});
        }
        else{
          allNotes.push({ ...doc.data(),noteid: doc.id});
        }

      });
        //console.log(pricedItems.length);
        this.setState({quoteNotes:allNotes.reverse(),pinnedNotes,loading:true}, function(){console.log("Loading Notes Complete");});

    })

    //let pricedItems=[];

        /*this.unsubscribe = this.props.firebase
        .getAvailableinventory()
        .onSnapshot(invSH => {
          
          //let pricedInventory = [];
          
          invSH.forEach(dc => 
            pricedItems.push({...dc.data(),itemLink:'<a href="/item/'+dc.itemID+'">'+dc.data().itemName+'</a>', itemSizeFull:dc.data().itemSizeFull,fobFullLocation: dc.data().warehouseName, itemid: dc.itemID})  
          );
        })*/


    this.unsubscribe = this.props.firebase
    .getFPItems()
    .onSnapshot(snapshot => {

      let pricedItems=[];

      snapshot.forEach(doc =>
        pricedItems.push({ ...doc.data(),itemLink:'<a href="/'+doc.data().companyid+'/item/'+doc.id+'">'+doc.data().itemName+'</a>', itemSizeFull:doc.data().unitSize + " " + doc.data().unitUnit ,fobFullLocation: doc.data().fobCity + " " + doc.data().fobState, itemid: doc.id }),
      );
        //console.log(pricedItems.length);
        this.setState({pricedItems,loading:false}, function(){console.log("Priced Items - Set State Complete");});


        console.log("editorder.js - setting up table");

        $('#example').DataTable({
          data: pricedItems,
          columns: this.state.tblColumns,
          pageLength: 10,
          columnDefs:[{targets:-1,data:null,defaultContent:'<button class="btn btn-sm btn-success">+</button>'}],
          //filter: true,
          //deferRender: true,
          initComplete: function () {
            this.api().columns([4,6,7]).every( function () {
                var column = this;
                var select = $('<select class="form-control filterCtrl"><option value="">...</option></select>')
                    .appendTo( $(column.footer()).empty() )
                    .on( 'change', function () {
                        var val = $.fn.dataTable.util.escapeRegex(
                            $(this).val()
                        );
 
                        column
                            .search( val ? '^'+val+'$' : '', true, false )
                            .draw();
                    } );
 
                column.data().unique().sort().each( function ( d, j ) {
                    select.append( '<option value="'+d+'">'+d+'</option>' )
                } );
            } );

            $('#example tfoot tr').appendTo('#example thead');
        }
        });

        $('.clear-filters').prependTo('#example_length');

    });
  
    var self = this;
    //console.log(customers.length);
    $('#example tbody').on( 'click', 'button', function () {
      var data = $('#example').DataTable().row( $(this).parents('tr') ).data();
      console.log(data.itemid);
      //alert( "Feature Coming Soon");
      self.addItemToCurrentTab(data.itemid,data.itemName,data.itemDescription,data.casesPerPallet,data.fullPrice,data.fobFullLocation,data.itemSizeFull,data.packSize,data.supplierName);
  } );
    
  }

  clearSearchFilters(){
    $('#example').DataTable().search( '' ).columns().search( '' ).draw();
    $('tfoot input').val('');

    $('.filterCtrl').each(function(){
      $(this).val('');
    });
  }

  //#region NOTES

  submitQuoteNote=(event,authUser)=>{
    let user = firebase.auth().currentUser;

    console.log(authUser);

    this.props.firebase.db.collection('inventoryorders').doc(this.state.quoteID).collection('notes').add({
      noteDate: firebase.firestore.Timestamp.now(),
      author: authUser.fname + " " + authUser.lname,
      note:this.state.quoteItemNote,
      pinnedNote:false
    });

    let newNote ={};
    newNote.noteDate = new Date();
    newNote.author = authUser.fname + " " + authUser.lname;
    newNote.pinnedNote = false;
    newNote.note = this.state.quoteItemNote;

    const allNotes = {...this.state.quoteNotes};

    this.setState({
      quoteNotes: allNotes,
      quoteItemNote:'',
      pinnedNote:false,
      showNotes:false,
    });
  }
//#endregion NOTES
 

  /*transferItemToTab(itemObj,tabID){
   
    let tabs = this.state.tabs;

    let updatetab = tabs.filter(t=>t.id == tabID);
    
    updatetab[0].dateCreated = firebase.firestore.Timestamp.now();
    updatetab[0].items.push(itemObj);
    updatetab[0].total = parseFloat(updatetab[0].total) + (parseInt(itemObj.qty)*parseFloat(itemObj.fullprice));
    this.setState({tabs});
    console.log(tabs);

  }*/

  removeItemFromTab(itemID,tabID){
    //NOT TO BE USED ANY MORE - NEED TO REMOVE THIS FUNCTION!
    console.log("Remove Item to Current Tab: " + itemID + " "+tabID+ " Current Tab: " + this.state.currentTab.id);
    const { tabs, currentTab } = this.state;
    let ctItems='';
    let tabTotal='';
    let isLoadedPrice=0;
    const updatedTabs = tabs.map(tab => {
      if (tab.id === this.state.currentTab.id) {
        //console.log(tab);
        ctItems = tab.items;
        let cItem = ctItems.find(i=>i.itemID === itemID);
        const j = ctItems.findIndex(i=>i.itemID === itemID);
        //console.log(ctItems);
        /*if(cItem.fullprice !== "00.00"){
          isLoadedPrice = 1;
        }*/
        //console.log(parseFloat(tab.total) - " " + parseInt(this.state.QtyItemQty)+ " " +parseInt(cItem.qty)+ " " + parseFloat(cItem.price));
        tabTotal = parseFloat(tab.total) - (parseInt(cItem.qty) * parseFloat(cItem.fullprice));
        //cItem.qty = 0;
        //ctItems.splice([j],1);
        return {
          ...tab,
          items: ctItems,
          total:tabTotal
          //content: evt.target.value
        };
      } else {
        return tab;
      }
    });
    console.log("Current Tab Total: "+tabTotal);
    //console.log(updatedTabs);
    //this.setState({showQtyModal:false, currentTab:sResults,QtyItemID:'',QtyItemQty:''});
    this.setState({
      tabs: updatedTabs,
      currentTab: {
        ...currentTab,
        items: ctItems,
        total:tabTotal
      }
    },()=>{this.updateTabTotal(tabID)});
  }

  addItemToCurrentTab(itemID,itemName,itemDescription,casesPerPallet,fobFullLocation,fullPrice,itemSizeFull,packSize,sN){
    console.log("Add Item to Current Tab: " + itemID);
    //let currentTab = this.state.currentTab;
    const newItem = {
      id:itemID,
      title:itemName,
      description:itemDescription,
      qty:'1',
      packsize:packSize,
      unitSize:itemSizeFull,
      casesperpallet:casesPerPallet,
      inventoryhold:false,
      frominventory:false,
      fobLocation:fobFullLocation,
      mutype:'',
      muprice:'',
      itemAddDesc:'',
      price:fullPrice,
      freightcost:'00.00',
      freightorigin:'',
      muvalue:'00',
      mutype:'',
      warehouseInOut:'',
      warehouseCost:'00',
      warehouseCostInterval:'',
      tabID:this.state.currentTab.id,
      fullprice:fullPrice,
      supplierName:sN
    }
    //currentTab.items.push(newItem);
    //currentTab.total = currentTab.total + (1 * parseFloat(newItem.price));
    //this.setState({currentTab});

    const { tabs, currentTab } = this.state;
    let ctItems='';
    let tabTotal='';
    let isLoadedPrice=0;
    const updatedTabs = tabs.map(tab => {
      if (tab.name === currentTab.name) {
        //console.log(tab);
        ctItems = tab.items;
        ctItems.push(newItem);
        tabTotal = parseFloat(tab.total) + (1 * parseFloat(newItem.price));
        return {
          ...tab,
          items: ctItems,
          total:tabTotal
          //content: evt.target.value
        };
      } else {
        return tab;
      }
    });
    console.log("Current Tab Total: "+tabTotal);
    //console.log(updatedTabs);
    //this.setState({showQtyModal:false, currentTab:sResults,QtyItemID:'',QtyItemQty:''});
    this.setState({
      tabs: updatedTabs,
      currentTab: {
        ...currentTab,
        items: ctItems,
        total:tabTotal
      }
    });
  }


  updateItemTotal=(itemTotal)=>{
    console.log("Updating Cart Total "  + parseFloat(itemTotal));
    this.setState({totalCost:itemTotal});
}

updateTabTotalCost(){

}


  findTabByID(tabID)
      {
        return this.state.tabs.find((tabItem)=>{
          return tabItem.id === parseInt(tabID);
        })
      }


  //#region unused function
  /*removeItemFromCurrentTab(itemID){
    console.log("Remove Item to Current Tab: " + itemID);
    const { tabs, currentTab } = this.state;
    let ctItems='';
    let tabTotal='';
    let isLoadedPrice=0;
    const updatedTabs = tabs.map(tab => {
      if (tab.name === currentTab.name) {
        //console.log(tab);
        ctItems = tab.items;
        let cItem = ctItems.find(i=>i.id === itemID);
        const j = ctItems.findIndex(i=>i.id === itemID);
        if(cItem.fullprice !== "00.00"){
          isLoadedPrice = 1;
        }
        //console.log(parseFloat(tab.total) - " " + parseInt(this.state.QtyItemQty)+ " " +parseInt(cItem.qty)+ " " + parseFloat(cItem.price));
        tabTotal = parseFloat(tab.total) - (parseInt(cItem.qty) * parseFloat(cItem.fullprice));
        cItem.qty = 0;
        ctItems.splice([j],1);
        return {
          ...tab,
          items: ctItems,
          total:tabTotal
          //content: evt.target.value
        };
      } else {
        return tab;
      }
    });
    console.log("Current Tab Total: "+tabTotal);
    //console.log(updatedTabs);
    //this.setState({showQtyModal:false, currentTab:sResults,QtyItemID:'',QtyItemQty:''});
    this.setState({
      tabs: updatedTabs,
      currentTab: {
        ...currentTab,
        items: ctItems,
        total:tabTotal
      }
    });
  }*/
//#endregion
  itemPriceUpdate(itemObj){
    if(itemObj !== undefined){
      console.log("Item Price Update:");console.log(itemObj);
      let tabs = this.state.tabs;
      //console.log(tabs);
      //console.log(itemObj.tabID);
      let updatetab = tabs.filter(t=>t.id == itemObj.tabID);
      //console.log(updatetab);
      const itemIndex = itemObj.itemID;
      updatetab[0].items[itemIndex] = itemObj;
      //console.log(updatetab[0].items);
      //const updatedItems = update(updatetab.items, {$splice: [[itemIndex,1,itemObj]]});
      this.setState({tabs},()=>{this.updateTabTotal(itemObj.tabID);});            
  }
}

updateTabTotal(tabID){
  console.log("Updating Tab Total for Tab: "+tabID)
  let tabs = this.state.tabs;
  console.log(tabs);
  let tab2Total = tabs.filter(t=>t.id == tabID);
  console.log(tab2Total);
  let ctItems = tab2Total[0].items;
  //console.log(ctItems);
  var tabTotal = parseFloat("0.00");
  let noteString = "Updating Tab Total for Tab: "+tabID;
  this.addChangeNote(this.state.quoteID,noteString);
  ctItems.map(item => { console.log(item.qty + "-"+ item.fullprice + " | " +(item.qty * parseFloat(item.fullprice).toFixed(2))); tabTotal = tabTotal + (Number(item.qty) * parseFloat(item.fullprice).toFixed(2))});
  tab2Total[0].total = tabTotal;
  if(tabID === this.state.currentTab.id)
  {
      this.state.currentTab.total = tabTotal;
  }
  this.setState({tabs});
  //console.log(tabs);
}

  componentWillUnmount()
  {
      
  }

  clearCart(){
    localStorage.clear();
    this.props.history.push('/itemsearch');
  }

  async saveCart(authUser){
    let userFullName = authUser.fname + " " + authUser.lname;
    console.log(this.state.tabs);
    const newQuote = await this.props.firebase.db.collection(`inventoryorders`).doc(this.state.quoteID).update({
      totalOrder:'',
      //activeRequest:true,
      warehouse:this.state.warehouse,
      status:"In Progress",
      tabs:this.state.tabs,
      customerPO:this.state.customerPO,
      salesOrderNumber:this.state.salesOrderNumber,
      shippingOrderNumber:this.state.shippingOrderNumber,
      purchaseOrderNumber:this.state.purchaseOrderNumber,
      salesNotes:this.state.salesNotes,
      lotNumber:this.state.lotNumber,
      bestByDate:this.state.bestByDate,
      estArrivalDate:this.state.estArrivalDate
  });

    const newDateInfo = await this.props.firebase.db.collection(`inventoryorders`).doc(this.state.quoteID).collection('updates').add({
      userID: firebase.auth().currentUser.uid,
      updateDate:firebase.firestore.Timestamp.now(),
      note:"Saved",
      userName:userFullName,
    });

    //this.setState({dbID:newQuote.id});
    //localStorage.clear();
    //this.props.history.push('/quote/'+newQuote.id);
    //this.addItems(this.state.items);
  }

  async completeCart(authUser){
    let userFullName = authUser.fname + " " + authUser.lname;
    //loop through warehouses to find the name of the one based on ID
    /*for (var i = this.state.warehouses.length; i--;)
    {
      for (var key in this.state.warehouses[i])
      {
        if (key.id = this.state.warehouse)
        {
          console.log(key);
          //this.setState({
            //warehouseName:key.name;
          //})
        }
      }
    }*/

    const newQuote = await this.props.firebase.db.collection(`inventoryorders`).doc(this.state.quoteID).update({
      totalOrder:'',
      //activeRequest:true,
      warehouse:this.state.warehouse,
      status:"Complete",
      tabs:this.state.tabs,
      customerPO:this.state.customerPO,
      salesOrderNumber:this.state.salesOrderNumber,
      shippingOrderNumber:this.state.shippingOrderNumber,
      purchaseOrderNumber:this.state.purchaseOrderNumber,
      salesNotes:this.state.salesNotes,
      lotNumber:this.state.lotNumber,
      bestByDate:this.state.bestByDate,
      estArrivalDate:this.state.estArrivalDate
  });

    const newDateInfo = await this.props.firebase.db.collection(`inventoryorders`).doc(this.state.quoteID).collection('updates').add({
      userID: firebase.auth().currentUser.uid,
      updateDate:firebase.firestore.Timestamp.now(),
      note:"Completed",
      userName:userFullName,
    });

    //add all items on Order Tab id=99 to inventory collection
    let tabs = this.state.tabs;
      console.log(tabs);
      let orderTab = tabs.filter(t=>t.id == 99);
      //console.log(orderTab);
      let orderItems = orderTab[0].items;
      orderItems.map(item => { 
        console.log(item.qty + "-"+ item.fullprice + " | " +(item.qty * parseFloat(item.fullprice).toFixed(2))); 
        //console.log(item.supplierName);
        //tabTotal = tabTotal + (Number(item.qty) * parseFloat(item.fullprice).toFixed(2))

        let newOrder ={};
          newOrder.orderID = this.state.quoteID;
          newOrder.dateAdded=firebase.firestore.Timestamp.now();
          newOrder.orderQty=item.qty;
          newOrder.itemPrice=item.fullprice;
          newOrder.freightCost=item.freightcost;
          newOrder.frieghtOrigin=item.freightorigin;
          newOrder.itemMUType=item.mutype;
          newOrder.itemMUValue=item.muvalue;
          newOrder.warehouseCostType=item.warehouseCostInterval;
          newOrder.warehouseInOut=item.warehouseInOut;
          newOrder.warehouseCostValue=item.warehouseCost;
          newOrder.lotNumber=this.state.lotNumber;
          newOrder.estArrivalDate=this.state.estArrivalDate;
          newOrder.bestByDate=this.state.bestByDate;

        if(this.state.warehouseinventory.includes(item.id))
        {
          //create a new order to add to the array
          /*const addedItem = this.props.firebase.db.collection(`inventory`).doc(item.id).set({
            orderedQty:parseInt(item.qty),
            orders:[...item.orders,newOrder]
          })*/
          

          /*const addedOrder = this.props.firebase.db.collection(`warehouseinventory`).doc(item.id).collection("orders").add({
            orderID:this.state.quoteID,
            dateAdded:firebase.firestore.Timestamp.now(),
            orderQty:item.qty,
            itemPrice: item.fullprice,
            freightCost:item.freightcost,
            frieghtOrigin:item.freightorigin,
            itemMUType:item.mutype,
            itemMUValue:item.muvalue,
            warehouseCostType: item.warehouseCostInterval,
            warehouseCostValue: item.warehouseCost,
            lotNumber:this.state.lotNumber,
            estArrivalDate:this.state.estArrivalDate,
            bestByDate:this.state.bestByDate,
          })*/
        }
        else{
          const addedItem = this.props.firebase.db.collection(`inventory`).add({
            dateAdded:firebase.firestore.Timestamp.now(),
            itemCasesPerPallet:item.casesperpallet,
            fobLocation:item.fobLocation,
            itemDescription:item.description,
            itemID:item.id,
            groupItemID:item.groupItemID,
            itemName:item.title,
            itemPrice:item.fullprice,
            //itemQty:parseInt(item.qty),
            packSize:item.packsize,
            itemSizeFull:item.unitSize,
            lotCount:0,
            //quoteID:'',
            supplierID:'',
            txnQty:0,
            holdQty:0,
            availQty:0,
            orderedQty:parseInt(item.qty),
            warehouseName:this.state.warehouseName,
            //orders:[...item.orders,newOrder]
            //warehouseCode:this.state.warehouse,
            //warehouseID:'',//this.state.warehouses.filter(c=>c.id==this.state.warehouse)[0],
            //warehouseName:'',
            warehouseCostType: item.warehouseCostInterval,
            warehouseCostValue: item.warehouseCost,
            warehouseInOut:item.warehouseInOut,
            lotNumber:this.state.lotNumber,
            estArrivalDate:this.state.estArrivalDate,
            bestByDate:this.state.bestByDate,
            orderID:this.state.quoteID,
            supplierName:item.supplierName
          })

          const addedOrder = this.props.firebase.db.collection(`inventory_incoming`).add({
            orderID:this.state.quoteID,
            itemID:item.id,
            groupItemID:item.groupItemID,
            itemName:item.title,
            dateAdded:firebase.firestore.Timestamp.now(),
            orderQty:item.qty,
            itemPrice: item.fullprice,
            fobLocation:item.fobLocation,
            itemSizeFull:item.unitSize,
            packSize:item.packsize,
            itemDescription:item.description,
            freightCost:item.freightcost,
            frieghtOrigin:item.freightorigin,
            itemMUType:item.mutype,
            itemMUValue:item.muvalue,
            warehouseCostType: item.warehouseCostInterval,
            warehouseCostValue: item.warehouseCost,
            warehouseInOut:item.warehouseInOut,
            warehouseName:this.state.warehouseName,
            lotNumber:this.state.lotNumber,
            estArrivalDate:this.state.estArrivalDate,
            bestByDate:this.state.bestByDate,
            orderID:this.state.quoteID,
            supplierName:item.supplierName
          })
        }


      });

    //this.setState({dbID:newQuote.id});
    localStorage.clear();
    this.props.history.push('/orderexport/'+this.state.quoteID);
    //this.addItems(this.state.items);
  }

  /*addItems = (itemsList) =>{
    //console.log("Add Location for ID: " + this.state.newAddID);
    console.log(itemsList);
    itemsList.map(itm=>{
        //console.log(itm.id);
        this.props.firebase.db.collection('quotes').doc(this.state.dbID).collection('tabs').add({
            itemID:itm.id,
            itemQty:itm.qty,
            itemFOBPrice:'',
            itemMUPrice:'',
            freightOrigin:'',
            freightCost:'',
            itemTotalCost:'',
            isActive:1,
            dateAdded:firebase.firestore.Timestamp.now(),
            dateUpdated:firebase.firestore.Timestamp.now(),
          })
    })
    
    this.props.firebase.db.collection('quotes').doc(this.state.dbID).collection('revisions').add({
        customerID:this.state.selectedCustomerID,
        customerLocationID:this.state.selectedCustomerSelectedLocation,
        quoteNote:this.state.quoteNote,
        status:this.state.status,
        clientStatus:this.state.clientStatus,
        dateAdded:firebase.firestore.Timestamp.now(),
    })

    localStorage.clear();

    this.props.history.push('/editquote/'+this.state.dbID);
  }*/

  handleCheckTotal = e =>{
    if(this.state.showTotal)
    {
      this.setState({showTotal:false});
    }
    else{
      this.setState({showTotal:true});
    }
  }

  renderMUPrice(itemPrice,MUPrice){
    console.log(itemPrice+"  "+ MUPrice);
    if(itemPrice > MUPrice)
    {
      return itemPrice;
    }
    if(itemPrice === MUPrice)
    {
      return itemPrice;
    }
    if(itemPrice < MUPrice)
    {
      return MUPrice;
    }
  }

  openItemsModal(){
    console.log("Open Items Modal");
    this.setState({ showItemsModal: true,});
  }

  closeItemsModal(){
    this.setState({ showItemsModal: false,});
  }

  openMUModal(itemid,mup,fobPrice,imuv,imut) {
    this.setState({ showMUModal: true, MUitemID:itemid, itemMUPrice:mup, itemFOBPrice:fobPrice, itemMUType: imut, itemMUValue:imuv });
  }

  closeModal() {
    this.setState({
      focusItemId: "",
      MUitemID:'',
      itemFOBPrice:'',
      MUitemPrice:'',
      itemMUPrice:'',
      itemMUValue:'',
      itemMUType:'',
      showMUModal: false
    });
}

openWHCostModal(itemid,itemPrice,fmc,fmo,wio) {
  this.setState({ showWarehouseCostModal: true,focusItemId:itemid, warehouseCost:fmc, warehouseCostInterval:fmo, warehouseInOut:wio });
}

closeWarehouseModal() {
  this.setState({
    warehouseCost:'00',
    warehouseCostInterval:'',
    warehouseInOut:'',
    showWarehouseCostModal: false
  });
}

openQtyModal(itemid,itemqty) {
    this.setState({ showQtyModal: true, QtyItemID:itemid, QtyItemQty:itemqty });
  }

  closeQtyModal() {
    this.setState({
      QtyItemID: "",
      QtyItemQty:'',
      showQtyModal: false
    });
}

openFreightModal(itemid,fmc,fmo) {
  this.setState({ showFreightModal: true, frieghtModalItem:itemid, frieghtModalCost: fmc, frieghtModalOrigin:fmo});
}

closeFreightModal() {
  this.setState({
    frieghtModalItem:'',
    frieghtModalOrigin:'',
    frieghtModalCost:'',
    showFreightModal: false
  });
}

  downIncrement=(evt)=>{
    var val = this.state.QtyItemQty;
    if(val > 0)
    {
      var newVal = --this.state.QtyItemQty;
      this.setState({
        QtyItemQty:newVal,
          itemTotalCost:Number(this.state.itemTotalCost) - (1 * this.state.itemPrice)
        });
      console.log(newVal);
    }
  }

  upIncrement=(evt)=>{
    var newVal = ++this.state.QtyItemQty;
    //console.log(modelItemNam);
    this.setState({
        QtyItemQty:newVal,
        itemTotalCost:Number(this.state.itemTotalCost) + (1 * this.state.itemPrice)
    });
    //console.log(newVal);
  }

  updateItemQty=(evt)=>{
    const { tabs, currentTab } = this.state;
    console.log("Updating Current Tab Item Qty for ID: "+currentTab.id); 
    console.log("Updating QTY for item: " + this.state.QtyItemID); 
    let noteString = "Updating Current Tab Item Qty for ID: "+currentTab.id + " Updating QTY for item: " + this.state.QtyItemID;
    this.addChangeNote(this.state.quoteID,noteString);

    let ctItems='';
    let tabTotal='';
    let fullPrice = '';
    const updatedTabs = tabs.map(tab => {
      if (tab.name === currentTab.name) {
        console.log(tab);
        ctItems = tab.items;
        let cItem = ctItems.find(i=>i.id === this.state.QtyItemID);
        console.log(parseFloat(tab.total) + " " + parseInt(this.state.QtyItemQty)+ " " +parseInt(cItem.qty)+ " " + parseFloat(cItem.price));
        tabTotal = parseFloat(tab.total) + ((parseInt(this.state.QtyItemQty) - parseInt(cItem.qty)) * parseFloat(cItem.price));
        if(cItem.fullprice == "00.00"){
          fullPrice = parseFloat(cItem.price);
        }
        else if(cItem.muvalue == "00" && cItem.freightcost == "00.00")
        {
          fullPrice = parseFloat(cItem.price);
        }
        else if(cItem.muvalue !== "00" && cItem.freightcost == "00.00")
        {
          fullPrice = parseFloat(cItem.muprice);
        }
        else if(cItem.muvalue == "00" && cItem.freightcost !== "00")
        {
          fullPrice = parseFloat(cItem.fullprice) + (parseFloat(cItem.freightcost) / parseInt(this.state.QtyItemQty));
        }
        else{
          fullPrice = parseFloat(cItem.muprice) + (parseFloat(cItem.freightcost) / parseInt(this.state.QtyItemQty));
        }
        //cItem.fullprice = parseFloat(cItem.price)
        //if(cItem.fullprice == "00.00"){
          //fullPrice = parseFloat(cItem.price) - (parseFloat(cItem.freightcost)/parseInt(cItem.qty)) + (parseFloat(this.state.frieghtModalCost)/parseInt(this.state.QtyItemQty));
        //}
        //else{
          //fullPrice = parseFloat(cItem.fullprice) - (parseFloat(cItem.freightcost)/parseInt(cItem.qty)) + (parseFloat(this.state.frieghtModalCost)/parseInt(this.state.QtyItemQty));
        //}
        cItem.fullprice = fullPrice;
        cItem.qty = this.state.QtyItemQty;
        return {
          ...tab,
          items: ctItems,
          total:tabTotal
          //content: evt.target.value
        };
      } else {
        return tab;
      }
    });
    console.log("Current Tab Total: "+tabTotal);
    //console.log(updatedTabs);
    //this.setState({showQtyModal:false, currentTab:sResults,QtyItemID:'',QtyItemQty:''});
    this.setState({
      showQtyModal:false,
      QtyItemID:'',
      QtyItemQty:'',
      tabs: updatedTabs,
      currentTab: {
        ...currentTab,
        items: ctItems,
        total:tabTotal
      }
    },()=>{this.updateTabTotal(this.state.currentTab.id)});
  }

  updateWarehouseCost=(e)=>{
    const { tabs, currentTab } = this.state;
    let ctItems='';
    let tabTotal='';
    let fullPrice = '';
    const updatedTabs = tabs.map(tab => {
      if (tab.name === currentTab.name) {
        //console.log(tab);
        ctItems = tab.items;
        let cItem = ctItems.find(i=>i.id === this.state.focusItemId);
        
        cItem.warehouseCost = this.state.warehouseCost;
        cItem.warehouseCostInterval = this.state.warehouseCostInterval;
        cItem.warehouseInOut = this.state.warehouseInOut;
        return {
          ...tab,
          items: ctItems
          //content: evt.target.value
        };
      } else {
        return tab;
      }
    });

    console.log(updatedTabs);
    //this.setState({showQtyModal:false, currentTab:sResults,QtyItemID:'',QtyItemQty:''});
    this.setState({
      showWarehouseCostModal:false,
      warehouseCostInterval:'',
      warehouseInOut:'',
      warehouseCostType:'',
      warehouseCostValue:'',
      tabs: updatedTabs,
      currentTab: {
        ...currentTab,
        items: ctItems,
        total:tabTotal
      }
    },()=>{this.updateTabTotal(this.state.currentTab.id)});
    //console.log("Updating Current Tab Item Qty for ID: "+currentTab.id); 
    //console.log("Updateing QTY for item: " + this.state.QtyItemID); 
    /*let ctItems='';
    let tabTotal='';
    let fullPrice = '';

    let noteString = "Updating Warehouse Cost: ";
    this.addChangeNote(this.state.quoteID,noteString);

    var finalFullPrice;
      if(this.state.warehouseCost == null)
      {
        finalFullPrice = this.state.fullprice;
      }
      else{
        finalFullPrice = parseFloat(this.state.muprice) + (parseFloat(this.state.freightcost)/parseFloat(this.state.qty));
      }

      this.setState({
        fullprice:finalFullPrice,
        showWarehouseCostModal:false,
        itemProfile:{
          ...this.state.itemProfile,
          fullprice:finalFullPrice
      }},()=>{this.updateTabTotal(this.state.currentTab.id)});
      let newTotal = parseFloat(finalFullPrice) * Number(this.state.qty);
      console.log("New Total: "+newTotal +" "+ finalFullPrice + " "+ this.state.qty );*/
      //this.props.itemPriceUpdate(this.state.itemProfile)
  }

  updateFreightCost=(evt)=>{
    const { tabs, currentTab } = this.state;
    //console.log("Updating Current Tab Item Qty for ID: "+currentTab.id); 
    //console.log("Updateing QTY for item: " + this.state.QtyItemID); 
    let noteString = "Updating Current Tab Item Freight for ID: "+currentTab.id + " Updating Freight for item: " + this.state.frieghtModalItem;
    this.addChangeNote(this.state.quoteID,noteString);

    let ctItems='';
    let tabTotal='';
    let fullPrice = '';
    const updatedTabs = tabs.map(tab => {
      if (tab.name === currentTab.name) {
        //console.log(tab);
        ctItems = tab.items;
        let cItem = ctItems.find(i=>i.id === this.state.frieghtModalItem);
        //fullPrice = parseFloat(cItem.fullprice) - (parseFloat(cItem.freightcost)/parseInt(cItem.qty)) + (parseFloat(this.state.frieghtModalCost)/parseInt(cItem.qty));
        /*if(parseFloat(cItem.fullprice).toFixed(2) === parseFloat(cItem.price).toFixed(2))
        {

        }*/
        if(cItem.fullprice == "00.00"){
          fullPrice = parseFloat(cItem.price) + (parseFloat(this.state.frieghtModalCost)/parseInt(cItem.qty));
        }
        else if(cItem.muvalue !== "00")
        {
          fullPrice = parseFloat(cItem.muprice) + (parseFloat(this.state.frieghtModalCost)/parseInt(cItem.qty));
        }
        else if(parseFloat(cItem.fullprice).toFixed(2) === parseFloat(cItem.price).toFixed(2))
        {
          if(cItem.muvalue !== "00")
          {
            fullPrice = parseFloat(cItem.muprice) + (parseFloat(this.state.frieghtModalCost)/parseInt(cItem.qty));
          }
          else{
            fullPrice = parseFloat(cItem.price) + (parseFloat(this.state.frieghtModalCost)/parseInt(cItem.qty));
          }
        }
        else{
          fullPrice = parseFloat(cItem.fullprice) + (parseFloat(this.state.frieghtModalCost)/parseInt(cItem.qty));
        }
        tabTotal = parseFloat(tab.total) + (parseInt(this.state.frieghtModalCost) - parseInt(cItem.freightcost));
        cItem.fullprice = fullPrice;
        cItem.freightcost = this.state.frieghtModalCost;
        cItem.freightorigin = this.state.frieghtModalOrigin;
        return {
          ...tab,
          items: ctItems,
          total:tabTotal
          //content: evt.target.value
        };
      } else {
        return tab;
      }
    });

    console.log(updatedTabs);
    //this.setState({showQtyModal:false, currentTab:sResults,QtyItemID:'',QtyItemQty:''});
    this.setState({
      showFreightModal:false,
      frieghtModalCost:'',
      frieghtModalOrigin:'',
      frieghtModalItem:'',
      tabs: updatedTabs,
      currentTab: {
        ...currentTab,
        items: ctItems,
        total:tabTotal
      }
    },()=>{this.updateTabTotal(this.state.currentTab.id)});
}

  calculatePrice(){
    var basePrice = parseFloat(this.state.MUitemPrice);
    var finalPrice;
    var altMarkup = parseFloat(this.state.itemMUValue);
    if(this.state.itemMUType == "Percent")
    {
      finalPrice = basePrice + (basePrice * (altMarkup/100));
    }
    else{
      finalPrice = basePrice + altMarkup;
    }

    this.setState({itemMUPrice:finalPrice.toFixed(2),itemTotalCost:(this.state.itemQty * finalPrice)});
}

updateItemPrice=(evt)=>{
  const { tabs, currentTab } = this.state;
  //console.log("Updating Current Tab Item Qty for ID: "+currentTab.id); 
  console.log("Updating Markup for item: " + this.state.MUitemID); 
  let noteString = "Updating Markup for item: " + this.state.MUitemID + " MU Value " + this.state.itemMUValue + " MU Type: " +this.state.itemMUType + " MU Price: " + this.state.itemMUPrice;
  this.addChangeNote(this.state.quoteID,noteString);
  let ctItems='';
  let tabTotal='';
  let fullPrice = '';
  const updatedTabs = tabs.map(tab => {
    if (tab.name === currentTab.name) {
      console.log(tab);
      ctItems = tab.items;
      let cItem = ctItems.find(i=>i.id === this.state.MUitemID);
      //fullPrice = parseFloat(cItem.fullprice) - parseFloat(cItem.price) + parseFloat(this.state.itemMUPrice);
          if(cItem.fullprice == "00.00" || isNaN(parseFloat(cItem.fullprice)))
          {
            fullPrice = parseFloat(this.state.itemMUPrice);
          }
          else if(this.state.itemMUPrice == cItem.muprice)
          {
            //do nothing
            fullPrice = parseFloat(cItem.fullprice);
          }
          else{
            //fullPrice = parseFloat(cItem.fullprice) - parseFloat(cItem.price) + parseFloat(this.state.itemMUPrice);
            fullPrice = parseFloat(this.state.itemMUPrice) + (parseFloat(cItem.freightcost)/parseInt(cItem.qty)); 
          }
      tabTotal = parseFloat(tab.total) - (parseFloat(cItem.price) * parseInt(cItem.qty)) + (parseFloat(fullPrice) * parseInt(cItem.qty))
      cItem.fullprice = fullPrice;
      cItem.muvalue = this.state.itemMUValue;
      cItem.mutype = this.state.itemMUType;
      cItem.muprice = this.state.itemMUPrice;
      return {
        ...tab,
        items: ctItems,
        total:tabTotal
        //content: evt.target.value
      };
    } else {
      return tab;
    }
  });

  console.log(updatedTabs);
  //this.setState({showQtyModal:false, currentTab:sResults,QtyItemID:'',QtyItemQty:''});
  this.setState({
    showMUModal:false,
    itemMUPrice:'',
    itemMUValue:'',
    itemMUType:'',
    MUitemID:'',
    tabs: updatedTabs,
    currentTab: {
      ...currentTab,
      items: ctItems,
      total:tabTotal
    }
  });
}

  removeItemHandler=(itemID,tabID)=>{
    //console.log("Remove Item " + itemID+ " from Tab: " +tabID);
    let noteString = "Remove Item Handler " + itemID + " On Current Tab";
    this.addChangeNote(this.state.quoteID,noteString);
    const { tabs, currentTab } = this.state;
    let ctItems='';
    let tabTotal='';
    let isLoadedPrice=0;
    const updatedTabs = tabs.map(tab => {
      if (tab.id === this.state.currentTab.id) {
        //console.log(tab);
        ctItems = tab.items;
        const j = ctItems.findIndex(i=>i.itemID === itemID);
        ctItems.splice([j],1);
        return {
          ...tab,
          items: ctItems,
          total:tabTotal
        };
      } else {
        return tab;
      }
    });
    console.log("Current Tab Total: "+tabTotal);
    this.setState({
      tabs: updatedTabs,
      currentTab: {
        ...currentTab,
        items: ctItems,
        total:tabTotal
      }
    },()=>{this.updateTabTotal(tabID)});

  }

  myMUChangeHandler=(event)=>{
    let nam = event.target.name;
    let val = event.target.value;
    let noteString = "MU Change Handler " + nam + '--'+val;
    this.addChangeNote(this.state.quoteID,noteString);

    let err = '';
    if(nam === "itemMUType" || nam === "itemMUValue")
    {
      console.log("A");
      if(nam === "itemMUType" && this.state.itemMUValue != null)
      {
        var finalPrice;
        if(val === "Percent")
        {
          finalPrice = parseFloat(this.state.itemFOBPrice) + (parseFloat(this.state.itemFOBPrice) * (parseFloat(this.state.itemMUValue)/100));
        }
        else{
          finalPrice = parseFloat(this.state.itemFOBPrice) + parseFloat(this.state.itemMUValue);
        }
        console.log("B");
        this.setState({itemMUPrice:finalPrice});
      }
      else if(nam === "itemMUType" && this.state.itemMUValue == null)
      {
        //do nothing
        console.log("C");
      }
      else if(nam==="itemMUValue" && this.state.itemMUType != null)
      {
        var finalPrice;
        if(this.state.itemMUType === "Percent")
        {
          finalPrice = parseFloat(this.state.itemFOBPrice) + (parseFloat(this.state.itemFOBPrice) * (val/100));
        }
        else if(this.state.itemMUType === "Dollar"){
          finalPrice = parseFloat(this.state.itemFOBPrice) + parseFloat(val);
        }
        console.log("D");
        this.setState({itemMUPrice:finalPrice});
      }
      else if(nam==="itemMUValue" && this.state.itemMUType == null)
      {
        //do nothing
        console.log("E");
      }
    }


    this.setState({errormessage: err});
    this.setState({[nam]: val});
  }

  myChangeHandler = (event) => {
    //console.log('Chages');
    let nam = event.target.name;
    let val = event.target.value;
    let err = '';

    let noteString = "Change Handler " + nam + '--'+val;
    this.addChangeNote(this.state.quoteID,noteString);

    if(val==="NaN")
    {
      err="Error!";
    }
    this.setState({errormessage: err});
    this.setState({[nam]: val});
    //console.log(nam + " " + val);
    //console.log(val);
  }
 
  selectReciever=(type,id,name)=>{
      console.log("Quote: " + type + '--'+id+"--"+name);
      let noteString = "Reciver Quote: " + type + '--'+id+"--"+name;
      this.addChangeNote(this.state.quoteID,noteString);
      if(type == "selectedCustomer"){
          //console.log("There is a selected Customer");
          if(typeof id == "object")
          {
            this.setState({selectedCustomerID:id.cid});   
          }
          else{
            this.setState({selectedCustomerID:id});   
          }
          this.setState({selectedCustomerName:name});
          if(this.state.status == '' || this.state.clientStatus == ''){
            this.setState({canSave:true});
          }
          if(this.state.status !== '' && this.state.clientStatus !== ''){
            this.setState({canSave:false});
          }
          console.log(this.state.selectedCustomerID);
      }
      else{
          this.setState({selectedCustomerSelectedLocation:id});
      }
      console.log("Select Reciever: " + type + "-"+id);
  }

  assignGroup = (event) =>{

    console.log("Copying Item to Tab");
    let nam = event.target.name;
    let val = event.target.value;//id of tab to copy to
    
    let controlName = nam.split("_");
    let item_id = controlName[1];

    let noteString = "Grouping Item: "+item_id+" to Item ID: "+val;
    if(val=="99")
    {
      this.setState({canComplete:false});
    }
    this.addChangeNote(this.state.quoteID,noteString);

    if(val !== "00"){
      //console.log(event.target);
      let currentTabCall = this.state.currentTab;
      //console.log(currentTabCall);
      let ctItems = currentTabCall.items;
      console.log(ctItems);
      let searchItem = ctItems.filter(i=>i.id == item_id);
      var itemIndex = ctItems.indexOf(searchItem[0]);
      console.log(searchItem, itemIndex);
      let tabs = this.state.tabs;
      searchItem[0].groupItemID = val;
      //console.log(tabs);
      let updatetab = tabs.filter(t=>t.id == 99);
      //let deepClone = {...searchItem[0]};
      //updatetab[0].dateCreated = firebase.firestore.Timestamp.now();
      //console.log("Price: " + deepClone.price + " Full Price: " + deepClone.fullprice + " Qty: "+deepClone.qty);
      //if(deepClone.fullprice === "00.00" && deepClone.qty > 0)
      //{
        //deepClone.fullprice = "00.00";
      //}
      //console.log(parseFloat(updatetab[0].total) + " " + parseFloat(deepClone.fullprice) +" "+ parseInt(deepClone.qty))
      //updatetab[0].total = parseFloat(updatetab[0].total) + (parseFloat(deepClone.fullprice) * parseInt(deepClone.qty));
      updatetab[0].items[0] = searchItem[0];//searchItem[0]];
      //console.log(updatetab[0].items);
      this.setState({tabs});
      //console.log(tabs);
    }
    //this.updateTabTotal(val);
  }

  backPage = (event) =>{
    //props.histroy.push('/itemsearch');
  }

  //Tab Controller
  handleDoubleClick = () => {
    this.setState({
      editTabNameMode: true
    });
  };

  handleEditTabName = e => {
    let noteString = "Editing Tab Name";
    this.addChangeNote(this.state.quoteID,noteString);
    const { currentTab, tabs } = this.state;

    const updatedTabs = tabs.map(tab => {
      if (tab.id === currentTab.id) {
        return {
          ...tab,
          name: e.target.value
        };
      } else {
        return tab;
      }
    });

    this.setState({
      tabs: updatedTabs,
      currentTab: {
        ...currentTab,
        name: e.target.value
      }
    });
  };

  handleOnBlur = () => {
    this.setState({
      editTabNameMode: false
    });
  };

  createTabs = () => {
    const { tabs, currentTab, editTabNameMode } = this.state;

    const allTabs = tabs.map(tab => {
      return (
        <li className="nav-item">
          {editTabNameMode && currentTab.id === tab.id ? (
            <input
              value={tab.name}
              onBlur={this.handleOnBlur}
              onChange={this.handleEditTabName}
              className="form-control"
            />
          ) : (
            <button
              className={currentTab.id === tab.id ? "tab active" : "tab"}
              onClick={() => this.handleSelectTab(tab)}
              onDoubleClick={() => this.handleDoubleClick(tab)}
            >
              {tab.name}
            </button>
          )}
        </li>
      );
    });

    return <ul className="nav nav-tabs">{allTabs}</ul>;
  };

  handleSelectTab = tab => {
    console.log("Selecting Tab: "+tab.id);
    this.setState({
      currentTab: tab,
      editMode: false,
      editTabNameMode: false
    });
  };

  handleAddTab = () => {
    let noteString = "Adding a new Tab";
    this.addChangeNote(this.state.quoteID,noteString);
    const { tabs } = this.state;
    console.log("Add a new Tab");
    const newTabObject = {
      id: tabs.length + 1,//uuid(),
      name: `Tab ${tabs.length + 1}`,
      notes: `This is Tab ${tabs.length + 1}`,
      items:[],
      total:"00.00",
      dateCreated:firebase.firestore.Timestamp.now()
    };

    this.setState({
      tabs: [...tabs, newTabObject],
      currentTab: newTabObject,
      editMode: false,
      editTabNameMode: false
    });
  };

  handleDeleteTab = tabToDelete => {
    //alert(tabToDelete);
    if(tabToDelete.id != 1 && tabToDelete.id != 99){
    const { tabs } = this.state;
    const tabToDeleteIndex = tabs.findIndex(tab => tab.id === tabToDelete.id);

    const updatedTabs = tabs.filter((tab, index) => {
      return index !== tabToDeleteIndex;
    });

    const previousTab =
      tabs[tabToDeleteIndex - 1] || tabs[tabToDeleteIndex + 1] || {};

    this.setState({
      tabs: updatedTabs,
      editMode: false,
      editTabNameMode: false,
      currentTab: previousTab
    });

  }
  else{
    alert("Cannot Delete This Tab");
  }
  };

  setEditMode = () => {
    this.setState({
      editMode: !this.state.editMode
    });
  };

  handleContentChange = e => {
    const { tabs, currentTab } = this.state;

    const updatedTabs = tabs.map(tab => {
      if (tab.name === currentTab.name) {
        return {
          ...tab,
          content: e.target.value
        };
      } else {
        return tab;
      }
    });

    this.setState({
      tabs: updatedTabs,
      currentTab: {
        ...currentTab,
        content: e.target.value
      }
    });
  };
  //END TAB CONTROLLER

  addChangeNote(orderID,noteString)
  {
    const newChangeNote = this.props.firebase.db.collection(`inventoryorders`).doc(this.state.quoteID).collection('updates').add({
      userID: firebase.auth().currentUser.uid,
      updateDate:firebase.firestore.Timestamp.now(),
      note:noteString,
    });
  }

  render() {
    const {pinnedNotes, updateOnly,quoteNotes,loading,statusDomain,clientStatusDomain,items,warehouse,warehouses,QtyItemID,MUitemID,MUitemPrice,frieghtModalItem,frieghtModalOrigin, frieghtModalCost,quoteDate,quoteID,totalCost,currentTab, editMode,selectedCustomerID,userID} = this.state;
    if (currentTab.items === undefined)
    {
      return(<div className='content'><h1>Loading...</h1></div>)
    }
    return (
      <AuthUserContext.Consumer>
      {authUser=>(
        <div className="content">
           <div className="row">
                        <div className="col-md-6">
                        <SlidingPane
                        closeIcon={<div>X</div>}
                        isOpen={this.state.showNotes}
                        title="Comments"
                        from="right"
                        width="400px"
                        onRequestClose={() => this.setState({ showNotes: false })}
                        >
                          <div>
                            <div className="row">
                              <input type="text" className="form-control" name="quoteItemNote" onChange={this.myChangeHandler}></input>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                              <input className="form-check-input" type="checkbox" name="pinnedNote" onChange={this.myChangeHandler}/>
                                <label for="pinnedNote">Pin this note?</label>
                              </div>
                              <div className="col-md-6"><button style={{marginTop:5+"px"}}className="btn btn-sm btn-success" onClick={e=>this.submitQuoteNote(e,authUser)}>Submit Note</button></div>
                            </div>
                          <div style={{overflow:'auto'},{marginTop:15+"px"}}>
                          {pinnedNotes.length > 0 && pinnedNotes.map(pN=>(
                            <div className="row" key={pN.noteid}>
                                <div className="col-sm-12">
                                  <hr></hr>
                                        <div className="row" style={{fontSize:.75+"em"}}>
                                          <div className="col-sm-12"><FontAwesomeIcon icon={faThumbtack}></FontAwesomeIcon><strong>{new Date(pN.noteDate.toDate()).toLocaleDateString()} {new Date(pN.noteDate.toDate()).toLocaleTimeString()}</strong> - <i>{pN.author}</i></div>
                                        </div>
                                        <div className="row" style={{fontSize:.75+"em"}}>
                                          <div className="col-sm-12">{pN.note}</div>
                                        </div>
                                </div>
                            </div>))}
                            {quoteNotes.length > 0 && quoteNotes.map(qN=>(
                            <div className="row" key={qN.noteid}>
                                <div className="col-sm-12">
                                  <hr></hr>
                                        <div className="row" style={{fontSize:.75+"em"}}>
                                          <div className="col-sm-12"><strong>{new Date(qN.noteDate.toDate()).toLocaleDateString()} {new Date(qN.noteDate.toDate()).toLocaleTimeString()}</strong> - <i>{qN.author}</i></div>
                                        </div>
                                        <div className="row" style={{fontSize:.75+"em"}}>
                                          <div className="col-sm-12">{qN.note}</div>
                                        </div>
                                </div>
                            </div>))}
                          </div>
                        </div>
                      </SlidingPane>
                        </div>
                        </div>
            <TableModal show={this.state.showItemsModal} handleClose={e => this.closeItemsModal(e)}>
            <div className="modal-content">
            <div className="modal-header">
                                    <h5 className="modal-title">Search Items</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e=>this.closeItemsModal()}>
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
            <div>
            <div className="clear-filters" style={{float:"left",marginRight:10+"px"}}>
                        <button type="button" className="btn btn-outline-danger right" onClick={e => this.clearSearchFilters()}><FontAwesomeIcon icon={faRedo}></FontAwesomeIcon> Clear Search/Filters</button>
                        </div>
                        <table id="example" className="display">
                                  <thead></thead>
                                  <tbody></tbody>
                                  <tfoot>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                  </tfoot>
                              </table>
                </div>
                </div>
            </TableModal>
            {/*<Modal show={this.state.showQtyModal} handleClose={e => this.closeQtyModal(e)}>
                <div className="modal-content">
                <div className="modal-header">
                                    <h5 className="modal-title">Update Item Quantity</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e=>this.closeQtyModal()}>
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                  <div className="modal-body">
                                    <input type="hidden" name="QtyItemID" value={QtyItemID}/>
                <strong>Qty:</strong>
                              <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                              <button className="btn btn-danger" type="button" id="button-addon1" onClick={e=>this.downIncrement(e)} disabled={this.state.QtyitemQty == 0}><FontAwesomeIcon icon={faMinus}></FontAwesomeIcon></button>
                                            </div>
                                            <input type="text" className="form-control" name="QtyItemQty" id="QtyItemQty" value={this.state.QtyItemQty} onChange={this.myChangeHandler}/>
                                            <div className="input-group-append">
                                              <button className="btn btn-success" type="button" id="button-addon2" onClick={e=>this.upIncrement(e)}><FontAwesomeIcon icon={faPlus}></FontAwesomeIcon></button>
                                            </div>
                                          </div>
                                          </div>
                                          <div className="modal-footer">
                                    <button type="button" className="btn btn-success" onClick={e => this.updateItemQty(e)}>Save changes</button>
                                    <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={e=>this.closeQtyModal()}>Close</button>
                                  </div>
                </div>
            </Modal>
             <Modal show={this.state.showMUModal} handleClose={e => this.closeModal(e)}>
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h5 className="modal-title">Add Markup</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e=>this.closeModal()}>
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                  <div className="modal-body">
                                    <input type="hidden" name="MUitemID" value={MUitemID}/>
                                    <label htmlFor="MUitemPrice">FOB Price</label>
                                    <input type="text" name="MUitemPrice" className="form-control" value={MUitemPrice} disabled/>
                                    <label htmlFor="itemMUValue">Mark Up Value</label>
                                    <input type="text" name="itemMUValue" className="form-control" onChange={this.myChangeHandler} value={this.state.itemMUValue}/>
                                    <label htmlFor="itemMUType">Mark Up Type</label>
                                    <select name="itemMUType" className="form-control" onChange={this.myChangeHandler} value={this.state.itemMUType}>
                                      <option value="">Select...</option>
                                      <option value="Dollar">Dollar</option>
                                      <option value="Percent">Percent</option>
                                    </select>
                                    <label htmlFor="itemMUPrice">Full Price</label>
                                    <input type="text" name="itemMUPrice" className="form-control" value={this.state.itemMUPrice} disabled/>
                                  </div>
                                  <div className="modal-footer">
                                    <button type="button" className="btn btn-primary" onClick={e => this.calculatePrice(e)}>Calculate Full Price</button>
                                    <button type="button" className="btn btn-success" onClick={e => this.updateItemPrice(e)}>Save changes</button>
                                    <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={e=>this.closeModal()}>Close</button>
                                  </div>
                                </div>
                            </Modal>
                            <Modal show={this.state.showFreightModal} handleClose={e => this.closeFreightModal(e)}>
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h5 className="modal-title">Add Freight Cost</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e=>this.closeFreightModal()}>
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                  <div className="modal-body">
                                    <input type="hidden" name="frieghtModalItem" value={frieghtModalItem}/>
                                    <label htmlFor="frieghtModalOrigin">Freight Origin</label>
                                    <input type="text" name="frieghtModalOrigin" className="form-control" value={this.frieghtModalOrigin}/>
                                    <label htmlFor="frieghtModalCost">Freight Cost</label>
                                    <input type="text" name="frieghtModalCost" className="form-control" onChange={this.myChangeHandler} value={this.frieghtModalCost}/>
                                  </div>
                                  <div className="modal-footer">
                                    
                                    <button type="button" className="btn btn-success" onClick={e => this.updateFreightCost(e)}>Save changes</button>
                                    <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={e=>this.closeFreightModal()}>Close</button>
                                  </div>
                                </div>
                            </Modal>*/}
            <nav className="navbar navbar-expand-lg navbar-light bg-yellow">
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav">
                    <li className="nav-item">
                    <a className="nav-link-menu" href="/home">Home</a>
                    </li>
                    <li className="nav-item">
                    <a className="nav-link-menu" href="/orders">Inventory Orders</a>
                    </li>
                    <li className="nav-item">
                    <a className="nav-link-menu" href="/inventory">Inventory List</a>
                    </li>
                    </ul>
                </div>
                </nav>
                {/*<div style={{textAlign:'center'}}>
                    <img src={warehouse}/>
                </div>*/}<div className="container-fluid">
                  <p>
                  {!updateOnly &&
<button style={{margin:5+"px"}} className="btn btn-outline-success" onClick={e=>this.saveCart(authUser)}>Save Order</button>}
{updateOnly && 
  <button style={{margin:5+"px"}} className="btn btn-success" onClick={e=>this.updateCart(authUser)}>Update Order</button>
}
{!updateOnly &&
  <button style={{margin:5+"px"}} className="btn btn-success" disabled={this.state.canComplete} onClick={e=>this.completeCart(authUser)}>Complete Order</button>
}
</p>
<ToastContainer autoClose={3000} toastStyle={{ backgroundColor: "green" }}/>
<div className="row">
      <div className="col-md-4">
        <div className="row">
          <div className="col-md-12">
              <div className="card card-body">
                {/*<InventoryRecieverCard onSelect={this.selectReciever}></InventoryRecieverCard>*/}
                <div className="card" style={{marginTop:15+"px"}}>
                      <div className="card-body">
                        <h6 className="card-subtitle mb-2 text-muted">Select Receiving Warehouse</h6>
                        {!loading &&
                          <select id="warehouse" name="warehouse" className="form-control" value={this.state.warehouse} onChange={this.myChangeHandler}>
                              <option value="00">Select Warehouse...</option>
                              {warehouses.map(wh=>(
                                  <option key={wh.wid} value={wh.wid}>{wh.name}</option>
                                  
                              ))}
                          </select>}
                      </div>
                    </div>
              </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
          <div className="card card-body">
          <div className="card">
              <div className="card-body">
                  <div className="form-group">
                      <label htmlFor="salesOrderNumber">Sharratt Sales Order:</label>
                      <input type="text" name="salesOrderNumber" className="form-control" onChange={this.myChangeHandler} value={this.state.salesOrderNumber}/>
                  </div>
                  <div className="form-group">
                      <label htmlFor="purchaseOrderNumber">Purchase Order:</label>
                      <input type="text" name="purchaseOrderNumber" className="form-control" onChange={this.myChangeHandler} value={this.state.purchaseOrderNumber}/>
                  </div>
                  <div className="form-group">
                      <label htmlFor="lotNumber">Lot Number:</label>
                      <input type="text" name="lotNumber" className="form-control" onChange={this.myChangeHandler} value={this.state.lotNumber}/>
                  </div>
                  <div className="form-group">
                      <label htmlFor="bestByDate">Best Buy Date:</label>
                      <input type="text" name="bestByDate" className="form-control" onChange={this.myChangeHandler} value={this.state.bestByDate}/>
                  </div>
                  <div className="form-group">
                      <label htmlFor="estArrivalDate">Est. Arrival Date:</label>
                      <input type="text" name="estArrivalDate" className="form-control" onChange={this.myChangeHandler} value={this.state.estArrivalDate}/>
                  </div>
                </div>
                </div>
              </div>
          </div>
        </div>
      </div>
      <div className="col-md-8">
          <div className="row">
            <div className="col-md-12">
            <div className="container">
                                    <div className="well">
                                    {/*<button className="btn-sm btn-info" onClick={this.handleAddTab} style={{marginBottom:10+"px"}}>
                                        <FontAwesomeIcon icon={faPlus}/> Add Tab
                                    </button>
                                    <button className="btn-sm btn-warning" onClick={e=>this.openItemsModal()} style={{marginLeft:10+"px",marginBottom:10+"px"}}>
                                        <FontAwesomeIcon icon={faPlus}/> Add Item
                                    </button>*/}
                                    <button className="btn-sm btn-outline-info" onClick={() => this.setState({ showNotes: true })} style={{marginLeft:10+"px",marginBottom:10+"px"}}>
                                        Order Notes
                                    </button>
                                    {this.createTabs()}
                                    <div className="tab-content" style={{marginTop:10+"px"}}>
                                        {editMode ? (
                                        <div>
                                            <textarea
                                            onChange={this.handleContentChange}
                                            value={this.state.currentTab.notes}
                                            />
                                            <button className="btn btn-success" onClick={this.setEditMode}>
                                            Done
                                            </button>
                                        </div>
                                        ) : (
                                        <div>
                                          <div className="row">
                                            <div className="col-md-4"><input type="checkbox" onClick={this.handleCheckTotal} onChange={this.handleCheckTotal} checked={this.state.showTotal}></input>{this.state.showTotal? <div style={{display:"inline"}}><strong> Tab Total: </strong>${((this.state.currentTab.total*100)/100).toFixed(2)}</div>:"---"}
                                            </div>
                                            <div className="col-md-3"><strong>Total Items: </strong>{this.state.currentTab.items.length}</div>
                                            <div className="col-md-2"></div>
                                            <div className="col-md-3"></div>
                                            </div>
                                            <table className="table table-striped table-hover">
                                <thead>
                                    <tr>
                                        {/*<th></th>*/}
                                        <th></th>
                                        <th>Item</th>
                                        {/*<th>Description</th>						
                                        <th>Pack Size</th>
                                        <th>Cases Per Pallet</th>
                                        <th>Unit Size</th>
                                        <th>Unit Cost</th>*/}
                                        <th>Case Price</th>
                                        <th>Qty</th>
                                        <th>Freight Cost</th>
                                        <th>Mark up</th>
                                        <th>Warehouse Cost</th>
                                        <th>Final Case Price/Unit Price</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                {this.state.currentTab.items.map((itemData,i)=> (
                                    //<InventoryItemCard key={i} item={itemData} tabID={currentTab.id} itemTabID={this.state.currentTab.items.indexOf(itemData)} tabNames={this.state.tabs} itemPriceUpdate={this.itemPriceUpdate.bind(this)} removeItem={this.removeItemFromTab.bind(this)} transferItem={this.transferItemToTab.bind(this)}/> 
                                    <React.Fragment>
                                       <Modal show={this.state.showWarehouseCostModal} handleClose={e => this.closeWarehouseModal(e)}>
                                    <div className="modal-content">
                                      <div className="modal-header">
                                        <h5 className="modal-title">Add Warehouse Cost</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e=>this.closeWarehouseModal()}>
                                          <span aria-hidden="true">&times;</span>
                                        </button>
                                      </div>
                                      <div className="modal-body">
                                      <label htmlFor="warehouseInOut">Warehouse In/Out Cost</label>
                                        <input type="text" name="warehouseInOut" className="form-control" onChange={this.myChangeHandler} value={this.state.warehouseInOut}/>
                                        <label htmlFor="warehouseCost">Warehouse Per-Case Cost</label>
                                        <input type="text" name="warehouseCost" className="form-control" onChange={this.myChangeHandler} value={this.state.warehouseCost}/>
                                      <label htmlFor="warehouseCostInterval">Cost Interval</label>
                                        <select name="warehouseCostInterval" className="form-control" onChange={this.myChangeHandler} value={this.state.warehouseCostInterval}>
                                          <option value="">Select...</option>
                                          <option value="OneTime">One Time</option>
                                          <option value="Monthly">Monthly</option>
                                          </select>
                                      </div>
                                      <div className="modal-footer">
                                        
                                        <button type="button" className="btn btn-success" onClick={e => this.updateWarehouseCost(e)} disabled={this.state.warehouseCost==null}>Update Pricing w/Warehouse Cost</button>
                                        <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={e=>this.closeWarehouseModal()}>Close</button>
                                      </div>
                                    </div>
                                </Modal>
                                    <Modal show={this.state.showQtyModal} handleClose={e => this.closeQtyModal(e)}>
               <div className="modal-content">
               <div className="modal-header">
                                   <h5 className="modal-title">Update Item Quantity</h5>
                                   <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e=>this.closeQtyModal()}>
                                     <span aria-hidden="true">&times;</span>
                                   </button>
                                 </div>
                                 <div className="modal-body">
               <strong>Qty:</strong>
                             <div className="input-group mb-3">
                                           <div className="input-group-prepend">
                                             <button className="btn btn-danger" type="button" id="button-addon1" onClick={e=>this.downIncrement(e)} disabled={this.state.itemQty == 0}><FontAwesomeIcon icon={faMinus}></FontAwesomeIcon></button>
                                           </div>
                                           <input type="text" className="form-control" name="QtyItemQty" id="QtyItemQty" value={this.state.QtyItemQty} onChange={this.myChangeHandler}/>
                                           <div className="input-group-append">
                                             <button className="btn btn-success" type="button" id="button-addon2" onClick={e=>this.upIncrement(e)}><FontAwesomeIcon icon={faPlus}></FontAwesomeIcon></button>
                                           </div>
                                         </div>
                                         </div>
                                         <div className="modal-footer">
                                   <button type="button" className="btn btn-success" onClick={e=>this.updateItemQty()}>Update Quantity</button>
                                   <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={e=>this.closeQtyModal()}>Close</button>
                                 </div>
               </div>
           </Modal>
           <Modal show={this.state.showFreightModal} handleClose={e => this.closeFreightModal(e)}>
                               <div className="modal-content">
                                 <div className="modal-header">
                                   <h5 className="modal-title">Add Freight Cost</h5>
                                   <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e=>this.closeFreightModal()}>
                                     <span aria-hidden="true">&times;</span>
                                   </button>
                                 </div>
                                 <div className="modal-body">
                                   <label htmlFor="frieghtModalOrigin">Freight Origin</label>
                                   <input type="text" name="frieghtModalOrigin" className="form-control required" value={this.state.frieghtModalOrigin} onChange={this.myChangeHandler}/>
                                   <label htmlFor="frieghtModalCost">Freight Cost</label>
                                   <input type="text" name="frieghtModalCost" className="form-control required" onChange={this.myChangeHandler} value={this.state.frieghtModalCost}/>
                                 </div>
                                 <div className="modal-footer">
                                   
                                   <button type="button" className="btn btn-success" onClick={e => this.updateFreightCost(e)} disabled={this.state.frieghtModalCost==null}>Update Pricing w/Freight</button>
                                   <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={e=>this.closeFreightModal()}>Close</button>
                                 </div>
                               </div>
                           </Modal>
                         <Modal show={this.state.showMUModal} handleClose={e => this.closeModal(e)}>
                               <div className="modal-content">
                                 <div className="modal-header">
                                   <h5 className="modal-title">Add Markup</h5>
                                   <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e=>this.closeModal()}>
                                     <span aria-hidden="true">&times;</span>
                                   </button>
                                 </div>
                                 <div className="modal-body">
                                   <label htmlFor="itemFOBPrice">FOB Price</label>
                                   <input type="text" name="itemFOBPrice" className="form-control" value={this.state.itemFOBPrice} disabled/>
                                   <label htmlFor="itemMUValue">Mark Up Value</label>
                                   <input type="text" name="itemMUValue" className="form-control" onChange={this.myMUChangeHandler} value={this.state.itemMUValue}/>
                                   <label htmlFor="itemMUType">Mark Up Type</label>
                                   <select name="itemMUType" className="form-control" onChange={this.myMUChangeHandler} value={this.state.itemMUType}>
                                     <option value="">Select...</option>
                                     <option value="Dollar">Dollar</option>
                                     <option value="Percent">Percent</option>
                                   </select>
                                   <label htmlFor="itemMUPrice">Marked Up Price (w/o Frieght)</label>
                                   <input type="text" name="itemMUPrice" className="form-control" value={this.state.itemMUPrice} disabled/>
                                 </div>
                                 <div className="modal-footer">
                                   {/*<button type="button" className="btn btn-primary" onClick={e => this.calculatePrice(e)} disable={this.state.price == this.state.fullprice}>Calculate Full Price</button>*/}
                                   <button type="button" className="btn btn-success" onClick={e => this.updateItemPrice(e)}>Update Pricing</button>
                                   <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={e=>this.closeModal()}>Close</button>
                                 </div>
                               </div>
                           </Modal>
                                <tr key={itemData.id}>
                                             <td><button type="button" className="btn btn-sm btn-danger" onClick={(event)=>this.removeItemHandler(itemData.id,itemData.tabID)}
                                             /*{() => {
                                              if (window.confirm("Delete this Item?")) { 
                                                   alert(itemData.itemID);
                                               //let removeToCollection = this.removeItemFromTab.bind(this,itemData.itemID,itemData.tabID);
                                               //removeToCollection();
                                                   //this.removeItemFromTab(itemData.itemID, itemData.tabID); 
                                              }
                                          }}*/>
                                               <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
                                               </button><button type="button" className="btn" data-toggle="collapse" data-target={`#collapse${itemData.id}`}>
                                                 <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon></button>
                                              </td>
                                             <td><a href={`/item/${itemData.id}`} target="blank">{itemData.title}</a></td>
                                             <td>${parseFloat(itemData.price).toFixed(2)}</td>
                                             <td><button onClick={e=>this.openQtyModal(itemData.id,itemData.qty)} className="btn btn-outline-primary">{itemData.qty}</button></td>
                                             <td><button onClick={e=>this.openFreightModal(itemData.id, itemData.freightcost, itemData.fobLocation)} className="btn btn-outline-primary"><FontAwesomeIcon icon={itemData.freightcost != '00.00'?faCheck:faEdit}></FontAwesomeIcon></button></td>
                                             <td><button onClick={e=>this.openMUModal(itemData.id,itemData.muprice,itemData.price,itemData.muvalue,itemData.mutype)} className="btn btn-outline-info"><FontAwesomeIcon icon={itemData.muvalue != '00'?faCheck:faEdit}></FontAwesomeIcon></button>
                                              </td>
                                              <td><button onClick={e=>this.openWHCostModal(itemData.id,itemData.fullPrice,itemData.warehouseCost,itemData.warehouseCostInterval,itemData.warehouseInOut)} className="btn btn-outline-dark"><FontAwesomeIcon icon={itemData.warehouseCost != '00'?faCheck:faEdit}></FontAwesomeIcon></button>
                                        </td>
                                              <td>${(Math.round(parseFloat(itemData.fullprice)*100)/100).toFixed(2)}/${((parseFloat(itemData.fullprice) / parseFloat(itemData.packsize)*100)/100).toFixed(3)}</td>
                                              <td><div className="input-group"><select name={`tabSelect_${itemData.id}`} onChange={this.assignGroup} className="form-control-sm">
                                                  <option value="00">Add to Inventory Group</option>
                                                  {this.state.inventoryNames.length >= 1 &&
                                                          this.state.inventoryNames.map(tabItem=>{
                                                            //console.log(itemData.groupItemID, tabItem.id);
                                                            if(itemData.groupItemID === tabItem.id){
                                                                return <option value={tabItem.id} selected>{tabItem.name}</option>
                                                            }
                                                            else{
                                                              return <option value={tabItem.id}>{tabItem.name}</option>
                                                            }
                                                          })
                                                  }
                                                </select></div></td>
                                         </tr> 
                                         <tr id={`collapse${itemData.id}`} className="collapse out">
                                              <td colSpan="3"><input type="text" className="form-control" name="itemAddDesc" placeholder="Additional Description" onChange={this.myChangeHandler} value={itemData.itemAddDesc}></input><br></br>{this.state.description}</td>
                                              <td><strong>Pack Size:</strong>{itemData.packsize}</td>
                                              <td><strong>Cases Per Pallet:</strong>{itemData.casesperpallet}</td>
                                              <td colSpan="2"><strong>Unit Size:</strong>{itemData.unitSize}</td>
                                              <td><strong>Unit Cost:</strong>${((parseFloat(itemData.price) / parseFloat(itemData.packsize)*100)/100).toFixed(3)}</td>
                                          </tr>  
                                          </React.Fragment>
                                          
                                          ))}
                                </tbody>
                                </table>

                                            {/*<p>{currentTab.notes}</p>
                                            <p><strong>Total Items: </strong>{items.length}</p>
                                            <p><strong>Tab Total: </strong>${this.state.currentTab.total}</p>*/}
                                            {currentTab.id ? (
                                            <div
                                                style={{ display: "flex", justifyContent: "space-between" }}
                                            >
                                                {/*<button
                                                className="btn btn-secondary"
                                                onClick={this.setEditMode}
                                                >
                                                    <FontAwesomeIcon icon={faPencilAlt}></FontAwesomeIcon>&nbsp;
                                                Edit
                                                </button>*/}<div className="btn-group" role="group" aria-label="Basic example">
                                                {/*<button className="btn btn-danger" onClick={() => this.handleDeleteTab(currentTab)}>
                                                    <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>&nbsp;
                                                Delete Tab
                                              </button>*/}
                                                {/*<a href={"/export/"+quoteID+"/"+this.state.currentTab.id} className="btn btn-info" target="_blank"> Export Current Tab</a>*/}
                                                {!updateOnly &&
                                                <button style={{margin:5+"px"}}  className="btn btn-outline-success" onClick={e=>this.saveCart(authUser)}>Save Order</button>
  }
                                                {updateOnly && 
                                                    <button style={{margin:5+"px"}} className="btn btn-success" onClick={e=>this.updateCart(authUser)}>Update Order</button>
                                                  }
                                                  {!updateOnly &&
                                                    <button style={{margin:5+"px"}} className="btn btn-success" disabled={this.state.canComplete} onClick={e=>this.completeCart(authUser)}>Complete Order</button>
                                                  }
                                                </div>
                                            </div>
                                            ) : (
                                            ""
                                            )}
                                        </div>
                                        )}
                                    </div>
                                    </div>
                                </div>
            </div>
          </div>
      </div>
    </div>
  </div>
  
        </div>
      )}</AuthUserContext.Consumer>
    );
  }
}
    
    export default withFirebase(InventoryOrder);
    
