import React,{Component} from 'react';
import { Switch,Route, Link } from 'react-router-dom';
import { compose } from 'recompose';
import * as ROUTES from '../../constants/routes';
import { AuthUserContext } from '../Session';
import { withAuthorization } from '../Session';
import {withFirebase} from '../Firebase';
import MasterNavBar from '../Navigation/navbar';
import '../User/userstyles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit,faTrashAlt,faUserEdit,faUserPlus,faFileExcel, faEraser } from '@fortawesome/free-solid-svg-icons';
//import * as ROLES from '../../contants/roles';
import { CSVLink } from "react-csv";
import {withRouter} from "react-router-dom";


const headers = [
    { label: "First Name", key: "fname" },
    { label: "Last Name", key: "lname" },
    { label: "Email", key: "email" },
    { label: "Phone", key: "phone" },
    { label: "Role", key: "role" }
  ];

const UserPage = () =>(
    <div className="content">
        <Switch>
            <Route exact path={ROUTES.USER_DETAILS} component={UserItem}/>
            <Route exact path={ROUTES.USERS} component={UserList}/>
        </Switch>
    </div>
);

class UserListBase extends Component {
    constructor(props){
        super(props);

        this.state = {
            loading:false,
            users:[],
        };
    }
    
    deleteUser(uid)
    {
        //console.log(pnid);
        this.props.firebase.deactiveUser(uid);
    }
    componentDidMount(){
        
        this.setState({loading:true});

        //console.log(this.props.firebase.userRef);
        
        this.unsubscribe = this.props.firebase
        .users()
        .onSnapshot(snapshot => {
          let users = [];
   
          snapshot.forEach(doc =>
            users.push({ ...doc.data(), uid: doc.id }),
          );

          this.setState({
            users,
            loading: false,
          });
        });
    }

    componentWillUnmount()
    {
        //this.props.firebase.users().off();
        this.unsubscribe();
    }

    render(){
            //const history = useHistory()

            const goBack = () => {
                this.props.history.goBack()
            }

        const csvReport = {
            data: this.state.users,
            headers: headers,
            filename: 'Users_Export.csv'
          };
        const {users, loading} = this.state;
        return(
            <AuthUserContext.Consumer>
            {authUser=>(
                <div>
                    <nav className="navbar navbar-expand-lg navbar-light bg-blue">
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav">
                        <li className="nav-item">
                            <button className="btn btn-outline-light" style={{marginTop:"25px"}} onClick={goBack}>Back</button>
                        </li>
                        {/*<li className="nav-item">
                        <a className="nav-link-menu" href="../home">Home</a>
                        </li>*/}
                        </ul>
                    </div>
                    </nav>
                    <div className="container-xl">
                    <div className="table-responsive">
                        <div className="table-wrapper">
                            <div className="table-title">
                                <div className="row">
                                    <div className="col-sm-5">
                                        <h2>User Management</h2>
                                    </div>
                                    <div className="col-sm-7">
                                        <a href="../adduser" className="btn btn-secondary"><FontAwesomeIcon icon={faUserPlus}></FontAwesomeIcon> Add User</a>
                                        <a href="#" className="btn btn-secondary"><FontAwesomeIcon icon={faFileExcel}></FontAwesomeIcon> <CSVLink {...csvReport}>Export to CSV</CSVLink></a>						
                                        
                                    </div>
                                </div>
                            </div>
                            {loading && <h1>Loading...</h1>}
                            <table className="table table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th>Name</th>						
                                        <th>Date Created</th>
                                        <th>Role</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {users.map(user=> (
                                   <tr key={user.uid}>
                                       <td>{user.fname} {user.lname}</td>
                                       <td>{new Date(user.dateadded.seconds*1000).toDateString()}</td>
                                       <td>{user.role}</td>
                                       <td><span className="status text-success">&bull;</span>{user.isActive == 1?"Active":"Inactive"}</td>
                                       <td>
                                       <div className="btn-group" role="group" aria-label="Basic example"><Link to={{pathname: `../edituser/${user.uid}`,state:{uid:user.uid}}} className="btn btn-sm btn-info" style={{maxHeight:31+"px"}}><FontAwesomeIcon style={{color:"white"}} icon={faEdit}></FontAwesomeIcon></Link><button type="button" className="btn btn-sm btn-danger" onClick={() => {
                                if (window.confirm("Delete this User?")) {
                                    
                                    let removeToCollection = this.deleteUser.bind(this,user.uid);
                                    removeToCollection();
                                }
                            }}><FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon></button></div>
                                        </td>
                                   </tr>     
                                ))}
                                </tbody>
                                </table>
                                </div>
                                </div>
                                </div>
                                </div>
                                 )}
                                 </AuthUserContext.Consumer>
                                            
        );
    }
}

/*const UserItem = ({match})=>(
    <div>
        <h2>User ({match.params.id})</h2>
    </div>
);*/

class UserItemBase extends Component{
    constructor(props){
        super(props);
        
        this.state = {
            loading:false,
            user:null,
            ...props.location.state,
        };
    }

    componentDidMount(){
        if(this.state.user){
            return;
        }

        this.setState({ loading: true });
 
        this.unsubscribe = this.props.firebase
        .user(this.props.match.params.id)
        .onSnapshot(snapshot => {

            //console.log(snapshot.data());

            this.setState({
                user: snapshot.data(),
                loading: false,
            });
        });
    }

    componentWillUnmount()
    {
        //this.props.firebase.users().off();
        this.unsubscribe && this.unsubscribe();;
    }

    render(){
        const {user,loading} = this.state;

        return(
            <div>
            <h3>User Details ({this.props.match.params.uid})</h3>
            {loading && <div>Loading User Info...</div>}

            {user && (
                <div>
                    <span>
                        <strong>ID:</strong>{user.uid}
                    </span>
                    <span>
                        <strong>Email:</strong>{user.email}
                    </span>
                    <span>
                        <strong>Role:</strong>{user.role}
                    </span>
                </div>
            )}
            </div>
        );
    }
}

const UserList = withFirebase(UserListBase);
const UserItem = withFirebase(UserItemBase);

const condition = authUser => !!authUser;

export default compose(withAuthorization(condition),withRouter)(UserPage);