//LIST OF ALL REMINDERS FOR TODAY
import React, { Component } from 'react';
import { Switch,Route, Link } from 'react-router-dom';

import * as ROUTES from '../../constants/routes';
import {withFirebase} from '../Firebase';
import firebase from 'firebase';
import ChartistGraph from 'react-chartist'


import 'bootstrap/dist/css/bootstrap.min.css';
import { AuthUserContext } from '../Session';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faHammer} from '@fortawesome/free-solid-svg-icons';
import { firestore } from 'firebase-admin';
import moment from 'moment';

class TodaysRemindersTable extends Component {
    constructor(props){
        super(props);

        this.state = {
            loading:false,
            events:this.props.events,
            filteredEvents:[],
            authUserID:this.props.auid,
            role:this.props.role,
        };
    }

    componentDidMount(){
        //var d = moment(rm.reminderDate).format("DD/MM/YYYY");
        //var today = moment(new Date()).format("DD/MM/YYYY");
        console.log("Role: " + this.state.role);
        if(this.state.role !== "Admin")
        {
          this.setState({filteredEvents:this.props.events.filter(item=>item.userID == this.state.authUserID),loading:false},()=>{this.somefunction()});
        }
        else{
            console.log("In Admin condition");
            this.setState({filteredEvents:this.props.events},()=>{this.somefunction()});
        }
        //

    }
    somefunction(){
        var today = moment(new Date()).format("DD/MM/YYYY");
        let sortedF1 = this.state.filteredEvents.sort((a,b)=> a.start-b.start).filter(item=>moment(item.start).format("DD/MM/YYYY") == today);
        let sortedF2;
        this.setState({filteredEvents:sortedF1,loading:false});
        console.log("Set State Complete");
        console.log("Filtered: "+this.state.filteredEvents.length.toString() + " - Sent: "+this.props.events.length.toString());
    }

    componentWillUnmount(){
        //this.unsubscribe();
    }

    render(){
        const {filteredEvents, loading} = this.state;
       
        return(
            <div style={{marginTop:25+"px"}}>
                
            {/*<h1><FontAwesomeIcon icon={faHammer}></FontAwesomeIcon>  Feature Under Construction  <FontAwesomeIcon icon={faHammer}></FontAwesomeIcon></h1>*/}
                {!loading && filteredEvents.length > 0 &&
                <table className="table table-striped table-hover">
                            <thead>
                                    <tr>
                                        <th>Reminder</th>
                                        <th>Date/Time</th>
                                        <th></th>						
                                    </tr>
                                </thead>
                                <tbody>
                                {filteredEvents.length > 0 && filteredEvents.map(qt=> (
                                    <tr key={qt.id}>
                                        <td><a href={"/company/"+qt.entityID} target="_blank">{qt.title}</a></td>
                                        <td>{moment(qt.end).format("MM/DD/YYYY").toString()}</td>
                                        <td></td>
                                    </tr>
                                    /*<tr key={qt.id}>
                                        <td><a href="/company/{qt.entityID}" target="_blank">{qt.entityName}</a></td>
                                        <td>{qt.reminderDate}</td>
                                        <td>{qt.reminderText}</td>
                                        <td></td>
                                    </tr>*/     
                                    ))}
                                </tbody>
                                </table>}
            </div>
        );
    }
}

export default TodaysRemindersTable;