import React from "react";
import Draggable from 'react-draggable';

const Modal = ({ handleClose, show, children }) => {
  const showHideClassName = show ? "modal d-block" : "modal d-none";
  return (
    <div className={showHideClassName}>
      <Draggable cancel={"input, svg, button, table"} enableUserSelectHack={false}>
      <div className="modal-container">
        {children}
        {/*<a href="javascript:;" className="modal-close" onClick={handleClose}>
          close
  </a>*/}
      </div>
      </Draggable>
    </div>
  );
};

export default Modal;