//QUOTE PRINTOUT VIEW

import React, { Component } from 'react';
import * as ROUTES from '../../constants/routes';
import {withFirebase} from '../Firebase';
import firebase from 'firebase';
import moment from "moment";
import { withAuthorization } from '../Session';
import 'bootstrap/dist/css/bootstrap.min.css';
import pageStyles from './view.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faFile,faPrint,faPhone, faEnvelope,faGlobe,faStar,faTrash,faPlus,faMinus,faRedo,faCheck,faThumbtack,faUserPlus,faTimes,faEdit,faEraser, faStickyNote,faIndustry,faBuilding,faHome, faTrashAlt,faPencilAlt, faCheckCircle, faInfoCircle} from '@fortawesome/free-solid-svg-icons';


class ExportQuoteView extends Component{

    constructor(props) {
        super(props);
        this.state = {
            quoteID:this.props.match.params.qid,
            tabID:this.props.match.params.tid,
            showTotal:'',
            tabsArray:[],
            itemsArray:[],
            tabTotal:'',
            quote:'',
            shipToLocation:'',
            loading:true,
        }
       
      }

      componentDidMount(){
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let foo = params.get('showTotal');
        this.setState({showTotal:foo});

        this.unsubscribe = this.props.firebase.db
        .collection(`quotes`).doc(this.props.match.params.qid)
        .onSnapshot(snapshot => {
            //console.log(pricedItems.length);
            console.log(snapshot.data());
            

            var tabsArray = [];
            var itemsArray = [];
            let tabTotal = '';
            for (var key in snapshot.data().tabs) {
                tabsArray.push(snapshot.data().tabs[key]);
                if(snapshot.data().tabs[key].id == this.state.tabID)
                {
                    console.log("Match: " + snapshot.data().tabs[key].id);
                    tabTotal = parseFloat(snapshot.data().tabs[key].total).toFixed(2);
                    for (var k in snapshot.data().tabs[key].items)
                    {
                        itemsArray.push(snapshot.data().tabs[key].items[k])
                    }
                }
            }
            //console.log(tabsArray.length + "  " + itemsArray.length);
            console.log(itemsArray[0]);

            this.setState({
                quote:snapshot.data(),
                tabsArray:tabsArray,
                itemsArray:itemsArray,
                tabTotal:tabTotal,
                loading:true,
            },()=>{

                this.unsubscribe = this.props.firebase
                .getLocation(this.state.quote.customerID, this.state.quote.customerLocationID)
                .onSnapshot(lsnapshot => {
                console.log(lsnapshot.data());
                //var location = lsnapshot.data();
                    this.setState({
                        shipToLocation:lsnapshot.data(),
                        loading:false,
                    });
                });     
            });
        });

        //console.log(this.state.shipToLocation);
      }

      print(){
        window.print();
    }

      render() {
        const {quote,quoteID,shipToLocation,tabID,tabTotal,itemsArray,loading,showTotal} = this.state;
        
        if(!itemsArray.length){
            return <div className="container" style={{marginTop:10+"px"}}><div className="col-md-12">Loading Quote...</div></div>;
        }
        let totalDiv;
        if(showTotal == "true")
        {
            totalDiv = <div><small>TOTAL</small> <span className="f-w-600">${this.state.tabTotal}</span></div>
        }else{
            totalDiv = <div></div>
        }
        

        return (
           
            <div className="container" style={{marginTop:10+"px"}}>
                 {loading && <div className="col-md-12">Loading Quote...</div>}
                <div className="col-md-12">
                    <div className={pageStyles.invoice}>
                        <div className={pageStyles.invoiceCompany} className="text-inverse f-w-600">
                            <span className="pull-right hidden-print" style={{paddingRight:15+"px"}}>
                            {/*<a href="javascript:;" className="btn btn-sm btn-white m-b-10 p-l-5"><i className="fa fa-file t-plus-1 text-danger fa-fw fa-lg"></i> Export as PDF</a>*/}
                            <button onClick={this.print} className="btn btn-sm btn-info"><FontAwesomeIcon icon={faPrint}></FontAwesomeIcon> Print</button>
                            </span>
                            Sharratt Provisions, INC
                        </div>
                        <div className={pageStyles.invoiceHeader}>
                            <div className={pageStyles.invoiceFrom}>
                            <small>from</small>
                            <address className="m-t-5 m-b-5">
                                <strong className="text-inverse">Sharratt Provisions</strong><br/>
                                Street Address<br/>
                                City, Zip Code<br/>
                                Phone: (123) 456-7890<br/>
                                Fax: (123) 456-7890
                            </address>
                            </div>
                            <div className={pageStyles.invoiceTo}>
                                <small>to</small>
                                <address className="m-t-5 m-b-5">
                                    <strong className="text-inverse">{quote.selectedCustomerName}</strong><br/>
                                    {this.state.shipToLocation.address1}<br/>
                                    {this.state.shipToLocation.address2}<br/>
                                    {this.state.shipToLocation.city}, {this.state.shipToLocation.stateAbbr}  {this.state.shipToLocation.zip}<br/>
                                </address>
                            </div>
                            <div className={pageStyles.invoiceDate}>
                                <small>Quote</small>
                                <div className="date text-inverse m-t-5">{moment(quote.quoteDate).format('MM/DD/yyyy')}</div>
                                <div className={pageStyles.invoiceDetail}>
                                    {quoteID}<br/>
                                    {quote.salesNotes}
                                </div>
                            </div>
                        </div>
                        <div className={pageStyles.invoiceContent}>
                        <div className={pageStyles.invoiceDetail}>
                                Total Items: {itemsArray.length} {typeof(itemsArray)}
                                </div>
                            <div className="table-responsive">
                            <table className="table table-invoice">
                                <thead>
                                    <tr>
                                        <th>ITEM DESCRIPTION</th>
                                        <th>UNIT SIZE</th>
                                        <th className="text-center" width="10%">QTY</th>
                                        <th className="text-center" width="10%">COST</th>
                                        <th className="text-right" width="20%">LINE TOTAL</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {itemsArray.map(function(i,idx){
                                        return (<tr key={idx}>
                                            <td>
                                                                <span className="text-inverse">{i.title}</span><br/>
                                                                <small>Description: {i.itemAddDesc} {i.description} - Pack Size: {i.packsize} - Cases Per Pallet: {i.casesperpallet}</small>
                                                        </td>
                                                        <td>{i.unitSize}</td>
                                                        <td>{i.qty}</td>
                                                        <td>${parseFloat(i.fullprice).toFixed(2)}</td>
                                                        <td>${parseFloat(parseFloat(i.fullprice).toFixed(2)*parseInt(i.qty))}</td>
                                            </tr>)
                                    })
                                    }
                                    {/*itemsArray.map(item=>{
                                                    <tr key={item.id}>
                                                        <td>
                                                                <span className="text-inverse">{item.title}</span><br/>
                                                                <small>{item.description} - {item.packsize} - {item.casesperpallet}</small>
                                                        </td>
                                                        <td>{item.unitSize}</td>
                                                        <td>{item.qty}</td>
                                                        <td>${item.fullprice}</td>
                                                        <td>$---</td>
                                                    </tr>
                                        })
                                    */}
                                    {/*<tr>
                                        <td>
                                        <span className="text-inverse">Website design &amp; development</span><br/>
                                        <small>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed id sagittis arcu.</small>
                                        </td>
                                        <td className="text-center">$50.00</td>
                                        <td className="text-center">50</td>
                                        <td className="text-right">$2,500.00</td>
                                    </tr>
                                    <tr>
                                        <td>
                                        <span className="text-inverse">Branding</span><br/>
                                        <small>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed id sagittis arcu.</small>
                                        </td>
                                        <td className="text-center">$50.00</td>
                                        <td className="text-center">40</td>
                                        <td className="text-right">$2,000.00</td>
                                    </tr>
                                    <tr>
                                        <td>
                                        <span className="text-inverse">Redesign Service</span><br/>
                                        <small>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed id sagittis arcu.</small>
                                        </td>
                                        <td className="text-center">$50.00</td>
                                        <td className="text-center">50</td>
                                        <td className="text-right">$2,500.00</td>
                                    </tr>*/}
                                </tbody>
                            </table>
                            </div>

                            <div className={pageStyles.invoicePrice}>
                            <div className={pageStyles.invoicePriceLeft}>
                                <div className={pageStyles.invoicePriceRow}>
                                    <div className={pageStyles.subPrice}>
                                        {/*<small>SUBTOTAL</small>
                                        <span className="text-inverse">$4,500.00</span>*/}
                                    </div>
                                    <div className={pageStyles.subPrice}>
                                        <i className="fa fa-plus text-muted"></i>
                                    </div>
                                    <div className={pageStyles.subPrice}>
                                        {/*<small>PAYPAL FEE (5.4%)</small>
                                        <span className="text-inverse">$108.00</span>*/}
                                    </div>
                                </div>
                            </div>
                            <div className={pageStyles.invoicePriceRight}>
                                {totalDiv}
                            </div>
                            </div>

                        </div>

                        <div className={pageStyles.invoiceNote}>
                            * Make all checks payable to Sharratt Provisions, INC.<br/>
                            * Payment is due within 30 days<br/>
                            * If you have any questions concerning this invoice, contact  [Name, Phone Number, Email]
                        </div>

                        <div className={pageStyles.invoiceFooter}>
                            <p className="text-center m-b-5 f-w-600">
                            THANK YOU FOR YOUR BUSINESS
                            </p>
                            <p className="text-center">
                            <span className="m-r-10"><i className="fa fa-fw fa-lg fa-globe"></i>office@sharrattprovisions.com</span>
                            <span className="m-r-10"><i className="fa fa-fw fa-lg fa-envelope"></i> --------- </span>
                            <span className="m-r-10"><i className="fa fa-fw fa-lg fa-phone-volume"></i>{moment(Date.now()).format("MM/DD/YYYY")}</span>
                            
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
      }
    }
    
    
    export default withFirebase(ExportQuoteView);
    
