import React from 'react'
import { faEdit,faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faSearch} from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';

//class based because of the need to track state 
class SearchBar extends React.Component{

    state = { term:''};

    //event handler for callback function
    /*onInputChange(event){
        console.log(event.target.value);
    }*/

    onInputClick(event){
        console.log('Click');
    }

    keyUpSearch(event){
        console.log("Search Input Key Up");
        //this.props.onSubmit(this.state.term);
    }
    //onFormSubmit = (event) =>
    onFormSubmit =event=>{
        //console.log(event.target.value);
        event.preventDefault(); //stop form from refreshing page

        //example below blows up because state is not a property of 'this', because this is undefined
        //console.log(this.state.term);

        //console.log('On Form Submit: '+this.state.term);
        this.props.onSubmit(this.state.term);

        
    }

    render(){
        return ( 
            <div className="ui segment"> 
                <form className="ui form" onSubmit={this.onFormSubmit}>
                    <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text"><FontAwesomeIcon icon={faSearch}></FontAwesomeIcon></span>
                    </div>
                    <input type="text" value={this.state.term} onChange={(e)=> this.setState({term:e.target.value})} onClick={(e)=>console.log("Alt Event Handler")} className="form-control" placeholder="Enter Search Term" onKeyUp={this.keyUpSearch}/>
                    </div>
                </form>
            </div>
            );
    }
}

export default SearchBar;