import React from 'react';
import { Calendar, momentLocalizer,Views } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";

const propTypes = {}
const localizer = momentLocalizer(moment);

let allViews = Object.keys(Views).map(k => Views[k])

const ColoredDateCellWrapper = ({ children }) =>
  React.cloneElement(React.Children.only(children), {
    style: {
      backgroundColor: 'lightblue',
    },
  })

class Selectable extends React.Component {
  constructor(props) {
    super(props)

    this.state = { 
        events:this.props.events,
        filteredEvents:[],
        authUserID:this.props.auid
    };
  }

  handleSelect = ({ start, end }) => {
    const title = window.prompt('New Event name')
    if (title)
      this.setState({
        events: [
          ...this.state.events,
          {
            start,
            end,
            title,
          },
        ],
      })
  }

  componentDidMount(){
      //console.log("Props: " + this.props.events + " State: " + this.state.events + " User ID: "+this.state.authUserID);
      //this.setState({events:this.props.events});
      //console.log("Events Count: " + this.state.events.length);
      //this.state.events.map((reminderItem)=>console.log(reminderItem.userID + " " + this.state.authUserID));
      //const newList = this.state.events.filter(item=>item.userID == this.state.authUserID);
      this.setState({filteredEvents:this.state.events.filter(item=>item.userID == this.state.authUserID)});
  }

  eventStyleGetter(event, start, end, isSelected) {
    console.log(event);
    var backgroundColor = '#B22222';
    var style = {
        backgroundColor: backgroundColor,
        borderRadius: '10px',
        opacity: 0.8,
        color: 'white',
        border: '0px',
        display: 'block'
    };
    return {
        style: style
    };
}

  render() {

    return (
        <Calendar
        selectable
        localizer={localizer}
        events={this.state.filteredEvents}
        components={{
            timeSlotWrapper: ColoredDateCellWrapper,
          }}
        startAccessor="start"
        endAccessor="end"
        onSelectEvent={event => window.open('../company/'+event.entityID+'#reminders', "_blank")}
        //onSelectSlot={this.handleSelect}
        //eventPropGetter={(this.eventStyleGetter)}
      />
    )
  }
}

Selectable.propTypes = propTypes

export default Selectable