import React,{Component} from 'react';
import {withFirebase} from '../Firebase';
import firebase from 'firebase';
//import { faEdit,faPlusCircle,faHome, faMinus,faPlus,faShoppingBasket,faRedo,faUser,faUserCircle,faLock,faSearch, faTrash, faBoxTissue} from '@fortawesome/free-solid-svg-icons';
import {faStar,faTrash,faPlus,faMinus,faRedo,faCheck,faThumbtack,faUserPlus,faTimes,faEdit,faEraser, faStickyNote,faIndustry,faBuilding,faHome,faTrashAlt,faPencilAlt, faCheckCircle, faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from '../Modal';
import $ from 'jquery'; 

class ItemCard extends Component{

    constructor(props) {
        super(props);

        this.state = {
         itemProfile:this.props.item,
         tabNames:this.props.tabNames,
         tabID:this.props.tabID,
         tabItemid:this.props.itemTabID,
         itemDBID:'',
         title:'',
         description:'',
         qty:'',
         price:'',
         //itemID:'',
         //itemName:'',
         //itemDescription:'',
         itemAddDesc:'',
         //itemQty:'',
         //itemLocal:'',
         freightcost:'',
         freightorigin:'',
         packsize:'',
         unitSize:'',
         casesperpallet:'',
         unitCost:'',
         deliveredUnitCost:'',
         frominventory:false,
         inventoryhold:false,
         //itemPrice:'',
         //casesPerPallet:'',
         //itemTotalCost:'',
         mutype:'',
         muvalue:'',
         muprice:'',
         finalprice:'', //price + Mark Up (value and type)
         //itemMUPrice:'', //itemPrice + Mark Up (value and type)
         showFreightModal:false,
         showQtyModal:false,
         showMUModal:false,
         itemIsActive:true,
         loading:true,
         
         //'id':this.state.modalItemID,'title':this.state.modalItemName,"description":this.state.modalItemDescription,"unitSize":this.state.modalItemUnitSize,"price":this.state.modalItemFullPrice,"casesperpallet":this.state.modalItemCasesPerPallet,"packsize":this.state.modalItemPackSize,"qty":this.state.modalItemQty,"mutype":"","muvalue":"00","freightcost":"00.00","freightorigin":"--","fullprice":this.state.modalItemFullPrice,"frominventory":false,"inventoryhold":false
        };
      }

    openQtyModal(itemid,itemqty) {
      //console.log(this.state.tabID +" "+this.state.tabItemid);
      console.log(this.state.itemProfile);
      this.setState({ showQtyModal: true, itemQty:itemqty });
    }
  
    closeQtyModal() {
      this.setState({
        showQtyModal: false
      });
  }

    openFreightModal(itemid,fmc,fmo) {
      this.setState({ showFreightModal: true });
    }
  
    closeFreightModal() {
      this.setState({
        showFreightModal: false
      });
  }

  openMUModal(itemid,fobPrice,imut,imuv) {
    this.setState({ showMUModal: true, MUitemPrice:fobPrice, itemMUType: imut, itemMUValue:imuv });
  }

  closeModal() {
    this.setState({
      itemMUPrice:'',
      itemMUValue:'',
      itemMUType:'',
      showMUModal: false
    });
}

  downIncrement=(evt)=>{
    var val = this.state.qty;
    if(val > 0)
    {
      var newVal = --this.state.qty;
      this.setState({
        qty:newVal,
          //itemTotalCost:Number(this.state.itemTotalCost) - (1 * this.state.itemPrice)
        });
      console.log(newVal);
    }
  }

  upIncrement=(evt)=>{
    var newVal = ++this.state.itemQty;
    //console.log(modelItemNam);
    this.setState({
        qty:newVal,
        //itemTotalCost:Number(this.state.itemTotalCost) + (1 * this.state.itemPrice)
    });
    //console.log(newVal);
  }

  componentWillReceiveProps(props) {
    console.log("Item Card Active Tab: "+ props.tabID + " - "+props.itemTabID);
    console.log(props.item);
    this.setState({itemTabID:props.itemTabID});
    //console.log("Item Card Active Tab Item ID: "+ props.itemTabID);
    this.setState({ tabID: props.tabID });
  }
      componentDidMount(){

        let tempPrice = this.state.itemProfile.fullprice == null?this.state.itemProfile.price:this.state.itemProfile.fullprice;
        let tempTotal = parseFloat(tempPrice) * Number(this.state.itemProfile.qty);

        
          //console.log("ITEM CARD - ITEM DATA:" + this.props.item.price);

          //console.log("tab names " + this.state.tabNames.length);

          if(this.state.itemProfile == null)
          {
              return;
          }
          else{
            console.log("ITEM ADD DESC DATA:" + this.state.itemProfile.itemAddDesc);

            this.setState({
              itemProfile:{
                ...this.state.itemProfile,
                tabID:this.props.tabID,
                itemID:this.props.itemTabID,
                itemAddDesc:this.state.itemProfile.itemAddDesc==''?'':this.state.itemProfile.itemAddDesc===undefined?'':this.state.itemProfile.itemAddDesc
              }
            },()=>{ this.props.itemPriceUpdate(this.state.itemProfile);});

            
            var formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              
                // These options are needed to round to whole numbers if that's what you want.
                //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
                //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
              });

              this.setState({
                itemDBID:this.state.itemProfile.id,
                qty:this.state.itemProfile.qty,
                description:this.state.itemProfile.description,
                itemAddDesc:this.state.itemProfile.itemAddDesc,
                title:this.state.itemProfile.title,
                price:this.state.itemProfile.price,
                freightcost:this.state.itemProfile.freightcost,
                freightorigin:this.state.itemProfile.freightorigin,
                muvalue:this.state.itemProfile.muvalue,
                mutype:this.state.itemProfile.mutype,
                muprice:this.state.itemProfile.muprice==null?this.state.itemProfile.price:this.state.itemProfile.muprice,
                fullprice:this.state.itemProfile.fullprice,
                packsize:this.state.itemProfile.packsize,
                unitSize:this.state.itemProfile.unitSize,
                casesperpallet:this.state.itemProfile.casesperpallet,
                /*itemID:this.state.itemProfile.id,
                itemName:this.state.itemProfile.itemName,
                itemDescription:this.state.itemProfile.itemDescription,
                itemQty:this.state.itemProfile.qty,
                freightOrigin:'',
                frieghtCost:'00',
                packSize:this.state.itemProfile.packsize,
                unitSize:this.state.itemProfile.unitSize,
                itemPrice:this.state.itemProfile.price,
                itemMUType:this.state.itemProfile.itemMUType,
                itemMUValue:this.state.itemProfile.itemMYValue,
                itemMUPrice:this.state.itemProfile.price,
                unitCost:parseFloat(this.state.itemProfile.price) / parseFloat(this.state.itemProfile.packsize),
                casesPerPallet:this.state.itemProfile.casesPerPallet,
                itemTotalCost:(this.state.itemProfile.qty * this.state.itemProfile.price)// + this.item.frieghtCost),*/
              });

              //this.props.onItemTotalUpdate(this.state.itemProfile.qty * this.state.itemProfile.price);
              
          }
      }
      myChangeHandler = (event) => {
        //console.log('Chages');
        let nam = event.target.name;
        let val = event.target.value;
        let err = '';
        {/*if(nam === "qty")
        {
          if(parseFloat(this.state.freightcost) > 0.00 && parseFloat(this.state.muvalue) > 0.00)
          {
            //we have both
            let newTotal = Number(this.state.muprice) + (Number(this.state.freightcost)/Number(val));
                //this.props.onItemTotalUpdate(newTotal);
                this.setState({
                    fullprice:newTotal,
                });
                let newTotalC = parseFloat(this.state.fullprice) * Number(this.state.qty);
                console.log("New Total: "+ newTotalC+ " "+ this.state.fullprice+ " "+this.state.qty);
                this.props.itemPriceUpdate({tabID:this.state.tabID,total:newTotalC ,itemId:this.state.tabItemid})
          }
          else if((parseFloat(this.state.freightcost) === 0.00 || this.state.freightcost === null) && parseFloat(this.state.muvalue) > 0.00){
            //only have Mark Up NO Freight
                this.setState({
                    fullprice:this.state.muprice,
                });
                let newTotal = parseFloat(this.state.fullprice) * Number(this.state.qty);
                console.log("New Total: "+ newTotal+ " "+ this.state.fullprice+ " "+this.state.qty);
                this.props.itemPriceUpdate({tabID:this.state.tabID,total:newTotal ,itemId:this.state.tabItemid})
          }
          else if(parseFloat(this.state.freightcost) > 0.00 && (parseFloat(this.state.muvalue) === 0.00 || this.state.muvalue === null)){
            //only have Freight No markup
            let newTotal = Number(this.state.price) + (Number(this.state.freightcost)/Number(val));
                //this.props.onItemTotalUpdate(newTotal);
                this.setState({
                    fullprice:newTotal,
                });
                let newTotalD = parseFloat(newTotal) * Number(this.state.qty);
                console.log("New Total: "+ newTotalD+ " "+ newTotal+ " "+this.state.qty);
                this.props.itemPriceUpdate({tabID:this.state.tabID,total:newTotalD ,itemId:this.state.tabItemid})
          }
          else{
            //no need to update final case price
          }
        }*/}

        {/*if(nam === "freightcost")
        {
            //console.log("Freight Cost");
            if(parseFloat(val) != 0.00)
            {
                
              if(this.state.muprice !== this.state.price)
              {
                let newFullPrice = parseFloat(this.state.muprice) + (Number(val)/Number(this.state.qty));
                this.setState({
                  fullprice:newFullPrice,
              });
              let newTotalA = parseFloat(this.state.fullprice) * Number(this.state.qty);
              console.log("New Total: "+ newTotalA+ " "+ this.state.fullprice+ " "+this.state.qty);
              this.props.itemPriceUpdate({tabID:this.state.tabID,total:newTotalA ,itemId:this.state.tabItemid})
              }
              else{
              let newTotal = (Number(this.state.fullprice) - Number(this.state.freightcost))+ (Number(val)/this.state.qty);
                //this.props.onItemTotalUpdate(newTotal);
                this.setState({
                    fullprice:newTotal,
                });
                let newTotalB = parseFloat(newTotal) * Number(this.state.qty);
                console.log("New Total: "+ newTotalB + " "+ newTotal + " "+this.state.qty);
                this.props.itemPriceUpdate({tabID:this.state.tabID,total:newTotalB ,itemId:this.state.tabItemid})
              }
            }
            else if(parseFloat(val) == 0.00)
            {
              let finalPrice = this.state.price;

              if(this.state.mutype != null){
                var altMarkup = parseFloat(this.state.muvalue);
                if(this.state.mutype == "Percent")
                {
                  finalPrice = finalPrice + (finalPrice * (altMarkup/100));
                }
                else{
                  finalPrice = finalPrice + altMarkup;
                }
              }
              this.setState({
                fullprice:finalPrice
              });
              let newTotal = parseFloat(finalPrice) * Number(this.state.qty);
              console.log("New Total: "+ newTotal+ " "+ finalPrice+ " "+this.state.qty);
              this.props.itemPriceUpdate({tabID:this.state.tabID,total:newTotal ,itemId:this.state.tabItemid})
            }
            else{
                this.setState({
                  fullprice:this.state.fullprice,
                });
                let newTotal = parseFloat(this.state.fullprice) * Number(this.state.qty);
                console.log("New Total: "+ newTotal+ " "+ this.state.fullprice+ " "+this.state.qty);
                this.props.itemPriceUpdate({tabID:this.state.tabID,total:newTotal ,itemId:this.state.tabItemid})
            }
        }*/}
        if(nam === "mutype" || nam === "muvalue")
        {
          console.log("A");
          if(nam === "mutype" && this.state.muvalue != null)
          {
            var finalPrice;
            if(val === "Percent")
            {
              finalPrice = parseFloat(this.state.price) + (parseFloat(this.state.price) * (parseFloat(this.state.muvalue)/100));
            }
            else{
              finalPrice = parseFloat(this.state.price) + parseFloat(this.state.muvalue);
            }
            console.log("B");
            this.setState({muprice:finalPrice,itemProfile:{
              ...this.state.itemProfile,
              muprice:finalPrice
            }});
          }
          else if(nam === "mutype" && this.state.muvalue == null)
          {
            //do nothing
            console.log("C");
          }
          else if(nam==="muvalue" && this.state.mutype != null)
          {
            var finalPrice;
            if(this.state.mutype === "Percent")
            {
              finalPrice = parseFloat(this.state.price) + (parseFloat(this.state.price) * (val/100));
            }
            else if(this.state.mutype === "Dollar"){
              finalPrice = parseFloat(this.state.price) + parseFloat(val);
            }
            console.log("D");
            this.setState({muprice:finalPrice,itemProfile:{
              ...this.state.itemProfile,
              muprice:finalPrice
            }});
          }
          else if(nam==="muvalue" && this.state.mutype == null)
          {
            //do nothing
            console.log("E");
          }
        }


        this.setState({errormessage: err});
        this.setState({[nam]: val});
        this.setState({
          itemProfile: {
            ...this.state.itemProfile,
            [nam]:val
          }
        },()=>{ this.props.itemPriceUpdate(this.state.itemProfile);})
        //console.log(nam + " " + val);
        //console.log(val);
      }

      /*calculatePrice(){
        var basePrice = parseFloat(this.state.itemMUPrice);
        var finalPrice;
        var altMarkup = parseFloat(this.state.itemMUValue);
        if(this.state.mutype == "Percent")
        {
          finalPrice = basePrice + (basePrice * (altMarkup/100));
        }
        else{
          finalPrice = basePrice + altMarkup;
        }
        
        this.setState({itemMUPrice:finalPrice.toFixed(2), itemTotalCost:(this.state.itemQty * finalPrice),});
    }*/

    tabTransfer = (event) =>{
      let val = event.target.value;//id of tab to copy to
      //this.state.itemProfile.tabID = parseInt(val);
      this.props.transferItem(this.state.itemProfile, val);
    }

    updateItemQty(){
      console.log("Updating Item Qty");
      //console.log(this.state.itemProfile)
      if(parseFloat(this.state.freightcost) > 0.00 && parseFloat(this.state.muvalue) > 0.00)
          {
            //we have both
            let newTotal = Number(this.state.muprice) + (Number(this.state.freightcost)/Number(this.state.qty));
                //this.props.onItemTotalUpdate(newTotal);
                this.setState({
                    fullprice:newTotal,
                    showQtyModal:false,
                    itemProfile:{
                      ...this.state.itemProfile,
                      fullprice:newTotal
                    }
                });
                let newTotalC = parseFloat(this.state.fullprice) * Number(this.state.qty);
                console.log("New Total: "+ newTotalC+ " "+ this.state.fullprice+ " "+this.state.qty);
                this.props.itemPriceUpdate(this.state.tabID, this.state.tabItemid, this.state.qty,this.state.itemProfile)
          }
          else if((parseFloat(this.state.freightcost) === 0.00 || this.state.freightcost === null) && parseFloat(this.state.muvalue) > 0.00){
            //only have Mark Up NO Freight
                this.setState({
                    fullprice:this.state.muprice,
                    showQtyModal:false,
                    itemProfile:{
                      ...this.state.itemProfile,
                      fullprice:this.state.muprice
                    }
                });
                let newTotal = parseFloat(this.state.fullprice) * Number(this.state.qty);
                console.log("New Total: "+ newTotal+ " "+ this.state.fullprice+ " "+this.state.qty);
                //this.props.itemPriceUpdate(this.state.itemProfile)
                this.props.itemPriceUpdate(this.state.tabID, this.state.tabItemid, this.state.qty,this.state.itemProfile)
          }
          else if(parseFloat(this.state.freightcost) > 0.00 && (parseFloat(this.state.muvalue) === 0.00 || this.state.muvalue === null)){
            //only have Freight No markup
            let newTotal = Number(this.state.price) + (Number(this.state.freightcost)/Number(this.state.qty));
                //this.props.onItemTotalUpdate(newTotal);
                this.setState({
                    fullprice:newTotal,
                    showQtyModal:false,
                    itemProfile:{
                      ...this.state.itemProfile,
                      fullprice:newTotal
                    }
                });
                let newTotalD = parseFloat(newTotal) * Number(this.state.qty);
                console.log("New Total: "+ newTotalD+ " "+ newTotal+ " "+this.state.qty);
                //this.props.itemPriceUpdate(this.state.itemProfile)
                this.props.itemPriceUpdate(this.state.tabID, this.state.tabItemid, this.state.qty,this.state.itemProfile)
          }
          else{
            //no need to update final case price
            this.setState({showQtyModal:false});
            //this.props.itemPriceUpdate(this.state.itemProfile)
            this.props.itemPriceUpdate(this.state.tabID, this.state.tabItemid, this.state.qty,this.state.itemProfile)
          }
    }

    updateFreightCost(){
      console.log(this.state.tabID);
      var finalFullPrice;
        if(this.state.muvalue == null || this.state.muvalue == "0")
        {
          finalFullPrice = parseFloat(this.state.muprice);
        }
        else{
          finalFullPrice = parseFloat(this.state.muprice) + (parseFloat(this.state.freightcost)/parseFloat(this.state.qty));
        }

        this.setState({
          fullprice:finalFullPrice,
          showFreightModal:false,
          itemProfile:{
            ...this.state.itemProfile,
            fullprice:finalFullPrice
        }},()=>{this.props.itemPriceUpdate(this.state.itemProfile)});
        let newTotal = parseFloat(finalFullPrice) * Number(this.state.qty);
        console.log("New Total: "+newTotal +" "+ finalFullPrice + " "+ this.state.qty );
        //this.props.itemPriceUpdate(this.state.itemProfile)
        console.log(this.state.itemProfile);
    }

      updateItemPrice(){
        //not sure what to do here
        var finalFullPrice;
        if(this.state.freightcost == null || this.state.freightcost == "0")
        {
          finalFullPrice = parseFloat(this.state.muprice);
        }
        else{
          finalFullPrice = parseFloat(this.state.muprice) + (parseFloat(this.state.freightcost)/parseFloat(this.state.qty));
        }

        this.setState({fullprice:finalFullPrice,showMUModal:false,itemProfile:{
          ...this.state.itemProfile,
          muprice:this.state.muprice,
          fullprice:finalFullPrice
        }},()=>{this.props.itemPriceUpdate(this.state.itemProfile);});
        let newTotal = parseFloat(finalFullPrice) * Number(this.state.qty);
        console.log("New Total: "+newTotal +" "+ finalFullPrice + " "+ this.state.qty );
        //console.log(this.state.itemProfile);
        //this.props.itemPriceUpdate(this.state.itemProfile);
      }

      calculateItemTotal()
      {
        //update item total
        //update unit cost based on freight
        var rdn = Math.random();
        //this.props.onItemTotalUpdate(Math.random());
      }

      removeItem(itemid){
        console.log("Item Card Remove Item: " + itemid);
        //this.props.removeItemFromCart(itemid);
      }

render()
{
    const{itemProfile,itemID,itemPrice,itemTotalCost,unitCost,itemMUPrice,showMUModal} = this.state;
return(
  <React.Fragment>
    <Modal show={this.state.showQtyModal} handleClose={e => this.closeQtyModal(e)}>
                    <div className="modal-content">
                    <div className="modal-header">
                                        <h5 className="modal-title">Update Item Quantity</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e=>this.closeQtyModal()}>
                                          <span aria-hidden="true">&times;</span>
                                        </button>
                                      </div>
                                      <div className="modal-body">
                    <strong>Qty:</strong>
                                  <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                  <button className="btn btn-danger" type="button" id="button-addon1" onClick={e=>this.downIncrement(e)} disabled={this.state.itemQty == 0}><FontAwesomeIcon icon={faMinus}></FontAwesomeIcon></button>
                                                </div>
                                                <input type="text" className="form-control" name="qty" id="qty" value={this.state.qty} onChange={this.myChangeHandler}/>
                                                <div className="input-group-append">
                                                  <button className="btn btn-success" type="button" id="button-addon2" onClick={e=>this.upIncrement(e)}><FontAwesomeIcon icon={faPlus}></FontAwesomeIcon></button>
                                                </div>
                                              </div>
                                              </div>
                                              <div className="modal-footer">
                                        <button type="button" className="btn btn-success" onClick={e=>this.updateItemQty()}>Update Quantity</button>
                                        <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={e=>this.closeQtyModal()}>Close</button>
                                      </div>
                    </div>
                </Modal>
                <Modal show={this.state.showFreightModal} handleClose={e => this.closeFreightModal(e)}>
                                    <div className="modal-content">
                                      <div className="modal-header">
                                        <h5 className="modal-title">Add Freight Cost</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e=>this.closeFreightModal()}>
                                          <span aria-hidden="true">&times;</span>
                                        </button>
                                      </div>
                                      <div className="modal-body">
                                        <label htmlFor="freightorigin">Freight Origin</label>
                                        <input type="text" name="freightorigin" className="form-control" value={this.state.freightorigin} onChange={this.myChangeHandler}/>
                                        <label htmlFor="freightcost">Freight Cost</label>
                                        <input type="text" name="freightcost" className="form-control" onChange={this.myChangeHandler} value={this.state.freightcost}/>
                                      </div>
                                      <div className="modal-footer">
                                        
                                        <button type="button" className="btn btn-success" onClick={e => this.updateFreightCost(e)} disabled={this.state.freightcost==null}>Update Pricing w/Freight</button>
                                        <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={e=>this.closeFreightModal()}>Close</button>
                                      </div>
                                    </div>
                                </Modal>
                              <Modal show={this.state.showMUModal} handleClose={e => this.closeModal(e)}>
                                    <div className="modal-content">
                                      <div className="modal-header">
                                        <h5 className="modal-title">Add Markup</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e=>this.closeModal()}>
                                          <span aria-hidden="true">&times;</span>
                                        </button>
                                      </div>
                                      <div className="modal-body">
                                        <label htmlFor="itemPrice">FOB Price</label>
                                        <input type="text" name="price" className="form-control" value={this.state.price} disabled/>
                                        <label htmlFor="muvalue">Mark Up Value</label>
                                        <input type="text" name="muvalue" className="form-control" onChange={this.myChangeHandler} value={this.state.muvalue}/>
                                        <label htmlFor="mutype">Mark Up Type</label>
                                        <select name="mutype" className="form-control" onChange={this.myChangeHandler} value={this.state.mutype}>
                                          <option value="">Select...</option>
                                          <option value="Dollar">Dollar</option>
                                          <option value="Percent">Percent</option>
                                        </select>
                                        <label htmlFor="muprice">Marked Up Price (w/o Frieght)</label>
                                        <input type="text" name="muprice" className="form-control" value={this.state.muprice} disabled/>
                                      </div>
                                      <div className="modal-footer">
                                        {/*<button type="button" className="btn btn-primary" onClick={e => this.calculatePrice(e)} disable={this.state.price == this.state.fullprice}>Calculate Full Price</button>*/}
                                        <button type="button" className="btn btn-success" onClick={e => this.updateItemPrice(e)}>Update Pricing</button>
                                        <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={e=>this.closeModal()}>Close</button>
                                      </div>
                                    </div>
                                </Modal>
                        <tr key={itemProfile.id}>
                          {/*<div>*/}
                          
                                
                                       <td><button type="button" className="btn btn-sm btn-danger" onClick=
                                       {() => {
                                        if (window.confirm("Delete this Item?")) {
                                            
                                          this.props.removeItem(itemProfile.itemID, itemProfile.tabID); 
                                          //let removeToCollection = this.removeItemFromCurrentTab.bind(this,itemProfile.id);
                                            //removeToCollection();
                                        }
                                    }}>
                                         <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
                                         </button><button type="button" className="btn" data-toggle="collapse" data-target={`#collapse${itemProfile.id}`}>
                                           <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon></button>
                                        </td>
                                       <td><a href={`/item/${itemProfile.id}`} target="blank">{this.state.title}</a></td>
                                       <td>${parseFloat(this.state.price).toFixed(2)}</td>
                                       <td><button onClick={e=>this.openQtyModal(this.state.id,this.state.qty)} className="btn btn-outline-primary">{this.state.qty}</button></td>
                                       <td><button onClick={e=>this.openFreightModal(this.state.id, this.state.frieghtcost, this.state.frieghtorigin)} className="btn btn-outline-primary"><FontAwesomeIcon icon={this.state.freightcost != '00.00'?faCheck:faEdit}></FontAwesomeIcon></button></td>
                                       <td><button onClick={e=>this.openMUModal(this.state.id,this.state.price)} className="btn btn-outline-info"><FontAwesomeIcon icon={this.state.muvalue != '00'?faCheck:faEdit}></FontAwesomeIcon></button>
                                        </td>
                                        <td>${(Math.round(parseFloat(this.state.fullprice)*100)/100).toFixed(2)}/${((parseFloat(this.state.fullprice) / parseFloat(this.state.packsize)*100)/100).toFixed(3)}</td>
                                        <td><div className="input-group"><select name={`tabSelect_${itemProfile.id}`} onChange={this.tabTransfer} className="form-control-sm">
                                            <option value="00">Add to Tab...</option>
                                            {this.state.tabNames.length > 1 &&
                                                this.state.tabNames.filter(t=>t.id !== this.props.tabID).map(tabItem=>(
                                                    <option value={tabItem.id}>{tabItem.name}</option>
                                                ))
                                            }
                                            </select></div></td>
                                   </tr> 
                                   <tr id={`collapse${this.state.itemDBID}`} className="collapse out">
                                        <td colSpan="3"><input type="text" className="form-control" name="itemAddDesc" placeholder="Additional Description" onChange={this.myChangeHandler} value={this.state.itemAddDesc}></input><br></br>{this.state.description}</td>
                                        <td><strong>Pack Size:</strong>{this.state.packsize}</td>
                                        <td><strong>Cases Per Pallet:</strong>{this.state.casesperpallet}</td>
                                        <td colSpan="2"><strong>Unit Size:</strong>{this.state.unitSize}</td>
                                        <td><strong>Unit Cost:</strong>${((parseFloat(this.state.price) / parseFloat(this.state.packsize)*100)/100).toFixed(3)}</td>
                                    </tr>  
                                    </React.Fragment>
)
}
}
export default withFirebase(ItemCard);