import React, {Component} from 'react';
import {compose} from 'recompose';
import * as ROUTES from '../../constants/routes';
import {withAuthorization} from '../Session';
import {withFirebase} from '../Firebase';

class ContactPage extends Component{

    constructor(props) {
        super(props);
        this.state = {
          title:'',
          entityID: this.props.match.params.cid,
          fname:'',
          lname:'',
          email:'',
          phone:'',
          ext:'',
          cellphone:'',
          primary:'off',
          isActive:1,
          errormessage: ''
        };
      }

      mySubmitHandler = (event) => {
        event.preventDefault();
        //console.log("State: " + this.state.entityID);
        /*let age = this.state.age;
        if (!Number(age)) {
          alert("Your age must be a number");
        }*/
        this.props.firebase.db.collection('entities').doc(this.state.entityID).collection('contacts').add({
          email:this.state.email,
          fname:this.state.fname,
          lname:this.state.lname,
          isActive:this.state.isActive,
          lname:this.state.lname,
          phone:this.state.phone,
          ext:this.state.ext,
          cellphone:this.state.cellphone,
          isPrimary:this.state.primary == "on"?true:false,
          title:this.state.title,
        });

        if(this.state.primary == "on")
        {
          var contactPrime = this.props.firebase.db.collection('entities').doc(this.state.entityID).update({
            primarycontact: this.state.fname + " " + this.state.lname
          });
        }

        this.setState({});
        this.props.history.push('/company/'+this.state.entityID);
      }

      componentDidMount(){
        this.setState({entityID:this.props.match.params.cid});
        console.log("State: " + this.state.entityID);
    }

      myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        let err = '';
        if (nam === "age") {
          if (val !="" && !Number(val)) {
            err = <strong>Your age must be a number</strong>;
          }
        }

        if (nam === "phone" || nam === "cellphone")
        {
          if(val < 10)
          {
            err = <strong>Phone Number must be at least 10 digits</strong>;
          }
          else{
            val = this.getFormattedPhoneNum(val);
          }
        }
        this.setState({errormessage: err});
        this.setState({[nam]: val});
      }

      cancelForm=(event)=>{
        this.props.history.push('/company/'+this.state.entityID);
      }

      setNewValue(newValue) {
        console.log('this is the State code:' + newValue);
      }

      getFormattedPhoneNum( input ) {
        let output = "(";
        input.replace( /^\D*(\d{0,3})\D*(\d{0,3})\D*(\d{0,4})/, function( match, g1, g2, g3 )
            {
              if ( g1.length ) {
                output += g1;
                if ( g1.length == 3 ) {
                    output += ")";
                    if ( g2.length ) {
                        output += " " + g2; 
                        if ( g2.length == 3 ) {
                            output += " - ";
                            if ( g3.length ) {
                                output += g3;
                            }
                        }
                    }
                 }
              }
            }       
          );        
        return output;
      }       

      render() {
        return (
          <div className="container">
          <form onSubmit={this.mySubmitHandler}>
          <h1>Add a contact</h1>
          {this.state.errormessage}
          <label htmlFor="fname">Contact First Name:</label>
          <input
            type='text'
            name='fname'
            className="form-control"
            onChange={this.myChangeHandler}
          />
          <label htmlFor="lname">Contact Last Name:</label>
          <input
            type='text'
            name='lname'
            className="form-control"
            onChange={this.myChangeHandler}
          />
          <label htmlFor="title">Title/Role:</label>
          <input
            type='text'
            name='title'
            className="form-control"
            onChange={this.myChangeHandler}
          />
          <label htmlFor="email">E-mail</label>
          <input
            type='text'
            name='email'
            className="form-control"
            onChange={this.myChangeHandler}
          />
          <div className="row">
            <div className="col-md-6">
            <label htmlFor="phone">Main Phone:</label>
              <input
                type='phone'
                name='phone'
                className="form-control"
                onChange={this.myChangeHandler}
              />
            </div>
            <div className="col-md-6">
            <label htmlFor="ext">Extension:</label>
              <input
                type='text'
                name='ext'
                className="form-control"
                style={{maxWidth:150+"px"}}
                onChange={this.myChangeHandler}
              />
            </div>
          </div>
          
          <label htmlFor="cellphone">Cell Phone:</label>
          <input
            type='phone'
            name='cellphone'
            className="form-control"
            onChange={this.myChangeHandler}
          />
         <label htmlFor="primary" style={{marginRight:15+"px"}}>Is Primary Contact?</label>
          <input
            type='checkbox'
            name='primary'
            onChange={this.myChangeHandler}
          />
          <hr/>
          <div className="row">
            <div className="col-md-4"><input type="submit" className="btn btn-success" value="Submit"/></div>
            <div className="col-md-4"></div>
            <div className="col-md-4"><input type="button" className="btn btn-danger pull-right" onClick={this.cancelForm} value="Cancel"/></div>
          </div>
          
          
          </form>
          </div>
        );
      }
    }

    const condition = authUser => !!authUser;
    const ContactPageForm = compose(
      withAuthorization(condition),
      withFirebase,
    )(ContactPage);
    
    export default withFirebase(ContactPage);
    
    export {ContactPageForm};
