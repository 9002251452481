//COMPONENT TO QUERY/FILTER QUOTES BY STATUS

import React, { Component } from 'react'
import {withFirebase} from '../Firebase';
import {Link} from 'react-router-dom'
import { compose } from 'recompose';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle,faClock,faHistory} from '@fortawesome/free-solid-svg-icons';
import {faUser,faUserCircle,faLock} from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AuthUserContext } from '../Session';
import * as ROLES from '../../constants/roles';
import {withAuthorization} from '../Session';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { selectFilter,Comparator } from 'react-bootstrap-table2-filter';
import moment from "moment";

class QuoteStatusTable extends Component {
    constructor(props) {
      super(props);
      this.state = {
        searchInquiries: null,
        searchQuotes:[],
        filteredQuotes:[],
        statusItems:[],
        statusItemsArr:{},
        filterTerm: "All Steps",
        loading:true
      };
    }
    handleSearch = event => {
      this.setState({ searchInquiries: event.target.value });
    };
    handleAnswer = event => {
      this.setState({ filterTerm: event.target.value });

      var filteredQuotes = this.state.searchQuotes.filter(data => {
        if(event.target.value  === "All Steps"){
            console.log("All Steps Data");
            return data;
        }
        else if(data.status === event.target.value)
        {
            console.log("Filtered Data");
            return data;
        }
        return null;
    });

    this.setState({filteredQuotes:filteredQuotes});
      
    };

    componentDidMount(){
        this.unsubscribe = this.props.firebase
        .activeQuotes()
        .onSnapshot(snapshot => {
          let customerquotes = [];
   
          snapshot.forEach(doc =>
            customerquotes.push({ ...doc.data(), qid: doc.id }),
          );

          this.setState({
            searchQuotes:customerquotes,
            filteredQuotes:customerquotes,
          });
        });

        this.unsubscribe = this.props.firebase
        .statusItems()
        .onSnapshot(snapshot => {
          let statusDomain = [];
   
          snapshot.forEach(doc =>
            statusDomain.push({ ...doc.data(), cid: doc.id }),
          );

          this.setState({
            statusItems:statusDomain,
            loading:false
          });
        });

    }

    render() {
      const {statusItems, statusItemsArr,searchQuotes,loading,filteredQuotes} = this.state;

      //const fooItems = {};
      statusItems.map((itm,index) =>{
        //let obj = {`${index`:`itm.statusValue`};
        statusItemsArr[index]=(itm.statusValue)
      });
        //console.log(statusItemsArr);

        const options = {
          // pageStartIndex: 0,
          sizePerPage: 5,
          hideSizePerPage: true,
          hidePageListOnlyOnePage: true
        };
        const columns = [
          {
            dataField: 'qid',
            text: 'Quote ID',
            hidden: true
          },
          {
            dataField: 'selectedCustomerName',
            text: 'Customer'
          },
          {
            dataField: 'quoteDate',
            text: 'Quote Date',
            Cell: row => (
              row.value
              /*<div>
                <span title={ row.value }>{ moment(row.value).format("LL") }</span>
              </div>*/
            )
          },
          {
            dataField: 'status',
            text: 'Status',
            filter:selectFilter({
              options: statusItemsArr,
              hidden:true,
            })
          },
          {
            dataField: 'qid',
            text: 'Link',
            formatter: (cellContent, row) => (
                  <a href={'quote/'+ cellContent} target="_blank" className="btn btn-sm btn-info btn-text-white">Go</a>
            )
          }
        ];

        
        const padding = {
          padding: "20px"
        };
        const marginBottom = {
          marginBottom: "0px"
        };
        const row = {
          margin: "0px"
        };

        /*var filteredQuotes = searchQuotes.filter(data => {
            if(this.state.filterTerm === "All Steps"){
                console.log("All Steps Data");
                return data;
            }
            else if(data.status === this.state.filterTerm)
            {
                console.log("Filtered Data");
                return data;
            }
            return null;
        });*/

        /*var inquiries = InquiresList.filter(data => {
          if (this.state.searchInquiries == null) {
            return data;
          } else if (
            data.user_code
              .toLowerCase()
              .includes(this.state.searchInquiries.toLowerCase())
          ) {
            return data;
          }
          return null;
        });
        inquiries = inquiries.filter(data => {
          if (this.state.answerStatus.toLowerCase() === "all") {
            console.log("All status");
            return data;
          } else if (
            data.answered.toLowerCase() === "answered" &&
            this.state.answerStatus.toLowerCase() === "answered"
          ) {
            console.log(this.state.answerStatus);
            return data;
          } else if (
            data.answered.toLowerCase() === "unanswered" &&
            this.state.answerStatus.toLowerCase() === "unanswered"
          ) {
            console.log(this.state.answerStatus);
            return data;
          }
          return null;
        });*/

        return (
            <div style={{marginLeft:25+"px"},{fontSize:.75+"em"}}>
                {loading && "Loading..."}
                <div className="row">
                    <div className="col-md-3">
                        <select name="status-filter" onChange={this.handleAnswer} className="form-control" style={{width:250+"px"}}>
                            <option value="All Steps">All Steps</option>
                            {statusItems.map(status => (
                                <option key={status.cid} value={status.statusValue}>{status.statusValue}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <React.Fragment>
                      <BootstrapTable
                      loading={this.state.loading}
                        keyField="qid"
                        data={ this.state.filteredQuotes }
                        columns={ columns }
                        pagination={ paginationFactory(options) }
                        filter={filterFactory()}
                      />
                      {/*<button className="btn btn-default" onClick={ () => this.deleteBookWithId() }>
                        delete last one book
                      </button>
                      <button className="btn btn-default" onClick={ () => this.addBook() }>
                        Add a book to the end
                      </button>*/}
                    </React.Fragment>
                {/*<div className="row">
                    <div className="col-md-3">
                        <select name="status-filter" onChange={this.handleAnswer} className="form-control" style={{width:250+"px"}}>
                            <option value="All Steps">All Steps</option>
                            {statusItems.map(status => (
                                <option key={status.cid} value={status.statusValue}>{status.statusValue}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="row" style={{marginTop:15+"px"}}>
                    <div className="col-md-12">
                    <table className="table table-striped table-hover table-bordered" style={{fontSize:.75+"em"}}>
                        <thead>
                            <tr>
                                <th>Customer</th>
                                <th>Quote Date</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                        {filteredQuotes.map(inquiry => (
                            <tr key={inquiry.qid}>
                                <td>{inquiry.selectedCustomerName}</td>
                                <td>{new Date(inquiry.quoteDate).toLocaleDateString()}</td>
                                <td>{inquiry.status}</td>
                                <td><a href={'/quote/'+inquiry.qid} className='btn btn-sm btn-info btn-text-white' target='_blank'>Go</a></td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    </div>
                        </div>*/}
            </div>
        );
    }
}

        export default withFirebase(QuoteStatusTable);