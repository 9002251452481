import React, { Component } from 'react';
import {withFirebase} from '../Firebase';
import 'bootstrap/dist/css/bootstrap.min.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPencilAlt,faCheckCircle,faTimes, faCheck} from '@fortawesome/free-solid-svg-icons';
import { Redirect } from 'react-router-dom';

class EntityControl extends Component{
    constructor(props){
        super(props);

        this.state = {
            loading:false,
            entitytype:this.props.companytype,
            companyid:this.props.cid,
            company:null,
            editMode:false,
            etypes:["Admin","Customer","Lead","Logistics","Prospect","Supplier","Warehouse"],
        };
    }

    componentDidMount(){
        //this.setState({loading:true});
    }

    componentWillUnmount(){
        //this.unsubscribe();
    }

    repEditMode=()=>{
        this.setState({editMode:true});

    }

    repNameSelect=(event)=>{
        let nam = event.target.name;
        let val = event.target.value;
        console.log(nam +"-"+val);
        this.setState({[nam]: val});
    }

    repNameCommit=()=>{
        //this.setState({username:'foobar'});
        //console.log("Commit new Rep: " + this.state.username + " for: "+this.state.company.entityID);
        this.props.firebase.updateType(this.props.cid,this.state.entitytype);
        this.setState({editMode:false});
    }

    repNameCancel=()=>{
        this.setState({editMode:false});
    }

    render(){
        const {editMode,etypes,entitytype, loading} = this.state;
        return(
            <div className="row" style={{marginTop:10+"px"}}><div className="col-sm-4"><strong>Company Type:</strong></div>
            {!editMode 
                ? <div className="col-sm-4">{entitytype}<span>     </span><FontAwesomeIcon icon={faPencilAlt} style={{cursor:'hand',marginLeft:15+"px"}} onClick={this.repEditMode} data-toggle="tooltip" data-placement="right" title="Edit"></FontAwesomeIcon></div> 
                : <div className="row"><div className="col-sm-8"><select name="entitytype" onChange={this.repNameSelect} className="form-control">
                    <option value="00">Select Type</option>
                {etypes.map(types=>
                    <option value={types} key={types}>{types}</option>
                )}
            </select>
            </div>
            {/*<div className="col-sm-1"><FontAwesomeIcon icon={faCheck} style={{color:"green",cursor:'hand'}} onClick={this.repNameCommit}></FontAwesomeIcon></div><div className="col-sm-1"><FontAwesomeIcon icon={faTimes} style={{color:"red"}} onClick={this.repNameCancel}></FontAwesomeIcon></div>*/}
            <div className="col-sm-3"><div className="btn-group" role="group" aria-label="Basic example"><button type="button" className="btn btn-sm btn-success" onClick={this.repNameCommit}>Submit</button><button type="button" className="btn btn-sm btn-danger" onClick={this.repNameCancel}>Cancel</button></div></div>
            </div>}
            </div>
        );
    }
}
export default withFirebase(EntityControl);
