import React, { Component } from 'react';

import * as ROUTES from '../../constants/routes';
import {withFirebase} from '../Firebase';

import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faFileImage} from '@fortawesome/free-solid-svg-icons';

class FileTable extends Component {
    constructor(props){
        super(props);

        this.state = {
            loading:false,
            companyid:this.props.cid,
            filesList:[],
        };
    }

    componentDidMount(){
        console.log('File Table On Mount: ' +this.state.companyid);
        let stoRef = this.props.firebase.storageRef.child('companydocs/'+this.state.companyid);
        console.log("Storage Ref: " + stoRef);
        let filesList = [];
        stoRef.listAll()
            .then((res) => {
                res.prefixes.forEach((folderRef) => {
                // All the prefixes under listRef.
                // You may call listAll() recursively on them.
                    //console.log("Folder Ref: " + folderRef);
                    folderRef.listAll().then((ress)=>{
                        ress.items.forEach((fitemRef) => {
                            // All the items under listRef.
                                fitemRef.getDownloadURL().then(function(fileURL){
                                    console.log("Folder Item Ref: " + fileURL + " Name: " + fitemRef.name);
                                    filesList.push({name:fitemRef.name,fullPath:fileURL});
                                }).then(()=>{
                                    //console.log("Setting State");
                                    this.setState({
                                        filesList,
                                        loading:false,
                                    });});
                            });
                    }).then(()=>{
                        //console.log("Setting State");
                        this.setState({
                            filesList,
                            loading:false,
                        });});
                });
                res.items.forEach((itemRef) => {
                // All the items under listRef.
                    console.log("Item Ref: " + itemRef);
                    itemRef.getDownloadURL().then(function(fileURL){
                        filesList.push({name:itemRef.name,fullPath:fileURL});
                    }).then(()=>{
                                    //console.log("Setting State");
                                    this.setState({
                                        filesList,
                                        loading:false,
                                    });});
                });
            }).catch((error) => {
                //Uh-oh, an error occurred!
                console.log("ERROR Getting File List: " + error);
            });

        /*stoRef.listAll().then(function (result) {
                let path = stoRef.fullPath;
                console.log(path);
                path = path.replace(/\b\/\b(?!.*?\b\/\b)/, "%2F");
                result.items.forEach(filterRef => {
                    console.log(filterRef);
                    filesList.push({name:filterRef.name, url: path + "%2F" + filterRef.name +"?alt=media"});   
                });
            }).then(()=>{
                console.log("Setting State");
                this.setState({
                    filesList,
                    loading:false,
                })
            }).catch((error) => {
                //Uh-oh, an error occurred!
                console.log("ERROR Getting File List: " + error);
            });*/
    }

    componentWillUnmount(){
        //this.unsubscribe();
    }

    render(
    ){

        const {filesList} = this.state;
        return(
            <div style={{marginTop:25+"px"}}>
                <h2> Available Documents: {filesList.length}</h2>
                <ul>
                {filesList.map(fileItem =>(
                    <li key={fileItem.cid}><a href={fileItem.fullPath}>{fileItem.name}</a></li>
                ))}
                </ul>
            </div>
        );
    }
}
export default withFirebase(FileTable);