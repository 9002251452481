import React,{Component} from 'react';
import SearchBar from '../Search/SearchBar';
import SearchTable from '../Search/SearchTable';
import { Switch,Route, Link } from 'react-router-dom';

import * as ROUTES from '../../constants/routes';
import { compose } from 'recompose';

import { withAuthorization } from '../Session';
import {withFirebase} from '../Firebase';
import firebase from 'firebase';
import { faEdit,faPlusCircle,faHome, faMinus,faPlus} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faShoppingBasket,faRedo,faUser,faUserCircle,faLock,faSearch} from '@fortawesome/free-solid-svg-icons';

import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import filterFactory,{textFilter,selectFilter} from 'react-bootstrap-table2-filter';
import { ThemeProvider } from 'styled-components';
import Modal from '../Modal';

class SupplierItemsPage extends Component{ 

  detailsFormatter(cell, row) {
    return (
      <Link to={{pathname: `/company/`+row.cid}}>Details</Link>
    );
  }
  constructor(props) {
    super(props);

    const staffOptions = {'Josh S': 'Josh S', 'Beth Sharratt':'Beth Sharratt', 'Annie Streufert':'Annie Streufert','Justin Guerin':'Justin Guerin'};
        const stateOptions = {'AL':'AL','AK':'AK','AS':'AS','AZ':'AZ','AR':'AR','CA':'CA','CO':'CO','CT':'CT','DE':'DE','DC':'DC','FL':'FL','GA':'GA','GU':'GU','HI':'HI','ID':'ID','IL':'IL','IN':'IN','IA':'IA','KS':'KS','KY':'KY','LA':'LA','ME':'ME','MD':'MD','MA':'MA','MI':'MI','MN':'MN','MS':'MS','MO':'MO','MT':'MT','NE':'NE','NV':'NV','NH':'NH','NJ':'NJ','NM':'NM','NY':'NY','NC':'NC','ND':'ND','MP':'MP','OH':'OH','OK':'OK','OR':'OR','PA':'PA','PR':'PR','RI':'RI','SC':'SC','SD':'SD','TN':'TN','TX':'TX','UT':'UT','VT':'VT','VA':'VA','VI':'VI','WA':'WA','WV':'WV','WI':'WI','WY':'WY'};
        const entityTypeOptions ={'Admin': 'Admin', 'Customer':'Customer','Lead':'Lead', 'Logistics':'Logistics','Prospect':'Prospect','Supplier':'Supplier',"Warehouse":"Warehouse"};

    this.state = { 
      term:'',
      entityID:this.props.match.params.cid,
      supplier:'',
      items: [],
      docIDs:[],
      cartItems:[],
      tblColumns:[
        {
          data: "itemid",
          title: "Item ID",
          sort: false,
          visible:false
        },
        {
          data: "itemName",
          title: "Item Name",
          sort: false,
          visible:false
        },
        {
          data: "itemLink",
          title: "Name",
          sort: true,
          //formatter: (cell, row) => <a href={'/'+this.state.entityID+'/item/'+row.itemid}> {cell} </a>
        },
        {
          data: "itemDescription",
          title: "Description",
          sort:true
        },
        {
          data: "itemSizeFull",
          title: "Unit Size",
          sort:true
        },
        {
          data: "packSize",
          title: "Pack Size",
          sort:true
        },
        {
          data: "fullPrice",
          title: "Price",
          sort:true,
          render: $.fn.dataTable.render.number( ',', '.', 2, '$' )
        },
        {
          data: "supplierName",
          title: "Supplier",
          sort:true,
          visible:false,
        },
        {
          data: "fobFullLocation",
          title: "FOB Location",
          sort:true
        },
        {
          title:"",
          sort:false, 
          render: function (data,type,row){
            if(row.fullPrice == "")
            {
              return "<button class='btn btn-sm btn-success' disabled>+</button>";  
            }
            else{
              return "<button class='btn btn-sm btn-success'>+</button>";            }
          }
          //defaultContent:`<button class="btn btn-sm btn-success" onClick="showModalFunction('foo')">+</button>`
        }/*,
        {
          dataField: "qty",
          text: "Order Qty",
          sort:true
        },
        {
          dataField: "--",
          text: "--",
          isDummy:true
        }*/
    ],
      loading:true,
      showModal:false,
      modalItemID:'',
      modalItemName:'',
      modalItemDescription:'',
      modalItemPackSize:'',
      modalItemUnitSize:'',
      modalItemFullPrice:'',
      modalItemCasesPerPallet:'',
      modalItemQty:'0',
      downIncrementBtn:true,
      cartItems:'',
    };

    this.showModalFunction = this.showModalFunction.bind(this);
    //this.props.dispatch(getCrmStatus(this.props.params.id));
  }



  componentDidMount (){
      console.log("Company Items for: "+this.state.entityID);
      this.setState({loading:true});
      

      this.unsubscribe = this.props.firebase
        .company(this.state.entityID)
        .onSnapshot(snapshot => {
            this.setState({supplier:snapshot.data().name,loading:false});

        });

      this.unsubscribe = this.props.firebase
        .getItemsBySupplier(this.state.entityID)
        .onSnapshot(snapshot => {
   
          let items=[];

          snapshot.forEach(doc =>
           items.push({ ...doc.data(),itemLink:'<a href="/'+doc.data().companyid+'/item/'+doc.id+'">'+doc.data().itemName+'</a>',itemSizeFull:doc.data().unitSize + " " + doc.data().unitUnit ,fobFullLocation: doc.data().fobCity + " " + doc.data().fobState, itemid: doc.id }),
          );
            //console.log("Total Items: " + items.length + " " + items[0].itemName);
            this.setState({items,loading:false});

            $('#example').DataTable({
              destroy: true,
              data: items,
              columns: this.state.tblColumns,
              order:[[1,'asc']],
              pageLength: 10,
              //columnDefs:[{targets:-1,data:null,defaultContent:'<button class="btn btn-sm btn-success addItemBtn">+</button>'}],
              //filter: true,
              //deferRender: true,
              initComplete: function () {
                this.api().columns([4,5,8]).every( function () {
                    var column = this;
                    var select = $('<select class="form-control filterCtrl"><option value="">...</option></select>')
                        .appendTo( $(column.footer()).empty() )
                        .on( 'change', function () {
                            var val = $.fn.dataTable.util.escapeRegex(
                                $(this).val()
                            );
     
                            column
                                .search( val ? '^'+val+'$' : '', true, false )
                                .draw();
                        } );
     
                    column.data().unique().sort().each( function ( d, j ) {
                        select.append( '<option value="'+d+'">'+d+'</option>' )
                    } );
                } );

                $('#example tfoot tr').appendTo('#example thead');
            }
            });

            $('.clear-filters').prependTo('#example_length');

        });
      
        
        $('#example tbody').on( 'click', 'button', function (){
          var data = $('#example').DataTable().row( $(this).parents('tr') ).data();
          console.log(data.itemid);

          $('#modalItemID').val(data.itemid);
          $('#modalItemName').val(data.itemName);
          $('#modalItemDescription').val(data.itemDescription);
          $('#modalItemPackSize').val(data.packSize);
          $('#modalItemUnitSize').val(data.itemSizeFull);
          $('#modalItemFullPrice').val(data.fullPrice);
          $('#modalItemCasesPerPallet').val(data.casesPerPallet);
          $("#basicModal").modal('show');
          /*this.setState({
            modalItemID:data.itemid,
            modalItemName:data.itemName,
            modalItemDescription:data.itemDescription,
            modalItemPackSize:data.packSize,
            modalItemCaseSize:data.itemSizeFull,
            modalItemFullPrice:data.fullPrice,
            showModal: true
          });*/
          //let user = firebase.auth().currentUser;
          //console.log(user.uid);
          //var dateToday = new Date();
          //var quoteTitle = dateToday.getFullYear().toString()+"_"+(dateToday.getMonth() + 1).toString()+"_"+dateToday.getDate().toString()+"_"+dateToday.getHours().toString()+"_"+dateToday.getMinutes().toString()+"_"+dateToday.getSeconds().toString()+"_"+user.uid;
          //var quoteObject = {'quoteID':quoteTitle,'dateTimeStarted':dateToday,'items':{'id':data.itemid,'title':data.itemName}};
          //localStorage.setItem('newquote', JSON.stringify(quoteObject));
          //alert( "Feature Coming Soon " + quoteTitle);
      });

    }

    openModal() {
      this.setState({ showModal: true });
    }
  
    closeModal() {
      this.setState({
        modalItemID:'',
        modalItemName:'',
        modalItemDescription:'',
        modalItemPackSize:'',
        modalItemUnitSize:'',
        modalItemFullPrice:'',
        modalItemCasesPerPallet:'',
        modalItemQty:'0',
        showModal: false
      });

      $("#basicModal").modal("hide");
  }

 

showModalFunction(itemName){
    this.setState({
      modalItemName:itemName,
      showModal: true })
  }


  addItemToQuote=()=>{
    if(localStorage.getItem('newquote') != null)
    {
      var quoteInfo = JSON.parse(localStorage.getItem('newquote'));
      quoteInfo['items'].push({'id':this.state.modalItemID,'title':this.state.modalItemName,"description":this.state.modalItemDescription,"itemAddDesc":'',"unitSize":this.state.modalItemUnitSize,"price":this.state.modalItemFullPrice,"casesperpallet":this.state.modalItemCasesPerPallet,"packsize":this.state.modalItemPackSize,"qty":this.state.modalItemQty,"mutype":"","muvalue":"00","freightcost":"00.00","fullprice":this.state.modalItemFullPrice,"frominventory":false,"inventoryhold":false});
      let val = this.state.cartItems;
      this.setState({cartItems: ++val});
      localStorage.setItem('newquote', JSON.stringify(quoteInfo));

    }
    else{
          let user = firebase.auth().currentUser;
          //console.log(user.uid);
          var dateToday = new Date();
          var quoteTitle = dateToday.getFullYear().toString()+"_"+(dateToday.getMonth() + 1).toString()+"_"+dateToday.getDate().toString()+"_"+dateToday.getHours().toString()+"_"+dateToday.getMinutes().toString()+"_"+dateToday.getSeconds().toString()+"_"+user.uid;
          var quoteObject = {'user':user.uid,'quoteID':quoteTitle,'dateTimeStarted':dateToday,'items':[{'id':this.state.modalItemID,'title':this.state.modalItemName,"description":this.state.modalItemDescription,"itemAddDesc":'',"unitSize":this.state.modalItemUnitSize,"price":this.state.modalItemFullPrice,"casesperpallet":this.state.modalItemCasesPerPallet,"packsize":this.state.modalItemPackSize,"qty":this.state.modalItemQty,"mutype":"","muvalue":"00","freightcost":"00.00","fullprice":this.state.modalItemFullPrice,"frominventory":false,"inventoryhold":false}]};
          this.setState({cartItems: 1});
          localStorage.setItem('newquote', JSON.stringify(quoteObject));
        }
    this.closeModal();
}
    componentWillReceiveProps(newProps) {
      
    }

    onColumnMatch(
      searchText,
      value,
      column,
      row
    ){
      // implement your custom match logic on every cell value
      console.log(searchText + " " +value);
    }

    addContactHandler(event){
      this.context.router.push('/addcompany');
    }

    clearSearchFilters(){
      $('#example').DataTable().search( '' ).columns().search( '' ).draw();
      $('tfoot input').val('');

      $('.filterCtrl').each(function(){
        $(this).val('');
      });

      
    }

    downIncrement=(evt)=>{
      var val = this.state.modalItemQty;
      if(val > 0)
      {
        var newVal = --this.state.modalItemQty;
        this.setState({modalItemQty:newVal});
        console.log(newVal);
      }
    }

    upIncrement=(evt)=>{
      var newVal = ++this.state.modalItemQty;
      const modelItemID = document.getElementById("modalItemID").value;
      const modelItemNam = document.getElementById("modalItemName").value;
      const modelItemDesc = document.getElementById("modalItemDescription").value;
      const modelItemFullPrice = document.getElementById("modalItemFullPrice").value;
      const modelItemCase = document.getElementById("modalItemUnitSize").value;
      const modelItemPack = document.getElementById("modalItemPackSize").value;
      const modelItemCasesPerPallet = document.getElementById("modalItemCasesPerPallet").value;
      //console.log(modelItemNam);
      this.setState({
        modalItemQty:newVal,
        modalItemName:modelItemNam,
        modalItemUnitSize:modelItemCase,
        modalItemDescription:modelItemDesc,
        modalItemFullPrice:modelItemFullPrice,
        modalItemPackSize:modelItemPack,
        modalItemCasesPerPallet:modelItemCasesPerPallet,
        modalItemID:modelItemID,
      });
      console.log(newVal);
    }

    renderCart(){
      if(this.state.cartItems > 0)
      {
        return <a className="nav-link-menu-cart" href="../quoteqa"><FontAwesomeIcon style={{margin:10+"px"}} icon={faShoppingBasket}></FontAwesomeIcon>{this.state.cartItems}</a>;
      }
      else
      {
        return <a className="nav-link-menu-cart" href="../quoteqa"><FontAwesomeIcon style={{margin:10+"px"}} icon={faShoppingBasket}></FontAwesomeIcon></a>;
      }
    }

    myChangeHandler = (event) => {
      let nam = event.target.name;
      let val = event.target.value;
      let err = '';
      //alert(nam+"-"+val);
      if(nam=="isActive" && this.state.isActive == true)
      {
        //this.setState({isActive:false});
        val = false;
        //document.getElementById('isActive').removeAttr('checked');
      }
      else if(nam=="isActive" && this.state.isActive == false){
        val=true;
      }

      if(nam=="confirmPrice" && this.state.confirmPrice == true)
      {
        //this.setState({isActive:false});
        val = false;
        //document.getElementById('isActive').removeAttr('checked');
      }
      else if(nam=="confirmPrice" && this.state.confirmPrice == false){
        val=true;
      }
      if(nam == "modalItemQty"){
        const modelItemID = document.getElementById("modalItemID").value;
        const modelItemNam = document.getElementById("modalItemName").value;
        const modelItemDesc = document.getElementById("modalItemDescription").value;
        const modelItemFullPrice = document.getElementById("modalItemFullPrice").value;
        const modelItemCase = document.getElementById("modalItemUnitSize").value;
        const modelItemPack = document.getElementById("modalItemPackSize").value;
        const modelItemCasesPerPallet = document.getElementById("modalItemCasesPerPallet").value;
        console.log(modelItemNam);
        this.setState({
          modalItemQty:val,
          modalItemName:modelItemNam,
          modalItemUnitSize:modelItemCase,
          modalItemDescription:modelItemDesc,
          modalItemFullPrice:modelItemFullPrice,
          modalItemPackSize:modelItemPack,
          modalItemCasesPerPallet:modelItemCasesPerPallet,
          modalItemID:modelItemID,
        });
      }
      
      this.setState({errormessage: err});
      this.setState({[nam]: val});
    }

    render(){

        const {items,supplier, tblColumns, loading} = this.state;
        const containerStyle={
          maxWidth:1200 +"px",
        };
        const { SearchBar, ClearSearchButton } = Search;
        const { ExportCSVButton } = CSVExport;
        if(items == "undefined")
        {
          return (<div><h1>Loading Item Data Please Wait...</h1></div>)
        }
        return (
          
            <div className="content">
                                <div className="modal fade" id="basicModal" tabindex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true">
                                  <div className="modal-dialog">
                                    <div className="modal-content">
                                      <div className="modal-header">
                                        <h4 className="modal-title" id="myModalLabel">Add Item to Quote</h4>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                          <span aria-hidden="true">&times;</span>
                                        </button>
                                      </div>
                                      <div className="modal-body">
                                        <table>
                                        <tr>
                                            <td></td>
                                            <td><input type="hidden" name="modalItemID" id="modalItemID" value={this.state.modalItemID}></input></td>
                                          </tr>
                                          <tr>
                                            <td><strong>Item Name:</strong></td>
                                            <td><input type="text" name="modalItemName" id="modalItemName" value={this.state.modalItemName} disabled={this.state.downIncrementBtn} onChange={this.myChangeHandler}></input></td>
                                          </tr>
                                          <tr>
                                            <td><strong>Item Description:</strong></td>
                                            <td><input type="text" name="modalItemDescription" id="modalItemDescription" value={this.state.modalItemDescription}  disabled onChange={this.myChangeHandler}></input></td>
                                          </tr>
                                          <tr>
                                            <td><strong>Unit Size:</strong></td>
                                            <td><input type="text" name="modalItemUnitSize" id="modalItemUnitSize" value={this.state.modalItemUnitSize} disabled onChange={this.myChangeHandler}></input></td>
                                          </tr>
                                          <tr>
                                            <td><strong>Pack Size:</strong></td>
                                            <td><input type="text" name="modalItemPackSize" id="modalItemPackSize" value={this.state.modalItemPackSize} disabled onChange={this.myChangeHandler}></input></td>
                                          </tr>
                                          <tr>
                                            <td><strong>Cases per Pallet:</strong></td>
                                            <td><input type="text" name="modalItemCasesPerPallet" id="modalItemCasesPerPallet" value={this.state.modalItemCaseSize} disabled onChange={this.myChangeHandler}></input></td>
                                          </tr>
                                          <tr>
                                            <td><strong>Price:</strong></td>
                                            <td><input type="text" name="modalItemFullPrice" id="modalItemFullPrice" value={this.state.modalItemFullPrice} disabled onChange={this.myChangeHandler}></input></td>
                                          </tr>
                                          <tr>
                                            <td><strong>Quantity Needed:</strong></td>
                                            <td>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                  <button class="btn btn-danger" type="button" id="button-addon1" onClick={e=>this.downIncrement(e)} disabled={this.state.modalItemQty == 0}><FontAwesomeIcon icon={faMinus}></FontAwesomeIcon></button>
                                                </div>
                                                <input type="text" class="form-control" name="modalItemQty" id="modalItemQty" value={this.state.modalItemQty} onChange={this.myChangeHandler}/>
                                                <div class="input-group-append">
                                                  <button class="btn btn-success" type="button" id="button-addon2" onClick={e=>this.upIncrement(e)}><FontAwesomeIcon icon={faPlus}></FontAwesomeIcon></button>
                                                </div>
                                              </div>
                                            </td>
                                            </tr>
                                        </table>
                                        
                                      </div>
                                      <div className="modal-footer" style={{borderTop:1+"px #000"}}>
                                        <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={e=>this.closeModal()}>Close</button>
                                        <button type="button" className="btn btn-primary" onClick={e=>this.addItemToQuote()} disabled={this.state.modalItemQty == 0}>Add to Cart</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                  <Modal show={this.state.showModal} handleClose={e => this.closeModal(e)}>
                                    <div class="modal-content">
                                      <div class="modal-header">
                                        <h5 class="modal-title">Add Item to Quote</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={e=>this.closeModal()}>
                                          <span aria-hidden="true">&times;</span>
                                        </button>
                                      </div>
                                      <div class="modal-body">
                                        <label htmlFor="unit">Item Name:</label>
                                        <span>{this.state.modalItemName}</span>
                                      </div>
                                      <div class="modal-footer">
                                        <button type="button" class="btn btn-success" onClick={e => this.addItemToQuote(e)}>Add Item</button>
                                        <button type="button" class="btn btn-danger" data-dismiss="modal" onClick={e=>this.closeUnitModal()}>Close</button>
                                      </div>
                                    </div>
                                </Modal>
               <nav className="navbar navbar-expand-lg navbar-light bg-red">
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav mr-auto">
                        <li className="nav-item">
                        <a className="nav-link-menu" href="../home">Home</a>
                        </li>
                        <li className="nav-item">
                        <a className="nav-link-menu" href="../suppliersearch">Supplier Search</a>
                        </li>
                        <li className="nav-item">
                        <a className="nav-link-menu" href="../signout">Sign Out</a>
                        </li>
                        </ul>
                        <ul class="navbar-nav">
                        
                        <li class="nav-item">
                          {this.renderCart()}
                        </li>
                        </ul>
                    </div>
                    </nav>
              {loading && <h1>Loading State...</h1>}
                
                <div className="container-lg">
                  <div>
                      <div className="row" style={{marginTop:10+"px"}}>
                        <div className="col-md-6"> <h3>Browse Items from {supplier}</h3></div>
                        <div className="col-md-2"><Link to={{pathname: `/additem/${this.state.entityID}`}}><button className="btn btn-danger" >Add Item</button></Link></div>
                      </div>
                    </div>
                    <div className="clear-filters" style={{float:"left",marginRight:10+"px"}}>
                            <button type="button" className="btn btn-outline-danger right" onClick={e => this.clearSearchFilters()}><FontAwesomeIcon icon={faRedo}></FontAwesomeIcon> Clear Search/Filters</button>
                            </div>
                            <table id="example" className="display">
                                      <thead></thead>
                                      <tbody></tbody>
                                      <tfoot>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                      </tfoot>
                                  </table>
                {/*<ToolkitProvider
                  keyField="itemid"
                  data={ items }
                  columns={ tblColumns }
                  search
                  exportCSV
                >
                  {
                    props => (
                      <div>
                        <div className="row"><div className="col-md-6"> <h3>Search Items from {supplier}:</h3></div></div>
                        <div className="row">
                          <div className="col-md-2"><SearchBar { ...props.searchProps } /></div>
                          <div className="col-md-1"><ClearSearchButton { ...props.searchProps } className="btn btn-light" /></div>
                        
                        <div className="col-md-2"><Link to={{pathname: `/additem/${this.state.entityID}`}}><button className="btn btn-primary" >Add Item</button></Link></div>
                        </div>
                        <div className="row" style={{alignItems:'center',marginTop:10+"px"}}><div className="col-md-2">Found Items: {this.state.items.length}</div><div className="col-md-9"></div><div className="col-md-1"><ExportCSVButton { ...props.csvProps } className="btn-sm btn-info">Export</ExportCSVButton></div></div>{this.state.items.length > 0 && this.state.customers ? null :null }
                        <hr />
                        <BootstrapTable
                          { ...props.baseProps}
                          noDataIndication="No Items Meet Search Requirements"
                          pagination={paginationFactory()}
                          filter={ filterFactory() }
                        />
                      </div>
                    )
                  }
                </ToolkitProvider>*/}
                    </div>
            </div>
            );
    }
}
const condition = authUser => !!authUser;

const SupplierItemsPageForm = compose(
  withAuthorization(condition),
  withFirebase,
)(SupplierItemsPage);

export default withFirebase(SupplierItemsPage);

export {SupplierItemsPageForm};


