import React,{Component} from 'react';
import {withFirebase} from '../Firebase';
import firebase from 'firebase';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

class QuoteRecieverCard extends Component {
    constructor(props) {
        super(props);
     
        this.state = {
          loading:true,
          customers:[],
          selectedCustomerID:this.props.scid,
          warehouses:[],
          selectedWarehouse:'',
          selectedWarehouseID:this.props.sclid,
          selectedCustomer:'',
          selectedCustomerLocations:[],
          selectedCustomerSelectedLocation:this.props.sclid,
          showCard:false,
          showWarehouseSelecter:false,
          showContactLocations:false,
          isWarehouse:false,
        };


    }

    componentDidMount(){
      console.log(this.props.sclid);
      if(this.props.scid !== 'Sharratt Provisions')
      {
        this.unsubscribe = this.props.firebase
        .company(this.props.scid)
        .onSnapshot(snapshot => {
          console.log(snapshot.data());
            this.setState({selectedCustomer:snapshot.data().name,loading:false});

        });
        this.setState({showCard:true,showContactLocations:true});
      }
      else{
        this.setState({showCard:true,isWarehouse:true,showWarehouseSelecter:true});
      }

      this.unsubscribe = this.props.firebase
      .locationsByCompany(this.props.scid)
      .onSnapshot(snapshot => {
        let selectedCustomerLocations = [];
 
        snapshot.forEach(doc =>
          selectedCustomerLocations.push({ ...doc.data(), lid: doc.id }),
        );

        selectedCustomerLocations.sort((a,b) => a.facilityType.localeCompare(b.facilityType));

        this.setState({
          selectedCustomerLocations:selectedCustomerLocations
        });
      });

        this.unsubscribe = this.props.firebase
        .warehouses()
        .onSnapshot(snapshot => {
          let warehouses = [];
   
          snapshot.forEach(doc =>
            warehouses.push({ ...doc.data(), wid: doc.id }),
          );

          this.setState({
            warehouses,
            loading: false,
          });
        });
    }

    myChangeHandler = (event) => {
        //console.log('Chages');
        let nam = event.target.name;
        let val = event.target.value;
        let err = '';
        console.log("myChangeHandler: "+nam + " - "+val);
        if (nam === "selectedCustomer" && val !== "00") {

          if (val === "01") {
            //val = "Sharratt Provisions";
            this.setState({showContactLocations:false});
            this.setState({showWarehouseSelecter:true});
            this.setState({showCard:false});
            this.setState({selectedCustomerID:val});
            const sharrattObject = {
              name: "Sharratt Provisions",
              cid:"Sharratt Provisions"
            };
            const sObj = Object.create(sharrattObject);
            this.setState({selectedCustomer:sObj});
            val = sObj;
          }
          else{
              //console.log("foo");
            this.unsubscribe = this.props.firebase
            .locationsByCompany(val)
            .onSnapshot(snapshot => {
              let selectedCustomerLocations = [];
       
              snapshot.forEach(doc =>
                selectedCustomerLocations.push({ ...doc.data(), lid: doc.id }),
              );
    
              selectedCustomerLocations.sort((a,b) => a.facilityType.localeCompare(b.facilityType));
    
              this.setState({
                selectedCustomerLocations:selectedCustomerLocations
              });
            });
              val = this.filterArrayElementByEdit(val);
              this.setState({showWarehouseSelecter:false});
              this.setState({showContactLocations:true});
          }
          this.setState({selectedCustomerID:val.cid});
            this.setState({selectedCustomer:val.cid});
            this.props.onSelect("selectedCustomer",val.cid,val.name);
          this.setState({showCard:true});
          
        }
        else if(nam === 'selectedCustomerSelectedLocation' && val != '00'){
            this.setState({showContactLocations:true});
            this.setState({selectedCustomerSelectedLocation:val});
            this.props.onSelect("selectedCustomerSelectedLocation",val);
        }
        else if(nam === 'selectedWarehouseID' && val != '00'){
            this.setState({showWarehouseSelecter:true});
            //this.setState({selectedCustomerSelectedLocation:val});
            this.setState({selectedWarehouseID:val});
            this.props.onSelect("selectedWarehouseID",val);
        }
        else{
            this.setState({showWarehouseSelecter:false});
            this.setState({showCard:false});
        }
        this.setState({errormessage: err});
        //this.setState({[nam]: val});
        //this.props.onSelect([nam],val);
        //console.log(nam + " " + val);
        //console.log("Reciever Card: " + nam +"="+val);
        //console.log(val);
      }

      filterArrayElementByEdit(id) {
        return this.state.customers.find((element) => {
          return element.cid === id;
        })
      }

    render(){
        const{isWarehouse,selectedCustomer,customers,warehouses,showCard,showWarehouseSelecter,selectedCustomerLocations,showContactLocations} = this.state;
        return(
            <div className="card">
                <div className="card-header-yl">
                                <span className="btn-text-white required">Recipient</span>
                            </div>
                            <div className="card-body">
                {/*<label htmlFor="selectedCustomer">Recipient:</label>*/}
                <div className="row">
                  <div className="col-md-12">{isWarehouse && "Sharratt Provisions"}{!isWarehouse && selectedCustomer}</div>
                </div>
                {showCard &&
                        <div className="card" style={{marginTop:15+"px"}}>
                            <div className="card-body">
                              <h6 className="card-subtitle mb-2 text-muted required">Receiving Location</h6>
                              <div className="row">
                                  <div className="col-md-12">
                                  {showContactLocations &&
                                        <select id="selectedCustomerSelectedLocation" name="selectedCustomerSelectedLocation" className="form-control" onChange={this.myChangeHandler} value={this.state.selectedCustomerSelectedLocation}>
                                            <option value="00">Select Location...</option>
                                            {selectedCustomerLocations.map(local=>(
                                                <option key={local.lid} value={local.lid}>{local.facilityType}-{local.address1} {local.address2} | {local.city},{local.stateAbbr}  {local.zip}</option>
                                            ))}
                                        </select>}
                                  </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6"></div>
                                  <div className="col-md-6"></div>
                              </div>
                              {showWarehouseSelecter &&
                                <select id="selectedWarehouseID" name="selectedWarehouseID" className="form-control" onChange={this.myChangeHandler} value={this.state.selectedWarehouseID}>
                                    <option value="00">Select Warehouse...</option>
                                    {warehouses.map(wh=>(
                                        <option key={wh.wid} value={wh.wid}>{wh.name}</option>
                                    ))}
                                </select>}
                            </div>
                          </div>}
                          </div>
            </div>
        );
    }
}

export default withFirebase(QuoteRecieverCard);