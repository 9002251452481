import React, { Component,useState } from 'react'
import ChartistGraph from 'react-chartist'
import {Link} from 'react-router-dom'
import { compose } from 'recompose';
import Footer from '../Sidebar/footer';
import { render } from "react-dom";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";


import { withAuthorization } from '../Session';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye,faEyeSlash,faCircle,faClock,faHistory} from '@fortawesome/free-solid-svg-icons';
import {faUser,faUserCircle,faLock} from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Sidebar/sidebar.css'
import { AuthUserContext } from '../Session';
import * as ROLES from '../../constants/roles';
import SelectableCalendar from '../Reminder/calendarview';
import DragDropCalendar from '../Reminder/dragdropcalendarview';
import DrawerMenu from '../Navigation/drawermenu';
import Box from '@mui/material/Box';
import AdminMenu from '../Navigation/admin_menu';

class AltDashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading:true,
      authUser:'',
      customers:[], //supplier,warehouse,customer
      contacts:[],
      reminders:[],
      items:[],
      leads:[],
      prospects:[],
      quotes:[],
      errormessage: '',
      showCalendar:true,
      showTiles:true,
      showInbox:true,
    };
  }


  componentDidMount(){

        //console.log(this.props.firebase.getCurrentUser());

        this.unsubscribe = this.props.firebase
        .customers()
        .onSnapshot(snapshot => {
          let customers = [];
   
          snapshot.forEach(doc =>
            customers.push({ ...doc.data(), cid: doc.id }),
          );

          this.setState({
            customers,
          });
        });

        this.unsubscribe = this.props.firebase
        .leads()
        .onSnapshot(snapshot => {
          let leads = [];
   
          snapshot.forEach(doc =>
            leads.push({ ...doc.data(), cid: doc.id }),
          );

          this.setState({
            leads,
          });
        });

        this.unsubscribe = this.props.firebase
        .prospects()
        .onSnapshot(snapshot => {
          let prospects = [];
   
          snapshot.forEach(doc =>
            prospects.push({ ...doc.data(), cid: doc.id }),
          );

          this.setState({
            prospects,
          });
        });

        this.unsubscribe = this.props.firebase
        .activeQuotes()
        .onSnapshot(snapshot => {
          let quotes = [];
   
          snapshot.forEach(doc =>
            quotes.push({ ...doc.data(), qid: doc.id }),
          );

          this.setState({
            quotes,
          });
        });

        /*this.unsubscribe = this.props.firebase
        .contacts()
        .onSnapshot(snapshot => {
          let contacts = [];
   
          snapshot.forEach(doc =>
            contacts.push({ ...doc.data(), uid: doc.id }),
          );

          this.setState({
            contacts,
          });
        });*/

        Date.prototype.addHours= function(h){
          this.setHours(this.getHours()+h);
          return this;
      }

        this.unsubscribe = this.props.firebase
        .getReminders()
        .onSnapshot(snapshot => {
          let reminders = [];
   
          snapshot.forEach(doc =>
            reminders.push({ 
                title:doc.data().entityName + "-"+doc.data().reminderText,
                entityID:doc.data().entityID,
                start:new Date(doc.data().reminderDate.seconds * 1000), 
                end:new Date(doc.data().reminderDate.seconds * 1000).addHours(.5),
                userID:doc.data().userID,
                id: doc.id
             }),
          );

          this.setState({
            reminders,
            loading:false
          });
        });

        this.unsubscribe = this.props.firebase
        .allItems()
        .onSnapshot(snapshot => {
          let items = [];
   
          snapshot.forEach(doc =>
            items.push({ ...doc.data(), itemid:doc.id}),
          );

          this.setState({
            items,
            loading:false
          });
        });

  }

  render() {
    const theme = {
      spacing: 8,
    }
    
    const {contacts, customers,reminders,leads,prospects,loading,items,quotes} = this.state;
    let dataPie = {
      labels: ["40%", "20%", "40%"],
      series: [40, 20, 40]
    }
    let dataSales = {
      labels: [
        "9:00AM",
        "12:00AM",
        "3:00PM",
        "6:00PM",
        "9:00PM",
        "12:00PM",
        "3:00AM",
        "6:00AM"
      ],
      series: [
        [287, 385, 490, 492, 554, 586, 698, 695],
        [67, 152, 143, 240, 287, 335, 435, 437],
        [23, 113, 67, 108, 190, 239, 307, 308]
      ]
    }
    return (
<AuthUserContext.Consumer>
            {authUser=>(
      <div className="content grey-bg">
        <DrawerMenu/>
        <AdminMenu/>
        <Box component="main" sx={{ flexGrow: 1, p: 3, pl:10 }}>
          <div className="row">
            </div>
          <div className="row">
            <div className="col-md-3">
            <div className="card ">
                <div className="card-header-alt">
                  <h4 className="card-title-alt">Contacts</h4>
                  {/*<p className="card-category">Contacts</p>*/}
                </div>
                <div className="card-body text-center">
              <h1><a href="../search">{customers.length}</a></h1>
                  <hr />
                  <div className="stats">
                  {/*<FontAwesomeIcon icon={faClock}></FontAwesomeIcon> Campaign sent 2 days ago*/}
                                    </div>
                </div>
              </div>
              <br></br>
              <div className="card ">
                <div className="card-header-alt">
                  <h4 className="card-title-alt">Quotes</h4>
                  {/*<p className="card-category">Prospects</p>*/}
                </div>
                <div className="card-body text-center">
                  <h1><a href="../quotes">{quotes.length}</a></h1>
                  <hr />
                  <div className="stats">
                                    </div>
                </div>
          </div>
            </div>
            <div className="col-md-3">
            <div className="card ">
                <div className="card-header-alt">
                  <h4 className="card-title-alt">Leads - Prospects</h4>
                  {/*<p className="card-category">Leads</p>*/}
                </div>
                <div className="card-body text-center">
                  <h1><a href="../search">{leads.length} - {prospects.length}</a></h1>
                  <hr />
                  <div className="stats">
                  
                                    </div>
                </div>
              </div>
              <br></br>
              <div className="card ">
                <div className="card-header-alt">
                  <h4 className="card-title-alt">Items</h4>
                  {/*<p className="card-category">Supplier & Inventory Items</p>*/}
                </div>
                <div className="card-body text-center">
                  <h1><Link to="/suppliersearch">{items.length}</Link></h1>
                  <hr />
                  <div className="stats">
                  
                                    </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
            {/*!loading && <SelectableCalendar auid={authUser.uid} events={reminders}></SelectableCalendar>*/}
            {!loading && <DragDropCalendar auid={authUser.uid} role={authUser.role} events={reminders}></DragDropCalendar>}
            {/*<div className="card ">
                <div className="card-header ">
                  <h4 className="card-title">Prospects</h4>
                  <p className="card-category">Prospects</p>
                </div>
                <div className="card-body text-center">
                  <h1><Link to="/search">{prospects.length}</Link></h1>
                  <hr />
                  <div className="stats">
                                    </div>
                </div>
          </div>*/}

            </div>
          </div>
          {/*<div className="row">

            <div className="col-md-4">
              <div className="card ">
                <div className="card-header ">
                  <h4 className="card-title">Email Statistics</h4>
                  <p className="card-category">Last Campaign Performance</p>
                </div>
                <div className="card-body ">
                  <ChartistGraph data={dataPie} type="Pie" />
                  <div className="legend">
                  <FontAwesomeIcon icon={faCircle} className="text-info"></FontAwesomeIcon> Open
                            <FontAwesomeIcon icon={faCircle}className="text-danger"></FontAwesomeIcon> Bounce
                            <FontAwesomeIcon icon={faCircle} className="text-warning"></FontAwesomeIcon> Unsubscribe
                                    </div>
                  <hr />
                  <div className="stats">
                  <FontAwesomeIcon icon={faClock}></FontAwesomeIcon> Campaign sent 2 days ago
                                    </div>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="card">
                <div className="card-header ">
                  <h4 className="card-title">Users Behavior</h4>
                  <p className="card-category">24 Hours performance</p>
                </div>
                <div className="card-body ">
                  <ChartistGraph data={dataSales} type="Line" />
                </div>
                <div className="card-footer ">
                  <div className="legend">
                    <FontAwesomeIcon icon={faCircle} className="text-info"></FontAwesomeIcon>Open
                    <FontAwesomeIcon icon={faCircle} className="text-danger"></FontAwesomeIcon>Click
                    <FontAwesomeIcon icon={faCircle} className="text-warning"></FontAwesomeIcon>Click Second Time
                </div>
                  <hr />
                  <div className="stats">
                  <FontAwesomeIcon icon={faHistory}></FontAwesomeIcon> Updated 3 minutes ago
                  </div>
                </div>
              </div>
            </div>

    </div>*/}
        </Box>
      </div>
            )}
            </AuthUserContext.Consumer>
    )
  }
}

const condition = authUser => !!authUser;

export default compose(withAuthorization(condition),)(AltDashboard);