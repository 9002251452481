import React, { Component } from 'react';

import * as ROUTES from '../../constants/routes';
import {withFirebase} from '../Firebase';

import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTrashAlt} from '@fortawesome/free-solid-svg-icons';

class FileTableControl extends Component {
    constructor(props){
        super(props);

        this.state = {
            loading:false,
            companyid:this.props.cid,
            filesList:[],
        };
    }

    componentDidMount(){
        console.log('File Table Control On Mount: ' +this.state.companyid);

        this.unsubscribe = this.props.firebase
        .documentsByCompany(this.state.companyid)
        .onSnapshot(snapshot => {
            let filesList = [];

            snapshot.forEach(doc =>
                filesList.push({ docName:doc.data().docName,docUrl:doc.data().docUrl,timestamp:new Date(doc.data().dateUploaded.seconds * 1000).toLocaleDateString(), docid: doc.id }),
              );

            this.setState({
                filesList,
                loading: false,
            });

          //console.log(this.state.location.stateAbbr);
        });
        
    }

    componentWillUnmount(){
        //this.unsubscribe();
    }

    deleteNote(pnid)
    {
        //console.log(pnid);
        this.props.firebase.deactivateDocument(pnid);
    }

    render(
    ){

        const {filesList} = this.state;
        return(
            <div>
                            <div className="row">
                                <div className="col-md-12">
                                <div className="table-responsive">
                        <div className="table-wrapper">
                            <div className="table-title">
                                <div className="row">
                                    <div className="col-sm-5">
                                        <h2>Documents</h2>
                                    </div>
                                    <div className="col-sm-7">
                                    </div>
                                </div>
                            </div>
                            <table className="table table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th>Document</th>
                                        <th>Uploaded Date</th>
                                        <th>Delete</th>						
                                    </tr>
                                </thead>
                                <tbody>
                                {filesList.map(fileDoc=> (
                                   <tr key={fileDoc.docid}>
                                       <td><a href={fileDoc.docUrl} target="_blank">{fileDoc.docName}</a></td>
                                       <td>{fileDoc.timestamp}</td>
                                       <td><button type="button" className="btn btn-sm btn-danger" onClick={() => {
                                if (window.confirm("Delete the item?")) {
                                    let pnID = fileDoc.docid;
                                    let removeToCollection = this.deleteNote.bind(this,fileDoc.docid);
                                    removeToCollection();
                                }
                            }} data-toggle="tooltip" data-placement="right" title="Delete"><FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon></button></td>
                                   </tr>     
                                ))}
                                </tbody>
                                </table>
                                </div>
                                </div>
                                </div>
                            </div>
                            </div>
        );
    }
}
export default withFirebase(FileTableControl);