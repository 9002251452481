import React,{Component} from 'react';
import warehouse from '../../images/coming_sonn.jpeg';
import { faEdit,faPlusCircle,faHome, faMinus,faPlus} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faShoppingBasket,faRedo,faUser,faUserCircle,faLock,faSearch,faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import RecieverCard from '../Cart/recievercard';
import ItemCard from '../Cart/itemcard';
import { AuthUserContext } from '../Session';
import {withFirebase} from '../Firebase';
import firebase from 'firebase';

class Cart extends Component{

    constructor(props) {
        super(props);

        this.state = {
         default:'',
         userID:'',
         quoteID:'',
         dbID:'',
         quoteDate:'',
         items:[],
         freight:'',
         contact:'',
         customers:'',
         totalCost:'00.00',
         loading:true,
         hideRecipient:true,
         note:'',
         status:'',
         clientStatus:'',
         customers:[],
         selectedCustomerID:'',
         selectedCustomerSelectedLocation:'',
         quoteNote:'',
         itemsModel:false,
         activeRequest: true,
        };
      }

      updateItemTotal=(itemTotal)=>{
          console.log("Updating Cart Total "  + parseFloat(itemTotal));
          this.setState({totalCost:itemTotal});
      }

      componentDidMount(){
       if(localStorage.getItem('newquote') != null){
            var quoteInfo = JSON.parse(localStorage.getItem('newquote'));
            console.log(quoteInfo);
            this.setState({
                userID: quoteInfo.user,
                quoteID:quoteInfo.quoteID,
                items:quoteInfo.items,
                quoteDate:quoteInfo.dateTimeStarted,
                totalCost:quoteInfo.items.itemTotalCost,
                loading:false,
            });
            console.log("Total Cost: " + this.state.totalCost);

            /*this.props.firebase.db.collection('quotes').add({
                userID: quoteInfo.user,
                quoteID:quoteInfo.quoteID,
                quoteDate:quoteInfo.dateTimeStarted,
                totalCost:'',
                activeRequest:true,
                customerID:'',
                customerLocationID:'',
                quoteNote:'',
                status:'',
                clientStatus:'',
              });*/

        } 
      }

      componentWillUnmount()
      {
          
      }

      clearCart(){
        localStorage.clear();
        this.props.history.push('/itemsearch');
      }

      async saveCart(){
        const newQuote = await this.props.firebase.quotes().add({
            userID: this.state.userID,
            quoteID:this.state.quoteID,
            quoteDate:this.state.quoteDate,
            totalQuote:'',
            activeRequest:true,
            customerID:this.state.selectedCustomerID,
            customerLocationID:this.state.selectedCustomerSelectedLocation,
            quoteNote:this.state.quoteNote,
            status:this.state.status,
            clientStatus:this.state.clientStatus,
        });

        this.setState({dbID:newQuote.id});
        this.addItems(this.state.items);
      }

      addItems = (itemsList) =>{
        //console.log("Add Location for ID: " + this.state.newAddID);
        console.log(itemsList);
        itemsList.map(itm=>{
            //console.log(itm.id);
            this.props.firebase.db.collection('quotes').doc(this.state.dbID).collection('items').add({
                itemID:itm.id,
                itemQty:itm.qty,
                itemFOBPrice:'',
                itemMUPrice:'',
                freightOrigin:'',
                freightCost:'',
                itemTotalCost:'',
                isActive:1,
                dateAdded:firebase.firestore.Timestamp.now(),
                dateUpdated:firebase.firestore.Timestamp.now(),
              })
        })
        
        this.props.firebase.db.collection('quotes').doc(this.state.dbID).collection('revisions').add({
            customerID:this.state.selectedCustomerID,
            customerLocationID:this.state.selectedCustomerSelectedLocation,
            quoteNote:this.state.quoteNote,
            status:this.state.status,
            clientStatus:this.state.clientStatus,
            dateAdded:firebase.firestore.Timestamp.now(),
        })

        localStorage.clear();

        this.props.history.push('/editquote/'+this.state.dbID);
      }

      openItemsModel(){
        console.log("Open Modal");
      }

      removeItemHandler=(itemID)=>{
          console.log(itemID);
          //this.state.items.map(item=>(console.log(item.id)));
          this.setState({items: this.state.items.filter(function(item){
            if(item.id !== itemID){
                return item;
            }
          })});

          var quoteInfo = JSON.parse(localStorage.getItem('newquote'));
          const currentItems = quoteInfo['items'];
          const updItems = currentItems.filter((item)=> item.id !== itemID);
        quoteInfo['items'] = updItems;
        this.state.cartItems.pop("1");
        localStorage.setItem('newquote', JSON.stringify(quoteInfo));

      }

      myChangeHandler = (event) => {
        //console.log('Chages');
        let nam = event.target.name;
        let val = event.target.value;
        let err = '';
    
        this.setState({errormessage: err});
        this.setState({[nam]: val});
        //console.log(nam + " " + val);
        //console.log(val);
      }
     
      selectReciever=(type,id)=>{
          console.log(type + '--'+id);
          if(type == "selectedCustomer"){
              //console.log("There is a selected Customer");
              if(typeof id == "object")
              {
                this.setState({selectedCustomerID:id.cid});   
              }
              else{
                this.setState({selectedCustomerID:id});   
              }
              //console.log(this.state.selectedCustomerID);
          }
          else{
              this.setState({selectedCustomerSelectedLocation:id});
          }
          //console.log("Select Reciever: " + type + "-"+id);
      }

      render() {
        const {items,quoteDate,quoteID,totalCost} = this.state;
        return (
            <div className="content">
                <nav className="navbar navbar-expand-lg navbar-light bg-red">
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav">
                        <li className="nav-item">
                        <a className="nav-link-menu" href="javascript:history.back()">Back</a>
                        </li>
                        {/*<li className="nav-item">
                        <a className="nav-link-menu" href={"/itemsearch"}>Item Search</a>
                        </li>*/}
                        <li className="nav-item">
                        <button className="btn btn-default text-white" type="button" onClick={e=>this.clearCart()}><FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon></button>
                        </li>
                        </ul>
                    </div>
                    </nav>
                    {/*<div style={{textAlign:'center'}}>
                        <img src={warehouse}/>
                    </div>*/}<div className="container-fluid">
                    <div className="row" style={{marginTop:15+"px"}}>
                        <div className="col-md-4">
                            <div className="card">
                            <div className="card-header-yl">
                                <span className="btn-text-white">Request Info</span>
                            </div>
                            <div className="card-body">
                                <div className="card-text"><strong>Items Count:</strong> {items.length}</div>
                                <div className="card-text"><strong>Quote ID:</strong>{quoteID.slice(-8)}</div>
                                <div className="card-text"><strong>Quote Initiated Date:</strong>{quoteDate}</div>
                            </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                        <div className="card">
                            <div className="card-header-yl">
                                <span className="btn-text-white">Total</span>
                            </div>
                            <div className="card-body">
                                <h5>{totalCost}</h5>
                                <div className="form-group">
                                <label htmlFor="quoteNote">Note:</label>
                                    <textarea name="quoteNote" className="form-control" onChange={this.myChangeHandler}></textarea>
                                </div>
                                <p className="card-text">This quote is good for 30 days.</p>
                            </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                        <div className="card">
                            <div className="card-body">
                            <div className="form-group">
                                <label htmlFor="status">Internal Status:</label>
                                <select id="status" name="status" className="form-control" onChange={this.myChangeHandler}>
                                    <option value="00">Select Status...</option>
                                    <option value="In Progress">In Progress</option>
                                    <option value="Review">Review</option>
                                    <option value="Complete">Complete</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="clientStatus">Client Quote Status:</label>
                                <select id="clientStatus" name="clientStatus" className="form-control" onChange={this.myChangeHandler}>
                                    <option value="00">Select Status...</option>
                                    <option value="Not Sent">Not Sent</option>
                                    <option value="On Hold">On Hold</option>
                                    <option value="Sent">Sent</option>
                                    <option value="Rejected">Rejected</option>
                                    <option value="Partial Accepted">Partial Accepted</option>
                                    <option value="Accepted">Accepted</option>
                                </select>
                            </div>
                                <div className="row">
                                    <div className="col-md-6"><button className="btn btn-danger" onClick={e=>this.clearCart()}>Discard Request</button></div>
                                    <div className="col-md-6"><button className="btn btn-success" onClick={e=>this.saveCart()}>Save Request</button></div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                        <div className="card" style={{height:500+"px"}}>
                        <div className="card-header-yl">
                                <span className="btn-text-white">Items<button className="btn btn-success float-right" onClick={e=>this.openItemsModel()}><FontAwesomeIcon icon={faPlus}></FontAwesomeIcon></button></span>
                            </div>
                        <div className="card-body">
                            <div style={{overflowY:"auto", maxHeight:400+"px"}}>
                        {items.map(item=>(
                            <ItemCard key={item.itemID} item={item} onItemTotalUpdate={this.updateItemTotal} removeItemFromCart={this.removeItemHandler}></ItemCard>

                        ))}
                         {/*}div className="card">
                            <div className="card-body">
                              <h5 className="card-title" style={{color:"#333333"}}>{item.title}</h5>
                              <h6 className="card-subtitle mb-2 text-muted">{item.description}</h6>
                              <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                              <a href="#" className="card-link">Remove</a>
                            </div>
                        </div>*/}
                        </div>
                        </div>
                        </div>
                        </div>
                        <div className="col-md-1">
                            
                        </div>
                        <div className="col-md-5">
                            {/*<label htmlFor="recipient">Recipient:</label>
                            <select id="recipient" name="recipient" className="form-control">
                                <option value="00">Select Recipient...</option>
                                <option value="01">Sharratt Provisions</option>
                        </select>*/}
                        
                        <RecieverCard onSelect={this.selectReciever}></RecieverCard>
                        </div>
                        
                    </div>
                    <div className="row">
                        <div className="col-md-7"></div>
                        <div className="col-md-4">
                       
                        </div>
                    </div>
                    </div>
            </div>
            
        );
      }
    }
    
    
    export default withFirebase(Cart);
    
