import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {compose} from 'recompose';

import {withFirebase} from '../Firebase';
import * as ROUTES from '../../constants/routes';
import './signin.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faUser,faUserCircle,faLock} from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import { PasswordForgotLink } from '../PasswordForgot';

const SignInPage = () =>(
    <div>
        {/*<h1>Sign In</h1>*/}
        <SignInForm/>
        
    </div>
);

const INITIAL_STATE = {
    email:'',
    password:'',
    error:null,
}

class SignInFormBase extends Component{
    constructor(props){
        super(props);

        this.state = {...INITIAL_STATE};
    }

    onSubmit = event =>{
        const {email,password} = this.state;

        this.props.firebase
            .doSignInWithEmailAndPassword(email,password)
            .then(()=> {
                this.setState({...INITIAL_STATE});
                this.props.history.push(ROUTES.ADMIN);
            })
            .catch(error => {
                this.setState({error});
            });

        event.preventDefault();
    };

    onChange = event => {
        this.setState({[event.target.name]:event.target.value});
    };

    render(){
        const {email,password,error} = this.state;

        const isInvalid = password === '' || email === '';

        return(
            <div className="container" style={{marginTop:100+"px"}}>
            <div className="form-box">
              <div className="header-form">
                <h4 className="text-primary text-center"><FontAwesomeIcon icon={faUserCircle} style={{fontSize:"110px"}}></FontAwesomeIcon></h4>
                <div className="image">
                </div>
              </div>
              <div className="body-form">
               <form onSubmit={this.onSubmit}>
                  <div className="input-group mb-3">
       <div className="input-group-prepend">
        <span className="input-group-text"><FontAwesomeIcon icon={faUser}></FontAwesomeIcon></span>
      </div>
      <input type="text" name="email" value={email} onChange={this.onChange} className="form-control" placeholder="Email Address" />
    </div>
     <div className="input-group mb-3">
       <div className="input-group-prepend">
        <span className="input-group-text"><FontAwesomeIcon icon={faLock}></FontAwesomeIcon></span>
      </div>
      <input type="password" name="password" value={password} onChange={this.onChange} className="form-control" placeholder="Password" />
    </div>
     <button type="submit" disabled={isInvalid} className="btn btn-secondary btn-block">LOGIN</button>
     <div className="message">
     {error && <p>{error.message}</p>}
     <div></div>
     </div>
       </form>
       <PasswordForgotLink/>
              </div>
            </div>
           </div>  

            /*<form onSubmit={this.onSubmit}>
                <input
                    name="email"
                    value={email}
                    onChange={this.onChange}
                    type="text"
                    placeholder="Email Address"
                />
                <input
                    name="password"
                    value={password}
                    onChange={this.onChange}
                    type="password"
                    placeholder="Password"
                />

                <button disabled={isInvalid} type="submit">Sign In</button>

                {error && <p>{error.message}</p>}
        </form>*/

        
        );
    }
}

const SignInForm = compose(
    withRouter,
    withFirebase,
)(SignInFormBase);

export default SignInPage;

export {SignInForm};