//ALL INVENTORY ITEMS LIST FOR ALL WAREHOUSES
import React,{Component} from 'react';
import SearchBar from '../Search/SearchBar';
import SearchTable from '../Search/SearchTable';
import { Switch,Route, Link } from 'react-router-dom';

import * as ROUTES from '../../constants/routes';
import { compose } from 'recompose';
import moment from 'moment';
import { withAuthorization } from '../Session';
import {withFirebase} from '../Firebase';
import firebase from 'firebase';
import { AuthUserContext } from '../Session';
import {faRedo,faShoppingBasket,faPlus,faMinus,faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import filterFactory,{textFilter,selectFilter} from 'react-bootstrap-table2-filter';
import { ThemeProvider } from 'styled-components';
import Modal from '../Modal';

class WarehouseList extends Component{

    constructor(props) {
        super(props);

        this.state = {
         loading:true,
         userID:'',
         warehouseItems:[],
         lots:[],
         holds:[],
         txns:[],
         rollUps:[],
         tblColumns:[
            {
                data: null,
                bSortable: false,
                class:'details-control',
                width:"15px",
                defaultContent:'+',
                render: function (data,type,row){
                      return "<button class='btn btn-sm btn-default'>+</button>";            }
              },
            {
              data: "itemID",
              title: "Item ID",
              bSortable: false,
              visible:false
            },
            {
              data: "itemName",
              title: "Item Name",
              sort: true,
              //formatter: (cell, row) => <a href={'/'+row.companyid+'/item/'+row.itemid}> {cell} </a>
            },
            {
              data: "itemDescription",
              title: "Description",
              sort:true
            },
            {
              data: "itemSizeFull",
              title: "Size",
              sort:true
            },
            {
              data: "casesPerPallet",
              title: "CPP",
              sort:true
            },
            {
              data: "avgPrice",
              title: "Avg. Price",
              //defaultContent:"$-",
              render: $.fn.dataTable.render.number( ',', '.', 2, '$' ),
              sort:true,
            },
            {
              data: "orderedQty",
              title: "Ordered",
              sort:true,
            },
            {
              data: "availableQty",
              title: "Qty on Hand",
              //class:'add-inventory',
              sort:true,
              render: function (data,type,row){
                if(row.orderedQty > row.availableQty)
                {
                  //return "<span class='add-inventory' style='cursor:pointer;color:#007bff'>"+row.availableQty+"</span>"; 
                  return `<button class='addLot btn btn-sm btn-info'>${row.availableQty}</button>`;
                }
                else{
                  return row.availableQty;            }
              }
                
            }
            /*,{
              title:"",
              bSortable: false,
              render: function (data,type,row){
                if(row.availableQty > 0)
                {
                  return "<button class='btn btn-sm btn-success add-item'>+</button>"; 
                  
                }
                else{
                  return "<button class='btn btn-sm btn-success add-item' disable>+</button>";       }
              }
            } */
        ],
        showModal:false,
        showInboundModal:false,
        showEditInboundModal:false,
        modalItemID:'',
        modalItemName:'',
        modalItemDescription:'',
        modalItemPackSize:'',
        modalItemUnitSize:'',
        modalItemFullPrice:'',
        modalItemCasesPerPallet:'',
        modalItemQty:'0',
        downIncrementBtn:true,
        cartItems:'',
        modalLogItemDate:'',
        modalLogItemID:'',
        modalLogLotID:'',
        modalLogItemLot:'',
        modalLogItemName:'',
        modalLogItemQty:'',
        modalLogItemAvail:false,
        modalLogItemBestByDate:'',
        modalLogItemNotes:'',
        selectedSupplierID:'',
        showSupplierSelectModal:false,
        canLogLot:true,
        editLot:false,
        confirmDelete:false,
        };
      }

      
      async componentDidMount(){
        this.setState({loading:true});

        if(localStorage.getItem('newquote') != null)
        {
          
          var quoteInfo = JSON.parse(localStorage.getItem('newquote'));
          //console.log("Item Search Cart Exists: "+ quoteInfo['items'].length);
          let cartItems = quoteInfo['items'].length;
          this.setState({cartItems:cartItems});
        }
  
        //console.log("Cart Items: " + this.state.cartItems);

        const inventoryRef = this.props.firebase.db.collection('inventory');
        const inventoryHoldRef = this.props.firebase.db.collection('inventory_holds');
        const inventoryLotsRef = this.props.firebase.db.collection('inventory_lots');
        const inventoryOutRef = this.props.firebase.db.collection('inventory_outgoing');
        const inventorySnapShot = await inventoryRef.get();
        //const inventoryLotsSnapShot = await inventoryLotsRef.get();
        //const inventoryHoldsSnapShot = await inventoryOutRef.get();
        //const inventoryOutsSnapShot = await inventoryHoldRef.get();
        
        if (inventorySnapShot.empty) {
          console.log('No matching documents.');
          return;
        }  
        
        let warehouseItems = [];

        inventorySnapShot.forEach(doc => {
          console.log(doc.id,'=>',doc.data().itemID ,'==>',doc.data());
          warehouseItems.push({ ...doc.data(),itemLink:'<a href="/'+doc.data().supplier+'/item/'+doc.itemID+'">'+doc.data().itemName+'</a>', itemSizeFull:doc.data().itemSizeFull ,casesPerPallet:doc.data().itemCasesPerPallet, fullPrice: doc.data().itemPrice,inventoryid: doc.id,lots:[],txns:[], orders:[]});
        });
        console.log(warehouseItems);
        

        const lots = await this.getLots();
        const txns = await this.getTxns();
        const holds = await this.getHolds();

        this.setState({warehouseItems,lots,holds,txns});


        //ASSOCIATE LOTS/HOLDS/TXNS with INVENTORY ITEMS
        const reducedItems = warehouseItems.reduce(function(m, d){
          //console.log("M - Previous Item: ");
          //console.log(m);
          //console.log("D - Current Item: ");
          console.log(d);
          let aQ = 0; 
          if(!m[d.itemID]){
            console.log("Building List of Unique Warehouse Items: " + d.itemName + " ("+d.itemID +")" );
            
            let lotItems = [];
            if(lots.length > 0)
            {
              lots.map(function(item, i){
                if(item.itemID == d.itemID)
                {
                  //console.log(item);
                  if(item.isAvailable == true)
                  {
                    aQ+=parseInt(item.qty);
                  }
                  lotItems.push(item);
                }
              })
            }
            let txnList = [];
            if(txns.length > 0)
            {
              txns.map(function(txn, t){
                //console.log(txn);
                if(txn.itemID == d.itemID){
                  aQ = aQ - txn.qty;
                txnList.push(txn);}
              })
            }
            if(holds.length > 0)
            {
              holds.map(function(hld, t){
                //console.log(txn);
                if(hld.itemID == d.itemID){
                txnList.push(hld);}
              })
            }
            let ct = d.orderedQty;
            if(d.lots.length !== 0)
            {
              ct = d.lots.length;
            }
            
            let aP = [];
            aP.push(d.fullPrice);
            let orderItems = [];
            orderItems.push(d.orderID);
            console.log(d.fullPrice + " - " + ct + " - " + aP);
            m[d.itemID] = {itemID:d.itemID, orders:orderItems, lots:lotItems, txns:txnList,itemName:d.itemName, itemSizeFull:d.itemSizeFull,casesPerPallet:d.casesPerPallet,itemDescription:d.itemDescription, orderedQty:parseInt(d.orderedQty), availableQty:aQ,fullprice:d.fullPrice,avgPrice:aP, count: 1};
            console.log(m);
            return m;
          }
          console.log("Adding to existing items: " + d.itemName);
          m[d.itemID].orders.push(d.orderID);
          m[d.itemID].avgPrice.push(d.fullPrice);
          m[d.itemID].availableQty += d.lots.length;
          m[d.itemID].orderedQty += parseInt(d.orderedQty);
         
          //console.log(m[d.itemID].lots);
          console.log(m[d.itemID]);

          return m;
       },{});

        this.setState({reducedItems});

          const result = Object.keys(reducedItems).map(function(k){
            const item  = reducedItems[k];
              if(parseInt(item.availableQty) >= 0){
                return {
                    itemID:item.itemID,
                    itemName: item.itemName,
                    lots:item.lots,
                    txns:item.txns,
                    orders:item.orders,
                    itemSizeFull:item.itemSizeFull,
                    casesPerPallet:item.casesPerPallet,
                    itemDescription: item.itemDescription,
                    avgPrice: item.avgPrice.reduce((a,b)=> a + b)/item.avgPrice.length,
                    orderedQty:parseInt(item.orderedQty),
                    availableQty:item.availableQty,
                }
              }
              else{
                //reducedItems.splice(k,1);
              }
          });

            //POPULATE TABLE FOR VISUAL
            $('#example').DataTable({
              data: result,//rollUpItems,
              columns: this.state.tblColumns,
              pageLength: 10,
              columnDefs:[{targets:-1,data:null,defaultContent:'<button class="btn btn-sm btn-success">+</button>'}],
              //filter: true,
               order:[[2,'asc']],
              //deferRender: true,
                    initComplete: function () {
                      this.api().columns([4]).every( function () {
                          var column = this;
                          var select = $('<select class="form-control filterCtrl"><option value="">...</option></select>')
                              .appendTo( $(column.footer()).empty() )
                              .on( 'change', function () {
                                  var val = $.fn.dataTable.util.escapeRegex(
                                      $(this).val()
                                  );
          
                                  column
                                      .search( val ? '^'+val+'$' : '', true, false )
                                      .draw();
                              } );
          
                          column.data().unique().sort().each( function ( d, j ) {
                              select.append( '<option value="'+d+'">'+d+'</option>' )
                          } );
                      } );
        
                      $('#example tfoot tr').appendTo('#example thead');
                    } 
                });
        
                  $('.clear-filters').prependTo('#example_length');
                //});
              
              var self = this;
  
              //console.log(customers.length);
              $('#example tbody').on( 'click', 'button.add-item', function () {
                  var data = $('#example').DataTable().row( $(this).parents('tr') ).data();
                  console.log(data.itemid);
                  //alert( "Feature Coming Soon");
              
                  $('#modalItemID').val(data.itemid);
                  $('#modalItemName').val(data.itemName);
                  $('#modalItemDescription').val(data.itemDescription);
                  $('#modalItemPackSize').val(data.packSize);
                  $('#modalItemUnitSize').val(data.itemSizeFull);
                  $('#modalItemFullPrice').val(data.fullPrice);
                  $('#modalItemCasesPerPallet').val(data.casesPerPallet);
                  $("#basicModal").modal('show');
              } );
  
                $('#example tbody').on( 'click', 'button.addLot', function () {
                //console.log(this);
                //var data = $('#example').DataTable().row( $(this).parents('tr') ).data();
                var tr = $(this).closest('tr');
                var row = $('#example').DataTable().row(tr);
                //console.log(row.data().itemID);
                var data = row.data();
                console.log(data.itemName + "-" + data.itemID);
                self.openLogModal(data.itemID,data.itemName);
            });

            $('#example tbody').on( 'click', 'button.editLot', function (e) {
              //console.log(this);
              //var data = $('#example').DataTable().row( $(this).parents('tr') ).data();
              //var tr = $(this).closest('tr');
              //var row = $('#example').DataTable().row(tr);
              //console.log(row.data().itemID);
              //var data = row.data();
              //console.log(data.itemName + "-" + data.itemID);
              //self.openEditLogModal(data.itemID,data.itemName);
              //alert(e.target.id);
              self.openEditLogModal(e.target.id);
          });
  
              $('#example tbody').on('click', 'td.details-control', function () {
                        //alert( "Feature Coming Soon");
                        var tr = $(this).closest('tr');
                  //var tdi = tr.find("i.fa");
                  var row = $('#example').DataTable().row(tr);
     
                  if (row.child.isShown()) {
                      // This row is already open - close it
                      row.child.hide();
                      tr.removeClass('shown');

                  }
                  else {
                      // Open this row
                      console.log(row.data());
                      row.child(format(row.data())).show();
                      tr.addClass('shown');
                  }
              });
  
              function format(d){
                  console.log(d);
  
                  // `d` is the original data object for the row
                  let lotString='<table cellpadding="5" cellspacing="0" border="0" class="table table-bordered" style="padding-left:50px;font-size:small">' +
                  '<tr>' +
                      '<td><strong>Lot Number</strong></td>' +
                      '<td><strong>Best Buy Date</strong></td>' +
                      '<td><strong>Ordered Qty</strong></td>' +
                      '<td><strong>Avail. Qty</strong></td>' +
                  '</tr>';
                  let txnString = '<table cellpadding="5" cellspacing="0" border="0" class="table table-bordered" style="padding-left:50px;font-size:small">' +
                      '<tr>' +
                          '<td><strong>Quote ID</strong></td>' +
                          '<td><strong>Txn Date</strong></td>' +
                          '<td><strong>Txn Qty</strong></td>' +
                          '<td><strong>From Lot</strong></td>' +
                          '<td><strong>Status</strong></td>' +
                      '</tr>';

                  let ordersString = '<table cellpadding="5" cellspacing="0" border="0" class="table table-bordered" style="padding-left:50px;font-size:small">' +
                  '<tr>' +
                      '<td>Inventory Orders</td>' +
                  '</tr>';

                  if(d.lots != null && d.lots.length >= 1){
                      d.lots.forEach(lt=>{
                        console.log(lt);
                        //console.log(moment(lt.bestBuyDate).format("MM/DD/YYYY") + " "+lt[0].casesInLot);
                        let availQty = 0;
                        if(lt.isAvailable === true)
                        {
                          availQty = lt.qty;
                        }
                        let bbd = "-";
                        console.log("DT Length: "+lt.bestByDate.length);
                        if(lt.bestByDate !== "")
                        {
                          bbd=moment(lt.bestByDate).format("MM/DD/YYYY");

                          if(lt.bestByDate.length <= 7)
                          {
                            console.log("Condition Met "+moment(lt.bestByDate).isValid());
                            bbd = moment(lt.bestByDate,"MM/YYYY",true).format("MM/YYYY");
                          }
                        }
                        

                        lotString += '<tr>' +
                            '<td><button class="editLot btn btn-sm btn-link" id='+lt.recordID+'>'+lt.lotNumber+'</button></td>' +
                            '<td>'+bbd+'</td>' +
                            '<td>'+lt.qty+'</td>' +
                            '<td>'+availQty+'</td>' +
                        '</tr>';
                      });
                  }
                  else{
                        lotString += '<tr>' +
                        '<td>&nbsp;</td>' +
                        '<td>&nbsp;</td>' +
                        '<td>&nbsp;</td>' +
                        '<td>&nbsp;</td>' +
                        '<td>&nbsp;</td>' +
                        '</tr>';
                  }
  
                  lotString +="</table>";
                  
  
                  if(d.txns != null && d.txns.length >= 1){
                      d.txns.forEach(tx=>{
                        //console.log(tx);
                        //console.log(tx[0]);
                            txnString += '<tr>' +
                            '<td>'+tx.quoteID+'</td>' +
                            '<td>'+moment(tx.txnDate).format("MM/DD/YYYY")+'</td>' +
                            '<td>'+tx.txnQty+'</td>' +
                            '<td>'+tx.fromLot+'</td>' +
                            '<td>'+tx.type+'</td>' +
                        '</tr>';
                      })
                  }
                  else{
                    d.txns.forEach(tx=>{
                        txnString += '<tr>' +
                        '<td>&nbsp;</td>' +
                        '<td>&nbsp;</td>' +
                        '<td>&nbsp;</td>' +
                        '<td>&nbsp;</td>' +
                        '<td>&nbsp;</td>' +
                    '</tr>';
                    });
                  }
                  txnString +="</table>";

                  
                  if(d.orders != null && d.orders.length >= 1){
                    d.orders.forEach(o=>{
                      //console.log(tx);
                      //console.log(tx[0]);
                      ordersString += '<tr>' +
                          '<td><a href="../inventory/order/'+o+'" target="_blank">LINK</td>' +
                      '</tr>';
                    })
                    }
                ordersString +="</table>";


                  if(d.txns.length == 0)
                  {
                    txnString = "NO TRANSACTIONS";
                  }
                  if(d.lots.length == 0)
                  {
                    lotString = "NO LOTS";
                  }
                  return '<table cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;font-size:small">' +
                      '<tr>' +
                          '<td>'+lotString+'</td>' +
                          '<td>&nbsp</td>' +
                          '<td>'+txnString+'</td>' +
                      '</tr><tr></td>' +
                        ordersString+'</td></tr>'+
                  '</table>';  


             }
      }

      clearSearchFilters(){
        $('#example').DataTable().search( '' ).columns().search( '' ).draw();
        $('tfoot input').val('');
  
        $('.filterCtrl').each(function(){
          $(this).val('');
        });
  
        
      }

      async getLots(){
        let warehouseLots = [];
        let invLotCollection = await this.props.firebase.db.collection("inventory_lots").get().then(
          function(lotSnapshot){
            if(lotSnapshot.empty)
            {
              console.log("NO LOTS");
            }
            else{
              lotSnapshot.forEach(lotDoc=>{
                //console.log("OUTGOING: "+doc.id + " " + outDoc.id);
                warehouseLots.push({...lotDoc.data(), recordID:lotDoc.id});
              });
            }
          });
          return warehouseLots;
      }

      async getTxns(){
        let warehouseTxns = [];
        let invOutCollection = this.props.firebase.db.collection("inventory_outgoing").get().then(
          function(outSnapshot){
            if(outSnapshot.empty)
            {
              console.log("NO OUTGOING");
            }
            else{
              outSnapshot.forEach(outDoc=>{
                //console.log("OUTGOING: "+doc.id + " " + outDoc.id);
                warehouseTxns.push({...outDoc.data(), recordID:outDoc.id, type:"outgoing"});
              });
            }
          });
          return warehouseTxns;
      }

      async getHolds(){
        let warehouseTxns = [];
        let invHoldCollection = await this.props.firebase.db.collection("inventory_holds").get().then(
          function(holdSnapshot){
            holdSnapshot.forEach(holdDoc=>{
              //console.log("HOLDS: " + doc.id + " " + holdDoc.id);
              warehouseTxns.push({...holdDoc.data(),type:"hold",recordID:holdDoc.id});
            });
            
          });
          return warehouseTxns;
      }

      deleteLot(lot_id)
      {
          //console.log(pnid);
          this.props.firebase.deactivateLot(lot_id);
      }

      openLogModal(id,name)
      {
        this.setState({showInboundModal:true, modalLogItemName:name, modalLogItemID:id});
        console.log(id + " " + name);
      }

      openEditLogModal(lotID)
      {
        let selLot = this.state.lots.filter(record => record.recordID ==lotID);
        let name = selLot[0].itemName;
        let id = selLot[0].itemID;
        let qty = selLot[0].qty;
        let bbd = selLot[0].bestByDate;
        let ad = selLot[0].dateRecieved;
        let nts = selLot[0].notes;
        let avail = selLot[0].isAvailable;
        let ln = selLot[0].lotNumber;
        //console.log(name + ' ' + id);
        this.setState({showEditInboundModal:true, editLot:true, modalLogLotID:lotID, modalLogItemName:name, modalLogItemID:id, modalLogItemBestByDate:bbd, modalLogItemDate:ad,modalLogItemNotes:nts,modalLogItemQty:qty,modalLogItemAvail:avail,modalLogItemLot:ln});
        //console.log(id + " " + name);
      }

      modalFocus(x){
        console.log(x);
      }

      closeEditLoggerModal(){
        this.setState({
          modalLogItemDate:'',
          modalLogItemLot:'',
          modalLogItemName:'',
          modalLogItemQty:'',
          modalLogItemID:'',
          modalLogLotID:'',
          modalLogItemNotes:'',
          modalLogItemBestByDate:'',
          modalLogItemAvail:false,
          showEditInboundModal: false
        });
  
        //$("#inventoryModal").modal("hide");
      }

      closeLoggerModal(){
        this.setState({
          modalLogItemDate:'',
          modalLogItemLot:'',
          modalLogItemName:'',
          modalLogItemQty:'',
          modalLogItemID:'',
          modalLogItemNotes:'',
          modalLogItemBestByDate:'',
          modalLogItemAvail:false,
          showInboundModal: false
        });
  
        //$("#inventoryModal").modal("hide");
      }

      openModal() {
        this.setState({ showModal: true });
      }
    
      closeModal() {
        this.setState({
          modalItemID:'',
          modalItemName:'',
          modalItemDescription:'',
          modalItemPackSize:'',
          modalItemUnitSize:'',
          modalItemFullPrice:'',
          modalItemCasesPerPallet:'',
          modalItemQty:'0',
          showModal: false
        });
  
        $("#basicModal").modal("hide");
    }

    closeSupplierModal=()=> {
      console.log("close");
    }
  
    showModalFunction(itemName){
      this.setState({
        modalItemName:itemName,
        showModal: true })
    }
  
  
    addItemToQuote=()=>{
      if(localStorage.getItem('newquote') != null)
      {
        var quoteInfo = JSON.parse(localStorage.getItem('newquote'));
        quoteInfo['items'].push({'id':this.state.modalItemID,'title':this.state.modalItemName,"description":this.state.modalItemDescription,"unitSize":this.state.modalItemUnitSize,"price":this.state.modalItemFullPrice,"casesperpallet":this.state.modalItemCasesPerPallet,"packsize":this.state.modalItemPackSize,"qty":this.state.modalItemQty,"mutype":"","muvalue":"00","freightcost":"00.00","fullprice":this.state.modalItemFullPrice,"frominventory":false,"inventoryhold":false});
        let val = this.state.cartItems;
        this.setState({cartItems: ++val});
        localStorage.setItem('newquote', JSON.stringify(quoteInfo));
  
      }
      else{
            let user = firebase.auth().currentUser;
            //console.log(user.uid);
            var dateToday = new Date();
            var quoteTitle = dateToday.getFullYear().toString()+"_"+(dateToday.getMonth() + 1).toString()+"_"+dateToday.getDate().toString()+"_"+dateToday.getHours().toString()+"_"+dateToday.getMinutes().toString()+"_"+dateToday.getSeconds().toString()+"_"+user.uid;
            var quoteObject = {'user':user.uid,'quoteID':quoteTitle,'dateTimeStarted':dateToday,'items':[{'id':this.state.modalItemID,'title':this.state.modalItemName,"description":this.state.modalItemDescription,"unitSize":this.state.modalItemUnitSize,"price":this.state.modalItemFullPrice,"casesperpallet":this.state.modalItemCasesPerPallet,"packsize":this.state.modalItemPackSize,"qty":this.state.modalItemQty,"mutype":"","muvalue":"00","freightcost":"00.00","fullprice":this.state.modalItemFullPrice,"frominventory":false,"inventoryhold":false}]};
            this.setState({cartItems: 1});
            localStorage.setItem('newquote', JSON.stringify(quoteObject));
          }
      this.closeModal();
  }
  downIncrement=(evt)=>{
    var val = this.state.modalItemQty;
    if(val > 0)
    {
      var newVal = --this.state.modalItemQty;
      this.setState({modalItemQty:newVal});
      console.log(newVal);
    }
  }
  
  upIncrement=(evt)=>{
    var newVal = ++this.state.modalItemQty;
    const modelItemID = document.getElementById("modalItemID").value;
    const modelItemNam = document.getElementById("modalItemName").value;
    const modelItemDesc = document.getElementById("modalItemDescription").value;
    const modelItemFullPrice = document.getElementById("modalItemFullPrice").value;
    const modelItemCase = document.getElementById("modalItemUnitSize").value;
    const modelItemPack = document.getElementById("modalItemPackSize").value;
    const modelItemCasesPerPallet = document.getElementById("modalItemCasesPerPallet").value;
    //console.log(modelItemNam);
    this.setState({
      modalItemQty:newVal,
      modalItemName:modelItemNam,
      modalItemUnitSize:modelItemCase,
      modalItemDescription:modelItemDesc,
      modalItemFullPrice:modelItemFullPrice,
      modalItemPackSize:modelItemPack,
      modalItemCasesPerPallet:modelItemCasesPerPallet,
      modalItemID:modelItemID,
    });
    console.log(newVal);
  }
      onColumnMatch(
        searchText,
        value,
        column,
        row
      ){
        // implement your custom match logic on every cell value
        console.log(searchText + " " +value);
      }
  
      addContactHandler(event){
        this.context.router.push('/addcompany');
      }
      renderCart(){
        if(this.state.cartItems > 0)
        {
          return <a className="nav-link-menu-cart" href="../quote"><FontAwesomeIcon style={{margin:10+"px"}} icon={faShoppingBasket}></FontAwesomeIcon>{this.state.cartItems}</a>;
        }
        else
        {
          return <a className="nav-link-menu-cart" href="../quote"><FontAwesomeIcon style={{margin:10+"px"}} icon={faShoppingBasket}></FontAwesomeIcon></a>;
        }
    }
  
    myChangeHandler = (event) => {
      let nam = event.target.name;
      let val = event.target.value;
      let err = '';
      //alert(nam+"-"+val);
      if(nam=="isActive" && this.state.isActive == true)
      {
        //this.setState({isActive:false});
        val = false;
        //document.getElementById('isActive').removeAttr('checked');
      }
      else if(nam=="isActive" && this.state.isActive == false){
        val=true;
      }
      if(nam=="modalLogItemAvail" && this.state.modalLogItemAvail == true)
      {
        //this.setState({isActive:false});
        val = false;
        //document.getElementById('isActive').removeAttr('checked');
      }
      else if(nam=="modalLogItemAvail" && this.state.modalLogItemAvail == false){
        val=true;
      }
  
      if(nam=="confirmPrice" && this.state.confirmPrice == true)
      {
        //this.setState({isActive:false});
        val = false;
        //document.getElementById('isActive').removeAttr('checked');
      }
      else if(nam=="confirmPrice" && this.state.confirmPrice == false){
        val=true;
      }

      if(nam=="modalLogItemLot" && val !== "")
      {
        console.log("CAN LOG LOT");
        this.setState({canLogLot:false,editLot:true});
      }
      
      this.setState({errormessage: err});
      this.setState({[nam]: val});
    }

    /*renderCart(){
        if(this.state.cartItems > 0)
        {
          return <a className="nav-link-menu-cart" href="../quote"><FontAwesomeIcon style={{margin:10+"px"}} icon={faShoppingBasket}></FontAwesomeIcon>{this.state.cartItems}</a>;
        }
        else
        {
          return <a className="nav-link-menu-cart" href="../quote"><FontAwesomeIcon style={{margin:10+"px"}} icon={faShoppingBasket}></FontAwesomeIcon></a>;
        }
    }*/

    deleteLotInventory=(lotID, mode)=>{
      if(mode == "1")
      {
        this.setState({confirmDelete:true});
      }
      else{
        const removeLot = this.props.firebase.db.collection(`inventory_lots`).doc(lotID).delete().then(function(){window.location.reload();});

        this.setState({
          modalLogLotID:'',
          modalLogItemDate:'',
          modalLogItemLot:'',
          modalLogItemName:'',
          modalLogItemBestByDate:'',
          modalLogItemDate:'',
          modalLogItemAvail:'',
          modalLogItemQty:'',
          modalLogItemID:'',
          modalLogItemNotes:'',
          showInboundModal: false
        });
      }
    }

      addLogItemToInventory=(event)=>
      {
        //console.log(event);

        const addLot = this.props.firebase.db.collection(`inventory_lots`).add({
          dateLogged:firebase.firestore.Timestamp.now(),
          dateRecieved:this.state.modalLogItemDate,
          itemID:this.state.modalLogItemID,
          itemName:this.state.modalLogItemName,
          notes: this.state.modalLogItemNotes,
          isAvailable:this.state.modalLogItemAvail,
          bestByDate:this.state.modalLogItemBestByDate,
          lotNumber:this.state.modalLogItemLot,
          qty:this.state.modalLogItemQty
        }).then(function(){
          window.location.reload();
        });

        /*const updateInventory = this.props.firebase.db.collection(`inventory`).doc(this.state.modalLogItemID).set({
          //nothing at this time
        })*/

        this.setState({
          modalLogLotID:'',
          modalLogItemDate:'',
          modalLogItemLot:'',
          modalLogItemName:'',
          modalLogItemQty:'',
          modalLogItemBestByDate:'',
          modalLogItemDate:'',
          modalLogItemAvail:'',
          modalLogItemID:'',
          modalLogItemNotes:'',
          showInboundModal: false
        });
  
        //$("#inventoryModal").modal("hide");
        this.forceUpdate();
        //this.props.history.push('/home');

      }

      updateLot=(event)=>
      {
        //console.log(event);

        const editLot = this.props.firebase.db.collection(`inventory_lots`).doc(this.state.modalLogLotID).update({
          dateLogged:firebase.firestore.Timestamp.now(),
          dateRecieved:this.state.modalLogItemDate,
          itemID:this.state.modalLogItemID,
          itemName:this.state.modalLogItemName,
          notes: this.state.modalLogItemNotes,
          isAvailable:this.state.modalLogItemAvail,
          bestByDate:this.state.modalLogItemBestByDate, 
          lotNumber:this.state.modalLogItemLot,
          qty:this.state.modalLogItemQty
        }).then(function(){
          window.location.reload();
        });

        /*const updateInventory = this.props.firebase.db.collection(`inventory`).doc(this.state.modalLogItemID).set({
          //nothing at this time
        })*/

        this.setState({
          modalDocID:'',
          modalLogLotID:'',
          modalLogItemDate:'',
          modalLogItemLot:'',
          modalLogItemName:'',
          modalLogItemQty:'',
          modalLogItemBestByDate:'',
          modalLogItemDate:'',
          modalLogItemAvail:'',
          modalLogItemID:'',
          modalLogItemNotes:'',
          showInboundModal: false
        });
  
        //$("#inventoryModal").modal("hide");
        this.forceUpdate();
        //this.props.history.push('/home');

      }


      render() {
        const { ExportCSVButton } = CSVExport;
        const {warehouseItems, tblColumns, loading,isStillLoading,confirmDelete,canLogLot,editLot} = this.state;
        const containerStyle={
          maxWidth:1200 +"px",
        };
        const { SearchBar, ClearSearchButton } = Search;
        
        if(warehouseItems == "undefined")
        {
          return (<div><h1>Loading Warehouse Data Please Wait...</h1></div>)
        }
        return (
            <div className="content">
                   
                                {/*<div className="modal fade" id="inventoryModal" tabindex="-1" role="dialog" aria-labelledby="inventoryModal" aria-hidden="true">*/}
                                <Modal show={this.state.showEditInboundModal} handleClose={e=>this.closeEditLoggerModal(e)}>
                                <div class="modal-dialog">
                                <div class="modal-content">
                                      <div class="modal-header">
                                        <h5 class="modal-title">Edit Incoming Inventory</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={e=>this.closeEditLoggerModal()}>
                                          <span aria-hidden="true">&times;</span>
                                        </button>
                                      </div>
                                      <div class="modal-body">
                                      <table>
                                        <tr>
                                            <td><input type="hidden" name="modalLogLotID" id="modalLogLotID" value={this.state.modalLogLotID}></input></td>
                                            <td><input type="hidden" name="modalLogItemID" id="modalLogItemID" value={this.state.modalLogItemID}></input></td>
                                          </tr>
                                          <tr>
                                            <td><strong>Item Name:</strong></td>
                                            <td><input type="text" name="modalLogItemName" id="modalLogItemName" value={this.state.modalLogItemName} readOnly></input></td>
                                          </tr>
                                          <tr>
                                            <td><strong>Qty Recieved:</strong></td>
                                            <td><input type="text" name="modalLogItemQty" id="modalLogItemQty" value={this.state.modalLogItemQty} onChange={this.myChangeHandler}></input></td>
                                          </tr>
                                          <tr>
                                            <td><strong>Lot Number:</strong></td>
                                            <td><input type="text" name="modalLogItemLot" id="modalLogItemLot" value={this.state.modalLogItemLot} onChange={this.myChangeHandler}></input></td>
                                          </tr>
                                          <tr>
                                            <td><strong>Best By Date:</strong></td>
                                            <td><input type="text" name="modalLogItemBestByDate" id="modalLogItemBestByDate" value={this.state.modalLogItemBestByDate} onChange={this.myChangeHandler}></input></td>
                                          </tr>
                                          <tr>
                                            <td><strong>Date Recieved:</strong></td>
                                            <td><input type="text" name="modalLogItemDate" id="modalLogItemDate" value={this.state.modalLogItemDate} onChange={this.myChangeHandler}></input></td>
                                          </tr>
                                          <tr>
                                            <td><strong>Notes:</strong></td>
                                            <td><input type="text" name="modalLogItemNotes" id="modalLogItemNotes" value={this.state.modalLogItemNotes} onChange={this.myChangeHandler}></input></td>
                                          </tr>
                                          <tr>
                                            <td><strong>Is available for sales:</strong></td>
                                            <td>
                                              {this.state.modalLogItemAvail &&
                                                  <input type="checkbox" name="modalLogItemAvail" id="modalLogItemAvail" value={this.state.modalLogItemAvail} checked onChange={this.myChangeHandler}></input>
                                                }
                                                { this.state.modalLogItemAvail == false && 
                                                  <input type="checkbox" name="modalLogItemAvail" id="modalLogItemAvail" value={this.state.modalLogItemAvail} onChange={this.myChangeHandler}></input>
                                                }
                                              
                                              </td>
                                          </tr>
                                          </table>
                                      </div>
                                      <div class="modal-footer">
                                        <div class="btn-group" role="group" aria-label="Basic example">
                                          {editLot && 
                                          <button type="button" class="btn btn-success btn-sm" onClick={e => this.updateLot(e)}>Update Lot</button>}
                                          <button type="button" class="btn btn-danger btn-sm" onClick={e=>this.deleteLotInventory(this.state.modalLogLotID,1)}>Delete Lot</button>
                                          <button type="button" class="btn btn-info btn-sm" data-dismiss="modal" onClick={e=>this.closeEditLoggerModal()}>Close</button>
                                        </div>
                                      </div>
                                      {confirmDelete && 
                                      <div className="d-flex justify-content-center flex-nowrap">
                                        <label>Confirm Delete ?</label>
                                        <div class="btn-group" role="group" aria-label="Basic example">
                                        <button type="button" class="btn btn-info btn-sm" onClick={e => this.cancelLotDelete(e)}>Cancel</button>
                                        <button type="button" class="btn btn-danger btn-sm" onClick={e=>this.deleteLotInventory(this.state.modalLogLotID,2)}>Delete Lot</button>
                                        </div>
                                      </div>}
                                    </div>
                                    </div>
                                {/*</div>*/}
                                </Modal>
                                <Modal show={this.state.showInboundModal} handleClose={e=>this.closeLoggerModal(e)}>
                                <div class="modal-dialog">
                                <div class="modal-content">
                                      <div class="modal-header">
                                        <h5 class="modal-title">Log Inventory</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={e=>this.closeLoggerModal()}>
                                          <span aria-hidden="true">&times;</span>
                                        </button>
                                      </div>
                                      <div class="modal-body">
                                      <table>
                                        <tr>
                                            <td></td>
                                            <td><input type="hidden" name="modalLogItemID" id="modalLogItemID" value={this.state.modalLogItemID}></input></td>
                                          </tr>
                                          <tr>
                                            <td><strong>Item Name:</strong></td>
                                            <td><input type="text" name="modalLogItemName" id="modalLogItemName" value={this.state.modalLogItemName} readOnly></input></td>
                                          </tr>
                                          <tr>
                                            <td><strong>Qty Recieved:</strong></td>
                                            <td><input type="text" name="modalLogItemQty" id="modalLogItemQty" value={this.state.modalLogItemQty} onChange={this.myChangeHandler}></input></td>
                                          </tr>
                                          <tr>
                                            <td><strong>Lot Number:</strong></td>
                                            <td><input type="text" name="modalLogItemLot" id="modalLogItemLot" value={this.state.modalLogItemLot} onChange={this.myChangeHandler}></input></td>
                                          </tr>
                                          <tr>
                                            <td><strong>Best By Date:</strong></td>
                                            <td><input type="text" name="modalLogItemBestByDate" id="modalLogItemBestByDate" value={this.state.modalLogItemBestByDate} onChange={this.myChangeHandler}></input></td>
                                          </tr>
                                          <tr>
                                            <td><strong>Date Recieved:</strong></td>
                                            <td><input type="text" name="modalLogItemDate" id="modalLogItemDate" value={this.state.modalLogItemDate} onChange={this.myChangeHandler}></input></td>
                                          </tr>
                                          <tr>
                                            <td><strong>Notes:</strong></td>
                                            <td><input type="text" name="modalLogItemNotes" id="modalLogItemNotes" value={this.state.modalLogItemNotes} onChange={this.myChangeHandler}></input></td>
                                          </tr>
                                          <tr>
                                            <td><strong>Is available for sales:</strong></td>
                                            <td><input type="checkbox" name="modalLogItemAvail" id="modalLogItemAvail" value={this.state.modalLogItemAvail} onChange={this.myChangeHandler}></input></td>
                                          </tr>
                                          </table>
                                      </div>
                                      <div class="modal-footer">
                                        <button type="button" class="btn btn-success" onClick={e => this.addLogItemToInventory(e)} disabled={this.state.canLogLot}>Log Inventory</button>
                                        <button type="button" class="btn btn-danger" data-dismiss="modal" onClick={e=>this.closeLoggerModal()}>Close</button>
                                      </div>
                                    </div>
                                    </div>
                                {/*</div>*/}
                                </Modal>
                                <Modal show={this.state.showSupplierSelectModal} handleClose={e=>this.closeSupplierModal(e)}>
                                <div class="modal-dialog">
                                <div class="modal-content">
                                      <div class="modal-header">
                                        <h5 class="modal-title">Select Supplier</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={e=>this.closeLoggerModal()}>
                                          <span aria-hidden="true">&times;</span>
                                        </button>
                                      </div>
                                      <div class="modal-body">
                                      <table>
                                          <tr>
                                            <td>
                                              <input type="text" name="modalLogItemLot" id="modalLogItemLot" value={this.state.modalLogItemLot} onChange={this.myChangeHandler}></input>
                                            </td>
                                          </tr>
                                          </table>
                                      </div>
                                      <div class="modal-footer">
                                        <button type="button" class="btn btn-success" onClick={e => this.addLogItemToInventory(e)}>Log Inventory</button>
                                        <button type="button" class="btn btn-danger" data-dismiss="modal" onClick={e=>this.closeLoggerModal()}>Close</button>
                                      </div>
                                    </div>
                                    </div>
                                </Modal>
                <nav className="navbar navbar-expand-lg navbar-light bg-green">
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav mr-auto">
                        <li className="nav-item">
                        <a className="nav-link-menu" href={"/home"}>Home</a>
                        </li>
                        </ul>
                        <ul class="navbar-nav">
                        <li class="nav-item">
                          {this.renderCart()}
                        </li>
                        </ul>
                    </div>
                    </nav>
                    {isStillLoading && <h1>Loading State...</h1>}
                <div className="container-lg">
                <div className="row" style={{marginTop:10+"px"}}><div className="col-md-4"> <h3>Search Items in Inventory</h3></div><div className="col-md-5"><Link to={{pathname: `${ROUTES.ORDER}`}}><button className="btn btn-success" >Add Inventory Item</button></Link></div></div>
                {/*<div className="row" style={{marginTop:10+"px"}}><div className="col-md-5"> <h3>Search Items in Inventory</h3></div></div>*/}
                <div className="clear-filters" style={{float:"left",marginRight:10+"px"}}>
                            <button type="button" className="btn btn-outline-danger right" onClick={e => this.clearSearchFilters()}><FontAwesomeIcon icon={faRedo}></FontAwesomeIcon> Clear Search/Filters</button>
                            </div>
                            <table id="example" className="display">
                                      <thead>
                                      </thead>
                                      <tbody></tbody>
                                      <tfoot>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            {/*<th></th>*/}
                                            {/*<th></th>*/}
                                        </tr>
                                      </tfoot>
                                  </table>
                </div>
            </div>
            
        );
      }
    }
    const condition = authUser => !!authUser;

const WarehouseListForm = compose(
  withAuthorization(condition),
  withFirebase,
)(WarehouseList);

export default withFirebase(WarehouseList);

export {WarehouseListForm};
    
    
