import React,{Component} from 'react';
import { Switch,Route, Link } from 'react-router-dom';
import { compose } from 'recompose';
import * as ROUTES from '../../constants/routes';
import { AuthUserContext } from '../Session';
import { withAuthorization } from '../Session';
import {withFirebase} from '../Firebase';
import '../User/userstyles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit,faTrashAlt,faUserEdit,faUserPlus,faFileExcel, faEraser, faPlus } from '@fortawesome/free-solid-svg-icons';
//import * as ROLES from '../../contants/roles';
import { CSVLink } from "react-csv";
import Modal from '../Modal';
import { thatReturnsFalse } from 'react-select-us-states';
import { firestore } from 'firebase-admin';
import firebase from 'firebase';


const headers = [
    { label: "First Name", key: "fname" },
    { label: "Last Name", key: "lname" },
    { label: "Email", key: "email" },
    { label: "Phone", key: "phone" },
    { label: "Role", key: "role" }
  ];

const DomainPage = () =>(
    <div className="container-fluid">
        <Switch>
            <Route exact path={ROUTES.USERS} component={UserList}/>
        </Switch>
    </div>
);

class DomainListBase extends Component {
    constructor(props){
        super(props);

        this.state = {
            prodLoading:true,
            matsLoading:true,
            unitsLoading:true,
            productTypes:[],
            materialTypes:[],
            unitsList:[],
            statusItems:[],
            clientStatusItems:[],
            matModal:false,
            prodModal:false,
            unitModal:false,
            statusModal:false,
            cStatusModal:false,
            editMatModal:false,
            editProdModal:false,
            editUnitModal:false,
            editStatusModal:false,
            editCStatusModal:false,
            status:'',
            clientstatus:'',
            focusID:'',
            focusDesc:'',
            focusType:'',
            unit:'',
            material:'',
            product:'',
            errormessage:'',
            clientStatusInput:'',
            quoteStatusInput:'',
            mode:'save',
            matID:'',
            unitID:'',
            pTypeID:'',
            qStatusID:'',
            cStatusID:'',
        };
    }
    
    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        let err = '';
        if (val == "" ) {
            alert(<strong>Your must supply a value</strong>);
            return;
        }
        this.setState({errormessage: err});
        this.setState({[nam]: val});
        //console.log(nam + " " + val);
      }
//#region - Unit
    addUnit=()=>{
        this.props.firebase.db.collection('itemunits').add({
            unitDesc:this.state.unit,
            isActive:1,
            dateAdded:firebase.firestore.Timestamp.now(),
        });

        this.closeUnitModal();
    }

    deleteUnit(uid)
    {
        //console.log(pnid);
        this.props.firebase.deactivateUnit(uid);
    }

   updateUnit=()=>{
      this.props.firebase.db.collection('itemunits').doc(this.state.unitID).update({
        unitDesc:this.state.unit,
        dateUpdated:firebase.firestore.Timestamp.now()
      });

      this.closeEUnitModal();
    }

    openUnitModal() {
      this.setState({ unitModal: true });
    }
  
    closeUnitModal() {
      this.setState({
        unit: "",
        mode:'save',
        unitModal: false
      });
  }

  openEditUnitModal=(uid,unitItem) =>{
    console.log(uid + " " + unitItem);
    if(uid==null){
      this.setState({ editUnitModal: true });
    }
    else{
      this.setState({ mode:'edit',unitID:uid, unit:unitItem,editUnitModal: true});
    }
    
  }

  closeEUnitModal() {
    this.setState({
      unit: "",
      unitID:"",
      mode:'save',
      editUnitModal: false
    });
}

//#endregion
//#region - Product
addProduct=()=>{
  this.props.firebase.db.collection('producttype').add({
      prodType:this.state.product,
      isActive:1,
      dateAdded:firebase.firestore.Timestamp.now(),
  });

  this.closeProdModal();
}

  deleteProduct(uid)
    {
        //console.log(pnid);
        this.props.firebase.deactivateProduct(uid);
    }

    updateProduct=()=>{
      this.props.firebase.db.collection('producttype').doc(this.state.pTypeID).update({
        prodType:this.state.product,
        dateUpdated:firebase.firestore.Timestamp.now()
      });

      this.closeEProdModal();
    }

    openEditProdModal=(pid,pt) =>{
      console.log(pid + " " + pt);
      if(pid==null){
        this.setState({ editProdModal: true });
      }
      else{
        this.setState({ mode:'edit',pTypeID:pid, product:pt,editProdModal: true});
      }
      
    }
  
    closeEProdModal() {
      this.setState({
        product: "",
        pTypeID:"",
        mode:'save',
        editProdModal: false
      });
  }

    openProdModal() {
      this.setState({ prodModal: true, });
    }
  
    closeProdModal() {
      this.setState({
        product:'',
        mode:'save',
        prodModal: false
      });
  }
//#endregion
    
//#region - Material
    addMaterial=()=>{
        this.props.firebase.db.collection('packingmaterial').add({
            materialType:this.state.material,
            isActive:1,
            dateAdded:firebase.firestore.Timestamp.now(),
        });

        this.closeMatModal();
    }

    deleteMaterial(uid)
    {
        //console.log(pnid);
        this.props.firebase.deactivateMaterial(uid);
    }

    updateMaterial=()=>{
      this.props.firebase.db.collection('packingmaterial').doc(this.state.matID).update({
        materialType:this.state.material,
        dateUpdated:firebase.firestore.Timestamp.now()
      });

      this.closeEMatModal();
    }

    openMatModal=({mid=null}) =>{
      if(mid==null){
        this.setState({ matModal: true });
      }
      else{
        this.setState({ matModal: true, mode:'edit',matID:mid });
      }
      
    }
  
    closeMatModal() {
      this.setState({
        material: "",
        mode:'save',
        matModal: false
      });
  }

  openEditMatModal=(mid,mt) =>{
    console.log(mid + " " + mt);
    if(mid==null){
      this.setState({ editMatModal: true });
    }
    else{
      this.setState({ mode:'edit',matID:mid, material:mt,editMatModal: true});
    }
    
  }

  closeEMatModal() {
    this.setState({
      material: "",
      mid:"",
      mode:'save',
      editMatModal: false
    });
}
    //#endregion
    
   

//#region - Status
    addStatus=()=>{
        this.props.firebase.db.collection('quotestatus').add({
          statusValue:this.state.statusValue,
          isActive:1,
          dateAdded:firebase.firestore.Timestamp.now(),
      });

      this.closeStatusModal();
    }

    deleteStatus(sid)
    {
      console.log("Delete Quote Status: " + sid);
      this.props.firebase.deactivateStatus(sid);
    }

      updateStatus=()=>{
      this.props.firebase.db.collection('quotestatus').doc(this.state.qStatusID).update({
        statusValue:this.state.quoteStatusInput,
        dateUpdated:firebase.firestore.Timestamp.now()
      });

      this.closeEStatusModal();
    }

    openStatusModal() {
      this.setState({ statusModal: true, });
    }
  
    closeStatusModal() {
      this.setState({
        clientStatus:'',
        statusValue:'',
        mode:'save',
        statusModal: false,
        cStatusModal:false
      });
    }
      openEditStatusModal=(qsid,qs) =>{
        console.log(qsid + " " + qs);
        if(qsid==null){
          this.setState({ editStatusModal: true });
        }
        else{
          this.setState({ mode:'edit',qStatusID:qsid, quoteStatusInput:qs,editStatusModal: true});
        }
        
      }
    
      closeEStatusModal() {
        this.setState({
          quoteStatusInput: "",
          qStatusID:"",
          mode:'save',
          editStatusModal: false
        });
    }
  //#endregion - Status
//#region - Client Status
    addClientStatus=()=>{
        this.props.firebase.db.collection('clientstatus').add({
          clientStatus:this.state.clientStatus,
          isActive:1,
          dateAdded:firebase.firestore.Timestamp.now(),
      });

      this.closeClientStatusModal();
    }

    deleteClientStatus(csid)
    {
      //console.log(pnid);
      this.props.firebase.deactivateClientStatus(csid);
    }

    updateClientStatus=()=>{
      this.props.firebase.db.collection('clientstatus').doc(this.state.cStatusID).update({
        clientStatus:this.state.clientStatusInput,
        dateUpdated:firebase.firestore.Timestamp.now()
      });

      this.closeECStatusModal();
    }

openClientStatusModal() {
  this.setState({ cStatusModal: true, });
}

closeClientStatusModal() {
  this.setState({
    product:'',
    mode:'save',
    cStatusModal: false
  });
}

openEditCStatusModal=(csid,cs) =>{
  console.log(csid + " " + cs);
  if(csid==null){
    this.setState({ editCStatusModal: true });
  }
  else{
    this.setState({ mode:'edit',cStatusID:csid, clientStatusInput:cs,editCStatusModal: true});
  }
  
}

closeECStatusModal() {
  this.setState({
    clientStatusInput: "",
    cStatusID:"",
    mode:'save',
    editCStatusModal: false
  });
}
//#endregion - Client Status
    componentDidMount(){
        this.setState({loading:true});

        //console.log(this.props.firebase.userRef);
        
        this.unsubscribe = this.props.firebase
        .packingMaterials()
        .onSnapshot(snapshot => {
          let materialTypes = [];
   
          snapshot.forEach(doc =>
            materialTypes.push({ ...doc.data(), mid: doc.id }),
          );

          this.setState({
            materialTypes,
            matsLoading: false,
          });
        });

        this.unsubscribe = this.props.firebase
        .itemUnits()
        .onSnapshot(snapshot => {
          let unitsList = [];
   
          snapshot.forEach(doc =>
            unitsList.push({ ...doc.data(), uid: doc.id }),
          );

          this.setState({
            unitsList,
            unitsLoading: false,
          });
        });

        this.unsubscribe = this.props.firebase
        .productTypes()
        .onSnapshot(snapshot => {
          let productTypes = [];
   
          snapshot.forEach(doc =>
            productTypes.push({ ...doc.data(), pid: doc.id }),
          );

          this.setState({
            productTypes,
            prodLoading: false,
          });
        });

        this.unsubscribe = this.props.firebase
        .clientStatusItems()
        .onSnapshot(snapshot => {
          let clientStatusItems = [];
   
          snapshot.forEach(doc =>
            clientStatusItems.push({ ...doc.data(), csid: doc.id }),
          );

          this.setState({
            clientStatusItems,
            prodLoading: false,
          });
        });

        this.unsubscribe = this.props.firebase
        .statusItems()
        .onSnapshot(snapshot => {
          let statusItems = [];
   
          snapshot.forEach(doc =>
            statusItems.push({ ...doc.data(), sid: doc.id }),
          );

          this.setState({
            statusItems,
            prodLoading: false,
          });
        });
    }

    componentWillUnmount()
    {
        //this.props.firebase.users().off();
        this.unsubscribe();
    }

    render(){

        const {unitsList,productTypes,materialTypes, matsLoading,unitsLoading,prodLoading,clientStatusItems,statusItems} = this.state;
        return(
            <AuthUserContext.Consumer>
            {authUser=>(
                <div className="content">
                  <Modal show={this.state.cStatusModal} handleClose={e => this.closeClientStatusModal(e)}>
                                    <div class="modal-content">
                                      <div class="modal-header">
                                        <h5 class="modal-title">Add Unit</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={e=>this.closeClientStatusModal()}>
                                          <span aria-hidden="true">&times;</span>
                                        </button>
                                      </div>
                                      <div class="modal-body">
                                        <label htmlFor="clientStatus">Client Status:</label>
                                        <input type="text" name="clientStatus" className="form-control" onChange={this.myChangeHandler}/>
                                      </div>
                                      <div class="modal-footer">
                                        <button type="button" class="btn btn-success" onClick={e => this.addClientStatus(e)}>Save changes</button>
                                        <button type="button" class="btn btn-danger" data-dismiss="modal" onClick={e=>this.closeClientStatusModal()}>Close</button>
                                      </div>
                                    </div>
                                </Modal>
                                <Modal show={this.state.editCStatusModal} handleClose={e => this.closeECStatusModal(e)}>
                                    <div class="modal-content">
                                      <div class="modal-header">
                                        <h5 class="modal-title">Edit Client Status:</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={e=>this.closeECStatusModal()}>
                                          <span aria-hidden="true">&times;</span>
                                        </button>
                                      </div>
                                      <div class="modal-body">
                                      <input type="hidden" name="cStatusID" value={this.state.cStatusID}/>
                                        <label htmlFor="clientStatusInput">Client Status:</label>
                                        <input type="text" name="clientStatusInput" className="form-control" onChange={this.myChangeHandler} value={this.state.clientStatusInput}/>
                                      </div>
                                      <div class="modal-footer">
                                        <button type="button" class="btn btn-success" onClick={e => this.updateClientStatus(e)}>Save changes</button>
                                        <button type="button" class="btn btn-danger" data-dismiss="modal" onClick={e=>this.closeECStatusModal()}>Close</button>
                                      </div>
                                    </div>
                                </Modal>
                                <Modal show={this.state.statusModal} handleClose={e => this.closeStatusModal(e)}>
                                    <div class="modal-content">
                                      <div class="modal-header">
                                        <h5 class="modal-title">Add Quote Status</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={e=>this.closeStatusModal()}>
                                          <span aria-hidden="true">&times;</span>
                                        </button>
                                      </div>
                                      <div class="modal-body">
                                        <label htmlFor="statusValue">Status:</label>
                                        <input type="text" name="statusValue" className="form-control" onChange={this.myChangeHandler}/>
                                      </div>
                                      <div class="modal-footer">
                                        <button type="button" class="btn btn-success" onClick={e => this.addStatus(e)}>Save changes</button>
                                        <button type="button" class="btn btn-danger" data-dismiss="modal" onClick={e=>this.closeStatusModal()}>Close</button>
                                      </div>
                                    </div>
                                </Modal>
                                <Modal show={this.state.editStatusModal} handleClose={e => this.closeEStatusModal(e)}>
                                    <div class="modal-content">
                                      <div class="modal-header">
                                        <h5 class="modal-title">Edit Quote Status</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={e=>this.closeEStatusModal()}>
                                          <span aria-hidden="true">&times;</span>
                                        </button>
                                      </div>
                                      <div class="modal-body">
                                      <input type="hidden" name="qStatusID" value={this.state.qStatusID}/>
                                        <label htmlFor="quoteStatusInput">Quote Status:</label>
                                        <input type="text" name="quoteStatusInput" className="form-control" onChange={this.myChangeHandler} value={this.state.quoteStatusInput}/>
                                      </div>
                                      <div class="modal-footer">
                                        <button type="button" class="btn btn-success" onClick={e => this.updateStatus(e)}>Save changes</button>
                                        <button type="button" class="btn btn-danger" data-dismiss="modal" onClick={e=>this.closeEStatusModal()}>Close</button>
                                      </div>
                                    </div>
                                </Modal>

                    <Modal show={this.state.unitModal} handleClose={e => this.closeUnitModal(e)}>
                                    <div class="modal-content">
                                      <div class="modal-header">
                                        <h5 class="modal-title">Add Unit</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={e=>this.closeUnitModal()}>
                                          <span aria-hidden="true">&times;</span>
                                        </button>
                                      </div>
                                      <div class="modal-body">
                                        <label htmlFor="unit">Unit:</label>
                                        <input type="text" name="unit" className="form-control" onChange={this.myChangeHandler}/>
                                      </div>
                                      <div class="modal-footer">
                                        <button type="button" class="btn btn-success" onClick={e => this.addUnit(e)}>Save changes</button>
                                        <button type="button" class="btn btn-danger" data-dismiss="modal" onClick={e=>this.closeUnitModal()}>Close</button>
                                      </div>
                                    </div>
                                </Modal>
                                <Modal show={this.state.editUnitModal} handleClose={e => this.closeEUnitModal(e)}>
                                    <div class="modal-content">
                                      <div class="modal-header">
                                        <h5 class="modal-title">Edit Unit</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={e=>this.closeEUnitModal()}>
                                          <span aria-hidden="true">&times;</span>
                                        </button>
                                      </div>
                                      <div class="modal-body">
                                      <input type="hidden" name="unitID" value={this.state.unitID}/>
                                        <label htmlFor="unit">Unit:</label>
                                        <input type="text" name="unit" className="form-control" onChange={this.myChangeHandler} value={this.state.unit}/>
                                      </div>
                                      <div class="modal-footer">
                                        <button type="button" class="btn btn-success" onClick={e => this.updateUnit(e)}>Save changes</button>
                                        <button type="button" class="btn btn-danger" data-dismiss="modal" onClick={e=>this.closeEUnitModal()}>Close</button>
                                      </div>
                                    </div>
                                </Modal>
                                <Modal show={this.state.matModal} handleClose={e => this.closeMatModal(e)}>
                                    <div class="modal-content">
                                      <div class="modal-header">
                                        <h5 class="modal-title">Add Material</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={e=>this.closeMatModal()}>
                                          <span aria-hidden="true">&times;</span>
                                        </button>
                                      </div>
                                      <div class="modal-body">
                                        
                                        <label htmlFor="material">Add Material:</label>
                                        <input type="text" name="material" className="form-control" onChange={this.myChangeHandler}/>
                                        
                                      </div>
                                      <div class="modal-footer">
                            
                                        <button type="button" class="btn btn-success" onClick={e => this.addMaterial(e)}>Save</button>
                                        <button type="button" class="btn btn-danger" data-dismiss="modal" onClick={e=>this.closeMatModal()}>Close</button>
                                      </div>
                                    </div>
                                </Modal>
                                <Modal show={this.state.editMatModal} handleClose={e => this.closeEMatModal(e)}>
                                    <div class="modal-content">
                                      <div class="modal-header">
                                        <h5 class="modal-title">Edit Material</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={e=>this.closeEMatModal()}>
                                          <span aria-hidden="true">&times;</span>
                                        </button>
                                      </div>
                                      <div class="modal-body">
                                        <input type="hidden" name="mid" value={this.state.matID}/>
                                        <label htmlFor="material">Edit Material:</label>
                                        <input type="text" name="material" className="form-control" onChange={this.myChangeHandler} value={this.state.material}/>
                                        
                                      </div>
                                      <div class="modal-footer">
                            
                                        <button type="button" class="btn btn-success" onClick={e => this.updateMaterial(e)}>Save</button>
                                        <button type="button" class="btn btn-danger" data-dismiss="modal" onClick={e=>this.closeEMatModal()}>Close</button>
                                      </div>
                                    </div>
                                </Modal>
                                <Modal show={this.state.prodModal} handleClose={e => this.closeProdModal(e)}>
                                    <div class="modal-content">
                                      <div class="modal-header">
                                        <h5 class="modal-title">Add Product Type</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={e=>this.closeProdModal()}>
                                          <span aria-hidden="true">&times;</span>
                                        </button>
                                      </div>
                                      <div class="modal-body">
                                        
                                        <label htmlFor="product">Product</label>
                                        <input type="text" name="product" className="form-control" onChange={this.myChangeHandler}/>
                                        
                                      </div>
                                      <div class="modal-footer">
                                    
                                        <button type="button" class="btn btn-success" onClick={e => this.addProduct(e)}>Save</button>
                                        <button type="button" class="btn btn-danger" data-dismiss="modal" onClick={e=>this.closeProdModal()}>Close</button>
                                      </div>
                                    </div>
                                </Modal>
                                <Modal show={this.state.editProdModal} handleClose={e => this.closeEProdModal(e)}>
                                    <div class="modal-content">
                                      <div class="modal-header">
                                        <h5 class="modal-title">Edit Product Type</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={e=>this.closeEProdModal()}>
                                          <span aria-hidden="true">&times;</span>
                                        </button>
                                      </div>
                                      <div class="modal-body">
                                      <input type="hidden" name="pTypeID" value={this.state.pTypeID}/>
                                        <label htmlFor="product">Product:</label>
                                        <input type="text" name="product" className="form-control" onChange={this.myChangeHandler} value={this.state.product}/>
                                      </div>
                                      <div class="modal-footer">
                                        <button type="button" class="btn btn-success" onClick={e => this.updateProduct(e)}>Save changes</button>
                                        <button type="button" class="btn btn-danger" data-dismiss="modal" onClick={e=>this.closeEProdModal()}>Close</button>
                                      </div>
                                    </div>
                                </Modal>
                    <nav className="navbar navbar-expand-lg navbar-light bg-blue">
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav">
                        <li className="nav-item">
                        <a className="nav-link-menu" href="../home">Home</a>
                        </li>
                        </ul>
                    </div>
                    </nav>
                    <div className="container-xl">
                    <div className="row">
                        <div className="col-md-4">
                        <div className="">
                        <div className="table-wrapper-domain">
                            <div className="table-title">
                                <div className="row">
                                    <div className="col-sm-5">
                                        <h2>Item Units Management</h2>
                                    </div>
                                    <div className="col-sm-7">
                                        <button onClick={e => this.openUnitModal()} className="btn btn-default"><FontAwesomeIcon icon={faPlus}></FontAwesomeIcon> Add Unit</button>
                                    </div>
                                </div>
                            </div>
                            {unitsLoading && <h1>Loading...</h1>}
                            <table className="table table-responsive-domain table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th>Unit</th>						
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {unitsList.map(unit=> (
                                   <tr key={unit.uid}>
                                       <td>{unit.unitDesc}</td>
                                       <td><span className="status text-success">&bull;</span>{unit.isActive == 1?"Active":"Inactive"}</td>
                                       <td>
                                       <div className="btn-group" role="group" aria-label="Basic example"><button className="btn btn-sm btn-info" style={{maxHeight:31+"px"}} onClick={e=>this.openEditUnitModal(unit.uid,unit.unitDesc)}><FontAwesomeIcon style={{color:"white"}} icon={faEdit}></FontAwesomeIcon></button><button type="button" className="btn btn-sm btn-danger" onClick={() => {
                                if (window.confirm("Delete this Unit?")) {
                                    
                                    let removeToCollection = this.deleteUnit.bind(this,unit.uid);
                                    removeToCollection();
                                }
                            }}><FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon></button></div>
                                        </td>
                                   </tr>     
                                ))}
                                </tbody>
                                </table>
                                </div>
                                </div>
                        </div>
                        <div className="col-md-4">
                        <div className="">
                        <div className="table-wrapper-domain">
                            <div className="table-title">
                                <div className="row">
                                    <div className="col-sm-5">
                                        <h2>Package Material Management</h2>
                                    </div>
                                    <div className="col-sm-7">
                                    <button onClick={e => this.openMatModal(e)} className="btn btn-default"><FontAwesomeIcon icon={faPlus}></FontAwesomeIcon> Add Packing Material</button>
                    
                                    </div>
                                </div>
                            </div>
                            {matsLoading && <h1>Loading...</h1>}
                            <table className="table table-responsive-domain table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th>Material</th>						
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {materialTypes.map(pm=> (
                                   <tr key={pm.mid}>
                                       <td>{pm.materialType}</td>
                                       <td><span className="status text-success">&bull;</span>{pm.isActive == 1?"Active":"Inactive"}</td>
                                       <td>
                                       <div className="btn-group" role="group" aria-label="Basic example"><button className="btn btn-sm btn-info" style={{maxHeight:31+"px"}} onClick={e=>this.openEditMatModal(pm.mid,pm.materialType)}><FontAwesomeIcon style={{color:"white"}} icon={faEdit}></FontAwesomeIcon></button><button type="button" className="btn btn-sm btn-danger" onClick={() => {
                                if (window.confirm("Delete this Packing Material?")) {
                                    
                                    let removeToCollection = this.deleteMaterial.bind(this,pm.mid);
                                    removeToCollection();
                                }
                            }}><FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon></button></div>
                                        </td>
                                   </tr>     
                                ))}
                                </tbody>
                                </table>
                                </div>
                                </div>
                        </div>
                        <div className="col-md-4">
                        <div className="">
                        <div className="table-wrapper-domain">
                            <div className="table-title">
                                <div className="row">
                                    <div className="col-sm-5">
                                        <h2>Product Type Management</h2>
                                    </div>
                                    <div className="col-sm-7">
                                    <button onClick={e => this.openProdModal()} className="btn btn-default"><FontAwesomeIcon icon={faPlus}></FontAwesomeIcon> Add Product Type</button>
                                    
                                    </div>
                                </div>
                            </div>
                            {prodLoading && <h1>Loading...</h1>}
                            <table className="table table-responsive-domain table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th>Product Type</th>						
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {productTypes.map(pType=> (
                                   <tr key={pType.pid}>
                                       <td>{pType.prodType}</td>
                                       <td><span className="status text-success">&bull;</span></td>
                                       <td>
                                       <div className="btn-group" role="group" aria-label="Basic example"><button className="btn btn-sm btn-info" style={{maxHeight:31+"px"}} onClick={e=>this.openEditProdModal(pType.pid,pType.prodType)}><FontAwesomeIcon style={{color:"white"}} icon={faEdit}></FontAwesomeIcon></button><button type="button" className="btn btn-sm btn-danger" onClick={() => {
                                if (window.confirm("Delete this Product Type?")) {
                                    
                                    let removeToCollection = this.deleteProduct.bind(this,pType.pid);
                                    removeToCollection();
                                }
                            }}><FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon></button></div>
                                        </td>
                                   </tr>     
                                ))}
                                </tbody>
                                </table>
                                </div>
                                </div>
                                </div>
                        </div>
                        <div className="row">
                        <div className="col-md-4">
                        <div className="">
                        <div className="table-wrapper-domain">
                            <div className="table-title">
                                <div className="row">
                                    <div className="col-sm-5">
                                        <h2>Internal Status Management</h2>
                                    </div>
                                    <div className="col-sm-7">
                                        <button onClick={e => this.openStatusModal()} className="btn btn-default"><FontAwesomeIcon icon={faPlus}></FontAwesomeIcon> Add Status</button>
                                    </div>
                                </div>
                            </div>
                            {unitsLoading && <h1>Loading...</h1>}
                            <table className="table table-responsive-domain table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th>Internal Status</th>						
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {statusItems.map(si=> (
                                   <tr key={si.sid}>
                                       <td>{si.statusValue}</td>
                                       <td><span className="status text-success">&bull;</span></td>
                                       <td>
                                       <div className="btn-group" role="group" aria-label="Basic example"><button className="btn btn-sm btn-info" style={{maxHeight:31+"px"}} onClick={e=>this.openEditStatusModal(si.sid,si.statusValue)}><FontAwesomeIcon style={{color:"white"}} icon={faEdit}></FontAwesomeIcon></button><button type="button" className="btn btn-sm btn-danger" onClick={() => {
                                if (window.confirm("Delete this Status?")) {
                                    
                                    let removeToCollection = this.deleteStatus.bind(this,si.sid);
                                    removeToCollection();
                                }
                            }}><FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon></button></div>
                                        </td>
                                   </tr>     
                                ))}
                                </tbody>
                                </table>
                                </div>
                                </div>
                        </div>
                        <div className="col-md-4">
                        <div className="">
                        <div className="table-wrapper-domain">
                            <div className="table-title">
                                <div className="row">
                                    <div className="col-sm-5">
                                        <h2>Quote Status Management</h2>
                                    </div>
                                    <div className="col-sm-7">
                                    <button onClick={e => this.openClientStatusModal(e)} className="btn btn-default"><FontAwesomeIcon icon={faPlus}></FontAwesomeIcon> Add Client Status</button>
                    
                                    </div>
                                </div>
                            </div>
                            {matsLoading && <h1>Loading...</h1>}
                            <table className="table table-responsive-domain table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th>Quote Status</th>						
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {clientStatusItems.map(csi=> (
                                   <tr key={csi.csid}>
                                       <td>{csi.clientStatus}</td>
                                       <td><span className="status text-success">&bull;</span></td>
                                       <td>
                                       <div className="btn-group" role="group" aria-label="Basic example"><button className="btn btn-sm btn-info" style={{maxHeight:31+"px"}} onClick={e=>this.openEditCStatusModal(csi.csid,csi.clientStatus)}><FontAwesomeIcon style={{color:"white"}} icon={faEdit}></FontAwesomeIcon></button><button type="button" className="btn btn-sm btn-danger" onClick={() => {
                                if (window.confirm("Delete this Client Status?")) {
                                    
                                    let removeToCollection = this.deleteClientStatus.bind(this,csi.csid);
                                    removeToCollection();
                                }
                            }}><FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon></button></div>
                                        </td>
                                   </tr>     
                                ))}
                                </tbody>
                                </table>
                                </div>
                                </div>
                        </div>
                        <div className="col-md-4">
                        <div className="">
                      
                                </div>
                                </div>
                        </div>
                    </div>
                    </div>
          
                                
                                
                                 )}
                                 </AuthUserContext.Consumer>
                                            
        );
    }
}

/*const UserItem = ({match})=>(
    <div>
        <h2>User ({match.params.id})</h2>
    </div>
);*/


const UserList = withFirebase(DomainListBase);


const condition = authUser => !!authUser;

export default compose(withAuthorization(condition),)(DomainListBase);