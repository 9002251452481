import React, { Component } from 'react';
import {Link} from 'react-router-dom';

import {withFirebase} from '../Firebase';
import * as ROUTES from '../../constants/routes';
import '../SignIn/signin.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faUser,faUserCircle,faLock,faEnvelope,faKey} from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';

const PasswordForgotPage = () =>(
    <div>
        <PasswordForgotForm/>
    </div>
);

const INITIAL_STATE = {
    email:'',
    error:null,
    errormessage:'',
}

class PasswordForgotFormBase extends Component{
    constructor(props){
        super(props);

        this.state = {...INITIAL_STATE};
    }

    onSubmit = event =>{
        const {email} = this.state;

        this.props.firebase
            .doPasswordReset(email)
            .then(()=>{
                this.setState({...INITIAL_STATE});
            })
            .catch(error=>{
                this.setState({error});
            });

        event.preventDefault();    
    };

    onChange = event => {
        //this.setState({[event.target.name]:event.target.name});
        let nam = event.target.name;
      let val = event.target.value;
      let err = '';

      
      this.setState({errormessage: err});
      this.setState({[nam]: val});
    };

    render(){
        const {email, error} = this.state;

        const isInvalid = email === '';

        return(
            <div className="container" style={{marginTop:10+"%"}}>
            <div className="form-box">
              <div className="header-form">
                <h4 className="text-primary text-center"><FontAwesomeIcon icon={faKey} style={{fontSize:"110px"}}></FontAwesomeIcon></h4>
                <div className="image">
                </div>
              </div>
              <div className="body-form">
               <form onSubmit={this.obSubmit}>
                  <div className="input-group mb-3">
       <div className="input-group-prepend">
        <span className="input-group-text"><FontAwesomeIcon icon={faUser}></FontAwesomeIcon></span>
      </div>
      <input type="text" name="email" onChange={this.onChange} className="form-control" placeholder="Email Address" />
    </div>
     <button type="submit" disabled={isInvalid} className="btn btn-secondary btn-block">Reset Password</button>
     <div className="message">
     {error && <p>{error.message}</p>}
     </div>
       </form>
              </div>
            </div>
           </div>  
        );
    }
}

const PasswordForgotLink = () => (
    <p>
        <Link to={ROUTES.PASSWORD_FORGOT}>Forgot Password?</Link>
    </p>
);

export default PasswordForgotPage;

const PasswordForgotForm = withFirebase(PasswordForgotFormBase);

export {PasswordForgotPage,PasswordForgotLink};
