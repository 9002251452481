import React, { Component } from 'react'
import ChartistGraph from 'react-chartist'
import {Link} from 'react-router-dom'
import { compose } from 'recompose';

import { withAuthorization } from '../Session';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle,faClock,faHistory} from '@fortawesome/free-solid-svg-icons';
import {faUser,faUserCircle,faLock} from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Sidebar/sidebar.css'
import { AuthUserContext } from '../Session';

class MasterNavBar extends Component {
    constructor(props) {
        super(props);
    }
render() {

return(
    <AuthUserContext.Consumer>
            {authUser=>(
        <nav className="navbar navbar-expand-lg navbar-light bg-blue" style={{marginBottom:'15px'}}>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNavDropdown">
              <ul className="navbar-nav">
                <li className="nav-item active">
                  <a className="nav-link-menu" href="#">Home <span className="sr-only">(current)</span></a>
                </li>
                {authUser.role === "Office" && 
                  <li className="nav-item">
                    <a className="nav-link-menu" href="../siteconfig">Manage Domains</a>
                  </li>
                  }
                  {authUser.role === "Office" && 
                  <li className="nav-item">
                    <a className="nav-link-menu" href="../suppliersearch">Supplier</a>
                  </li>
                  }
                  {authUser.role === "Admin" && 
                  <li className="nav-item dropdown">
                    <a className="nav-link-menu dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Site Management
                    </a>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                      <a className="dropdown-item" href="../reports">Reporting</a>
                      <a className="dropdown-item" href="../siteconfig">Domains</a>
                      <a className="dropdown-item" href="../pricing">Pricing</a>
                      <a className="dropdown-item" href="../users">Users</a>
                      <a className="dropdown-item" href="../about">About</a>
                      {/*<a className="dropdown-item" href="../suppliersearch">Supplier</a>*/}
                    </div>
                  </li>
                  }
                  {authUser.role === "Admin" && 
                  <li className="nav-item">
                    <a className="nav-link-menu" href="../suppliersearch">Supplier</a>
                  </li>
                  }
                  {authUser.role === "Buyer" && 
                  <li className="nav-item">
                  <a className="nav-link-menu" href="../siteconfig">Manage Domains</a>
                </li>
                  }
                  {authUser.role === "Buyer" && 
                  <li className="nav-item">
                    <a className="nav-link-menu" href="../suppliersearch">Suppliers</a>
                  </li>
                  }
                <li className="nav-item">
                  <a className="nav-link-menu" href="../search">Contacts</a>
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link-menu dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Items
                  </a>
                  <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                  <a className="dropdown-item" href="../itemsearch">Item Search</a>
                    <a className="dropdown-item" href="../inventory">Warehouse Inventory</a>
                    {/*<a className="dropdown-item" href="">Order Inventory</a>
                    <a className="dropdown-item" href="../confirm">Log Inventory</a>
                    <a className="dropdown-item" href="../suppliersearch">Supplier</a>*/}
                  </div>
                </li>
                {authUser.role === "Admin" && 
                <li className="nav-item dropdown">
                  <a className="nav-link-menu dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Quotes & Orders
                  </a>
                  <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                  <a className="dropdown-item" href="../quotes">Quotes</a>
                  <a className="dropdown-item" href="../orders">Inventory Orders</a>
                    
                    {/*<a className="dropdown-item" href="../suppliersearch">Supplier</a>*/}
                  </div>
                </li>
                }
                {/*<li className="nav-item">
                  <a className="nav-link-menu" href="../quotes">Quotes</a>
                </li>*/}
                <li className="nav-item">
                    <a className="nav-link-menu" href="../signout">Sign Out</a>
                  </li>
              </ul>
            </div>
          </nav>
        )}
          </AuthUserContext.Consumer>
    )
    }
}
const condition = authUser => !!authUser;

export default compose(withAuthorization(condition),)(MasterNavBar);