import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyAEAGq7D-BaZAuSFN2ikyRDRVp-Qr-vx1Y",
    authDomain: "proviso-f5371.firebaseapp.com",
    projectId: "proviso-f5371",
    storageBucket: "proviso-f5371.appspot.com",
    messagingSenderId: "808739194668",
    appId: "1:808739194668:web:2fa471bd3df24aff6a0e89",
    measurementId: "G-1085D7N9Y5"
  };

  class Firebase {
      constructor(){
        app.initializeApp(firebaseConfig);

        /* Helper */

        this.fieldValue = app.firestore.FieldValue;
        this.emailAuthProvider = app.auth.EmailAuthProvider;

        this.auth = app.auth();
        this.db = app.firestore();
        this.storageRef = app.storage().ref();
  }

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doPasswordReset=(email)=>
  {
    this.auth().sendPasswordResetEmail(email)
  .then(() => {
    // Password reset email sent!
    // ..
  })
  .catch((error) => {
    var errorCode = error.code;
    var errorMessage = error.message;
    // ..
  });

  }

  onAuthUserListener = (next, fallback) =>
  this.auth.onAuthStateChanged(authUser => {
    if (authUser) {
      console.log(authUser.uid);
      this.user(authUser.uid)
        .get()
        .then(snapshot => {
          const dbUser = snapshot.data();
          
          // default empty roles
          /*if (!dbUser.role) {
            dbUser.role = '';
          }*/

          // merge auth and db user
          authUser = {
            uid: authUser.uid,
            email: authUser.email,
            userFullName: authUser.fname + " " + authUser.lname,
            userFirstName: authUser.fname,
            role: authUser.role,
            ...dbUser,
          };
          //console.log(authUser.role);
          next(authUser);
        });
    } else {
      fallback();
    }
  });
  doSignOut = () => this.auth.signOut();
 updateRep(entityID, rep){
   //console.log(entityID + " " + rep);
    var comp = this.db.collection(`entities`).doc(entityID).update({repName:rep});
    //var uComp = await comp.update({repName:rep});
  }

  updateType(entityID, newtype){
    //console.log(entityID + " " + newtype);
     var comp = this.db.collection(`entities`).doc(entityID).update({entityType:newtype});
     //var uComp = await comp.update({repName:rep});
   }

   updateCompanyName(entityID, newName){
    let compAssign = this.db.collection(`entities`).doc(entityID).update({name:newName});
   }
   updateCompanyCode(entityID, newCode){
    let compCodeAssign = this.db.collection(`entities`).doc(entityID).update({contactCode:newCode});
   }
   deactivateUser(uid)
   {
      let user = this.db.collection(`users`).doc(uid).update({isActive:0});
   }
   deactivateNote(noteID)
   {
      let note = this.db.collection(`transcript`).doc(noteID).update({isActive:0});
   }

   deactivateUnit(unitID)
   {
      let unit = this.db.collection(`itemunits`).doc(unitID).update({isActive:0});
   }

   deactivateMaterial(matID)
   {
      let mat = this.db.collection(`packingmaterial`).doc(matID).update({isActive:0});
   }

   deactivateProduct(pID)
   {
      let prod = this.db.collection(`producttype`).doc(pID).update({isActive:0});
   }

   deactivateDocument(noteID)
   {
      let note = this.db.collection(`entityDocuments`).doc(noteID).update({isActive:0});
   }

   deactivateContact(entityID,contactID)
   {
     //console.log(contactID);
    var user = this.db.collection('entities').doc(entityID).collection(`contacts`).doc(contactID).update({isActive:0});
   }

   deactivateStatus(sID)
   {
      let s = this.db.collection(`quotestatus`).doc(sID).update({isActive:0});
   }

   deactivateClientStatus(csID)
   {
      let cs = this.db.collection(`clientstatus`).doc(csID).update({isActive:0});
   }

   deactivateCompany(cID)
   {
     let dc = this.db.collection(`entities`).doc(cID).delete();
   }

   searchAllContacts(searchTerm)
   {
     
   }

   updateCorp(entityID,newcorpCity,newcorpState){
    this.props.firebase.db.collection('entities').doc(entityID).update({
      corpCity: newcorpCity,
      corpState:newcorpState,
    });
   }

   addKeywords(newArray,companyid){
     this.db.collection('entities').doc(companyid).update({})
   }

   getQuoteFullMarkUp(quoteID)
   {
     let quoteItem = this.db.collection(`clientstatus`).doc(quoteID);
     if(quoteItem != null)
     {
        
     }
     else{

     }
   }

 getReminders()
   {
    const today = new Date();
    const yesterday = new Date(today);
    const tomorrow = new Date(today);
    
    yesterday.setDate(yesterday.getDate() - 3);
    tomorrow.setDate(tomorrow.getDate() + 3);

    //console.log(yesterday+'---'+tomorrow);
    
    //console.log("Current User: " + currentUserID);

   //return this.db.collection('reminders').where('isActive','==',1).where('reminderDate','>',yesterday).where('reminderDate','<',tomorrow);
   return this.db.collection('reminders').where('isActive','==',1)
  }

   getRemindersByCompany(companyid)
   {
    const today = new Date();
    return this.db.collection('reminders').where("entityID","==",companyid).where("isActive","==",1).where('reminderDate','>=',today).orderBy('reminderDate',"desc");
   }

  //*** USER API ****
  user = uid => this.db.doc(`users/${uid}`);
  getUser = uid => this.db.doc(`users/${uid}`);
  getCurrentUser = () =>this.auth.currentUser.getUid();
  getCurrentUserFullName = ()=>this.auth.currentUser;

  users = () => this.db.collection('users').where('isActive','==',1);

  usersByCompany = companyid => this.db.collection('contacts').where('companyid','==',companyid).where('isActive','==',1);

  //*** COMPANIES API ***
  company = companyid => this.db.doc(`entities/${companyid}`);
  //entityProfile = companyid =>this.db.collection('entities').where
  companies = () => this.db.collection('entities');
  customer = () => this.db.collection('entities').where("entityType","==","Customer");
  leads = () => this.db.collection('entities').where("entityType","==","Lead");
  prospects = () => this.db.collection('entities').where("entityType","==","Prospect");

  reminders = () =>this.db.collection('reminders').where('isActive','==',1);

  locations = () =>this.db.collection('locations');
  getLocation = (companyid,locationid) => this.db.doc(`entities/${companyid}`).collection('locations').doc(locationid);
  getItemsBySupplier = (supplierid) => this.db.collection('items').where('isActive','==',true).where('companyid','==',supplierid).orderBy("itemName","asc");
  // *** CONTACTS API ***
  contact = contactid => this.db.doc(`contacts/${contactid}`);

  //ALL CONTACTS - NO LONGER USED
  //contacts = () => this.db.collection('contacts');

   //REPORTS
   contactsByState = stateAbbr => this.db.collection('entities').where('corpState','==',stateAbbr);


  //ITEMS - ALL
  allItems = () => this.db.collection('items').orderBy("itemName","asc");
  getFPItems = () => this.db.collection('items').where('needsPricing','==',0).orderBy("itemName","asc");
  getNPItems = () => this.db.collection('items').where('needsPricing','==',1).orderBy("itemName","asc");
  getNoMUItems = () =>this.db.collection('items').where('needsPricing','==',1).where('fobCasePrice','<>',"").orderBy("itemName","asc");
  //Company QUERIES
  //companyContacts = entityID => contacts.where("entityID","==",entityID).get();
  notes = () => this.db.collection('transcript');
  notesByCompany = companyid => this.db.collection('transcript').where('entityID',"==",companyid).where("isActive","==",1).where("pinned","==",false).orderBy('datetime', "desc");
  pinnedNotesByCompany = companyid => this.db.collection('transcript').where('entityID',"==",companyid).where("isActive","==",1).where("pinned","==",true);
  locationsByCompany = companyid => this.db.doc(`entities/${companyid}`).collection('locations').where("isActive","==",1);
  contactsByCompany = companyid => this.db.doc(`entities/${companyid}`).collection('contacts').where("isActive","==",1);
  remindersByCompany = companyid => this.db.collection('reminders').where("entityID","==",companyid).where("isActive","==",1).orderBy('reminderDate','desc');
  primaryContact = companyid => this.db.doc(`entities/${companyid}`).collection('contacts').where("isPrimary","==",true);
  corpLocation = companyid => this.db.doc(`entities/${companyid}`).collection('locations').where("locationType","==","Corporate");
  documentsByCompany = companyid => this.db.collection('entityDocuments').where('entityID','==',companyid).where("isActive","==",1);
  
  //QUOTES
  quotes = () => this.db.collection('quotes');
  activeQuotes = () =>this.db.collection('quotes').where('activeRequest','==',true).orderBy('quoteDate','desc');

  //quoteByID = qid => this.db.doc(`quotes/${qid}`);
  quotesByCompany = companyID => this.db.collection(`quotes`).where('customerID','==',companyID);
  internalquotes = () => this.db.collection('quotes').where("selectedCustomerID","==","Sharratt Provisions");
  quotesBySalesPerson = personID => this.db.collection('quotes').where("userID","==",personID)

  //INVENTORY
  inventory = () => this.db.collection('inventory');
  //warehouseInventory = () =>this.db.collection('warehouseinventory');
  inventoryByWarehouse = warehouseID => this.db.collection('inventory').where("warehouseID","==",warehouseID);
  incompleteInventory = () => this.db.collection('inventory').where("itemQty","!=","lotCount");
  availInventory = () => this.db.collection('inventory').where("itemQty",">",0);
  getAvailableinventory = () => this.db.collection('inventory');
  //warehouses = () => this.db.collection('warehouse').where("isActive","==",1);
  getWarehouse = warehouseid => this.db.collection('warehouse').where('warehouseID',"==",warehouseid);

  //GET A LIST OF INVENTORYORDERS
  inventoryorders = () => this.db.collection('inventoryorders').orderBy('orderDate','desc');

  //Item by ID
  getItem = itemid => this.db.collection('items').doc(itemid);
  //FILES
  filesByCompany = companyid => this.storageRef.child('companydocs/'+companyid).listAll();

  //dashboard metrics
  leads = ()=>this.db.collection('entities').where("entityType","==","Lead");
  prospects =()=> this.db.collection('entities').where("entityType","==","Prospect");
  customers =()=>this.db.collection('entities').where("entityType","==","Customer");
  suppliers =()=> this.db.collection('entities').where("entityType","==","Supplier");
  warehouses = () => this.db.collection('entities').where("entityType","==","Warehouse");

   //quote items
   recipients = () =>this.db.collection('entities').where("entityType","in",["Customer","Prospect","Lead"]);

  //LOOKUPS
  packingMaterials = () => this.db.collection('packingmaterial').where('isActive','==',1).orderBy('materialType','asc');
  itemUnits = () =>this.db.collection('itemunits').where('isActive','==',1);
  productTypes = () => this.db.collection('producttype').where('isActive','==',1).orderBy('prodType','asc');
  statusItems = () => this.db.collection('quotestatus').where('isActive','==',1).orderBy('statusValue','asc');
  clientStatusItems = () => this.db.collection('clientstatus').where('isActive','==',1).orderBy('clientStatus','asc');
  //PRICING
  productsWithPricing = () => this.db.collection('items').where('isActive','==',1).where("fullPrice",'!=','');
  productsWithoutPricing = () => this.db.collection('items').where('isActive','==',1).where("fullPrice",'==','');
}
export default Firebase;