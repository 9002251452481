import React, { Component } from 'react';
import {withFirebase} from '../Firebase';
import { DatePicker, DatePickerInput } from 'rc-datepicker';
import 'rc-datepicker/lib/style.css';
import { withAuthorization } from '../Session';
import { compose } from 'recompose';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPencilAlt,faCheckCircle,faTimes, faCheck} from '@fortawesome/free-solid-svg-icons';
import { Redirect } from 'react-router-dom';
import { AuthUserContext } from '../Session';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from "moment";
import firebase from 'firebase';

class ReminderControl extends Component{
    constructor(props){
        super(props);

        this.state = {
            loading:false,
            companyId:this.props.cid,
            companyName:this.props.cname,
            userID:this.props.authUser,
            isActive:1,
            reminderDate:'',
            reminderTime:'',
            editMode:false,
        };
    }

    componentDidMount(){
        console.log("Reminder Did Mount: " + this.state.companyName);
        if(this.state.companyName == '')
        {
            //console.log("Company name does not exist");
            this.unsubscribe = this.props.firebase
            .company(this.props.cid)
            .onSnapshot(snapshot => {
                //console.log("Snapshot Name: " + snapshot.data().name);
            this.setState({
                companyName:snapshot.data().name,
                loading: true,
            });
            });
        }
        //this.setState({userID:authUser.uid});

    }

    componentWillUnmount(){
        //this.unsubscribe();
    }

    onChange = (jsDate, dateString) => {
        // ...
        //console.log(jsDate + " "+dateString);
        this.setState({reminderDate:jsDate});
    }

    repNameSelect=(event)=>{
            let nam = event.target.name;
            let val = event.target.value;
            //console.log(nam +" "+val);
            this.setState({[nam]: val});
    }

    handleValueChange = value => {
        //console.log(value && value.format('HH:mm:ss'));
        this.setState({ reminderTime: value });
      };

    repNameCommit=()=>{
        //this.setState({username:'foobar'});
        console.log("Commit new Company Name: " + this.state.companyName + " for: "+this.state.companyId);
        this.props.firebase.updateCompanyName(this.props.cid,this.state.companyName);
        this.setState({editMode:false});
    }

    cancelForm = (event)=>{
        console.log("Form Cancelled");
        this.myFormRef.reset();
      }

      createReminder = (event,authUser)=>{
        event.preventDefault();
        console.log("Form Submitted:" +authUser + " - "+ this.state.companyName);

        this.props.firebase.db.collection('reminders').add({
            entityID:this.state.companyId,
            entityName:this.state.companyName,
            userID:authUser.uid,
            isActive:1,
            //reminderDate:this.state.reminderDate,
            reminderDate: moment(this.state.reminderDate +" "+ this.state.reminderTime).toDate(),
            //reminderDate:firebase.firestore.Timestamp.now(),
            reminderText:this.state.reminderText
        })

        this.myFormRef.reset();
      }

    render(){
        const format = 'h:mm A';

        const now = moment();

        const {editMode,companyName} = this.state;
        return(
            <AuthUserContext.Consumer>
            {authUser=>(
            <div style={{margin:10+"px"}}>
                <form onSubmit={event =>this.createReminder(event,authUser)} ref={(el) => this.myFormRef = el}>
                    <div className="row" >
                        <div className="col-md-12"><input type="text" className="form-control" name="reminderText" placeholder="Reminder Message" onChange={this.repNameSelect}></input></div></div>
                        <div className="row" style={{marginTop:10+"px"}}>
                        <div className="col-md-4"><DatePickerInput
                            name="reminderDate"
                            onChange={this.onChange}
                            className='my-custom-datepicker-component'
                            />
                            </div>
                            <div className="col-md-4"><TimePicker
                            name="reminderTime"
                            showSecond={false}
                            defaultValue={now}
                            className="xxx"
                            onChange={this.handleValueChange}
                            format={format}
                            use12Hours
                            inputReadOnly
                          /></div>
                            <div className="col-md-4">
                            <div className="btn-group" role="group" aria-label="Basic example"><button type="submit" className="btn btn-sm btn-success">Submit</button><button type="button" className="btn btn-sm btn-danger" onClick={this.cancelForm}>Cancel</button></div> 
                            </div>
                    </div>
                </form>
            </div>
            )}
            </AuthUserContext.Consumer>
        );
    }
}

const condition = authUser => !!authUser;

export default withFirebase(ReminderControl);
