import React,{Component} from 'react';
import SearchBar from '../Search/SearchBar';
import SearchTable from '../Search/SearchTable';
import { Switch,Route, Link,withRouter } from 'react-router-dom';

import * as ROUTES from '../../constants/routes';
import { compose } from 'recompose';

import { withAuthorization } from '../Session';
import {withFirebase} from '../Firebase';
import { faRedo,faEdit,faPlusCircle,faHome} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faUser,faUserCircle,faLock,faSearch} from '@fortawesome/free-solid-svg-icons';

import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import BootstrapTable,{TableHeaderColumn} from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import filterFactory,{textFilter,selectFilter} from 'react-bootstrap-table2-filter';
import { ThemeProvider } from 'styled-components';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 



class SearchPage extends Component{ 

  detailsFormatter(cell, row) {
    return (
      <Link to={{pathname: `/company/`+row.cid}}>Details</Link>
    );
  }
  constructor(props) {
    super(props);

    const staffOptions = {'Josh S': 'Josh S', 'Beth Sharratt':'Beth Sharratt', 'Annie Streufert':'Annie Streufert','Justin Guerin':'Justin Guerin'};
        const stateOptions = {'AL':'AL','AK':'AK','AS':'AS','AZ':'AZ','AR':'AR','CA':'CA','CO':'CO','CT':'CT','DE':'DE','DC':'DC','FL':'FL','GA':'GA','GU':'GU','HI':'HI','ID':'ID','IL':'IL','IN':'IN','IA':'IA','KS':'KS','KY':'KY','LA':'LA','ME':'ME','MD':'MD','MA':'MA','MI':'MI','MN':'MN','MS':'MS','MO':'MO','MT':'MT','NE':'NE','NV':'NV','NH':'NH','NJ':'NJ','NM':'NM','NY':'NY','NC':'NC','ND':'ND','MP':'MP','OH':'OH','OK':'OK','OR':'OR','PA':'PA','PR':'PR','RI':'RI','SC':'SC','SD':'SD','TN':'TN','TX':'TX','UT':'UT','VT':'VT','VA':'VA','VI':'VI','WA':'WA','WV':'WV','WI':'WI','WY':'WY'};
        const entityTypeOptions ={'Admin': 'Admin', 'Customer':'Customer','Lead':'Lead', 'Logistics':'Logistics','Prospect':'Prospect','Supplier':'Supplier',"Warehouse":"Warehouse"};

    let stateFilter;

    this.state = { 
      term:'',
      customers: [],
      docIDs:[],
      altTblColumns:[
        {
          data: "cid",
          title: "Company ID",
          visible:false,
        },
        {
          data: "supplierLink",
          title: "Supplier Name",
          //formatter: (cell, row) => <a href={'/company/'+row.cid}> {cell} </a>
        },
        {
          data: "primarycontact",
          title: "Primary Contact",
        },
        {
          data: "corpCity",
          title: "City",
        },
        {
            data: "corpState",
            title: "State",
        },
        {
            data: "cid",
            text: "Items",
            orderable:false,
            //sDummyField:true,
            render: x => `<a href="/items/${x}">Items</a>`
            
        }   
    ],
      tblColumns:[
        {
          dataField: "cid",
          text: "Company ID",
          sort: false,
          hidden:true
        },
        {
          dataField: "name",
          text: "Supplier Name",
          sort: true,
          formatter: (cell, row) => <a href={'/company/'+row.cid}> {cell} </a>
        },
        {
          dataField: "primarycontact",
          text: "Primary Contact",
          sort:true
        },
        {
          dataField: "corpCity",
          text: "City",
          sort:true
        },
        {
            dataField: "corpState",
            text: "State",
            //sort:true,
            filter: selectFilter({
              options: stateOptions,
              getFilter: (filter) => {
                stateFilter = filter;
              },
              onFilter: filterVal => console.log(`Filter quality ${filterVal}`)
          })
        },
        {
            dataField: "items",
            text: "Items",
            sort:false,
            isDummyField:true,
            formatter: (cell, row) => <a href={'/items/'+row.cid}>Items</a>
            
        }   
    ],
      loading:true
    };

    const handleClick = () => {
      console.log('handle clear click');
      stateFilter('');
    };

    //this.props.dispatch(getCrmStatus(this.props.params.id));
  }


  componentDidMount (){
      this.setState({loading:true});
      
      this.unsubscribe = this.props.firebase
        .suppliers()
        .onSnapshot(snapshot => {
   
          let customers=[];

          snapshot.forEach(doc =>
           customers.push({ ...doc.data(), supplierLink:"<a href='/company/"+doc.id+"'>"+doc.data().name+"</a>",cid: doc.id }),
          );
            console.log(customers.length);
            this.setState({customers,loading:false});
            
            $('#example').DataTable({
              data: customers,
              columns: this.state.altTblColumns,
              pageLength: 10,
              //filter: true,
              //deferRender: true,
              initComplete: function () {
                this.api().columns([4]).every( function () {
                    var column = this;
                    var select = $('<select class="form-control filterCtrl"><option value="">...</option></select>')
                        .appendTo( $(column.footer()).empty() )
                        .on( 'change', function () {
                            var val = $.fn.dataTable.util.escapeRegex(
                                $(this).val()
                            );
     
                            column
                                .search( val ? '^'+val+'$' : '', true, false )
                                .draw();
                        } );
     
                    column.data().unique().sort().each( function ( d, j ) {
                        select.append( '<option value="'+d+'">'+d+'</option>' )
                    } );
                } );

                $('#example tfoot tr').appendTo('#example thead');
            }
            });

            $('.clear-filters').prependTo('#example_length');

        });
      
      {/*var q
      uery = this.props.firebase.db.collection('entities');
       query.get().then((qSnapshot)=>{
        qSnapshot.forEach((document)=>{
          //console.log("Document: " + document.data().name)
          //customers.push({entityType:document.data().entityType,repName:document.data().repName,name:document.data().name});
          document.ref.collection('contacts').get().then((cSnapshot)=>{
            cSnapshot.forEach((contact)=>{
          f(contact.data().isPrimary == true && contact.data().isActive == 1){
                //customers.push({entityType:document.data().entityType,repName:document.data().repName,name:document.data().name,primarycontact:contact.data().fname});
                document.ref.collection('locations').get().then((lSnapshot)=>{
                  lSnapshot.forEach((lItem)=>{
                    if(lItem.data().isActive == 1)
                    {
                      //console.log("Location: " + lItem.data().city + ", " + lItem.data().stateAbbr+" Contact: " + contact.data().fname + " " + contact.data().lname + " For "+document.data().name + " " + document.id + " - "+document.data().repName);
                      customers.push({entityType:document.data().entityType,repName:document.data().repName,name:document.data().name,city:lItem.data().city, stateAbbr: lItem.data().stateAbbr, primarycontact: contact.data().fname + " " + contact.data().lname,cid:document.id});
                    }
                  });
                });
                }
                //customers.push({entityType:document.data().entityType,repName:document.data().repName,name:document.data().name,primarycontact:contact.data().fname});
            });
          });
        });*/}

        
        //console.log(customers.length);
      
    }

    handlerClickCleanFiltered() {
      this.refs.name1.cleanFiltered();
    }

    componentWillReceiveProps(newProps) {
      
    }

    onColumnMatch(
      searchText,
      value,
      column,
      row
    ){
      // implement your custom match logic on every cell value
      console.log(searchText + " " +value);
    }

    addContactHandler(event){
      this.context.router.push('/addcompany');
    }
    
    clearSearchFilters(){
      $('#example').DataTable().search( '' ).columns().search( '' ).draw();
      $('tfoot input').val('');

      $('.filterCtrl').each(function(){
        $(this).val('');
      });

      
    }

    render(){
      const goBack = () => {
        this.props.history.goBack()
    }
      const { ExportCSVButton } = CSVExport;
        const {customers, tblColumns, loading} = this.state;
        const containerStyle={
          maxWidth:1200 +"px",
        };
        const { SearchBar, ClearSearchButton } = Search;
        
        if(customers === "undefined")
        {
          return (<div><h1>Loading Customer Data Please Wait...</h1></div>)
        }
        return (
          
            <div className="content">
               <nav class="navbar navbar-expand-lg navbar-light bg-red">
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarNav">
                        <ul class="navbar-nav">
                        <li className="nav-item">
                            <button className="btn btn-outline-light" style={{marginTop:"25px"}} onClick={goBack}>Back</button>
                        </li>
                        <li className="nav-item">
                        <a className="nav-link-menu" href="../home">Home</a>
                        </li>
                        <li class="nav-item">
                        <a className="nav-link-menu" href="../itemsearch">Item Search</a>
                        </li>
                        <li class="nav-item">
                        <a className="nav-link-menu" href="../signout">Sign Out</a>
                        </li>
                        </ul>
                    </div>
                    </nav>
              {/*<div className="row">
              <div className="col-md-2">
                <Link to={{pathname: `${ROUTES.ADMIN}`}}><button className="btn btn-warning" style={{marginTop:30+"px"}}><FontAwesomeIcon icon={faHome}></FontAwesomeIcon></button></Link>
                </div>
                <div className="col-md-10">

                </div>
                
        </div>*/}
              {loading && <h1>Loading State...</h1>}
                {/*<div className="row">
                  <div className="col-md-10">
                <SearchBar onSubmit={this.onSearchSubmit}/>
                </div>
                <div className="col-md-2">
                <Link to={{pathname: `${ROUTES.ADD_COMPANY}`}}><button className="btn btn-info" style={{marginTop:30+"px"}}>Add Customer</button></Link>
                </div></div>*/}
                <div className="container-lg">
                <div className="row" style={{marginTop:10+"px"}}><div className="col-md-3"> <h3>Search Suppliers</h3></div><div className="col-md-2"><Link to={{pathname: `${ROUTES.ADD_COMPANY}`}}><button className="btn btn-danger" >Add Supplier</button></Link></div></div>
                <div className="clear-filters" style={{float:"left",marginRight:10+"px"}}>
                            <button type="button" className="btn btn-outline-danger right" onClick={e => this.clearSearchFilters()}><FontAwesomeIcon icon={faRedo}></FontAwesomeIcon> Clear Search/Filters</button>
                            </div>
                            <table id="example" className="display">
                                      <thead></thead>
                                      <tbody></tbody>
                                      <tfoot>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                      </tfoot>
                                  </table>
                    </div>
            </div>
            );
    }
}
const condition = authUser => !!authUser;

const SearchPageForm = compose(
  withAuthorization(condition),
  withFirebase,
)(SearchPage);

export default withFirebase(SearchPage);

export {SearchPageForm};


