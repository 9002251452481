import React, { Component } from 'react'
import ChartistGraph from 'react-chartist'
import {Link} from 'react-router-dom'
import { compose } from 'recompose';

import { withAuthorization } from '../Session';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle,faClock,faHistory, faEye, faList, faCalendar} from '@fortawesome/free-solid-svg-icons';
import {faUser,faUserCircle,faLock} from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Sidebar/sidebar.css'
import { AuthUserContext } from '../Session';
import * as ROLES from '../../constants/roles';
import SelectableCalendar from '../Reminder/calendarview';
import DragDropCalendar from '../Reminder/dragdropcalendarview';
import QuoteTable from '../Dashboard/statustable';
import TodaysReminders from '../Reminder/todaylist';

class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading:true,
      authUser:'',
      customers:[], //supplier,warehouse,customer
      contacts:[],
      reminders:[],
      items:[],
      leads:[],
      prospects:[],
      quotes:[],
      errormessage: '',
      seeCalendar: true,
    };
  }

  componentDidMount(){

        //console.log(this.props.firebase.getCurrentUser());

        this.unsubscribe = this.props.firebase
        .customers()
        .onSnapshot(snapshot => {
          let customers = [];
   
          snapshot.forEach(doc =>
            customers.push({ ...doc.data(), cid: doc.id }),
          );

          this.setState({
            customers,
          });
        });

        this.unsubscribe = this.props.firebase
        .leads()
        .onSnapshot(snapshot => {
          let leads = [];
   
          snapshot.forEach(doc =>
            leads.push({ ...doc.data(), cid: doc.id }),
          );

          this.setState({
            leads,
          });
        });

        this.unsubscribe = this.props.firebase
        .prospects()
        .onSnapshot(snapshot => {
          let prospects = [];
   
          snapshot.forEach(doc =>
            prospects.push({ ...doc.data(), cid: doc.id }),
          );

          this.setState({
            prospects,
          });
        });

        this.unsubscribe = this.props.firebase
        .activeQuotes()
        .onSnapshot(snapshot => {
          let quotes = [];
   
          snapshot.forEach(doc =>
            quotes.push({ ...doc.data(), qid: doc.id }),
          );

          this.setState({
            quotes,
          });
        });

        /*this.unsubscribe = this.props.firebase
        .contacts()
        .onSnapshot(snapshot => {
          let contacts = [];
   
          snapshot.forEach(doc =>
            contacts.push({ ...doc.data(), uid: doc.id }),
          );

          this.setState({
            contacts,
          });
        });*/

        Date.prototype.addHours= function(h){
          this.setHours(this.getHours()+h);
          return this;
      }

        this.unsubscribe = this.props.firebase
        .getReminders()
        .onSnapshot(snapshot => {
          let reminders = [];
   
          snapshot.forEach(doc =>
            reminders.push({ 
                title:doc.data().entityName + "-"+doc.data().reminderText,
                entityID:doc.data().entityID,
                start:new Date(doc.data().reminderDate.seconds * 1000), 
                end:new Date(doc.data().reminderDate.seconds * 1000).addHours(.5),
                userID:doc.data().userID,
                id: doc.id
             }),
          );

          this.setState({
            reminders,
            loading:false
          });
        });

        this.unsubscribe = this.props.firebase
        .allItems()
        .onSnapshot(snapshot => {
          let items = [];
   
          snapshot.forEach(doc =>
            items.push({ ...doc.data(), itemid:doc.id}),
          );

          this.setState({
            items,
            loading:false
          });
        });

  }

  swapView(){
    if(this.state.seeCalendar)
    {
      this.setState({seeCalendar:false})
    }
    else{
      this.setState({seeCalendar:true})
    }
    
  }

  render() {
  
    const {contacts,seeCalendar, customers,reminders,leads,prospects,loading,items,quotes} = this.state;
    let dataPie = {
      labels: ["40%", "20%", "40%"],
      series: [40, 20, 40]
    }
    let dataSales = {
      labels: [
        "9:00AM",
        "12:00AM",
        "3:00PM",
        "6:00PM",
        "9:00PM",
        "12:00PM",
        "3:00AM",
        "6:00AM"
      ],
      series: [
        [287, 385, 490, 492, 554, 586, 698, 695],
        [67, 152, 143, 240, 287, 335, 435, 437],
        [23, 113, 67, 108, 190, 239, 307, 308]
      ]
    }
    return (
<AuthUserContext.Consumer>
            {authUser=>(
      <div className="content">
        <nav className="navbar navbar-expand-lg navbar-light bg-blue" style={{marginBottom:'15px'}}>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNavDropdown">
              <ul className="navbar-nav">
                <li className="nav-item active">
                  <a className="nav-link-menu" href="#">Home <span className="sr-only">(current)</span></a>
                </li>
                {authUser.role === "Office" && 
                  <li className="nav-item">
                    <a className="nav-link-menu" href="../siteconfig">Manage Domains</a>
                  </li>
                  }
                  {authUser.role === "Office" && 
                  <li className="nav-item">
                    <a className="nav-link-menu" href="../suppliersearch">Supplier</a>
                  </li>
                  }
                  {authUser.role === "Admin" && 
                  <li className="nav-item dropdown">
                    <a className="nav-link-menu dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Site Management
                    </a>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                      <a className="dropdown-item" href="../reports">Reporting</a>
                      <a className="dropdown-item" href="../siteconfig">Domains</a>
                      <a className="dropdown-item" href="../pricing">Pricing</a>
                      <a className="dropdown-item" href="../users">Users</a>
                      <a className="dropdown-item" href="../warehouses">Warehouses</a>
                      <a className="dropdown-item" href="../about">About</a>
                      <a className="dropdown-item" href="../althome">Alt Home</a>
                      {/*<a className="dropdown-item" href="../suppliersearch">Supplier</a>*/}
                    </div>
                  </li>
                  }
                  {authUser.role === "Admin" && 
                  <li className="nav-item">
                    <a className="nav-link-menu" href="../suppliersearch">Supplier</a>
                  </li>
                  }
                  {authUser.role === "Buyer" && 
                  <li className="nav-item">
                  <a className="nav-link-menu" href="../siteconfig">Manage Domains</a>
                </li>
                  }
                  {authUser.role === "Buyer" && 
                  <li className="nav-item">
                    <a className="nav-link-menu" href="../suppliersearch">Suppliers</a>
                  </li>
                  }
                <li className="nav-item">
                  <a className="nav-link-menu" href="../search">Contacts</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link-menu" href="../itemsearch">Items</a>
                </li>
                {/*<<li className="nav-item dropdown">
                  <a className="nav-link-menu dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Items
                  </a>
                  <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                  <a className="dropdown-item" href="../itemsearch">Item Search</a>
                    a className="dropdown-item" href="../inventory">Warehouse Inventory</a>
                    <a className="dropdown-item" href="">Order Inventory</a>
                    <a className="dropdown-item" href="../confirm">Log Inventory</a>
                    <a className="dropdown-item" href="../suppliersearch">Supplier</a>
                  </div>
                </li>*/}
                {authUser.role === "Admin" && 
                <li className="nav-item dropdown">
                  <a className="nav-link-menu dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Inventory
                  </a>
                  <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                  <a className="dropdown-item" href="../inventory">Inventory</a>
                  <a className="dropdown-item" href="../orders">Inventory Orders</a>
                  <a className="dropdown-item" href="../inventory/order">Create Inventory Orders</a>
                    {/*<a className="dropdown-item" href="../suppliersearch">Supplier</a>*/}
                  </div>
                </li>
                }
                {authUser.role === "Admin" && 
                 <li className="nav-item">
                 <a className="nav-link-menu" href="../quotes">Quotes</a>
               </li>}
                {/*<li className="nav-item dropdown">
                  <a className="nav-link-menu dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Quotes
                  </a>
                  <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                  <a className="dropdown-item" href="../quotes">Quotes</a>
                  
                  </div>
                </li>*/}
                <li className="nav-item">
                    <a className="nav-link-menu" href="../signout">Sign Out</a>
                  </li>
              </ul>
            </div>
          </nav>
        <div className="container-fluid">
          <div className="row">
            </div>
            <div className="row">
              <div className="col-md-6">
                <QuoteTable></QuoteTable>
              </div>
              <div className="col-md-6">
              {this.state.seeCalendar ? <FontAwesomeIcon id="swapView" style={{color:"lightgrey",marginLeft:15+"px",cursor:"pointer"}} icon={faList} onClick={e=>this.swapView()}></FontAwesomeIcon> : <FontAwesomeIcon id="swapView" style={{color:"lightgrey",marginLeft:15+"px",cursor:"pointer"}} icon={faCalendar} onClick={e=>this.swapView()}></FontAwesomeIcon> }
              {!loading && seeCalendar && <DragDropCalendar auid={authUser.uid} role={authUser.role} events={reminders}></DragDropCalendar>}
              {!loading && !seeCalendar && <TodaysReminders auid={authUser.uid} role={authUser.role} events={reminders}></TodaysReminders>}
              </div>
            </div>
          <div className="row" style={{marginTop:15+"px"}}>
            <div className="col-md-3">
            <div className="card ">
                <div className="card-header-bl">
                  <h4 className="card-title">Contacts</h4>
                  {/*<p className="card-category">Contacts</p>*/}
                </div>
                <div className="card-body text-center">
              <h1><a href="../search">{customers.length}</a></h1>
                  <hr />
                  <div className="stats">
                  {/*<FontAwesomeIcon icon={faClock}></FontAwesomeIcon> Campaign sent 2 days ago*/}
                                    </div>
                </div>
              </div>
              </div>
              <div className="col-md-3">
              <div className="card ">
                <div className="card-header-yl">
                  <h4 className="card-title">Quotes</h4>
                  {/*<p className="card-category">Prospects</p>*/}
                </div>
                <div className="card-body text-center">
                  <h1><a href="../quotes">{quotes.length}</a></h1>
                  <hr />
                  <div className="stats">
                                    </div>
                </div>
          </div>
            </div>
            <div className="col-md-3">
            <div className="card ">
                <div className="card-header-dbl">
                  <h4 className="card-title">Leads - Prospects</h4>
                  {/*<p className="card-category">Leads</p>*/}
                </div>
                <div className="card-body text-center">
                  <h1><a href="../search">{leads.length} - {prospects.length}</a></h1>
                  <hr />
                  <div className="stats">
                  
                                    </div>
                </div>
              </div>
              </div>
              <div className="col-md-3">
              <div className="card ">
                <div className="card-header-rd">
                  <h4 className="card-title">Items</h4>
                  {/*<p className="card-category">Supplier & Inventory Items</p>*/}
                </div>
                <div className="card-body text-center">
                  <h1><Link to="/suppliersearch">{items.length}</Link></h1>
                  <hr />
                  <div className="stats">
                  
                                    </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
            {/*!loading && <SelectableCalendar auid={authUser.uid} events={reminders}></SelectableCalendar>*/}
            
            {/*<div className="card ">
                <div className="card-header ">
                  <h4 className="card-title">Prospects</h4>
                  <p className="card-category">Prospects</p>
                </div>
                <div className="card-body text-center">
                  <h1><Link to="/search">{prospects.length}</Link></h1>
                  <hr />
                  <div className="stats">
                                    </div>
                </div>
          </div>*/}

            </div>
          </div>
          {/*<div className="row">

            <div className="col-md-4">
              <div className="card ">
                <div className="card-header ">
                  <h4 className="card-title">Email Statistics</h4>
                  <p className="card-category">Last Campaign Performance</p>
                </div>
                <div className="card-body ">
                  <ChartistGraph data={dataPie} type="Pie" />
                  <div className="legend">
                  <FontAwesomeIcon icon={faCircle} className="text-info"></FontAwesomeIcon> Open
                            <FontAwesomeIcon icon={faCircle}className="text-danger"></FontAwesomeIcon> Bounce
                            <FontAwesomeIcon icon={faCircle} className="text-warning"></FontAwesomeIcon> Unsubscribe
                                    </div>
                  <hr />
                  <div className="stats">
                  <FontAwesomeIcon icon={faClock}></FontAwesomeIcon> Campaign sent 2 days ago
                                    </div>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="card">
                <div className="card-header ">
                  <h4 className="card-title">Users Behavior</h4>
                  <p className="card-category">24 Hours performance</p>
                </div>
                <div className="card-body ">
                  <ChartistGraph data={dataSales} type="Line" />
                </div>
                <div className="card-footer ">
                  <div className="legend">
                    <FontAwesomeIcon icon={faCircle} className="text-info"></FontAwesomeIcon>Open
                    <FontAwesomeIcon icon={faCircle} className="text-danger"></FontAwesomeIcon>Click
                    <FontAwesomeIcon icon={faCircle} className="text-warning"></FontAwesomeIcon>Click Second Time
                </div>
                  <hr />
                  <div className="stats">
                  <FontAwesomeIcon icon={faHistory}></FontAwesomeIcon> Updated 3 minutes ago
                  </div>
                </div>
              </div>
            </div>

    </div>*/}
        </div>
      </div>
            )}
            </AuthUserContext.Consumer>
    )
  }
}

const condition = authUser => !!authUser;

export default compose(withAuthorization(condition),)(Dashboard);