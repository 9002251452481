import React, { Component } from 'react'
import * as ROUTES from '../../constants/routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Files/fileupload.css';
import { withFirebase } from '../Firebase';
import firebase from 'firebase';

class FileUpload extends Component {
    constructor(props) {
        super(props);
     
        this.state = {
          loading:false,
          companyid:this.props.cid,
          docName:'',
          isActive:1,
          dateUploaded:'',
          uploading: false,
          percent: 0,
          file: '',
          error: ''
        };

        //console.log(this.props);
        this.handleFileSelect = this.handleFileSelect.bind(this);
        this.handleFileUpload = this.handleFileUpload.bind(this);
        //this.recordFile = this.recordFile.bind(this);

    }


    handleFileSelect(e) {
      this.setState({file: e.target.files[0]})
    }

    handleFileUpload=()=>{
      this.setState({uploading: true})
      console.log(this.state.docName);
      //console.log("Company ID: " + this.state.companyid);
      //console.log("File: "+this.state.file.name);
      let fileUrl = 'not done yet';
      this.props.firebase.storageRef.child('companydocs/'+this.state.companyid+'/').child(this.state.file.name)
        .put(this.state.file)
        .then(snap => {
         
          // the loading percent logic here?
          // how do i keep tabs on the percent?
            snap.ref.getDownloadURL().then(function(downloadURL) {
            console.log("File available at", downloadURL);
            //this.setState({docUrl:downloadURL});
            //this.recordFile(downloadURL);
            fileUrl = downloadURL;
            

            //this.setState({uploading: false,docName:'',file:''})
          }).then
          (()=>{
            //console.log(fileUrl);
            firebase.firestore().collection(`entityDocuments`).add({
              docName:this.state.docName,
              docUrl:fileUrl,
              dateUploaded:firebase.firestore.Timestamp.now(),
              entityID:this.state.companyid,
              isActive:1,
            });
            this.setState({uploading:false,file:'',docName:''})
            document.getElementById('docName').value = "";
            document.getElementById('fileUpload').value = "";
          });

        })
        .catch(err => this.setState({error: err.message}))

       /*
       this.props.firebase.db.collection(`entityDocuments`).add({
              docName:this.state.docName,
              docUrl:downloadURL,
              dateUploaded:firebase.firestore.Timestamp.now(),
              entityID:this.state.entityID,
              isActive:1,
            });
            */

    }


    recordFile(downloadURL) {
      this.props.firebase.db.collection(`entityDocuments`).add({
        docName:this.state.docName,
        docUrl:downloadURL,
        dateUploaded:firebase.firestore.Timestamp.now(),
        entityID:this.state.companyid,
        isActive:1,
      });

      this.setState({file:'',docName:''});
    }
    

    componentDidMount(){
      this.setState({companyid: this.props.cid});
      //console.log("On Mount: "+this.state.companyid);
    }

    myChangeHandler = (event) => {
      let nam = event.target.name;
      let val = event.target.value;
      let err = '';
      
      this.setState({errormessage: err});
      this.setState({[nam]: val});
    }
    
    render() {
      return (
        <div>
          <div className="row" style={{paddingTop:25+"px"}}>
            <div className="col-md-4"><input type='text' id="docName" name="docName" onChange={this.myChangeHandler} className="form-control" style={{}} placeholder="Document Name"/></div>
            <div className="col-md-4"><input type='file' id="fileUpload" onChange={this.handleFileSelect} className="form-control"/></div>
            <div className="col-md-2"><button onClick={this.handleFileUpload} className="btn btn-success">Upload</button></div>
          </div>
            <div className="row">
            {this.state.uploading 
              ? <div>
                  <div className='load-bar'/>
                  <span>Uploading: {this.state.percent}%</span>
                </div>
              : null
            }
            </div>
            <div className="row">
              <pre>
                <code>
                  {this.state.error ? <span className='error'>{this.state.error}</span> : null}
                  {/*JSON.stringify(this.state.file, null, 2)*/}
                </code>
              </pre>
            </div>
          </div>
      );
    }
}

export default withFirebase(FileUpload);