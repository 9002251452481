import React, { Component } from 'react';
import {withFirebase} from '../Firebase';
import 'bootstrap/dist/css/bootstrap.min.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPencilAlt,faCheckCircle,faTimes, faCheck} from '@fortawesome/free-solid-svg-icons';
import { Redirect } from 'react-router-dom';

class CustomerCodeControl extends Component{
    constructor(props){
        super(props);

        this.state = {
            loading:false,
            companyId:this.props.cid,
            contactCode:this.props.cocode,
            editMode:false,
            users:[],
        };
    }

    componentDidMount(){
        
    }

    componentWillUnmount(){
        //this.unsubscribe();
    }

    repEditMode=()=>{
        this.setState({editMode:true});

    }

    repNameSelect=(event)=>{
        let nam = event.target.name;
        let val = event.target.value;
        //console.log(nam);
        this.setState({[nam]: val});
    }

    repNameCommit=()=>{
        //this.setState({username:'foobar'});
        //console.log("Commit new Company Name: " + this.state.companyName + " for: "+this.state.companyId);
        this.props.firebase.updateCompanyCode(this.props.cid,this.state.contactCode);
        this.setState({editMode:false});
    }

    repNameCancel=()=>{
        this.setState({editMode:false});
    }

    render(){
        const {editMode,contactCode} = this.state;
        return(
            <div className="row" style={{marginTop:10+"px"}}><div className="col-sm-4"><strong>Company Code:</strong></div>
            {!editMode 
                ? <div className="col-sm-4">{contactCode}<span>     </span><FontAwesomeIcon icon={faPencilAlt} style={{cursor:'hand',marginLeft:15+"px"}} onClick={this.repEditMode} data-toggle="tooltip" data-placement="right" title="Edit"></FontAwesomeIcon></div>
                : <div className="row" style={{marginTop:10+"px"}}><div className="col-md-8"><input type="text" name="contactCode" onChange={this.repNameSelect} className="form-control" value={contactCode}/></div>
            <div className="col-md-4"><div className="btn-group" role="group" aria-label="Basic example"><button type="button" className="btn btn-sm btn-success" onClick={this.repNameCommit}>Submit</button><button type="button" className="btn btn-sm btn-danger" onClick={this.repNameCancel}>Cancel</button></div></div>
            </div>}
            </div>
        );
    }
}
export default withFirebase(CustomerCodeControl);
