import React, { Component } from 'react';
import { Switch,Route, Link, useNavigate } from 'react-router-dom';
import { DatePicker, DatePickerInput } from 'rc-datepicker';
import 'rc-datepicker/lib/style.css';

import * as ROUTES from '../../constants/routes';
import {withFirebase} from '../Firebase';
import { AuthUserContext } from '../Session';
import ChartistGraph from 'react-chartist'
import { compose } from 'recompose';
import moment from "moment";

import { withAuthorization } from '../Session';

import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faStar,faThumbtack,faUserPlus,faTimes,faEdit,faEraser, faStickyNote,faIndustry,faBuilding,faHome, faTrashAlt,faPencilAlt, faCheckCircle} from '@fortawesome/free-solid-svg-icons';
import NoteBasePage from '../Notes';
import QuoteTable from '../Quote/quotetable';
import FileTable from '../Files/filetable';
import FileUpload from '../Files';
import RepControl from '../Admin/repcontrol';
import EntityControl from '../Admin/entitytypecontrol';
import CustomerCodeControl from '../Admin/custcode';
import LocationControl from '../Company/newoffice';
import CompanyNameControl from '../Admin/coname';
import RemindersControl from '../Reminder';
import FileTableControl from '../Files/filetablecontrol';
import Modal from '../Modal';
import TabControl from '../Quote/tabcontrol';


import {NotesList} from '../Notes/notebase';

class CompanyDetailsPage extends Component{
    constructor(props) {
        super(props);
        this.state = {
          entityID:this.props.match.params.cid,
          entityName:'',
          entityType:'',
          entity:null,
          notes:[],
          users:[],
          items:[],
          pinnednotes:[],
          locations:[],
          reminders:[],
          quotes:[],
          showNoteForm:false,
          showTypeControl:false,
          showRepControl:false,
          showLocationsControl:false,
          modal:false,
          Rmodel:false,
          reminderID:'',
          reminderText:'',
          reminderDate:'',
          noteid:'',
          comment:'',
          errormessage: '',
          fullUrl:'',
          activeTab:'',
          ...props.location.state,
        };

        this.cancelNotesHandler = this.cancelNotesHandler.bind(this);
      }
      componentDidMount(){
        this.setState({loading:true});
        this.setState({fullUrl:window.location.href});
        let activeTabUrl = 'location';
        if(window.location.href.indexOf("#") !== -1)
        {
            let locationArray = window.location.href.split("#");
            activeTabUrl = locationArray[1];
        }

        this.setState({activeTab:activeTabUrl});
        console.log("Active Tab: "+this.state.fullUrl);
        //this.setState({entityID:this.props.match.params.cid});
        //console.log("State: " + this.state.entityID + " - Param: "+this.props.match.params.cid);
        
        this.unsubscribe = this.props.firebase
        .contactsByCompany(this.props.match.params.cid)
        .onSnapshot(snapshot => {
          let users = [];
   
          snapshot.forEach(doc =>
            users.push({ ...doc.data(), uid: doc.id }),
          );

            users.sort((a,b)=>a.isPrimary - b.isPrimary);
            users.reverse();

          this.setState({
            users,
            loading: true,
          });
        });
        
        this.unsubscribe = this.props.firebase
        .company(this.props.match.params.cid)
        .onSnapshot(snapshot => {
            console.log("Snapshot Name: " + snapshot.data().name);
          this.setState({
            entity:snapshot.data(),
            entityName:snapshot.data().name,
            entityType:snapshot.data().entityType,
            loading: true,
          });
        });

        console.log("Details EntityType: " + this.state.entityType);
        this.unsubscribe = this.props.firebase
        .locationsByCompany(this.props.match.params.cid)
        .onSnapshot(snapshot => {
          let locations = [];

          snapshot.forEach(doc =>
            locations.push({ ...doc.data(), locationid: doc.id }),
          );

          this.setState({
            locations,
            loading: true,
          });
        });

        this.unsubscribe = this.props.firebase
        .pinnedNotesByCompany(this.props.match.params.cid)
        .onSnapshot(snapshot => {
          let pinnednotes = [];

          snapshot.forEach(doc =>
            pinnednotes.push({ comment:doc.data().comment,timestamp:new Date(doc.data().datetime.seconds * 1000).toLocaleDateString() + "-" +new Date(doc.data().datetime.seconds * 1000).toLocaleTimeString(), noteid: doc.id }),
          );

          this.setState({
            pinnednotes,
            loading: true,
          });
        });

        this.unsubscribe = this.props.firebase
        .notesByCompany(this.props.match.params.cid)
        .onSnapshot(snapshot => {
          let notes = [];
   
          snapshot.forEach(doc =>
            notes.push({ 
                comment:doc.data().comment,
                timestamp:new Date(doc.data().datetime.seconds * 1000).toLocaleDateString() + " " +new Date(doc.data().datetime.seconds * 1000).toLocaleTimeString(), 
                noteid: doc.id }),
          );

          this.setState({
            notes,
            loading: true,
          });
        });

        this.unsubscribe = this.props.firebase
        .remindersByCompany(this.props.match.params.cid)
        .onSnapshot(snapshot => {
          let reminders = [];
   
          snapshot.forEach(doc =>
            reminders.push({ 
                comment:doc.data().reminderText,
                timestamp:new Date(doc.data().reminderDate.seconds * 1000).toLocaleDateString() + " " +new Date(doc.data().reminderDate.seconds * 1000).toLocaleTimeString(), 
                rid: doc.id }),
          );

          this.setState({
            reminders,
            loading: true,
          });
        });

        

        this.unsubscribe = this.props.firebase
        .getItemsBySupplier(this.props.match.params.cid)
        .onSnapshot(snapshot => {
          let items = [];
   
          snapshot.forEach(doc =>
            items.push({ 
                itemName:doc.data().itemName,
                itemDescription:doc.data().itemDescription,
                itemid: doc.id }),
          );

          this.setState({
            items,
            loading: false,
          });
        });
        
        console.log("Details Entity Name: "+this.state.entityName);
    }

    componentWillUnmount()
    {
        //this.props.firebase.users().off();
        //this.unsubscribe();
        this.setState = (state,callback)=>{
            return;
        };
    }

    deleteUser=(entityid,userid)=>{
        //console.log('Deactivating User: '+userid + ' for: '+entityid);
        this.props.firebase.deactivateContact(entityid,userid);
    }

    deleteComment(commentID){
        //console.log(commentID);
    }

    deleteNote(pnid)
    {
        //console.log(pnid);
        this.props.firebase.deactivateNote(pnid);
    }

    deleteLocation(locationid)
    {
        //console.log(pnid);
        this.props.firebase.deactivateLocation(locationid);
    }

    deleteCompany(companyid)
    {
        console.log(companyid);
        this.props.firebase.deactivateCompany(companyid);
        this.props.history.push('/search');
    }

    /*deleteContact(contactid)
    {
        //console.log(pnid);
        this.props.firebase.deactiveateContact(contactid);
    }*/

    activateNoteForm=()=>{
        this.setState({showNoteForm:true});
    }

    deactivateNoteForm=()=>{
        this.setState({showNoteForm:false});
    }

    cancelNotesHandler=()=>{
        //console.log("Cancel Note Form");
        this.setState({showNoteForm:false});
    }

    activateLocationForm=()=>{
        this.setState({showLocationsControl:true});
    }

    deactivateLocationForm=()=>{
        this.setState({showLocationsControl:false});
    }

    cancelLocationsHandler=()=>{
        //console.log("Cancel Note Form");
        this.setState({showLocationsControl:false});
    }

    modalOpen(noteid,comment) {
        this.setState({ modal: true, noteid:noteid, comment:comment });
      }
    
      modalClose() {
        this.setState({
          modalInputName: "",
          modal: false
        });
    }

    modalReminderOpen(reminderID,reminderText,reminderDate) {
        this.setState({ Rmodal: true, reminderID:reminderID, reminderText:reminderText, reminderDate:reminderDate });
      }
    
      modalRClose() {
        this.setState({
          modalInputName: "",
          Rmodal: false
        });
    }

    onChange = (jsDate, dateString) => {
        // ...
        //console.log(jsDate + " "+dateString);
        this.setState({reminderDate:jsDate});
    }

    myChangeHandler = (event) => {
        console.log('Chages');
        let nam = event.target.name;
        let val = event.target.value;
        let err = '';
        if (nam === "age") {
          if (val !=="" && !Number(val)) {
            err = <strong>Your age must be a number</strong>;
          }
        }
        this.setState({errormessage: err});
        this.setState({[nam]: val});
        //console.log(nam + " " + val);
      }

    updateNote(event){
        this.props.firebase.db.collection('transcript').doc(this.state.noteid).update({
            comment:this.state.comment
          });

          this.modalClose();
    }

    updateReminder(event){
        this.props.firebase.db.collection('reminders').doc(this.state.reminderID).update({
            entityName: this.state.entityName,
            reminderDate:this.state.reminderDate,
            reminderText: this.state.reminderText
          })

          this.modalRClose();
    }

      render() {
          const{entityID, entityName,entityType,entity,notes,users,locations,reminders,items,loading,pinnednotes,showNoteForm,showLocationsControl,showRepControl,showTypeControl} = this.state;
          let dataPie = {
            labels: ["40%", "20%", "40%"],
            series: [40, 20, 40]
          }
          const rot = {
            transform: `rotate(315deg)`
          };

          const pencilColor={
              color:`#999`
          };
        return (
            <AuthUserContext.Consumer>
            {authUser=>(
            <div className="content">
                <Modal show={this.state.modal} handleClose={e => this.modalClose(e)}>
                                <div className="form-group">
                                    <label>Edit Note:</label>
                                    <input type="hidden" name="noteid" value={this.state.noteid}/>
                                    <input
                                    type="text"
                                    value={this.state.comment}
                                    name="comment"
                                    onChange={this.myChangeHandler}
                                    className="form-control"
                                    />
                                </div>
                                <div className="form-group">
                                    <button className="btn btn-success" onClick={e => this.updateNote(e)} type="button">
                                    Save
                                    </button>
                                    <button className="btn btn-danger" onClick={e => this.modalClose(e)} type="button">
                                    Cancel
                                    </button>
                                </div>
                                </Modal>
                        <Modal show={this.state.Rmodal} handleClose={e => this.modalRClose(e)}>
                                <div className="form-group">
                                    <label>Edit Reminder:</label>
                                    <input type="hidden" name="reminderID" value={this.state.reminderID}/>
                                    <input
                                    type="text"
                                    value={this.state.reminderText}
                                    name="reminderText"
                                    onChange={this.myChangeHandler}
                                    className="form-control"
                                    />
                                    <DatePickerInput
                                        name="reminderDate"
                                        onChange={this.onChange}
                                        value={moment(this.state.reminderDate).format("YYYY-MM-DD")}
                                        className='my-custom-datepicker-component'
                                        />
                                </div>
                                <div className="form-group">
                                    <button className="btn btn-success" onClick={e => this.updateReminder(e)} type="button">
                                    Save
                                    </button>
                                    <button className="btn btn-danger" onClick={e => this.modalRClose(e)} type="button">
                                    Cancel
                                    </button>
                                </div>
                        </Modal>
                <nav className="navbar navbar-expand-lg navbar-light bg-blue">
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav">
                        <li className="nav-item">
                        <a className="nav-link-menu" href="../home">Home</a>
                        </li>
                        <li className="nav-item">
                        <a className="nav-link-menu" href="../search">Contact Search</a>
                        </li>
                        {authUser.role === "Admin" && 
                            <li className="nav-item">
                                <button type="button" className="btn btn-sm btn-danger" style={{marginTop:25+"px"}} onClick={() => {
                                        if (window.confirm("Delete this Company?")) {
                                            
                                            let removeCompany = this.deleteCompany.bind(this,this.state.entityID);
                                            removeCompany();
                                        }
                                    }}>Delete Company <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon></button>
                            </li>
                        }   
                        </ul>
                    </div>
                    </nav>
            
            <div className="container-lg" style={{paddingTop:35+"px"}}>
               
                {loading && <div>Loading Company Info...</div>}
                <div className="row" style={{paddingTop:25+"px"}}>
                    <div className="col-md-6">
                    {/*<h1>Information</h1>*/}
                        {entity && (
                            <div>
                                {/*<div className="card">
                                    <div className="card-header">
                                        <h1>{entity.name}
                                        <Link to={{pathname: `../company/${entityID}/edit`,state:{entity},}} style={{position:'absolute',right:30+'px'}} data-toggle="tooltip" data-placement="right" title="Edit"><FontAwesomeIcon icon={faPencilAlt} style={pencilColor}></FontAwesomeIcon></Link>
                                        </h1>
                                        </div>
                                    <div className="card-block">
                                        <h5 className="card-title"></h5>
                                        <RepControl cid={entityID} username={entity.repName}/>
                                        <EntityControl cid={entityID} companytype={entity.entityType}/>
                                    </div>
                        </div>*/}
                            <div className="card">
                                <div className="card-body">
                                    <CompanyNameControl cid={entityID} coname={entity.name}/>
                                    <hr></hr>
                                    <RepControl cid={entityID} username={entity.repName}/>
                                    <EntityControl cid={entityID} companytype={entity.entityType}/>
                                    <CustomerCodeControl cid={entityID} contactCode={entity.contactCode}/>
                                </div>
                            </div>
                            </div>
                            )}
                    </div>
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-body">
                        <div className="row" >
                            <div className="col-sm-12" style={{display:"inline-flex"}}>
                                <h3><FontAwesomeIcon style={rot} icon={faThumbtack}></FontAwesomeIcon>  Notes</h3>
                                <div className="btn-toolbar pull-right" style={{paddingLeft:35+"px"}}>
                                    {/*<div className="btn-group">
                                    <Link to={{pathname: `../addnote/${entityID}`,state:{entity}}} style={{fontSize:20+'px'}}><FontAwesomeIcon style={{color:'green'}} icon={faPlusSquare}></FontAwesomeIcon></Link>
                                        </div>*/}
                                </div>
                            </div>
                        </div>
                        
                    {pinnednotes.map(pn=> (
                        <div className="alert alert-primary" role="alert" key={pn.noteid}>
                            <div className="row"><div className="col-md-10"><span style={{fontSize:16+"px"}}>{pn.comment}</span></div><div className="col-md-2">
                                <div className="btn-group" role="group" aria-label="Basic example">
                                    <button className="btn btn-sm btn-info" style={{maxHeight:31+"px"}} onClick={e => this.modalOpen(pn.noteid,pn.comment)}><FontAwesomeIcon style={{color:"white"}} icon={faEdit}></FontAwesomeIcon></button>
                                    <button type="button" className="btn btn-sm btn-danger" onClick={() => {
                                if (window.confirm("Delete this Note?")) {
                                    
                                    let removeToCollection = this.deleteNote.bind(this,pn.noteid);
                                    removeToCollection();
                                }
                            }}><FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon></button></div></div></div>
                            
                            <div className="row"><div className="col-md-10"><span className="help-text" style={{fontSize:12+"px"}}>{pn.timestamp}</span></div></div>
                        </div>
                    ))}
                    </div>
                    </div>
                    </div>
                </div>
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active" id="locations-tab" data-toggle="tab" href="#locations" role="tab" aria-controls="locations" aria-selected="true">Locations</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Contacts</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Notes</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" id="messages-tab" data-toggle="tab" href="#messages" role="tab" aria-controls="messages" aria-selected="false">Documents</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" id="reminders-tab" data-toggle="tab" href="#reminders" role="tab" aria-controls="reminders" aria-selected="false">Reminders</a>
                        </li>
                        {entityType == "Customer" &&
                        <li className="nav-item">
                            <a className="nav-link" id="settings-tab" data-toggle="tab" href="#settings" role="tab" aria-controls="settings" aria-selected="false">Quotes</a>
                        </li>
                       }
                       {entityType == "Customer" && 
                        <li className="nav-item">
                            <a className="nav-link" id="details-tab" data-toggle="tab" href="#details" role="tab" aria-controls="details" aria-selected="false">Details</a>
                        </li>}
                        {entityType == "Supplier" &&
                        <li className="nav-item">
                            <a className="nav-link" id="inventory-tab" data-toggle="tab" href="#inventory" role="tab" aria-controls="inventory" aria-selected="false">Items</a>
                        </li>}
                        </ul>

                        <div className="tab-content">
                        <div className="tab-pane active" id="locations" role="tabpanel" aria-labelledby="locations-tab">
                        <div className="row">
                                <div className="col-md-6">
                                        {showLocationsControl ?<LocationControl cid={entityID}/>: null}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                        <div className="table-responsive">
                        <div className="table-wrapper">
                            <div className="table-title">
                                <div className="row">
                                    <div className="col-sm-5">
                                        <h2>Locations</h2>
                                    </div>
                                    <div className="col-sm-7">
                                    {showLocationsControl? <a href="#" className="btn btn-secondary" onClick={this.deactivateLocationForm}><FontAwesomeIcon icon={faTimes}></FontAwesomeIcon> Close Location</a> : <a href="#" className="btn btn-secondary" onClick={this.activateLocationForm}><FontAwesomeIcon icon={faStickyNote}></FontAwesomeIcon> Add Location</a>}
                                    </div>
                                </div>
                            </div>
                            {loading && <h1>Loading...</h1>}
                            <table className="table table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th>Location Type</th>
                                        <th>Address</th>						
                                        <th>Address (Suite)</th>
                                        <th>City</th>
                                        <th>State</th>
                                        <th>Zip</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {locations.map(local=> (
                                   <tr key={local.locationid}>
                                       <td>{local.facilityType}</td>
                                       <td>{local.address1}</td>
                                       <td>{local.address2}</td>
                                       <td>{local.city}</td>
                                       <td>{local.stateAbbr}</td>
                                       <td>{local.zip}</td>
                                       <td>
                                       <div className="btn-group" role="group" aria-label="Basic example"><Link to={{pathname: `../editoffice/${local.locationid}`,state:{companyid:entityID,locationid:local.locationid}}} className="btn btn-sm btn-info" style={{maxHeight:31+"px"}}><FontAwesomeIcon style={{color:"white"}} icon={faEdit}></FontAwesomeIcon></Link><button type="button" className="btn btn-sm btn-danger" onClick={() => {
                                if (window.confirm("Delete the item?")) {
                                    
                                    let removeToCollection = this.deleteNote.bind(this,local.id);
                                    removeToCollection();
                                }
                            }}><FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon></button></div>
                                            {/*<a href="#" className="settings" title="Edit" data-toggle="tooltip"><FontAwesomeIcon icon={faEdit}></FontAwesomeIcon></a>
                                            <FontAwesomeIcon icon={faEraser} style={{color:'red',cursor:'hand'}} title="Delete" data-toggle="tooltip" onClick={()=>{this.deleteLocation(local.id)}}></FontAwesomeIcon>*/}
                                        </td>
                                   </tr>     
                                ))}
                                </tbody>
                                </table>
                                </div>
                                </div>
                        </div>
                        </div>
                        </div>
                        <div className="tab-pane" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <div className="table-responsive">
                        <div className="table-wrapper">
                            <div className="table-title">
                                <div className="row">
                                    <div className="col-sm-5">
                                        <h2>Contacts</h2>
                                    </div>
                                    <div className="col-sm-7">
                                        <a href={"/addcontact/"+entityID} className="btn btn-secondary"><FontAwesomeIcon icon={faUserPlus}></FontAwesomeIcon> Add Contact</a>
                                    </div>
                                </div>
                            </div>
                            {loading && <h1>Loading...</h1>}
                            <table className="table table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th>Primary Contact</th>
                                        <th>Name</th>						
                                        <th>Title</th>
                                        <th>Office Phone</th>
                                        <th>Ext.</th>
                                        <th>Cell Phone</th>
                                        <th>E-Mail</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {users.map(user=> (
                                   <tr key={user.uid}>
                                       <td>{user.isPrimary == true?<FontAwesomeIcon icon={faStar}></FontAwesomeIcon>:""}</td>
                                       <td>{user.fname} {user.lname}</td>
                                       <td>{user.title}</td>
                                       <td>{user.phone}</td>
                                       <td>{user.ext}</td>
                                       <td>{user.cellphone}</td>
                                       <td><a href={`mailto:${user.email}`}>{user.email}</a></td>
                                       <td>
                                       <div className="btn-group" role="group" aria-label="Basic example"><Link to={{pathname: `../editcontact/${user.uid}`,state:{cid:entityID,contactid:user.uid}}} style={{maxHeight:31+"px"}} className="btn btn-sm btn-info" ><FontAwesomeIcon style={{color:"white"}} icon={faEdit}></FontAwesomeIcon></Link><button type="button" className="btn btn-sm btn-danger" onClick={() => {
                                if (window.confirm("Delete this Contact?")) {
                                    
                                    let removeToCollection = this.deleteUser.bind(this,entityID,user.uid);
                                    removeToCollection();
                                }
                            }}><FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon></button></div>
                                        </td>
                                   </tr>     
                                ))}
                                </tbody>
                                </table>
                                </div>
                                </div>
                        </div>
                        <div className="tab-pane" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                            <div className="row">
                                <div className="col-md-6">
                                        {showNoteForm ?<NoteBasePage />: null}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                <div className="table-responsive">
                        <div className="table-wrapper">
                            <div className="table-title">
                                <div className="row">
                                    <div className="col-sm-5">
                                        <h2>Notes</h2>
                                    </div>
                                    <div className="col-sm-7">
                                        {showNoteForm? <a href="#" className="btn btn-secondary" onClick={this.deactivateNoteForm}><FontAwesomeIcon icon={faTimes}></FontAwesomeIcon> Close Note</a> : <a href="#" className="btn btn-secondary" onClick={this.activateNoteForm}><FontAwesomeIcon icon={faStickyNote}></FontAwesomeIcon> Add Note</a>}
                                    </div>
                                </div>
                            </div>
                            <table className="table table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th>Note</th>
                                        <th>Date/Time</th>
                                        <th>Delete</th>						
                                    </tr>
                                </thead>
                                <tbody>
                                {notes.map(note=> (
                                   <tr key={note.noteid}>
                                       <td>{note.comment}</td>
                                       <td>{note.timestamp}</td>
                                       <td><div className="btn-group" role="group" aria-label="Basic example">
                                       <button className="btn btn-sm btn-info" style={{maxHeight:31+"px"}} onClick={e => this.modalOpen(note.noteid,note.comment)}><FontAwesomeIcon style={{color:"white"}} icon={faEdit}></FontAwesomeIcon></button><button type="button" className="btn btn-sm btn-danger" onClick={() => {
                                if (window.confirm("Delete this Note?")) {
                                    
                                    let removeToCollection = this.deleteNote.bind(this,note.noteid);
                                    removeToCollection();
                                }
                            }}><FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon></button></div>
                                    </td>
                                   </tr>     
                                ))}
                                </tbody>
                                </table>
                                </div>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane" id="messages" role="tabpanel" aria-labelledby="messages-tab">
                            <FileUpload cid={entityID}></FileUpload>
                            {/*<FileTable cid={entityID}></FileTable>*/}
                            <FileTableControl cid={entityID}></FileTableControl>
                            </div>
                            <div className="tab-pane" id="reminders" role="tabpanel" aria-labelledby="reminders-tab">
                                <div className="row">
                                <RemindersControl cid={entityID} cname={entityName} authUser={this.props.firebase.auth.currentUser}/>
                                </div>
                            <div className="row">
                                <div className="col-md-12">
                                <div className="table-responsive">
                        <div className="table-wrapper">
                            <div className="table-title">
                                <div className="row">
                                    <div className="col-sm-5">
                                        <h2>Reminders</h2>
                                    </div>
                                    <div className="col-sm-7">
                                        {/*showNoteForm? <a href="#" className="btn btn-secondary" onClick={this.deactivateNoteForm}><FontAwesomeIcon icon={faTimes}></FontAwesomeIcon> Cancel Note</a> : <a href="#" className="btn btn-secondary" onClick={this.activateNoteForm}><FontAwesomeIcon icon={faStickyNote}></FontAwesomeIcon> Add Note</a>*/}
                                    </div>
                                </div>
                            </div>
                            {loading && <h1>Loading...</h1>}
                            <table className="table table-striped table-hover">
                            <thead>
                                    <tr>
                                        <th>Reminder</th>
                                        <th>Date/Time</th>
                                        <th>Rep</th>
                                        <th>Controls</th>						
                                    </tr>
                                </thead>
                                <tbody>
                                {reminders.map(reminder=> (
                                   <tr key={reminder.rid}>
                                       <td>{reminder.comment}</td>
                                       <td>{reminder.timestamp}</td>
                                       <td>{reminder.userID}</td>
                                       <td><div className="btn-group" role="group" aria-label="Basic example"><button className="btn btn-sm btn-info" style={{maxHeight:31+"px"}} onClick={e => this.modalReminderOpen(reminder.rid,reminder.comment,reminder.reminderDate)}><FontAwesomeIcon style={{color:"white"}} icon={faEdit}></FontAwesomeIcon></button><button type="button" className="btn btn-sm btn-danger" onClick={() => {
                                if (window.confirm("Delete this reminder?")) {
                                    let pnID = reminder.rid;
                                    let removeToCollection = this.deleteNote.bind(this,reminder.rid);
                                    removeToCollection();
                                }
                            }} data-toggle="tooltip" data-placement="right" title="Delete"><FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon></button></div></td>
                                   </tr>     
                                ))}
                                </tbody>
                                </table>
                                </div>
                                </div>
                                </div>
                            </div>
                        </div>
                        {entityType == "Customer" &&
                        <div className="tab-pane" id="settings" role="tabpanel" aria-labelledby="settings-tab">
                            <QuoteTable cid={entityID}></QuoteTable>
                        </div>}
                        {entityType == "Customer" &&
                        <div className="tab-pane" id="details" role="tabpanel" aria-labelledby="details-tab">
                            <h3>Customer Details Sheet</h3>
                            {/*<TabControl></TabControl>*/}
                        </div>}
                        {(entityType == "Supplier") ?
                        <div className="tab-pane" id="inventory" role="tabpanel" aria-labelledby="inventory-tab">
                                                      <div className="row">
                                <div className="col-md-12">
                                <div className="table-responsive">
                        <div className="table-wrapper">
                            <div className="table-title">
                                <div className="row">
                                    <div className="col-sm-5">
                                        <h2>Items</h2>
                                    </div>
                                    <div className="col-sm-7">
                                        {/*showNoteForm? <a href="#" className="btn btn-secondary" onClick={this.deactivateNoteForm}><FontAwesomeIcon icon={faTimes}></FontAwesomeIcon> Cancel Note</a> : <a href="#" className="btn btn-secondary" onClick={this.activateNoteForm}><FontAwesomeIcon icon={faStickyNote}></FontAwesomeIcon> Add Note</a>*/}
                                    </div>
                                </div>
                            </div>
                            {loading && <h1>Loading...</h1>}
                            <table className="table table-striped table-hover">
                            <thead>
                                    <tr>
                                        <th>Item Name</th>
                                        <th>Description</th>
                                        <th>--</th>
                                        <th>Controls</th>						
                                    </tr>
                                </thead>
                                <tbody>
                                {items.map(item=> (
                                   <tr key={item.itemid}>
                                       <td><a href={'../item/'+item.itemid}>{item.itemName}</a></td>
                                       <td>{item.itemDescription}</td>
                                       <td>--</td>
                                       <td><div className="btn-group" role="group" aria-label="Basic example"><Link to={{pathname: `../editreminder/${item.itemid}`,state:{companyid:entityID,itemid:item.itemid}}} className="btn btn-sm btn-info" style={{maxHeight:31+"px"}}><FontAwesomeIcon style={{color:"white"}} icon={faEdit}></FontAwesomeIcon></Link><button type="button" className="btn btn-sm btn-danger" onClick={() => {
                                if (window.confirm("Delete this Reminder?")) {
                                    
                                    let removeToCollection = this.deleteItem.bind(this,item.itemid);
                                    removeToCollection();
                                }
                            }}><FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon></button></div></td>
                                   </tr>     
                                ))}
                                </tbody>
                                </table>
                                </div>
                                </div>
                                </div>
                            </div>
                        </div>: ""}
                        </div>

                
                        </div>
            </div>
             )}
             </AuthUserContext.Consumer>
        );
    }
}
const condition = authUser => !!authUser;
const CompanyDetailsPageForm = compose(
    withAuthorization(condition),
    withFirebase,
  )(CompanyDetailsPage);
  
  export default withFirebase(CompanyDetailsPage);
  
  export {CompanyDetailsPageForm};
//export default withFirebase(CompanyDetailsPage);