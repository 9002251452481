import React, {Component} from 'react';
import {compose} from 'recompose';

import {withFirebase} from '../Firebase';
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";


class AllNoteBase extends Component {
    constructor(props){
        super(props);

        this.state = {
            loading:false,
            companyid:null,
            columns:[
                  {
                    dataField: "comment",
                    text: "Comment",
                    sort: true
                  },
                  {
                    dataField: "timestamp",
                    text: "Time Stamp",
                    sort:true
                  },
                  {
                      dataField: "pinned",
                      text: "Pinned Comment",
                      sort:false
                  }      
            ],
            notes:[],
        };
    }

    componentDidMount(){
        this.setState({loading:true});
        this.setState({companyid:this.props.match.params.cid});
        console.log(this.state.companyid);
        
        this.unsubscribe = this.props.firebase
            .notesByCompany(this.props.match.params.cid)
            .orderBy('datetime', "desc")
            .onSnapshot(snapshot => {
                let noteArray = [];

                snapshot.forEach(doc =>
                    noteArray.push({ comment:doc.data().comment,timestamp:new Date(doc.data().datetime.seconds * 1000).toDateString() + "-" +new Date(doc.data().datetime.seconds * 1000).toTimeString(), pinned: doc.data().pinned,uid: doc.id }),
                );
    
                console.log(noteArray);

                this.setState({
                    notes:noteArray,
                    loading: false,
                });
            });

        console.log(this.state.notes.length);

    }

    componentWillUnmount(){
        this.unsubscribe();
    }

    render(){
        const {notes, loading} = this.state;

        return(
            <div>
                {loading && <div>Loading...</div>}
                {notes ? (
                <BootstrapTable
                    bootstrap4
                    keyField="uid"
                    data={this.state.notes}
                    columns={this.state.columns}
                    pagination={paginationFactory({ sizePerPage: 5 })}
                />
                ):(<div>No notes on file...</div>)}
            </div>
        );
    }
}
export default withFirebase(AllNoteBase);