import React, {Component} from 'react';
import {compose} from 'recompose';
import * as ROUTES from '../../constants/routes';
import {withAuthorization} from '../Session';
import {withFirebase} from '../Firebase';
import { faThList } from '@fortawesome/free-solid-svg-icons';

class EditContactPage extends Component{

    constructor(props) {
        super(props);
        this.state = {
          entityID: this.props.location.state.cid,
          contactID: this.props.location.state.contactid,
          contact:'',
          errormessage: '',
          email:'',
          isPrimary:false,
          fname:'',
          lname:'',
          lname:'',
          phone:'',
          ext:'',
          cellphone:'',
          title:'',
        };
      }

      mySubmitHandler = (event) => {
        event.preventDefault();
        /*let age = this.state.age;
        if (!Number(age)) {
          alert("Your age must be a number");
        }*/
        console.log(this.state.ext);
        this.props.firebase.db.collection(`entities`).doc(this.state.entityID).collection(`contacts`).doc(this.state.contactID).update({
          email:this.state.email,
          fname:this.state.fname,
          lname:this.state.lname,
          isActive:this.state.isActive,
          isPrimary:this.state.isPrimary,
          lname:this.state.lname,
          phone:this.state.phone,
          cellphone:this.state.cellphone,
          title:this.state.title,
          companyid:this.state.entityID,
          ext:this.state.ext,
        });

        if(this.state.primary == "on")
        {
          this.props.firebase.db.collection('entities').doc(this.state.entityID).update({
            primaryContact: this.state.fname + " " + this.state.lname
          });
        }

        this.setState({});
        this.props.history.push('/company/'+this.state.entityID);
      }

      componentDidMount(){
        this.setState({loading:true});

        console.log(this.state.entityID + " " + this.props.cid+" "+ this.state.contactID+" "+this.props.contactid);

        this.unsubscribe = this.props.firebase.db
        .collection(`entities`).doc(this.state.entityID).collection(`contacts`).doc(this.state.contactID)
        .onSnapshot(snapshot => {
          this.setState({
            contact:snapshot.data(),
            email:snapshot.data().email,
            fname:snapshot.data().fname,
            lname:snapshot.data().lname,
            isActive:snapshot.data().isActive,
            isPrimary:snapshot.data().isPrimary,
            lname:snapshot.data().lname,
            phone:snapshot.data().phone,
            ext:snapshot.data().ext,
            cellphone:snapshot.data().cellphone,
            title:snapshot.data().title,
            loading: false,
          });
        });
        
    }

    componentWillUnmount() {
      // fix Warning: Can't perform a React state update on an unmounted component
      this.setState = (state,callback)=>{
          return;
      };
  }

      myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        let err = '';
        if (nam === "age") {
          if (val !="" && !Number(val)) {
            err = <strong>Your age must be a number</strong>;
          }
        }
        if(nam=="isPrimary" && this.state.isPrimary == true)
        {
          //this.setState({isActive:false});
          val = false;
          //document.getElementById('isActive').removeAttr('checked');
        }
        else if(nam=="isPrimary" && this.state.isPrimary == false){
          val=true;
        }
        if (nam === "phone" || nam === "cellphone")
        {
          if(val < 10)
          {
            err = <strong>Phone Number must be at least 10 digits</strong>;
          }
          else{
            val = this.getFormattedPhoneNum(val);
          }
        }
        this.setState({errormessage: err});
        this.setState({[nam]: val});
      }

      cancelForm=(event)=>{
        this.props.history.push('/company/'+this.state.entityID);
      }

      setNewValue(newValue) {
        console.log('this is the State code:' + newValue);
      }

      getFormattedPhoneNum( input ) {
        let output = "(";
        input.replace( /^\D*(\d{0,3})\D*(\d{0,3})\D*(\d{0,4})/, function( match, g1, g2, g3 )
            {
              if ( g1.length ) {
                output += g1;
                if ( g1.length == 3 ) {
                    output += ")";
                    if ( g2.length ) {
                        output += " " + g2; 
                        if ( g2.length == 3 ) {
                            output += " - ";
                            if ( g3.length ) {
                                output += g3;
                            }
                        }
                    }
                 }
              }
            }       
          );        
        return output;
      }       

      render() {
        let {loading, error, errormessage,contact} = this.state;
        return (
          <div className="container">
          <form onSubmit={this.mySubmitHandler}>
          <h1>Edit contact</h1>
          {this.state.errormessage}
          <label htmlFor="fname">Contact First Name:</label>
          <input
            type='text'
            name='fname'
            className="form-control"
            value={this.state.fname}
            onChange={this.myChangeHandler}
          />
          <label htmlFor="lname">Contact Last Name:</label>
          <input
            type='text'
            name='lname'
            className="form-control"
            value={this.state.lname}
            onChange={this.myChangeHandler}
          />
          <label htmlFor="title">Title/Role:</label>
          <input
            type='text'
            name='title'
            className="form-control"
            value={this.state.title}
            onChange={this.myChangeHandler}
          />
          <label htmlFor="email">E-mail</label>
          <input
            type='text'
            name='email'
            className="form-control"
            value={this.state.email}
            onChange={this.myChangeHandler}
          />
                    <div className="row">
            <div className="col-md-6">
            <label htmlFor="phone">Main Phone:</label>
              <input
                type='phone'
                name='phone'
                className="form-control"
                value={this.state.phone}
                onChange={this.myChangeHandler}
              />
            </div>
            <div className="col-md-6">
            <label htmlFor="ext">Extension:</label>
              <input
                type='text'
                name='ext'
                className="form-control"
                value={this.state.ext}
                style={{maxWidth:150+"px"}}
                onChange={this.myChangeHandler}
              />
            </div>
          </div>
          <label htmlFor="cellphone">Cell Phone:</label>
          <input
            type='phone'
            name='cellphone'
            className="form-control"
            value={this.state.cellphone}
            onChange={this.myChangeHandler}
          />
          <input type="checkbox" className="form-check-input" name="isPrimary" checked={this.state.isPrimary} onChange={this.myChangeHandler}></input>
          <label htmlFor="isPrimary">Is Primary Contact?</label>
          <hr/>
          <div className="row">
            <div className="col-md-4"><input type="submit" className="btn btn-success" value="Submit"/></div>
            <div className="col-md-4"></div>
            <div className="col-md-4"><input type="button" className="btn btn-danger pull-right" onClick={this.cancelForm} value="Cancel"/></div>
          </div>
          
          
          </form>
          </div>
        );
      }
    }

    const condition = authUser => !!authUser;
    const EditContactPageForm = compose(
      withAuthorization(condition),
      withFirebase,
    )(EditContactPage);
    
    export default withFirebase(EditContactPage);
    
    export {EditContactPageForm};
