//ABOUT PAGE with VERSION - SHOULD MATCH packages.json version
import React, { Component } from 'react'
import ChartistGraph from 'react-chartist'
import {Link} from 'react-router-dom'
import { compose } from 'recompose';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle,faClock,faHistory} from '@fortawesome/free-solid-svg-icons';
import {faUser,faUserCircle,faLock} from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Sidebar/sidebar.css'
import { AuthUserContext } from '../Session';
import * as ROLES from '../../constants/roles';
import SelectableCalendar from '../Reminder/calendarview';
import DragDropCalendar from '../Reminder/dragdropcalendarview';
import {withAuthorization} from '../Session';

const AppVersionPage =()=>
(
    <AuthUserContext.Consumer>
            {authUser=>(
      <div className="content">
        <nav className="navbar navbar-expand-lg navbar-light bg-blue" style={{marginBottom:'15px'}}>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNavDropdown">
              <ul className="navbar-nav">
                <li className="nav-item active">
                  <a className="nav-link-menu" href="../home">Home <span className="sr-only">(current)</span></a>
                </li>
                {authUser.role === "Office" && 
                  <li className="nav-item">
                    <a className="nav-link-menu" href="../siteconfig">Manage Domains</a>
                  </li>
                  }
                  {authUser.role === "Office" && 
                  <li className="nav-item">
                    <a className="nav-link-menu" href="../suppliersearch">Supplier</a>
                  </li>
                  }
                  {authUser.role === "Admin" && 
                  <li className="nav-item dropdown">
                    <a className="nav-link-menu dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Site Management
                    </a>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                      <a className="dropdown-item" href="../reports">Reporting</a>
                      <a className="dropdown-item" href="../siteconfig">Domains</a>
                      <a className="dropdown-item" href="../pricing">Pricing</a>
                      <a className="dropdown-item" href="../users">Users</a>
                      <a className="dropdown-item" href="../about">About</a>
                      {/*<a className="dropdown-item" href="../suppliersearch">Supplier</a>*/}
                    </div>
                  </li>
                  }
                  {authUser.role === "Admin" && 
                  <li className="nav-item">
                    <a className="nav-link-menu" href="../suppliersearch">Supplier</a>
                  </li>
                  }
                  {authUser.role === "Buyer" && 
                  <li className="nav-item">
                  <a className="nav-link-menu" href="../siteconfig">Manage Domains</a>
                </li>
                  }
                  {authUser.role === "Buyer" && 
                  <li className="nav-item">
                    <a className="nav-link-menu" href="../suppliersearch">Suppliers</a>
                  </li>
                  }
                <li className="nav-item">
                  <a className="nav-link-menu" href="../search">Contacts</a>
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link-menu dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Items
                  </a>
                  <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                  <a className="dropdown-item" href="../itemsearch">Item Search</a>
                    <a className="dropdown-item" href="../inventory">Warehouse Inventory</a>
                    {/*<a className="dropdown-item" href="">Order Inventory</a>
                    <a className="dropdown-item" href="../confirm">Log Inventory</a>
                    <a className="dropdown-item" href="../suppliersearch">Supplier</a>*/}
                  </div>
                </li>
                {authUser.role === "Admin" && 
                <li className="nav-item dropdown">
                  <a className="nav-link-menu dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Quotes & Orders
                  </a>
                  <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                  <a className="dropdown-item" href="../quotes">Quotes</a>
                  <a className="dropdown-item" href="../orders">Inventory Orders</a>
                    
                    {/*<a className="dropdown-item" href="../suppliersearch">Supplier</a>*/}
                  </div>
                </li>
                }
                {/*<li className="nav-item">
                  <a className="nav-link-menu" href="../quotes">Quotes</a>
                </li>*/}
                <li className="nav-item">
                    <a className="nav-link-menu" href="../signout">Sign Out</a>
                  </li>
              </ul>
            </div>
          </nav>
        <div className="container-fluid">
        <h1>Provisions Manager</h1>
        <p>Copyright 2021</p>
        <h3>Current Version 1.9.7</h3>
        <h5>Version Notes:</h5>
        <div className="scrollTable">
        <table>
        <tr>
            <td>
              1.9.7 (07-16-2023)
            </td>
            <td>
              <ul>
                <li>Item Full Price Update</li>
                <li>Home Menu Update</li>
                <li>Warehouse Inventory Management Update</li>
              </ul>
            </td>
          </tr>
        <tr>
            <td>
              1.9.6 (05-31-2023)
            </td>
            <td>
              <ul>
                <li>Item Full Price Update</li>
              </ul>
            </td>
          </tr>
        <tr>
            <td>
              1.9.5 (05-18-2023)
            </td>
            <td>
              <ul>
                <li>Inventory Module Update</li>
                <li>Orders Module Updates</li>
                <li>Lot Management Update</li>
                <li>Item Full Price Update</li>
                <li>Update Item Module (Annie's bug)</li>
              </ul>
            </td>
          </tr>
        <tr>
            <td>
              1.9.4 (04-27-2023)
            </td>
            <td>
              <ul>
                <li>Inventory Module Update</li>
                <li>Orders Module Misc Bug Fixes</li>
                <li>Remove Inventory Pricing</li>
                <li>Item Full Price Update</li>
                <li>---</li>
                <li>---</li>
              </ul>
            </td>
          </tr>
        <tr>
            <td>
              1.9.3 (03-31-2023)
            </td>
            <td>
              <ul>
                <li>Inventory Module</li>
                <li>Warehouse Module - edit shipment</li>
                <li>Pricing Update</li>
              </ul>
            </td>
          </tr>
        <tr>
            <td>
              1.9.2 (03-19-2023)
            </td>
            <td>
              <ul>
                <li>Inventory Module</li>
                <li>Warehouse Module</li>
                <li>Item Search Module</li>
              </ul>
            </td>
          </tr>
        <tr>
            <td>
              1.9.1 (03-5-2023)
            </td>
            <td>
              <ul>
                <li>Inventory Module</li>
              </ul>
            </td>
          </tr>
        <tr>
            <td>
              1.9.0 (03-01-2023)
            </td>
            <td>
              <ul>
                <li>Order Editing</li>
              </ul>
            </td>
          </tr>
        <tr>
            <td>
              1.8.9 (01-31-2023)
            </td>
            <td>
              <ul>
                <li>Order Editing</li>
              </ul>
            </td>
          </tr>
        <tr>
            <td>
              1.8.8 (01-08-2023)
            </td>
            <td>
              <ul>
                <li>Order Editing</li>
              </ul>
            </td>
          </tr>
        <tr>
            <td>
              1.8.7 (12-05-2022)
            </td>
            <td>
              <ul>
                <li>Inventory Ordering</li>
                <li>Inventory Items</li>
                <li>Misc Enhancements</li>
              </ul>
            </td>
          </tr>
        <tr>
            <td>
              1.8.5 (10-31-2022)
            </td>
            <td>
              <ul>
                <li>Inventory Ordering</li>
                <li>Inventory Items</li>
                <li>Schedule View Update</li>
                <li>Misc Enhancements</li>
              </ul>
            </td>
          </tr>
        <tr>
            <td>
              1.8.0 (09-26-2022)
            </td>
            <td>
              <ul>
                <li>Inventory Ordering</li>
                <li>Inventory Items</li>
                <li>Misc Enhancements</li>
              </ul>
            </td>
          </tr>
        <tr>
            <td>
              1.7.2 (07-29-2022)
            </td>
            <td>
              <ul>
                <li>Misc Enhancements</li>
              </ul>
            </td>
          </tr>
        <tr>
            <td>
              1.7.1 (06-19-2022)
            </td>
            <td>
              <ul>
                <li>Pricing Table Enhancement</li>
                <li>Quote Notes Bug</li>
                <li>Misc Enhancements</li>
              </ul>
            </td>
          </tr>
        <tr>
            <td>
              1.7.0 (06-05-2022)
            </td>
            <td>
              <ul>
                <li>Quote Breakdown Visual Enhancement</li>
                <li>Controls to Edit Domains</li>
                <li>Admin control to delete quotes</li>
                <li>Draggable Mark Up and Freight Windows</li>
                <li>Dashboard Quote Status w/Filter</li>
                <li>Pricing bugs</li>
              </ul>
            </td>
          </tr>
        <tr>
            <td>
              1.6.4 (04-28-2022)
            </td>
            <td>
              <ul>
                <li>Bug fix: Totals in Tab, Export</li>
                <li>Add and correct columns in Breakdown</li>
              </ul>
            </td>
          </tr>
        <tr>
            <td>
              1.6.3 (04-24-2022)
            </td>
            <td>
              <ul>
                <li>Bug fix: Mark-up % calculation</li>
              </ul>
            </td>
          </tr>
        <tr>
            <td>
              1.6.2 (04-14-2022)
            </td>
            <td>
              <ul>
                <li>Bug fix: Additional Item Description</li>
              </ul>
            </td>
          </tr>
        <tr>
            <td>
              1.6.1 (03-29-2022)
            </td>
            <td>
              <ul>
                <li>Update Quote Breakdown</li>
                <li>Miscillanious Bug Fixes</li>
              </ul>
            </td>
          </tr>
        <tr>
            <td>
              1.6 (03-10-2022)
            </td>
            <td>
              <ul>
                <li>Update Quote Controls</li>
                <li>Enhance 'Pricing To Be Set' - pagination, search</li>
                <li>Delete Item capability</li>
              </ul>
            </td>
          </tr>
        <tr>
            <td>
              1.5.5 (01-24-2022)
            </td>
            <td>
              <ul>
                <li>Quote Breakdown Page</li>
                <li>Update Item Price - reset mark-up price</li>
                <li>Quote Status and Client Status Domain Management Update</li>
              </ul>
            </td>
          </tr>
        <tr>
            <td>
              1.5.4 (01-16-2022)
            </td>
            <td>
              <ul>
                <li>Bug fix Add Tab/Copy Item - NaN error</li>
                <li>Preliminary Quote $ Breakdown</li>
              </ul>
            </td>
          </tr>
        <tr>
            <td>
              1.5.3
            </td>
            <td>
              <ul>
                <li>Bug fixes to Quotes</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              1.5.2
            </td>
            <td>
              <ul>
                <li>Bug fixes to Quotes, Freight Cost, Mark Up Calculations, Tab Totalling</li>
                <li>Back Buttons on pages where needed</li>
              </ul>
            </td>
          </tr>
        </table>
        </div>
    </div>
    </div>)}
    </AuthUserContext.Consumer>

);

const condition = authUser => !!authUser;

export default withAuthorization(condition)(AppVersionPage);