import React,{Component} from 'react';
import SearchBar from '../Search/SearchBar';
import SearchTable from '../Search/SearchTable';
import { Switch,Route, Link, withRouter } from 'react-router-dom';

import * as ROUTES from '../../constants/routes';
import { compose } from 'recompose';

import { withAuthorization } from '../Session';
import { AuthUserContext } from '../Session';
import {withFirebase} from '../Firebase';
import firebase from 'firebase';
import { faRedo,faEdit,faPlusCircle,faHome} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faUser,faUserCircle,faLock,faSearch,faShoppingBasket,faPlus,faMinus} from '@fortawesome/free-solid-svg-icons';

import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import filterFactory,{textFilter,selectFilter} from 'react-bootstrap-table2-filter';
import { ThemeProvider } from 'styled-components';
import Modal from '../Modal';


class SearchPage extends Component{ 

  detailsFormatter(cell, row) {
    return (
      <Link to={{pathname: `/company/`+row.cid}}>Details</Link>
    );
  }
  constructor(props) {
    super(props);

    const staffOptions = {'Josh S': 'Josh S', 'Beth Sharratt':'Beth Sharratt', 'Annie Streufert':'Annie Streufert','Justin Guerin':'Justin Guerin'};
        const stateOptions = {'AL':'AL','AK':'AK','AS':'AS','AZ':'AZ','AR':'AR','CA':'CA','CO':'CO','CT':'CT','DE':'DE','DC':'DC','FL':'FL','GA':'GA','GU':'GU','HI':'HI','ID':'ID','IL':'IL','IN':'IN','IA':'IA','KS':'KS','KY':'KY','LA':'LA','ME':'ME','MD':'MD','MA':'MA','MI':'MI','MN':'MN','MS':'MS','MO':'MO','MT':'MT','NE':'NE','NV':'NV','NH':'NH','NJ':'NJ','NM':'NM','NY':'NY','NC':'NC','ND':'ND','MP':'MP','OH':'OH','OK':'OK','OR':'OR','PA':'PA','PR':'PR','RI':'RI','SC':'SC','SD':'SD','TN':'TN','TX':'TX','UT':'UT','VT':'VT','VA':'VA','VI':'VI','WA':'WA','WV':'WV','WI':'WI','WY':'WY'};
        const entityTypeOptions ={'Admin': 'Admin', 'Customer':'Customer','Lead':'Lead', 'Logistics':'Logistics','Prospect':'Prospect','Supplier':'Supplier',"Warehouse":"Warehouse"};

    this.state = { 
      term:'',
      customers: [],
      docIDs:[],
      tblColumns:[
        {
          data: "itemid",
          title: "Item ID",
          sort: false,
          visible:false
        },
        {
          data: "itemLink",
          title: "Item Name",
          sort: true,
          //formatter: (cell, row) => <a href={'/'+row.companyid+'/item/'+row.itemid}> {cell} </a>
        },
        {
          data: "itemDescription",
          title: "Description",
          sort:true
        },
        {
          data: "itemSizeFull",
          title: "Size",
          sort:true
        },
        {
          data: "packSize",
          title: "Pack Size",
          sort:true
        },
        {
          data: "fullPrice",
          title: "Price",
          //defaultContent:"$-",
          render: $.fn.dataTable.render.number( ',', '.', 2, '$' ),
          sort:true,
        },
        {
          data: "supplierName",
          title: "Supplier",
          sort:true,
            
        },
        {
          data: "fobFullLocation",
          title: "FOB Location",
          sort:false,
            
        },
        {
          title:"",
          sort:false, 
          render: function (data,type,row){
            if(row.fullPrice == "")
            {
              return "<button class='btn btn-sm btn-success' disabled>+</button>";  
            }
            else{
              return "<button class='btn btn-sm btn-success'>+</button>";            }
          }
        }/*,
        {
            dataField: "caseQty",
            text: "Case Qty Needed",
            sort:false,
            isDummyField:true,
        },
        {
            dataField: "controls",
            text: "-----",
            sort:false,
            isDummyField:true,
            
        }*/      
    ],
      loading:true,
      showModal:false,
      modalItemID:'',
      modalItemName:'',
      modalItemDescription:'',
      modalItemPackSize:'',
      modalItemUnitSize:'',
      modalItemFullPrice:'',
      modalItemCasesPerPallet:'',
      modalItemFOBLocation:'',
      modalItemQty:'0',
      downIncrementBtn:true,
      cartItems:'',
      showQuoteModal:false,
    };

    this.showModalFunction = this.showModalFunction.bind(this);
    //this.props.dispatch(getCrmStatus(this.props.params.id));
  }



  componentDidMount (){
      this.setState({loading:true});
      

      if(localStorage.getItem('newquote') != null)
      {
        
        var quoteInfo = JSON.parse(localStorage.getItem('newquote'));
        console.log("Item Search Cart Exists: "+ quoteInfo['items'].length);
        let cartItems = quoteInfo['items'].length;
        this.setState({cartItems:cartItems});
      }

      //console.log("Cart Items: " + quoteInfo['items'] + this.state.cartItems);
      let customers=[];
      this.unsubscribe = this.props.firebase
            .getAvailableinventory()
            .onSnapshot(invSH => {
              
              //let pricedInventory = [];
              
              invSH.forEach(dc => 
                customers.push({...dc.data(),fullPrice:dc.data().itemPrice,supplierName:dc.data().supplierName,itemLink:'<a href="/item/'+dc.data().itemID+'">'+dc.data().itemName+'</a>', itemSizeFull:dc.data().itemSizeFull,packSize:dc.data().packSize, supplierName:dc.data().warehouseName ,fobFullLocation: dc.data().fobLocation, itemid: dc.data().itemID})  
              );
            })



      this.unsubscribe = this.props.firebase
        .allItems()
        .onSnapshot(snapshot => {
   
          //let customers=[];

          snapshot.forEach(doc =>
           customers.push({ ...doc.data(),itemLink:'<a href="/'+doc.data().companyid+'/item/'+doc.id+'">'+doc.data().itemName+'</a>', itemSizeFull:doc.data().unitSize + " " + doc.data().unitUnit ,fobFullLocation: doc.data().fobCity + " " + doc.data().fobState, itemid: doc.id }),
          );
            console.log(customers.length);
            this.setState({customers,loading:false}, function(){console.log("set state complete");});


            console.log("setting up table");

            $('#example').DataTable({
              data: customers,
              columns: this.state.tblColumns,
              pageLength: 10,
              columnDefs:[{targets:-1,data:null,defaultContent:'<button class="btn btn-sm btn-success">+</button>'}],
              //filter: true,
               order:[[1,'asc']],
              //deferRender: true,
              initComplete: function () {
                this.api().columns([4,6,7]).every( function () {
                    var column = this;
                    var select = $('<select class="form-control filterCtrl"><option value="">...</option></select>')
                        .appendTo( $(column.footer()).empty() )
                        .on( 'change', function () {
                            var val = $.fn.dataTable.util.escapeRegex(
                                $(this).val()
                            );
     
                            column
                                .search( val ? '^'+val+'$' : '', true, false )
                                .draw();
                        } );
     
                    column.data().unique().sort().each( function ( d, j ) {
                        select.append( '<option value="'+d+'">'+d+'</option>' )
                    } );
                } );

                $('#example tfoot tr').appendTo('#example thead');
            }
            });

            $('.clear-filters').prependTo('#example_length');

        });
        
        let self = this;

        //console.log(customers.length);
        $('#example tbody').on( 'click', 'button', function () {
          var data = $('#example').DataTable().row( $(this).parents('tr') ).data();
          console.log(data.itemid);
          //alert( "Feature Coming Soon");
      
          $('#modalItemID').val(data.itemid);
          $('#modalItemName').val(data.itemName);
          $('#modalItemDescription').val(data.itemDescription);
          $('#modalItemPackSize').val(data.packSize);
          $('#modalItemUnitSize').val(data.itemSizeFull);
          $('#modalItemFullPrice').val(data.fullPrice);
          $('#modalItemCasesPerPallet').val(data.casesPerPallet);
          $('#modalItemFOBLocation').val(data.fobFullLocation);
          $("#basicModal").modal('show');
      } );
  
    }

    
    clearSearchFilters(){
      $('#example').DataTable().search( '' ).columns().search( '' ).draw();
      $('tfoot input').val('');

      $('.filterCtrl').each(function(){
        $(this).val('');
      });

      
    }

    componentWillReceiveProps(newProps) {
      
    }

    handleQuoteModalOpen() {
      this.setState({ showQuoteModal: true });
    }
  
    closeQuoteModal() {
      this.setState({
        showQuoteModal: false
      });

      //$("#basicModal").modal("hide");
  }

    openModal() {
      this.setState({ showModal: true });
    }
  
    closeModal() {
      this.setState({
        modalItemID:'',
        modalItemName:'',
        modalItemDescription:'',
        modalItemPackSize:'',
        modalItemUnitSize:'',
        modalItemFullPrice:'',
        modalItemFOBLocation:'',
        modalItemCasesPerPallet:'',
        modalItemQty:'0',
        showModal: false
      });

      $("#basicModal").modal("hide");
  }

  showModalFunction(itemName){
    this.setState({
      modalItemName:itemName,
      showModal: true })
  }

  clearCart(){
    localStorage.clear();
    this.setState({
      showQuoteModal: false
    });
  }

  addItemToQuote=()=>{
    if(localStorage.getItem('newquote') != null)
    {
      var quoteInfo = JSON.parse(localStorage.getItem('newquote'));
      quoteInfo['items'].push({'id':this.state.modalItemID,'title':this.state.modalItemName,"description":this.state.modalItemDescription,"itemAddDesc":'',"unitSize":this.state.modalItemUnitSize,"price":this.state.modalItemFullPrice,"casesperpallet":this.state.modalItemCasesPerPallet,"packsize":this.state.modalItemPackSize,"qty":this.state.modalItemQty,"mutype":"","muvalue":"00","muprice":this.state.modalItemFullPrice,"freightcost":"00.00","freightorigin":"--","fullprice":this.state.modalItemFullPrice,"frominventory":false,"inventoryhold":false,"fobLocation":this.state.modalItemFOBLocation,"groupItemID":"99"});
      let val = this.state.cartItems;
      this.setState({cartItems: ++val});
      localStorage.setItem('newquote', JSON.stringify(quoteInfo));

    }
    else{
          let user = firebase.auth().currentUser;
          //console.log(user.uid);
          var dateToday = new Date();
          var quoteTitle = dateToday.getFullYear().toString()+"_"+(dateToday.getMonth() + 1).toString()+"_"+dateToday.getDate().toString()+"_"+dateToday.getHours().toString()+"_"+dateToday.getMinutes().toString()+"_"+dateToday.getSeconds().toString()+"_"+user.uid;
          var quoteObject = {'user':user.uid,'salesRep':user.userFullName,'quoteID':quoteTitle,'dateTimeStarted':dateToday,'items':[{'id':this.state.modalItemID,'title':this.state.modalItemName,"description":this.state.modalItemDescription,"itemAddDesc":'',"unitSize":this.state.modalItemUnitSize,"price":this.state.modalItemFullPrice,"casesperpallet":this.state.modalItemCasesPerPallet,"packsize":this.state.modalItemPackSize,"muprice":this.state.modalItemFullPrice,"qty":this.state.modalItemQty,"mutype":"","muvalue":"00","freightcost":"00.00","fullprice":this.state.modalItemFullPrice,"frominventory":false,"inventoryhold":false,"fobLocation":this.state.modalItemFOBLocation,"groupItemID":"99"}]};
          this.setState({cartItems: 1});
          localStorage.setItem('newquote', JSON.stringify(quoteObject));
        }
    this.closeModal();
}
downIncrement=(evt)=>{
  var val = this.state.modalItemQty;
  if(val > 0)
  {
    var newVal = --this.state.modalItemQty;
    this.setState({modalItemQty:newVal});
    console.log(newVal);
  }
}

upIncrement=(evt)=>{
  var newVal = ++this.state.modalItemQty;
  const modelItemID = document.getElementById("modalItemID").value;
  const modelItemNam = document.getElementById("modalItemName").value;
  const modelItemDesc = document.getElementById("modalItemDescription").value;
  const modelItemFullPrice = document.getElementById("modalItemFullPrice").value;
  const modelItemCase = document.getElementById("modalItemUnitSize").value;
  const modelItemPack = document.getElementById("modalItemPackSize").value;
  const modelItemCasesPerPallet = document.getElementById("modalItemCasesPerPallet").value;
  const modalItemFOBLocation = document.getElementById("modalItemFOBLocation").value;
  //console.log(modelItemNam);
  this.setState({
    modalItemQty:newVal,
    modalItemName:modelItemNam,
    modalItemUnitSize:modelItemCase,
    modalItemDescription:modelItemDesc,
    modalItemFullPrice:modelItemFullPrice,
    modalItemPackSize:modelItemPack,
    modalItemFOBLocaiton:modalItemFOBLocation,
    modalItemCasesPerPallet:modelItemCasesPerPallet,
    modalItemID:modelItemID,
  });
  console.log(newVal);
}
    onColumnMatch(
      searchText,
      value,
      column,
      row
    ){
      // implement your custom match logic on every cell value
      console.log(searchText + " " +value);
    }

    addContactHandler(event){
      this.context.router.push('/addcompany');
    }
    renderCart(){
      if(this.state.cartItems > 0)
      {
        //return <a className="nav-link-menu-cart" href="../quote"><FontAwesomeIcon style={{margin:10+"px"}} icon={faShoppingBasket}></FontAwesomeIcon>{this.state.cartItems}</a>;
        return <a className="nav-link-menu-cart" style={{color:"#fff",cursor:"pointer"}} onClick={e=>this.handleQuoteModalOpen(e)}><FontAwesomeIcon style={{margin:10+"px"}} icon={faShoppingBasket} ></FontAwesomeIcon>{this.state.cartItems}</a>;
      }
      else
      {
        return <FontAwesomeIcon style={{margin:10+"px"}} icon={faShoppingBasket}></FontAwesomeIcon>;
      }
  }

  myChangeHandler = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    let err = '';
    //alert(nam+"-"+val);
    if(nam=="isActive" && this.state.isActive == true)
    {
      //this.setState({isActive:false});
      val = false;
      //document.getElementById('isActive').removeAttr('checked');
    }
    else if(nam=="isActive" && this.state.isActive == false){
      val=true;
    }

    if(nam=="confirmPrice" && this.state.confirmPrice == true)
    {
      //this.setState({isActive:false});
      val = false;
      //document.getElementById('isActive').removeAttr('checked');
    }
    else if(nam=="confirmPrice" && this.state.confirmPrice == false){
      val=true;
    }
    if(nam == "modalItemQty"){
      const modelItemID = document.getElementById("modalItemID").value;
      const modelItemNam = document.getElementById("modalItemName").value;
      const modelItemDesc = document.getElementById("modalItemDescription").value;
      const modelItemFullPrice = document.getElementById("modalItemFullPrice").value;
      const modelItemCase = document.getElementById("modalItemUnitSize").value;
      const modelItemPack = document.getElementById("modalItemPackSize").value;
      const modelItemCasesPerPallet = document.getElementById("modalItemCasesPerPallet").value;
      console.log(modelItemNam);
      this.setState({
        modalItemQty:val,
        modalItemName:modelItemNam,
        modalItemUnitSize:modelItemCase,
        modalItemDescription:modelItemDesc,
        modalItemFullPrice:modelItemFullPrice,
        modalItemPackSize:modelItemPack,
        modalItemCasesPerPallet:modelItemCasesPerPallet,
        modalItemID:modelItemID,
      });
    }
    
    this.setState({errormessage: err});
    this.setState({[nam]: val});
  }
    render(){
      const goBack = () => {
        this.props.history.goBack()
    }
        
        const { ExportCSVButton } = CSVExport;
        const {customers, tblColumns, loading} = this.state;
        const containerStyle={
          maxWidth:1200 +"px",
        };
        const { SearchBar, ClearSearchButton } = Search;
        
        if(customers == "undefined")
        {
          return (<div><h1>Loading Customer Data Please Wait...</h1></div>)
        }
        return (
          <AuthUserContext.Consumer>
            {authUser=>(
            <div className="content">
                                              <div className="modal fade" id="basicModal" tabIndex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true">
                                  <div className="modal-dialog">
                                    <div className="modal-content">
                                      <div className="modal-header">
                                        <h4 className="modal-title" id="myModalLabel">Add Item to Quote</h4>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                          <span aria-hidden="true">&times;</span>
                                        </button>
                                      </div>
                                      <div className="modal-body">
                                        <table>
                                        <tr>
                                            <td></td>
                                            <td><input type="hidden" name="modalItemID" id="modalItemID" value={this.state.modalItemID}></input></td>
                                          </tr>
                                          <tr>
                                            <td><strong>Item Name:</strong></td>
                                            <td><input type="text" name="modalItemName" id="modalItemName" value={this.state.modalItemName} disabled={this.state.downIncrementBtn} onChange={this.myChangeHandler}></input></td>
                                          </tr>
                                          <tr>
                                            <td><strong>Item Description:</strong></td>
                                            <td><input type="text" name="modalItemDescription" id="modalItemDescription" value={this.state.modalItemDescription}  disabled onChange={this.myChangeHandler}></input></td>
                                          </tr>
                                          <tr>
                                            <td><strong>Unit Size:</strong></td>
                                            <td><input type="text" name="modalItemUnitSize" id="modalItemUnitSize" value={this.state.modalItemUnitSize} disabled onChange={this.myChangeHandler}></input></td>
                                          </tr>
                                          <tr>
                                            <td><strong>Pack Size:</strong></td>
                                            <td><input type="text" name="modalItemPackSize" id="modalItemPackSize" value={this.state.modalItemPackSize} disabled onChange={this.myChangeHandler}></input></td>
                                          </tr>
                                          <tr>
                                            <td><strong>Cases per Pallet:</strong></td>
                                            <td><input type="text" name="modalItemCasesPerPallet" id="modalItemCasesPerPallet" value={this.state.modalItemCaseSize} disabled onChange={this.myChangeHandler}></input></td>
                                          </tr>
                                          <tr>
                                            <td><strong>Price:</strong></td>
                                            <td><input type="text" name="modalItemFullPrice" id="modalItemFullPrice" value={this.state.modalItemFullPrice} disabled onChange={this.myChangeHandler}></input></td>
                                          </tr>
                                          <tr>
                                            <td><strong>FOB Location:</strong></td>
                                            <td><input type="text" name="modalItemFOBLocation" id="modalItemFOBLocation" value={this.state.modalItemFOBLocation} disabled onChange={this.myChangeHandler}></input></td>
                                          </tr>
                                          <tr>
                                            <td><strong>Quantity Needed:</strong></td>
                                            <td>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                  <button class="btn btn-danger" type="button" id="button-addon1" onClick={e=>this.downIncrement(e)} disabled={this.state.modalItemQty == 0}><FontAwesomeIcon icon={faMinus}></FontAwesomeIcon></button>
                                                </div>
                                                <input type="text" class="form-control" name="modalItemQty" id="modalItemQty" value={this.state.modalItemQty} onChange={this.myChangeHandler}/>
                                                <div class="input-group-append">
                                                  <button class="btn btn-success" type="button" id="button-addon2" onClick={e=>this.upIncrement(e)}><FontAwesomeIcon icon={faPlus}></FontAwesomeIcon></button>
                                                </div>
                                              </div>
                                            </td>
                                            </tr>
                                        </table>
                                        
                                      </div>
                                      <div className="modal-footer" style={{borderTop:1+"px #000"}}>
                                        <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={e=>this.closeModal()}>Close</button>
                                        <button type="button" className="btn btn-primary" onClick={e=>this.addItemToQuote()} disabled={this.state.modalItemQty == 0}>Add to Cart</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                  <Modal show={this.state.showModal} handleClose={e => this.closeModal(e)}>
                                    <div class="modal-content">
                                      <div class="modal-header">
                                        <h5 class="modal-title">Add Item to Quote</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={e=>this.closeModal()}>
                                          <span aria-hidden="true">&times;</span>
                                        </button>
                                      </div>
                                      <div class="modal-body">
                                        <label htmlFor="unit">Item Name:</label>
                                        <span>{this.state.modalItemName}</span>
                                      </div>
                                      <div class="modal-footer">
                                        <button type="button" class="btn btn-success" onClick={e => this.addItemToQuote(e)}>Add Item</button>
                                        <button type="button" class="btn btn-danger" data-dismiss="modal" onClick={e=>this.closeUnitModal()}>Close</button>
                                      </div>
                                    </div>
                                </Modal>
                                <Modal show={this.state.showQuoteModal} handleClose={e => this.closeQuoteModal(e)}>
                                    <div class="modal-content">
                                      <div class="modal-header">
                                        <h5 class="modal-title">Building a Quote?</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={e=>this.closeQuoteModal()}>
                                          <span aria-hidden="true">&times;</span>
                                        </button>
                                      </div>
                                      <div class="modal-body">
                                        Use the buttons to select your next action...
                                      </div>
                                      <div class="modal-footer">
                                        <Link class="btn btn-warning" to={{pathname: `${ROUTES.ORDER}`}}>Inventory</Link>
                                        <Link class="btn btn-primary" to={{pathname: `${ROUTES.QUOTEQA}`}}>Customer</Link>
                                        <button type="button" class="btn btn-danger" onClick={e => this.clearCart(e)}>Clear Items</button>
                                        <button type="button" class="btn btn-info" data-dismiss="modal" onClick={e=>this.closeQuoteModal()}>Continue</button>
                                      </div>
                                    </div>
                                </Modal>
               <nav class="navbar navbar-expand-lg navbar-light bg-red">
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarNav">
                        <ul class="navbar-nav mr-auto">
                        <li className="nav-item">
                            <button className="btn btn-outline-light" style={{marginTop:"25px"}} onClick={goBack}>Back</button>
                        </li>
                        <li className="nav-item">
                        <a className="nav-link-menu" href="../home">Home</a>
                        </li>
                        <li class="nav-item">
                        <a className="nav-link-menu" href="../suppliersearch">Supplier Search</a>
                        </li>
                        <li class="nav-item">
                        <a className="nav-link-menu" href="../signout">Sign Out</a>
                        </li>
                        
                        </ul>
                        <ul class="navbar-nav">
                        
                        <li class="nav-item">
                        {authUser.role === "Admin" && 
                          this.renderCart()
                        }
                           {authUser.role === "Sales" && 
                          this.renderCart()
                        }
                        </li>
                        </ul>
                    </div>
                    </nav>
              {/*<div className="row">
              <div className="col-md-2">
                <Link to={{pathname: `${ROUTES.ADMIN}`}}><button className="btn btn-warning" style={{marginTop:30+"px"}}><FontAwesomeIcon icon={faHome}></FontAwesomeIcon></button></Link>
                </div>
                <div className="col-md-10">

                </div>
                
        </div>*/}
              {loading && <h1>Loading State...</h1>}
                {/*<div className="row">
                  <div className="col-md-10">
                <SearchBar onSubmit={this.onSearchSubmit}/>
                </div>
                <div className="col-md-2">
                <Link to={{pathname: `${ROUTES.ADD_COMPANY}`}}><button className="btn btn-info" style={{marginTop:30+"px"}}>Add Customer</button></Link>
                </div></div>*/}
                <div className="container-lg">
                <div className="row" style={{marginTop:10+"px"}}><div className="col-md-3"> <h3>Search Items</h3></div><div className="col-md-2"><Link to={{pathname: `${ROUTES.ADD_COMPANY}`}}><button className="btn btn-danger" >Add Supplier</button></Link></div></div>
                <div className="clear-filters" style={{float:"left",marginRight:10+"px"}}>
                            <button type="button" className="btn btn-outline-danger right" onClick={e => this.clearSearchFilters()}><FontAwesomeIcon icon={faRedo}></FontAwesomeIcon> Clear Search/Filters</button>
                            </div>
                            <table id="example" className="display">
                                      <thead></thead>
                                      <tbody></tbody>
                                      <tfoot>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                      </tfoot>
                                  </table>
                {/*<ToolkitProvider
                  keyField="cid"
                  data={ customers }
                  columns={ tblColumns }
                  search
                >
                  {
                    props => (
                      <div>
                        <div className="row"><div className="col-md-3"> <h3>Search Items:</h3></div></div>
                        <div className="row">
                        <div className="col-md-2"><SearchBar { ...props.searchProps } /></div>
                          <div className="col-md-1"><ClearSearchButton { ...props.searchProps } className="btn btn-light" /></div>
                        
                        <div className="col-md-2"><Link to={{pathname: `${ROUTES.ADD_COMPANY}`}}><button className="btn btn-primary" >Add Supplier</button></Link></div>
                        </div>
                        <div className="row" style={{alignItems:'center',marginTop:10+"px"}}><div className="col-md-2">Found Items: {this.state.customers.length}</div><div className="col-md-9"></div><div className="col-md-1"><ExportCSVButton { ...props.csvProps } className="btn-sm btn-info">Export</ExportCSVButton></div></div>
    
                        <hr />
                        <BootstrapTable
                          { ...props.baseProps}
                          noDataIndication="No Company Meets Search Requirements"
                          pagination={paginationFactory()}
                          filter={ filterFactory() }
                        />
                      </div>
                    )
                  }
                </ToolkitProvider>*/}
                    </div>
            </div>
            )}
            </AuthUserContext.Consumer>
            );
    }
    
}
const condition = authUser => !!authUser;

const SearchPageForm = compose(
  withAuthorization(condition),
  withFirebase,
)(SearchPage);

export default withFirebase(SearchPage);

export {SearchPageForm};


/*function customMatchFunc({
  searchText,
  value,
  column,
  row
}) {
  //console.log("Search Function: " + searchText);
  if (typeof value !== 'undefined') {
    if(searchText.indexOf(' ') >= 0)
    {
      console.log("Space!");
    }
    return value.contains(searchText);
  }
  return false;
}*/