//LIST OF ALL ORDERS
import React,{Component} from 'react';
import SearchBar from '../Search/SearchBar';
import SearchTable from '../Search/SearchTable';
import { Switch,Route, Link,withRouter } from 'react-router-dom';

import * as ROUTES from '../../constants/routes';
import { compose } from 'recompose';
import moment from 'moment';
import { withAuthorization } from '../Session';
import {withFirebase} from '../Firebase';
import firebase from 'firebase';
import { AuthUserContext } from '../Session';
import { faRedo,faEdit,faPlusCircle,faHome} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faUser,faUserCircle,faLock,faSearch,faShoppingBasket,faPlus,faMinus} from '@fortawesome/free-solid-svg-icons';

import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import filterFactory,{textFilter,selectFilter} from 'react-bootstrap-table2-filter';
import { ThemeProvider } from 'styled-components';
import Modal from '../Modal';


class SearchOrderPage extends Component{ 

  detailsFormatter(cell, row) {
    return (
      <Link to={{pathname: `/company/`+row.cid}}>Details</Link>
    );
  }
  constructor(props) {
    super(props);

    const staffOptions = {'Josh S': 'Josh S', 'Beth Sharratt':'Beth Sharratt', 'Annie Streufert':'Annie Streufert','Justin Guerin':'Justin Guerin'};
        const stateOptions = {'AL':'AL','AK':'AK','AS':'AS','AZ':'AZ','AR':'AR','CA':'CA','CO':'CO','CT':'CT','DE':'DE','DC':'DC','FL':'FL','GA':'GA','GU':'GU','HI':'HI','ID':'ID','IL':'IL','IN':'IN','IA':'IA','KS':'KS','KY':'KY','LA':'LA','ME':'ME','MD':'MD','MA':'MA','MI':'MI','MN':'MN','MS':'MS','MO':'MO','MT':'MT','NE':'NE','NV':'NV','NH':'NH','NJ':'NJ','NM':'NM','NY':'NY','NC':'NC','ND':'ND','MP':'MP','OH':'OH','OK':'OK','OR':'OR','PA':'PA','PR':'PR','RI':'RI','SC':'SC','SD':'SD','TN':'TN','TX':'TX','UT':'UT','VT':'VT','VA':'VA','VI':'VI','WA':'WA','WV':'WV','WI':'WI','WY':'WY'};
        const entityTypeOptions ={'Admin': 'Admin', 'Customer':'Customer','Lead':'Lead', 'Logistics':'Logistics','Prospect':'Prospect','Supplier':'Supplier',"Warehouse":"Warehouse"};

    this.state = { 
      term:'',
      customers: [],
      orders:[],
      tblColumns:[
        {
            data: "orderID",
            title: "Order ID",
            sort: false,
            visible:false
          },
          {
            data: "purchaseOrderNumber",
            title: "PO Number",
            sort: false,
            visible:true
          },
        {
          title: "Order Link",
          render: function (data,type,row){
            //if(row.status == "Complete")
            //{
              //return `<a href="/orderexport/${row.oid}"  target='_blank'>Order Details</a>
              //</div>`;
            //}
            //else{
              return `<a href="/inventory/order/${row.oid}"  target='_blank'>Order Details</a>
              </div>`;
            //}

          },
          sort: false,
          visible:true
        },
        {
          data: "status",
          title: "Order Status",
          sort: false,
          visible:true
        },
        {
          data: "orderDateFormat",
          title: "Order Date",
          sort:false,
        },
        {
          title:"",
          sort:false, 
          render: function (data,type,row){
            if(row.status == "Complete")
            {
              return `<div class="btn-group"><a href="/orderexport/${row.oid}" class='btn btn-info' target='_blank'>View</a>
              </div>`;
            }
            else{
              return `<div class="btn-group"><a href="/inventory/order/${row.oid}" class='btn btn-info' style='height:38px' target='_blank'>View</a><button type="button" class="delBtn btn btn-danger">Delete</button>
              </div>`;
            }

          }
        }    
    ],
      loading:true,
      modalOrderID:'',
      modalOrderDate:'',
      showDeleteModal:false,
    };
  }

  handleClick(event){
    alert("FOO");
    }

  componentDidMount (){
      this.setState({loading:true});

      this.unsubscribe = this.props.firebase
        .inventoryorders()
        .onSnapshot(snapshot => {
   
          let orders=[];

          snapshot.forEach(doc =>
            orders.push({ ...doc.data(),purchaseOrderNumber:doc.data().purchaseOrderNumber,status:doc.data().status,warehouse:doc.data().warehouse,orderLink:'<a href="/inventory/order/'+doc.id+'">Order Details</a>',orderDateFormat:doc.data().orderDate.toDate().toLocaleDateString(),timestamp:new Date(doc.data().orderDate.seconds * 1000).toDateString() + "-" +new Date(doc.data().orderDate.seconds * 1000).toTimeString(), orderID: doc.id.slice(-6).toUpperCase(),oid: doc.id }),
          );
            console.log("Inventory Orders: " + orders.length.toString());
            //console.log(orders);
            this.setState({orders,loading:false}, function(){console.log("Orders List Set State Complete");});


            console.log("setting up table");
            $('#example').DataTable({
              destroy: true,
              order:[[4,'desc']],
              data: orders,
              columns: this.state.tblColumns,
              pageLength: 10,
              columnDefs:[{targets:-1,data:null,defaultContent:'<button class="btn btn-sm btn-success">View</button>'}],
              //filter: true,
              //deferRender: true,
              initComplete: function () {
                this.api().columns([3]).every( function () {
                    var column = this;
                    var select = $('<select class="form-control filterCtrl"><option value="">...</option></select>')
                        .appendTo( $(column.footer()).empty() )
                        .on( 'change', function () {
                            var val = $.fn.dataTable.util.escapeRegex(
                                $(this).val()
                            );
     
                            column
                                .search( val ? '^'+val+'$' : '', true, false )
                                .draw();
                        } );
     
                    column.data().unique().sort().each( function ( d, j ) {
                        select.append( '<option value="'+d+'">'+d+'</option>' )
                    } );
                } );

                $('#example tfoot tr').appendTo('#example thead');
            }
            });

            $('.clear-filters').prependTo('#example_length');

        });

        var self = this;
        $('#example tbody').on( 'click','.delBtn', function () {
          //alert("Delete Item?");
          var data = $('#example').DataTable().row( $(this).parents('tr') ).data();
          console.log(data.oid + " " + data.orderLink + " "+data.orderDate);
          //alert( "Feature Coming Soon");
          //$('#modalQuoteFor').text(data.contactLink);
          self.setModalState(data.orderLink,data.oid,data.orderDate);
          //$("#modalQuoteFor").val(data.contactLink);
          $("#modelOrderDate").text(new Date(data.orderDate.seconds *1000).toDateString());
          $("#modalQuoteID").text(data.oid);
          $("#deleteModal").modal('show');
      } );

    }

    setModalState(qDate,oid)
    {

      //var coName = link.substring(link.indexOf(">")+1, link.lastIndexOf("<"));

      this.setState({
        modalorderDate:qDate,
        modalOrderID:oid,
        showDeleteModal:true
      })
    }

    
    clearSearchFilters(){
      $('#example').DataTable().search( '' ).columns().search( '' ).draw();
      $('tfoot input').val('');

      $('.filterCtrl').each(function(){
        $(this).val('');
      });

      
    }

    componentWillReceiveProps(newProps) {
      
    }

    openModal() {
      this.setState({ showModal: true });
    }
  
    closeDeleteModal(){
      $("#deleteModal").modal("hide");
    }

    closeModal() {
      this.setState({
        modalItemID:'',
        modalItemName:'',
        modalItemDescription:'',
        modalItemPackSize:'',
        modalItemUnitSize:'',
        modalItemFullPrice:'',
        modalItemCasesPerPallet:'',
        modalItemQty:'0',
        showModal: false
      });

      $("#basicModal").modal("hide");
  }

  showModalFunction(itemName){
    this.setState({
      modalItemName:itemName,
      showModal: true })
  }

  deleteQuote(quoteid)
  {
    console.log('Deleting Quote ID: '+quoteid+" "+this.state.modalOrderID);
    this.props.firebase.db.collection('inventoryorders').doc(quoteid).delete();
    //window.location.reload();
    this.setState({modalorderDate:'',
      modalOrderID:'',
      showDeleteModal:false});

      $("#deleteModal").modal("hide");
  }

  addItemToQuote=()=>{
    if(localStorage.getItem('newquote') != null)
    {
      var quoteInfo = JSON.parse(localStorage.getItem('newquote'));
      quoteInfo['items'].push({'id':this.state.modalItemID,'title':this.state.modalItemName,"description":this.state.modalItemDescription,"unitSize":this.state.modalItemUnitSize,"price":this.state.modalItemFullPrice,"casesperpallet":this.state.modalItemCasesPerPallet,"packsize":this.state.modalItemPackSize,"qty":this.state.modalItemQty,"mutype":"","muvalue":"00","freightcost":"00.00","fullprice":"00.00","frominventory":false,"inventoryhold":false});
      let val = this.state.cartItems;
      this.setState({cartItems: ++val});
      localStorage.setItem('newquote', JSON.stringify(quoteInfo));

    }
    else{
          let user = firebase.auth().currentUser;
          //console.log(user.uid);
          var dateToday = new Date();
          var quoteTitle = dateToday.getFullYear().toString()+"_"+(dateToday.getMonth() + 1).toString()+"_"+dateToday.getDate().toString()+"_"+dateToday.getHours().toString()+"_"+dateToday.getMinutes().toString()+"_"+dateToday.getSeconds().toString()+"_"+user.uid;
          var quoteObject = {'user':user.uid,'quoteID':quoteTitle,'dateTimeStarted':dateToday,'items':[{'id':this.state.modalItemID,'title':this.state.modalItemName,"description":this.state.modalItemDescription,"unitSize":this.state.modalItemUnitSize,"price":this.state.modalItemFullPrice,"casesperpallet":this.state.modalItemCasesPerPallet,"packsize":this.state.modalItemPackSize,"qty":this.state.modalItemQty,"mutype":"","muvalue":"00","freightcost":"00.00","fullprice":"00.00","frominventory":false,"inventoryhold":false}]};
          this.setState({cartItems: 1});
          localStorage.setItem('newquote', JSON.stringify(quoteObject));
        }
    this.closeModal();
}
downIncrement=(evt)=>{
  var val = this.state.modalItemQty;
  if(val > 0)
  {
    var newVal = --this.state.modalItemQty;
    this.setState({modalItemQty:newVal});
    console.log(newVal);
  }
}

upIncrement=(evt)=>{
  var newVal = ++this.state.modalItemQty;
  const modelItemID = document.getElementById("modalItemID").value;
  const modelItemNam = document.getElementById("modalItemName").value;
  const modelItemDesc = document.getElementById("modalItemDescription").value;
  const modelItemFullPrice = document.getElementById("modalItemFullPrice").value;
  const modelItemCase = document.getElementById("modalItemUnitSize").value;
  const modelItemPack = document.getElementById("modalItemPackSize").value;
  const modelItemCasesPerPallet = document.getElementById("modalItemCasesPerPallet").value;
  //console.log(modelItemNam);
  this.setState({
    modalItemQty:newVal,
    modalItemName:modelItemNam,
    modalItemUnitSize:modelItemCase,
    modalItemDescription:modelItemDesc,
    modalItemFullPrice:modelItemFullPrice,
    modalItemPackSize:modelItemPack,
    modalItemCasesPerPallet:modelItemCasesPerPallet,
    modalItemID:modelItemID,
  });
  console.log(newVal);
}
    onColumnMatch(
      searchText,
      value,
      column,
      row
    ){
      // implement your custom match logic on every cell value
      console.log(searchText + " " +value);
    }

    addContactHandler(event){
      this.context.router.push('/addcompany');
    }
    renderCart(){
      if(this.state.cartItems > 0)
      {
        return <a className="nav-link-menu-cart" href="../quote"><FontAwesomeIcon style={{margin:10+"px"}} icon={faShoppingBasket}></FontAwesomeIcon>{this.state.cartItems}</a>;
      }
      else
      {
        return <a className="nav-link-menu-cart" href="../quote"><FontAwesomeIcon style={{margin:10+"px"}} icon={faShoppingBasket}></FontAwesomeIcon></a>;
      }
  }

  myChangeHandler = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    let err = '';
    //alert(nam+"-"+val);
    if(nam=="isActive" && this.state.isActive == true)
    {
      //this.setState({isActive:false});
      val = false;
      //document.getElementById('isActive').removeAttr('checked');
    }
    else if(nam=="isActive" && this.state.isActive == false){
      val=true;
    }

    if(nam=="confirmPrice" && this.state.confirmPrice == true)
    {
      //this.setState({isActive:false});
      val = false;
      //document.getElementById('isActive').removeAttr('checked');
    }
    else if(nam=="confirmPrice" && this.state.confirmPrice == false){
      val=true;
    }
    
    this.setState({errormessage: err});
    this.setState({[nam]: val});
  }
    render(){
      const goBack = () => {
        this.props.history.goBack()
    }
        const { ExportCSVButton } = CSVExport;
        const {customers, tblColumns, loading} = this.state;
        const containerStyle={
          maxWidth:1200 +"px",
        };
        const { SearchBar, ClearSearchButton } = Search;
        
        if(customers == "undefined")
        {
          return (<div><h1>Loading Customer Data Please Wait...</h1></div>)
        }
        return (
          
            <div className="content">
              <div className="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-labelledby="deleteModal" aria-hidden="true">
                                  <div className="modal-dialog">
                                    <div className="modal-content">
                                      <div className="modal-header">
                                        <h4 className="modal-title" id="myModalLabel">Delete Order</h4>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                          <span aria-hidden="true">&times;</span>
                                        </button>
                                      </div>
                                      <div className="modal-body">
                                        <table>
                                          <tr>
                                            <td><strong>Quote Date:</strong></td>
                                            <td><label id="modelOrderDate">{this.state.modalQuoteDate}</label></td>
                                            <td></td>
                                          </tr>
                                          <tr>
                                            <td><strong>Quote ID:</strong></td>
                                            <td><label id="modalOrderID">{this.state.modalOrderID}</label></td>
                                            <td></td>
                                          </tr>
                                        </table>
                                        
                                      </div>
                                      <div className="modal-footer" style={{borderTop:1+"px #000"}}>
                                        <button type="button" className="btn btn-info" data-dismiss="modal" onClick={e=>this.closeDeleteModal()}>Close</button>
                                        <button type="button" className="btn btn-primary" onClick={e=>this.deleteQuote(this.state.modalOrderID)}>Delete Quote</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                             <div className="modal fade" id="basicModal" tabindex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true">
                                  <div className="modal-dialog">
                                    <div className="modal-content">
                                      <div className="modal-header">
                                        <h4 className="modal-title" id="myModalLabel">Add Item to Order</h4>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                          <span aria-hidden="true">&times;</span>
                                        </button>
                                      </div>
                                      <div className="modal-body">
                                        <table>
                                        <tr>
                                            <td></td>
                                            <td><input type="hidden" name="modalItemID" id="modalItemID" value={this.state.modalItemID}></input></td>
                                          </tr>
                                          <tr>
                                            <td><strong>Item Name:</strong></td>
                                            <td><input type="text" name="modalItemName" id="modalItemName" value={this.state.modalItemName} disabled={this.state.downIncrementBtn} onChange={this.myChangeHandler}></input></td>
                                          </tr>
                                          <tr>
                                            <td><strong>Item Description:</strong></td>
                                            <td><input type="text" name="modalItemDescription" id="modalItemDescription" value={this.state.modalItemDescription}  disabled onChange={this.myChangeHandler}></input></td>
                                          </tr>
                                          <tr>
                                            <td><strong>Unit Size:</strong></td>
                                            <td><input type="text" name="modalItemUnitSize" id="modalItemUnitSize" value={this.state.modalItemUnitSize} disabled onChange={this.myChangeHandler}></input></td>
                                          </tr>
                                          <tr>
                                            <td><strong>Pack Size:</strong></td>
                                            <td><input type="text" name="modalItemPackSize" id="modalItemPackSize" value={this.state.modalItemPackSize} disabled onChange={this.myChangeHandler}></input></td>
                                          </tr>
                                          <tr>
                                            <td><strong>Cases per Pallet:</strong></td>
                                            <td><input type="text" name="modalItemCasesPerPallet" id="modalItemCasesPerPallet" value={this.state.modalItemCaseSize} disabled onChange={this.myChangeHandler}></input></td>
                                          </tr>
                                          <tr>
                                            <td><strong>Price:</strong></td>
                                            <td><input type="text" name="modalItemFullPrice" id="modalItemFullPrice" value={this.state.modalItemFullPrice} disabled onChange={this.myChangeHandler}></input></td>
                                          </tr>
                                          <tr>
                                            <td><strong>Quantity Needed:</strong></td>
                                            <td>
                                            <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                  <button className="btn btn-danger" type="button" id="button-addon1" onClick={e=>this.downIncrement(e)} disabled={this.state.modalItemQty == 0}><FontAwesomeIcon icon={faMinus}></FontAwesomeIcon></button>
                                                </div>
                                                <input type="text" className="form-control" name="modalItemQty" id="modalItemQty" value={this.state.modalItemQty} onChange={this.myChangeHandler}/>
                                                <div className="input-group-append">
                                                  <button className="btn btn-success" type="button" id="button-addon2" onClick={e=>this.upIncrement(e)}><FontAwesomeIcon icon={faPlus}></FontAwesomeIcon></button>
                                                </div>
                                              </div>
                                            </td>
                                            </tr>
                                        </table>
                                        
                                      </div>
                                      <div className="modal-footer" style={{borderTop:1+"px #000"}}>
                                        <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={e=>this.closeModal()}>Close</button>
                                        <button type="button" className="btn btn-primary" onClick={e=>this.addItemToQuote()} disabled={this.state.modalItemQty == 0}>Add to Cart</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                  <Modal show={this.state.showModal} handleClose={e => this.closeModal(e)}>
                                    <div className="modal-content">
                                      <div className="modal-header">
                                        <h5 className="modal-title">Add Item to Quote</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e=>this.closeModal()}>
                                          <span aria-hidden="true">&times;</span>
                                        </button>
                                      </div>
                                      <div className="modal-body">
                                        <label htmlFor="unit">Item Name:</label>
                                        <span>{this.state.modalItemName}</span>
                                      </div>
                                      <div className="modal-footer">
                                        <button type="button" className="btn btn-success" onClick={e => this.addItemToQuote(e)}>Add Item</button>
                                        <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={e=>this.closeUnitModal()}>Close</button>
                                      </div>
                                    </div>
                                </Modal>
               <nav className="navbar navbar-expand-lg navbar-light bg-yellow">
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav mr-auto">
                        <li className="nav-item">
                        <a className="nav-link-menu" href="../home">Home</a>
                        </li>
                        <li className="nav-item">
                        <a className="nav-link-menu" href="../itemsearch">Item Search</a>
                        </li>
                        <li className="nav-item">
                        <a className="nav-link-menu" href="../signout">Sign Out</a>
                        </li>
                        
                        </ul>
                        <ul className="navbar-nav">
                        
                        <li className="nav-item">
                          {this.renderCart()}
                        </li>
                        </ul>
                    </div>
                    </nav>
              {/*<div className="row">
              <div className="col-md-2">
                <Link to={{pathname: `${ROUTES.ADMIN}`}}><button className="btn btn-warning" style={{marginTop:30+"px"}}><FontAwesomeIcon icon={faHome}></FontAwesomeIcon></button></Link>
                </div>
                <div className="col-md-10">

                </div>
                
        </div>*/}
              {loading && <h1>Loading State...</h1>}
                {/*<div className="row">
                  <div className="col-md-10">
                <SearchBar onSubmit={this.onSearchSubmit}/>
                </div>
                <div className="col-md-2">
                <Link to={{pathname: `${ROUTES.ADD_COMPANY}`}}><button className="btn btn-info" style={{marginTop:30+"px"}}>Add Customer</button></Link>
                </div></div>*/}
                <div className="container-lg">
                <div className="row" style={{marginTop:10+"px"}}><div className="col-md-3"> <h3>Search Items</h3></div><div className="col-md-2"><Link to={{pathname: `${ROUTES.ORDER}`}}><button className="btn btn-warning" >Create Order</button></Link></div></div>
                <div className="clear-filters" style={{float:"left",marginRight:10+"px"}}>
                            <button type="button" className="btn btn-outline-warning right" onClick={e => this.clearSearchFilters()}><FontAwesomeIcon icon={faRedo}></FontAwesomeIcon> Clear Search/Filters</button>
                            </div>
                            <table id="example" className="display">
                                      <thead></thead>
                                      <tbody></tbody>
                                      <tfoot>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                      </tfoot>
                                  </table>
                {/*<ToolkitProvider
                  keyField="cid"
                  data={ customers }
                  columns={ tblColumns }
                  search
                >
                  {
                    props => (
                      <div>
                        <div className="row"><div className="col-md-3"> <h3>Search Items:</h3></div></div>
                        <div className="row">
                        <div className="col-md-2"><SearchBar { ...props.searchProps } /></div>
                          <div className="col-md-1"><ClearSearchButton { ...props.searchProps } className="btn btn-light" /></div>
                        
                        <div className="col-md-2"><Link to={{pathname: `${ROUTES.ADD_COMPANY}`}}><button className="btn btn-primary" >Add Supplier</button></Link></div>
                        </div>
                        <div className="row" style={{alignItems:'center',marginTop:10+"px"}}><div className="col-md-2">Found Items: {this.state.customers.length}</div><div className="col-md-9"></div><div className="col-md-1"><ExportCSVButton { ...props.csvProps } className="btn-sm btn-info">Export</ExportCSVButton></div></div>
    
                        <hr />
                        <BootstrapTable
                          { ...props.baseProps}
                          noDataIndication="No Company Meets Search Requirements"
                          pagination={paginationFactory()}
                          filter={ filterFactory() }
                        />
                      </div>
                    )
                  }
                </ToolkitProvider>*/}
                    </div>
            </div>
            );
    }
}
const condition = authUser => !!authUser;

const SearchOrderPageForm = compose(
  withAuthorization(condition),
  withFirebase,
)(SearchOrderPage);

export default withFirebase(SearchOrderPage);

export {SearchOrderPageForm};
