export const LANDING = '/';
export const SIGN_IN = '/signin';
export const SIGN_OUT= '/signout';
export const HOME = '/admin';
export const ADMIN = '/home';
export const SEARCH = '/search';
export const PASSWORD_FORGOT = '/forgot';
export const USERS = '/users';
export const USER_DETAILS = '/users/:uid';
export const ADD_USER = '/adduser';
export const EDIT_USER = '/edituser/:uid';
export const ADD_COMPANY = '/addcompany';
export const COMPANY_DETAILS = '/company/:cid';
export const ADD_CONTACT = '/addcontact/:cid';
export const EDIT_CONTACT = '/editcontact/:contactid';
export const ADD_NOTE = '/addnote/:cid';
export const NOTE_DETAILS = '/note/:nid';
export const NOTES = '/notes';
export const ALLNOTES = '/allnotes/:cid';
export const EDITEXAMPLE = '/company/:cid/edit';
export const EDITLOCATION = '/editoffice/:lid';
export const COMPANYITEMS = '/items/:cid';
export const ITEMSEARCH = '/itemsearch';
export const ADD_ITEM = '/additem/:cid';
export const EDIT_ITEM = '/:cid/item/:itemid';
export const VIEW_ITEM = '/item/:itemid';
export const SUPPLIER_SEARCH = '/suppliersearch';
export const DOMAINS = '/siteconfig';
export const WAREHOUSE = '/inventory';
export const WAREHOUSEMGR = '/warehouses';
export const PRICING = '/pricing';
export const CART = '/cart';
export const REPORT = '/reports';
export const QUOTE = '/quote';
export const ALLQUOTES = '/quotes';
export const EDIT_QUOTE = '/quote/:qid';
export const EDIT_QUOTE_RD = "/quote/r/:qid"
export const VIEW_QUOTE = '/export/:qid/:tid';
export const ADD_INVENTORY_ITEM = '/addinventoryitem/:cid';
export const EDIT_INVENTORY_ITEM = '/:cid/inventoryitem/:itemid';
export const ORDER = '/inventory/order';
export const EDIT_ORDER = '/inventory/order/:oid';
export const ORDER_EXPORT = '/orderexport/:oid';
export const ALLORDERS = "/orders";
export const QUOTE_ADMIN_VALUE = '/quote/breakdown/:qid';
export const ORDER_ADMIN_VALUE = '/order/breakdown/:oid';
export const ABOUT = '/about';
export const QUOTEQA = '/quoteqa';
export const ALTHOME = '/althome';
